<form [formGroup]="personalDetails" id="msform" >
    <!-- progressbar -->
    <ul id="progressbar" style="text-align: center;">
       <li [ngClass]="step>=0 ? 'active ' : 'inactive'">Company Type</li>
        <li [ngClass]="step>=1 ? 'active ' : 'inactive'">Company Details</li> 
        <li [ngClass]="step>=2 ? 'active' : 'inactive'">Declarant Details</li>
        <li [ngClass]="step>=3 ? 'active' : 'inactive'">Add member </li>
        <li [ngClass]="step==4 ? 'active' : 'inactive'">Add Attachments </li>
        
    </ul>


    <!------------------------------------------------------------------>

    


    
    <!-- fieldsets -->
    <fieldset  *ngIf="step == 0" >
        <h2 class="fs-title">Company Type</h2>

       
       

    

    <section class="block relative z-1 " >

      
        <div class="justify-center flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4 ">
            <div class="flex flex-wrap ">
              

              <div  class="w-full lg:w-4/12 px-4 ">
               
                <div class="hover:-mt-4 relative flex flex-col min-w-0 break-words  w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150" style=" border: 2px solid rgb(87, 83, 83); 
              ;display: flex;" >

<br>
<br>

               
                <a (click)=" typeSelected('Company account')" class="relative flex flex-col">
                  <div
                    class="justify-center flex flex-wrap"
                  >
                    <img
                      alt="..."
                      style="width: 50%;height: 50%;"
                      src="assets/images/icons/comp.png"
                      
                    />
                    
                  </div>

                  
                </a>


                <div  style="display: flex; justify-content: center;">
                  <h2 style="font-size: 110%;color: #615f5f; text-align: center;">Company account</h2>

                </div>
                <div style="display: flex; justify-content: center;">
                  <input (click)="typeSelected('Company account')" type="button"  class="next action-button" value="Start"/>
                </div>

                <br>
<br>
              </div>
              
              </div>

    
              
              <div  class="w-full lg:w-4/12 px-4">
              <div class="hover:-mt-4 relative flex flex-col min-w-0 break-words  w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150" style=" border: 2px solid rgb(87, 83, 83); /* Définit une bordure de 2 pixels avec une couleur noire */
              padding: 10px;" >
              <br>
              <br>
               
                <a (click)=" typeSelected('Office account')" class="relative flex flex-col">
                  <div
                  style="display: flex; justify-content: center;"
                  >
                    <img
                      alt="..."
                      style="width: 50%;height: 50%;"
                      src="assets/images/icons/employ.png"
                      

                    />
                    
                  </div>

                  
                </a>
                <div  style="display: flex; justify-content: center;">
                  <h2 style="font-size: 110%;color: #615f5f; text-align: center;">Office account <br>     </h2>

                </div>
                <div style="display: flex; justify-content: center;">
                  <input (click)="typeSelected('Office account')" type="button"  class="next action-button" value="Start"/>
                </div>
                <br>
                <br>
              </div>
              </div>

              <div  class="w-full lg:w-4/12 px-4">
              <div class="hover:-mt-4 relative flex flex-col min-w-0 break-words  w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150" style=" border: 2px solid rgb(87, 83, 83); /* Définit une bordure de 2 pixels avec une couleur noire */
              padding: 10px;">
              <br>
              <br>
               
                <a (click)=" typeSelected('Public Broker')" class="relative flex flex-col">
                  <div
                  style="display: flex; justify-content: center;"
                  >
                    <img
                      alt="..."
                      style="width: 50%;height: 50%;"
                      src="assets/images/icons/broker.png"
                     

                    />
                    
                  </div>

                  
                </a>
                <div  style="display: flex; justify-content: center;">
                  <h2 style="font-size: 110%;color: #615f5f; text-align: center;">Public Broker</h2>

                </div>
                <div style="display: flex; justify-content: center;">
                  <input (click)="typeSelected('Public Broker')" type="button"  class="next action-button" value="Start"/>
                </div>

                <br>
<br>
              </div>
              </div>


              <div  class="w-full lg:w-4/12 px-4">
              <div class="hover:-mt-4 relative flex flex-col min-w-0 break-words  w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150" style=" border: 2px solid rgb(87, 83, 83); /* Définit une bordure de 2 pixels avec une couleur noire */
              padding: 10px;">
              <br>
              <br>
               
                <a (click)=" typeSelected('Container Terminal')" class="relative flex flex-col">
                  <div
                  style="display: flex; justify-content: center;"
                  >
                    <img
                      alt="..."
                      style="width: 50%;height: 50%;"
                      src="assets/images/icons/cargo.png"
                      

                    />
                    
                  </div>

                  
                </a>
                <div  style="display: flex; justify-content: center;">
                  <h2 style="font-size: 110%;color: #615f5f; text-align: center;">Container Terminal</h2>

                </div>
                <div style="display: flex; justify-content: center;">
                  <input (click)="typeSelected('Container Terminal')" type="button"  class="next action-button" value="Start"/>
                </div>
                <br>
<br>
              </div>
              </div>

              <div  class="w-full lg:w-4/12 px-4">

              <div class="hover:-mt-4 relative flex flex-col min-w-0 break-words  w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150" style=" border: 2px solid rgb(87, 83, 83); /* Définit une bordure de 2 pixels avec une couleur noire */
              padding: 10px;">
              <br>
              <br>
               
                <a (click)=" typeSelected('Shipping line / Agent Maritime')" class="relative flex flex-col">
                  <div
                  style="display: flex; justify-content: center;"
                  >
                    <img
                      alt="..."
                      style="width: 50%;height: 50%;"
                      src="assets/images/icons/maritime-laws.png"
                      

                    />
                    
                  </div>

                  
                </a>
                <div  style="display: flex; justify-content: center;">
                  <h2 style="font-size: 110%;color: #615f5f; text-align: center;">Shipping line / Agent Maritime</h2>

                </div>
                <div style="display: flex; justify-content: center;">
                  <input (click)="typeSelected('Shipping line / Agent Maritime')" type="button"  class="next action-button" value="Start"/>
                </div>
                <br>
<br>
              </div>

              </div>

              <div  class="w-full lg:w-4/12 px-4">
              <div class="hover:-mt-4 relative flex flex-col min-w-0 break-words  w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150" style=" border: 2px solid rgb(87, 83, 83); /* Définit une bordure de 2 pixels avec une couleur noire */
              padding: 10px;">
               <br>
               <br>
                <a (click)=" typeSelected('Airline')" class="relative flex flex-col">
                  <div   style="display: flex; justify-content: center;"
                   >
                    <img
                      alt="..."
                      style="width: 50%;height: 50%;"
                      src="assets/images/icons/airline.png"
                      

                    />
                    
                  </div>

                  
                </a>
                <div  style="display: flex; justify-content: center;">
                  <h2 style="font-size: 110%;color: #615f5f; text-align: center;">Airline</h2>

                </div>
                <div style="display: flex; justify-content: center;">
                  <input (click)="typeSelected('Airline')" type="button"  class="next action-button" value="Start"/>
                </div>
                <br>
<br>
              </div>

              </div>

              <div  class="w-full lg:w-4/12 px-4">
              <div class="hover:-mt-4 relative flex flex-col min-w-0 break-words  w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150" style="border: 2px solid rgb(87, 83, 83); /* Définit une bordure de 2 pixels avec une couleur noire */
              padding: 10px;">
               <br>
               <br>
                <a (click)=" typeSelected('Freight Forwarder')" class="relative flex flex-col">
                  <div
                  style="display: flex; justify-content: center;"
                  >
                    <img
                      alt="..."
                      style="width: 50%;height: 50%;"
                      src="assets/images/icons/international-shipping.png"
                      

                    />
                    
                  </div>

                  
                </a>
                <div  style="display: flex; justify-content: center;">
                  <h2 style="font-size: 110%;color: #615f5f; text-align: center;">Freight Forwarder</h2>

                </div>
                <div style="display: flex; justify-content: center;">
                  <input (click)="typeSelected('Freight Forwarder')" type="button"  class="next action-button" value="Start"/>
                </div>
                <br>
<br>
              </div>

              </div>

              <div  class="w-full lg:w-4/12 px-4">
              <div class="hover:-mt-4 relative flex flex-col min-w-0 break-words  w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150" style="border: 2px solid rgb(87, 83, 83); /* Définit une bordure de 2 pixels avec une couleur noire */
              padding: 10px;">
               <br>
               <br>
                <a (click)=" typeSelected('Qualitative testing laboratories')" class="relative flex flex-col">
                  <div
                  style="display: flex; justify-content: center;"
                   >
                    <img
                      alt="..."
                      style="width: 50%;height: 50%;"
                      src="assets/images/icons/chemistry.png"
                     

                    />
                    
                  </div>

                  
                </a>
                <div  style="display: flex; justify-content: center;">
                  <h2 style="font-size: 110%;color: #615f5f; text-align: center;">Qualitative testing laboratories</h2>

                </div>
                <div style="display: flex; justify-content: center;">
                  <input (click)="typeSelected('Qualitative testing laboratories')" type="button"  class="next action-button" value="Start"/>
                </div>
                <br>
<br>
              </div>

              </div>

              
              <div  class="w-full lg:w-4/12 px-4">
              <div class="hover:-mt-4 relative flex flex-col min-w-0 break-words  w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150" style=" border: 2px solid rgb(87, 83, 83); /* Définit une bordure de 2 pixels avec une couleur noire */
              padding: 10px;">
               <br>
               <br>
                <a (click)=" typeSelected('Airline Warehouse')" class="relative flex flex-col">
                  <div
                  style="display: flex; justify-content: center;"
                  >
                    <img
                      alt="..."
                      style="width: 50%;height: 50%;"
                      src="assets/images/icons/global-distribution.png"
                      

                    />
                    
                  </div>

                  
                </a>
                <div  style="display: flex; justify-content: center;">
                  <h2 style="font-size: 110%;color: #615f5f; text-align: center;">Airline Warehouse</h2>

                </div>
                <div style="display: flex; justify-content: center;">
                  <input (click)="typeSelected('Airline Warehouse')" type="button"  class="next action-button" value="Start"/>
                </div>
                <br>
<br>
              </div>
            </div>
    
             
            </div>
          </div>
        </div>
     
    </section>




 


     


        
    </fieldset>
</form>
<!------------------------------------------------------------------------------------------->


<!-------------------------------------------------------------------------------------------------------->
<form [formGroup]="personalDetails" id="msform"> 
<!-- fieldsets -->
<fieldset  *ngIf="step == 1" >
<h2 class="fs-title">Company Details</h2>

<!---select class="form-select form-select-lg  form-field"   formControlName="activityType" aria-placeholder="Activity Type">
<option  value="" selected disabled>Activity Type</option>
<option value="Marketing">Marketing</option>
<option value="Retail">Retail</option>
<option value="Sales">Sales</option>
<option value="Operations management">Operations management</option>
<option value="Manufacturing">Manufacturing</option>
<option value="Customer Service">Customer Service</option>
<option value="Event management">Event management</option>
<option value="Accounting">Accounting</option>
<option value="Audit">Audit</option>

</select--->



<input type="text" formControlName="raisonSocial"  [ngClass]="{ 'is-invalid': personal_step && personal.raisonSocial.errors }" placeholder="Company Name"/>
<div *ngIf="personal_step && personalDetails.controls.raisonSocial.errors && personalDetails.controls.raisonSocial.dirty ||personalDetails.controls.raisonSocial.touched">
<div *ngIf="personal.raisonSocial.errors?.required">Company Name is required</div>
</div>


<input type="text" formControlName="rne" name="rne" placeholder="RNE"/>
<div *ngIf="personal_step && personalDetails.controls.rne.errors && personalDetails.controls.rne.dirty ||personalDetails.controls.rne.touched">
<div *ngIf="personal.rne.errors?.required">RNE is required</div>
</div>
<input  type="text" formControlName="matriculeFiscal" name="matriculeFiscal" placeholder="Tax ID number"/>
<div *ngIf="personal_step && personalDetails.controls.matriculeFiscal.errors && personalDetails.controls.matriculeFiscal.dirty ||personalDetails.controls.matriculeFiscal.touched">
<div *ngIf="personal.matriculeFiscal.errors?.required">Tax ID number is required</div>
</div>




<select class="form-select form-select-lg  form-field"   formControlName="documentSubmissionCentre" aria-placeholder="Document Submission Centre">
<option value="" disabled selected>Document Submission Centre</option>
<option value="Ariana Submission Centre">Ariana Submission Centre</option>
<option value="Tunis Submission Centre">Tunis Submission Centre</option>
<option value="Kasserine Submission Centre">Kasserine Submission Centre</option>
</select>






<input type="text" formControlName="email" name="email" placeholder="Company Email"/>
<div *ngIf="personal_step && personalDetails.controls.email.errors && personalDetails.controls.email.dirty ||personalDetails.controls.email.touched">
<div *ngIf="personal.email.errors?.required">Company Email is required</div>
<div *ngIf="personal.email.errors?.pattern">Company Email must be a valid email address.</div>
</div>
<input type="text" formControlName="phone" name="phone" placeholder="Company Phone"/>
<div *ngIf="personal_step && personalDetails.controls.phone.errors && personalDetails.controls.phone.dirty ||personalDetails.controls.phone.touched">
<div *ngIf="personal.phone.errors?.required">Company Phone is required</div>
</div>

<input type="text" formControlName="address" name="address" placeholder="Address"/>
<div *ngIf="personal_step && personalDetails.controls.address.errors && personalDetails.controls.address.dirty ||personalDetails.controls.address.touched">
<div *ngIf="personal.address.errors?.required">Company address is required</div>

</div>
<select class="form-select form-select-lg  form-field"   formControlName="country" aria-placeholder="country">


<option *ngFor="let country of countryList" value={{country}}>{{country}}</option>

</select>


<div *ngIf="personal_step && personalDetails.controls.country.errors && personalDetails.controls.country.dirty ||personalDetails.controls.country.touched">
<div *ngIf="personal.country.errors?.required">Country is required</div>

</div>

<div  style="display: flex; justify-content: center;">
<div >

<label class="form-check-label"  style="color: #615f5f; font-size: 14px;">
Are you food importer?
<input class="form-check-input" type="checkbox" value="" formControlName="foodImporter"  > 
</label>
</div>
</div>




<div *ngIf="willBeUpdate">
<input (click)="update()" type="button" name="next" class="next action-button" value="Update"/>
</div>

<div *ngIf="willBeUpdate == false">
<input (click)="previous()" type="button" name="previous" class="previous action-button-previous" value="Previous"/>
<input (click)="next()" type="button" name="next" class="next action-button" value="Next" [disabled]="personalDetails.pristine || personalDetails.invalid" />
</div>






</fieldset>
</form>

<!-------------------------------------------------------------------------------------------------------->


 <form [formGroup]="addressDetails" id="msform">
    <fieldset *ngIf="step == 2" >
        <h2 class="fs-title">Declarant Details</h2>
        <input type="text" formControlName="firstlastname"  placeholder="firstname lastname" />

        <select class="form-select form-select-lg  form-field"   formControlName="applicantStatus" aria-placeholder="Applicant Status">
          <option  value="" selected disabled>Applicant Status</option>
          <option value="Manager">Manager</option>
          <option value="Member">Member</option>
          
        </select>
        <div *ngIf="address_step && addressDetails.controls.applicantStatus.errors && addressDetails.controls.applicantStatus.dirty ||addressDetails.controls.applicantStatus.touched">
          <div *ngIf="address.applicantStatus.errors?.required">Applicant Status is required</div>
      </div>

        

        <select class="form-select form-select-lg  form-field"   formControlName="typeofIdentification" aria-placeholder="Type of Identification" (change)=" onchange()">
          <option  value="" selected disabled>Type of Identification</option>
          <option value="CIN">CIN</option>
          <option value="Passport">Passport</option>
          
        </select>

        <div *ngIf="address_step && addressDetails.controls.typeofIdentification.errors && addressDetails.controls.typeofIdentification.dirty ||addressDetails.controls.typeofIdentification.touched">
            <div *ngIf="address.typeofIdentification.errors?.required">Type of Identification is required</div>
        </div>



        <input type="text" formControlName="nationalID"  placeholder="National ID"/>
        <div *ngIf="address_step && addressDetails.controls.nationalID.errors && addressDetails.controls.nationalID.dirty ||addressDetails.controls.nationalID.touched">
            <div *ngIf="address.nationalID.errors?.required">National ID is required</div>
        </div>
        <input type="text" formControlName="expiryDate"  placeholder={{plceHolder}} onfocus="(this.type='date')"/>
        <div *ngIf="address_step && addressDetails.controls.expiryDate.errors && addressDetails.controls.expiryDate.dirty ||addressDetails.controls.expiryDate.touched">
            <div *ngIf="address.expiryDate.errors?.required">{{plceHolder}} is required</div>
        </div>

        <input type="text" formControlName="email" name="email" placeholder="Declarant Email"/>
      
      <input type="text" formControlName="phone" name="phone" placeholder="Declarant Phone"/>
      <div *ngIf="address_step && addressDetails.controls.phone.errors && addressDetails.controls.phone.dirty ||addressDetails.controls.phone.touched">
        <div *ngIf="address.phone.errors?.required"> Phone is required</div>
    </div >


        <input (click)="previous()" type="button" name="previous" class="previous action-button-previous" value="Previous"/>
        <input  (click)="next()" type="button" name="next" class="next action-button " value="Next" [disabled]="addressDetails.pristine || addressDetails.invalid"/>
    </fieldset>
</form>



<!-------------------------------------------------------------------------------------------------------->
 <form [formGroup]="educationalDetails" id="msform">
    <fieldset *ngIf="step == 3">
        <h2 class="fs-title">Member Details</h2>
        
    <div  style="display: flex; justify-content: center;">
      <div >
      
      <label class="form-check-label"   style="font-size: 14px;color: #615f5f;">
          Do you have member ? <input class="form-check-input" type="checkbox" value="" formControlName="haveCustomerNumber" (change)="intializeForm()" > 
      </label>
    </div>
    </div>

    <div *ngIf="educationalDetails.controls.haveCustomerNumber.value" >
      
      <input type="text" formControlName="firstname" placeholder="Firstname "/>
        <div *ngIf="education_step && educationalDetails.controls.firstname.errors && educationalDetails.controls.firstname.dirty || educationalDetails.controls.firstname.touched">
            <div *ngIf="education.firstname.errors?.required">Firstname is required</div>
        </div>
        <input type="text" formControlName="lastname" placeholder="Lastname"/>
        <div *ngIf="education_step && educationalDetails.controls.lastname.errors && educationalDetails.controls.lastname.dirty || educationalDetails.controls.lastname.touched">
            <div *ngIf="education.lastname.errors?.required">Lastname is required</div>
        </div>
        <input type="text" formControlName="username" placeholder="Username "/>
        <div *ngIf="education_step && educationalDetails.controls.username.errors && educationalDetails.controls.username.dirty || educationalDetails.controls.username.touched">
            <div *ngIf="education.username.errors?.required">Username is required</div>
        </div>
        <select class="form-select form-select-lg  form-field"   formControlName="typeofIdentification" aria-placeholder="Type of Identification" (change)=" onchangeMember()">
          <option  value="" selected disabled>Type of Identification</option>
          <option value="CIN">CIN</option>
          <option value="Passport">Passport</option>
          
        </select>
       

        <div *ngIf="education_step && educationalDetails.controls.typeofIdentification.errors && educationalDetails.controls.typeofIdentification.dirty || educationalDetails.controls.typeofIdentification.touched">
            <div *ngIf="education.typeofIdentification.errors?.required">Type of Identification is required</div>
        </div>



        <input type="text" formControlName="nationalID"  placeholder="National ID"/>
        <div *ngIf="education_step && educationalDetails.controls.nationalID.errors && educationalDetails.controls.nationalID.dirty || educationalDetails.controls.nationalID.touched">
            <div *ngIf="education.nationalID.errors?.required">National ID is required</div>
        </div>

        
        <input type="text" formControlName="expiryDate"  placeholder={{plceHolder}} onfocus="(this.type='date')"/>
        <div *ngIf="education_step && educationalDetails.controls.expiryDate.errors && educationalDetails.controls.expiryDate.dirty || educationalDetails.controls.expiryDate.touched">
            <div *ngIf="education.expiryDate.errors?.required">{{plceHolder}} is required</div>
        </div>

        <input type="text" formControlName="email" name="email" placeholder="Member Email"/>
      <div *ngIf="education_step && educationalDetails.controls.email.errors && educationalDetails.controls.email.dirty || educationalDetails.controls.email.touched">
        <div *ngIf="education.email.errors?.required">Email is required</div>
        <div *ngIf="education.email.errors?.pattern"> Email must be a valid email address.</div>
     </div>
      <input type="text" formControlName="phone" name="phone" placeholder="Member Phone"/>
      <div *ngIf="education_step && educationalDetails.controls.phone.errors && educationalDetails.controls.phone.dirty || educationalDetails.controls.phone.touched">
        <div *ngIf="education.phone.errors?.required"> Phone is required</div>
    </div>
    <input type="text" formControlName="teamName" name="teamName" placeholder="Team Name"/>
    <div *ngIf="education_step && educationalDetails.controls.teamName.errors && educationalDetails.controls.teamName.dirty || educationalDetails.controls.teamName.touched">
      <div *ngIf="education.teamName.errors?.required"> Team Name is required</div>
  </div>

</div>  
        <input (click)="previous()" type="button" name="previous" class="previous action-button-previous" value="Previous"/>
        <input  (click)="submit()" type="button" name="next" class="next action-button " value="Next"  />
    </fieldset>
</form>


<!-------------------------------------------------------------------------------------------------------->
<form [formGroup]="filesToUpload"  id="msform">
  <fieldset *ngIf="step == 4">
      <h2 class="fs-title">Attachments Details</h2>

      <!------------------  registration number ---------------------->

    <span id="file-name" class="file-name" style="font-size: 14px;color: #615f5f;">Attachment of Commercial register(PDF)* </span> 
  <!------  <div class="file-input-wrapper" >
      <input type="file" id="file-input" class="file-input"  >
      <label for="file-input" class="custom-file-input" >Choose a file</label>
      <span id="file-name" class="file-name">No file selected</span>
    </div>
<br>  -->

<div class="row">
  <div class="col-8">
    <label class="btn btn-default p-0">
      <input type="file" accept="application/pdf" (change)="selectRegistrationNumberFile($event)" formControlName="registrationNumberField"/>
    </label>
  </div>
</div>

<div *ngIf="files_step && filesToUpload.controls.registrationNumberField.errors && filesToUpload.controls.registrationNumberField.dirty ||filesToUpload.controls.registrationNumberField.touched">
  <div *ngIf="filesUpload.registrationNumberField.errors?.required">Commercial register is required</div>
</div>
    <!------------------  National register ---------------------->

    <span id="file-name" class="file-name" style="font-size: 14px;color: #615f5f;">Attachment of Tax ID(PDF)* </span> 
    <div class="row">
      <div class="col-8">
        <label class="btn btn-default p-0">
          <input type="file" accept="application/pdf" (change)="selectNationalregister($event)" formControlName="nationalregisterField"/>
        </label>
      </div>
    </div>


    <div *ngIf="files_step && filesToUpload.controls.nationalregisterField.errors && filesToUpload.controls.nationalregisterField.dirty ||filesToUpload.controls.nationalregisterField.touched">
      <div *ngIf="filesUpload.nationalregisterField.errors?.required">Tax ID is required</div>
    </div>
   
    <!------------------  Evidence of permission to carry out activity ---------------------->

    <span id="file-name" class="file-name" style="font-size: 14px;color: #615f5f;">Attachment CIN (National Identity Card) Manager(PDF)* </span> 
    <div class="row">
      <div class="col-8">
        <label class="btn btn-default p-0">
          <input type="file" accept="application/pdf" (change)="selectEvidencepermission($event)" formControlName="evidencepermissionField"/>
        </label>
      </div>
    </div>


    <div *ngIf="files_step && filesToUpload.controls.evidencepermissionField.errors && filesToUpload.controls.evidencepermissionField.dirty ||filesToUpload.controls.evidencepermissionField.touched">
      <div *ngIf="filesUpload.evidencepermissionField.errors?.required">CIN Manager is required</div>
    </div>

    <!------------------  Evidence of power ---------------------->
 
    <span id="file-name" class="file-name" style="font-size: 14px;color: #615f5f;">Status(PDF)* </span> 
    <div class="row">
      <div class="col-8">
        <label class="btn btn-default p-0">
          <input type="file" accept="application/pdf" (change)="selectevidencepowerFile($event)" formControlName="evidencepowerFileField"/>
        </label>
      </div>
    
      
    </div>
    <div *ngIf="files_step && filesToUpload.controls.evidencepowerFileField.errors && filesToUpload.controls.evidencepowerFileField.dirty ||filesToUpload.controls.evidencepowerFileField.touched">
      <div *ngIf="filesUpload.evidencepowerFileField.errors?.required">Status is required</div>
    </div>
    <br>

    <div *ngIf="message" class="alert alert-secondary" role="alert">{{ message }}</div>

      <input (click)="previous()" type="button" name="previous" class="previous action-button-previous" value="Previous"/>
      <input  [disabled]="filesToUpload.pristine || filesToUpload.invalid" (click)="uploadAll()"type="submit" name="submit" class="submit action-button" value="Upload"/>
  </fieldset>
</form>




<!-------------------------------------------------------------------------------------------------->
