

<nav class="navbar" *ngIf="isSignedIn">
  <div class="logo">
    <img src="assets/images/auth/logo-nav3.png" alt="TSN" />
    <a [routerLink]="['/']">
      <span><span class="logo-text">ACID</span> PORTAL</span>
    </a>
  </div>

  <div class="item-globale" [ngClass]="{ hidden: !navbarOpen, 'block': navbarOpen }" id="example-navbar-warning">
    <ul class="nav-items" *ngIf="isSimpleUser">
      <li class="flex items-center"><a [routerLink]="['/']">    {{ 'home' | translate }}</a></li>
      <li class="flex items-center"><a [routerLink]="['/dashboard']">   {{ "companies" | translate }}</a></li>
      <li class="flex items-center">
        <div class="language-selector">
          <select id="language" (change)="ChangeLang($event)" [value]="lang">
            <option [selected]="lang === 'en'" value="en">🇬🇧&ensp;English</option>
            <option [selected]="lang === 'fr'" value="fr">🇫🇷&ensp;French</option>
            <option [selected]="lang === 'ab'" value="ab">🇸🇦&ensp;Arabe</option>
          </select>
        </div>
      </li>
    </ul>
    <ul class="nav-items" *ngIf="isManager">
      <li class="flex items-center"><a [routerLink]="['/']">    {{ 'home' | translate }}</a></li>
      <li class="flex items-center"><a [routerLink]="['/members']">  {{ "members" | translate }}</a></li>
      <li class="flex items-center"><a [routerLink]="['/request']">{{ "acidRequests" | translate }}</a></li>
      <li class="flex items-center"><a [routerLink]="['/acid']">{{ "acidFolders" | translate }}</a></li>
      <li class="flex items-center">
        <div class="language-selector">
          <select id="language" (change)="ChangeLang($event)" [value]="lang">
            <option [selected]="lang === 'en'" value="en">🇬🇧&ensp;English</option>
            <option [selected]="lang === 'fr'" value="fr">🇫🇷&ensp;French</option>
            <option [selected]="lang === 'ab'" value="ab">🇸🇦&ensp;Arabe</option>
          </select>
        </div>
      </li>
    </ul>
      <ul class="nav-items" *ngIf="isAdmin">
        <li class="flex items-center"><a [routerLink]="['/']">    {{ 'home' | translate }}</a></li>
        <li class="flex items-center"><a [routerLink]="['/admin/mode']">Mode</a></li>
        <li class="flex items-center"><a [routerLink]="['/admin/acid-requests']">Acid Requests</a></li>
        <li class="flex items-center"><a [routerLink]="['/admin/importers']">Importers</a></li>
        <li class="flex items-center"><a [routerLink]="['/admin/exporters']">Exporters</a></li>
        <li class="flex items-center">
          <div class="language-selector">
            <select id="language" (change)="ChangeLang($event)" [value]="lang">
              <option [selected]="lang === 'en'" value="en">🇬🇧&ensp;English</option>
              <option [selected]="lang === 'fr'" value="fr">🇫🇷&ensp;French</option>
              <option [selected]="lang === 'ab'" value="ab">🇸🇦&ensp;Arabe</option>
            </select>
          </div>
        </li>
     
      </ul>
      <ul class="nav-items" *ngIf="isExporter">
        <li class="flex items-center"><a [routerLink]="['/']">    {{ 'home' | translate }}</a></li>
        <li class="flex items-center"><a [routerLink]="['/exporter/company']">Company Details</a></li>
        <li class="flex items-center"><a [routerLink]="['/acid']">Acid Folders</a></li>
        <li class="flex items-center">
          <div class="language-selector">
            <select id="language" (change)="ChangeLang($event)" [value]="lang">
              <option [selected]="lang === 'en'" value="en">🇬🇧&ensp;English</option>
              <option [selected]="lang === 'fr'" value="fr">🇫🇷&ensp;French</option>
              <option [selected]="lang === 'ab'" value="ab">🇸🇦&ensp;Arabe</option>
            </select>
          </div>
        </li>
     
      </ul>

      <ul class="nav-items" *ngIf="isMember">
        <li class="flex items-center"><a [routerLink]="['/']">    {{ 'home' | translate }}</a></li>
        <li class="flex items-center"><a [routerLink]="['/request']">ACID Requests</a></li>
        <li class="flex items-center">
          <div class="language-selector">
            <select id="language" (change)="ChangeLang($event)" [value]="lang">
              <option [selected]="lang === 'en'" value="en">🇬🇧&ensp;English</option>
              <option [selected]="lang === 'fr'" value="fr">🇫🇷&ensp;French</option>
              <option [selected]="lang === 'ab'" value="ab">🇸🇦&ensp;Arabe</option>
            </select>
          </div>
        </li>
     
      </ul>
 
      <ul class="nav-items" *ngIf="isPlateformAdmin">
        <li class="flex items-center"><a [routerLink]="['/']">    {{ 'home' | translate }}</a></li>
        <li class="flex items-center"><a [routerLink]="['/platform/ministries']">Ministries</a></li>
        <li class="flex items-center"><a [routerLink]="['/platform/account']"> Accounts</a></li>
        <li class="flex items-center"><a [routerLink]="['/platform/lists']">Lists</a></li>
        <li class="flex items-center"><a [routerLink]="['/platform/blackList']">BlackList</a></li>
        <li class="flex items-center"><a [routerLink]="['/platform/exporters']">Exporters</a></li>
        <li class="flex items-center"><a [routerLink]="['/platform/users-for-double-auth']">Activer 2FA</a></li>
       
        <li class="flex items-center">
          <div class="language-selector">
            <select id="language" (change)="ChangeLang($event)" [value]="lang">
              <option [selected]="lang === 'en'" value="en">🇬🇧&ensp;English</option>
              <option [selected]="lang === 'fr'" value="fr">🇫🇷&ensp;French</option>
              <option [selected]="lang === 'ab'" value="ab">🇸🇦&ensp;Arabe</option>
            </select>
          </div>
        </li>
     
      </ul>
      <ul class="nav-items" *ngIf="isMinistry">
        <li class="flex items-center"><a [routerLink]="['/']">    {{ 'home' | translate }}</a></li>
        <li class="flex items-center"><a [routerLink]="['/admin/acid-requests']">ACID Requests</a></li>
       
        <li class="flex items-center">
          <div class="language-selector">
            <select id="language" (change)="ChangeLang($event)" [value]="lang">
              <option [selected]="lang === 'en'" value="en">🇬🇧&ensp;English</option>
              <option [selected]="lang === 'fr'" value="fr">🇫🇷&ensp;French</option>
              <option [selected]="lang === 'ab'" value="ab">🇸🇦&ensp;Arabe</option>
            </select>
          </div>
        </li>
     
      </ul>
  </div>
  <div class="profil">
    <div class="user" *ngIf="isSignedIn" (click)="toggleDropdown()">
      <img src="assets/images/auth/user.png" alt="">
      <span>{{ firstName }} {{ lastName }}
        <i class="fas" [ngClass]="isDropdownOpen ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
      </span>
      <div *ngIf="isDropdownOpen" class="dropdown-menu">
        <ul>
          <li><a [routerLink]="['/profile']"><i class="fas fa-user" style="color: #3498DB;"></i>&ensp;Profile</a></li>
          <!--<li><i class="fas fa-cogs" style="color: #E74C3C;"></i>&ensp;Settings</li>-->
          <li><a [routerLink]="['/password']"><i class="fas fa-key" style="color: #E74C3C;"></i>&ensp;Change password</a></li>
          <li  (click)="onSubmit()"><i class="fas fa-sign-out-alt" style="color: #2ECC71;"></i>&ensp;Logout</li>
        </ul>
      </div>
    </div>
  </div>
</nav>


