export class ProductDetails {

     id?: number;
     itemNo?: string;
     productCode?: Date;
     commodityCode?: string;
     descriptionOfGoods?: Date;
     tradeMarkOwner?: string;
     brandName?: string;
     model?: string;
     quantity?: string;
     quantityUnit?: string;
     unitPrice?: string;
     grossWeight?: string;
     netWeight?: number;
     weightUnit?: number;
     totalPrice?: number;
     
     
  }
