<section class="login-container">
  <div class="auth-fluid">
    <div class="auth-card">
      <form class="auth-form" action="#" method="post" [formGroup]="angForm" novalidate>
        <div class="image-container">
          <img src="assets/images/auth/logo-dark.png" alt="TSN" class="logo-img">
        </div>
        
        <div class="auth-box">
          <div class="icon-container">
            <img src="assets/images/icons/message.png" alt="message" class="icon-img">
          </div>

          <div class="message-container">
            <h1 class="message-title">Verified!</h1>
            <p class="message-text">You should enter new password.</p>
          </div>

          <hr />

          <!-- Current Password -->
          <div class="input-group">
            <input #inputPassword2 type="password" class="input-field" placeholder="Current Password" formControlName="currentpassword" id="currentpassword">
            <span class="input-icon" (click)="toggleEyeIcon(inputPassword2)">
              <i class="fa" style="color: black;" [ngClass]="{'fa-eye-slash': toggleEye, 'fa-eye': !toggleEye}"></i>
            </span>
          </div>

          <div class="error-message" *ngIf="angForm.controls['currentpassword'].invalid && (angForm.controls['currentpassword'].dirty || angForm.controls['currentpassword'].touched)">
            <div *ngIf="angForm.controls['currentpassword'].errors.required">Current Password is required.</div>
            <div *ngIf="angForm.controls['currentpassword'].errors.minlength">Current Password must be at least 6 characters.</div>
          </div>

          <!-- New Password -->
          <div class="input-group">
            <input #inputPassword type="password" class="input-field" placeholder="Enter new Password" formControlName="password" id="password">
            <span class="input-icon" (click)="toggleEyeIcon(inputPassword)">
              <i class="fa" style="color: black;" [ngClass]="{'fa-eye-slash': toggleEye, 'fa-eye': !toggleEye}"></i>
            </span>
          </div>

          <div class="error-message" *ngIf="angForm.controls['password'].invalid && (angForm.controls['password'].dirty || angForm.controls['password'].touched)">
            <div *ngIf="angForm.controls['password'].errors.required">Password is required.</div>
            <div *ngIf="angForm.controls['password'].errors.minlength">Password must be at least 6 characters.</div>
          </div>

          <!-- Confirm Password -->
          <div class="input-group">
            <input #inputPassword3 type="password" class="input-field" placeholder="Confirm Password" formControlName="confirmPassword" id="confirmPassword">
            <span class="input-icon" (click)="toggleEyeIcon(inputPassword3)">
              <i class="fa" style="color: black;" [ngClass]="{'fa-eye-slash': toggleEye, 'fa-eye': !toggleEye}"></i>
            </span>
          </div>

          <div class="error-message" *ngIf="angForm.controls['confirmPassword'].invalid && (angForm.controls['confirmPassword'].dirty || angForm.controls['confirmPassword'].touched)">
            <div *ngIf="angForm.controls['confirmPassword'].errors.required">Confirm Password is required.</div>
            <div *ngIf="angForm.controls['confirmPassword'].errors.minlength">Confirm Password must be at least 6 characters.</div>
          </div>

          <div class="button-container">
            <button type="button" class="submit-button" [disabled]="angForm.pristine || angForm.invalid" (click)="onSubmit()">Save</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
