import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Company } from 'src/app/models/Company';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-card-table',
  templateUrl: './card-table.component.html',
  styleUrls: ['./card-table.component.css']
})

export class CardTableComponent implements OnInit {
  companies: Company[] = [];
  currentCompany: Company = {};
  currentIndex = -1;
  title = '';
  usernameManger?:string;
  page = 1;
  count = 0;
  pageSize = 5;
  pageSizes = [5, 10, 50];
  myid!:any;
  companyId:any;
  searchText='';

  @Input()
  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  constructor(private route: ActivatedRoute,public companyService: CompanyService,public authService:AuthService,public notificationService:NotificationService,
    private userService:UserService) { 
    this.myid=this.authService.currentUserValue.user_id
   }

  ngOnInit(): void {

      this.retrieveCompanies();

      this.route.paramMap.subscribe(params => {
        const companyIdParam = params.get('companyId');
        this.companyId = companyIdParam ? +companyIdParam : null;
        
        if(this.companyId != null){
          this.onDelete(this.companyId)
        }
        
      });
    
    
  
  }

  getRequestParams(mangerid: number, page: number, pageSize: number): any {
    let params: any = {};

    
      params[`mangerid`] = mangerid;
    

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  retrieveCompanies(): void {
    const params = this.getRequestParams(this.myid, this.page, this.pageSize);

    this.companyService.getAllCompaniesByManagerPagination(params)
    .subscribe(
      response => {
        const { companies, totalItems } = response;
        this.companies = companies;
        this.count = totalItems;
        
        //console.log(response);
      },
      error => {
        console.log(error);
      });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveCompanies();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveCompanies();
  }

  refreshList(): void {
    this.retrieveCompanies();
    this.currentCompany = {};
    this.currentIndex = -1;
  }

  setActiveCompany(tutorial: Company, index: number): void {
    this.currentCompany = tutorial;
    this.currentIndex = index;
  }



  

  onDelete(id:any){

    this.companyService.deleteCompany(id,this.myid).subscribe({
      next: (data) => { 
        console.log(data);
        this.notificationService.showSuccess("","Company Deleted ! ")
        window.location.href ='dashboard'
        //this.notificationService.showSuccess("","Company Deleted ! ")
      },
      error: (err) => {
        console.log(err)
        this.notificationService.showError("", "Company not found!");
      },
    });
    

  }

  
  

}
