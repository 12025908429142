import { Component, OnInit, HostListener } from '@angular/core';
import { AuthService } from '../services/auth.service';

import { TranslateService } from '@ngx-translate/core';
import { interval } from 'rxjs';
declare var AOS: any;

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {
  currentDateTime: Date = new Date();
  isSignedIn = false;
  formattedDate: string = '';
  navbarOpen = false;
  lang: string = 'en';
  isMenuOpen: boolean = false;
  isScrolled = false;
isShow: boolean;
topPosToStartShowing = 100;
showMore = false;
  shapeImages: string[] = [
    '../../assets/images/test3.png',  
    '../../assets/images/test3.png',
    '../../assets/images/test3.png'
  ];
  imageSrc: string = this.shapeImages[0];
  currentImageIndex: number = 0;
  isImageVisible: boolean = true;  

  constructor(private translateService: TranslateService, public authService: AuthService) {
    if (this.authService.currentUserValue.user_id) {
      this.isSignedIn = true;
    }
  }

  ngOnInit(): void {
    AOS.init();  // Initialisation de AOS
    const storedLang = localStorage.getItem('lang');
    if (storedLang) {
      this.lang = storedLang;
      this.translateService.use(this.lang);
    }
    interval(1000).subscribe(() => {
      this.currentDateTime = new Date();
      this.formatDateInArabic();
    });
    setInterval(() => {
      this.changeShapeImage();
    }, 10000);  
  }
// Fonction pour formater la date en arabe
formatDateInArabic() {
  this.formattedDate = new Intl.DateTimeFormat('ar-EG', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  }).format(this.currentDateTime);
}
  changeShapeImage(): void {
    this.isImageVisible = false;

    setTimeout(() => {
      this.currentImageIndex = (this.currentImageIndex + 1) % this.shapeImages.length;
      this.imageSrc = this.shapeImages[this.currentImageIndex];
      this.isImageVisible = true;
      AOS.refresh();  
    }, 500); 
  }

  setNavbarOpen() {
    this.navbarOpen = !this.navbarOpen;
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.isScrolled = window.scrollY > 50;
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.isShow = scrollPosition >= this.topPosToStartShowing;
  }

  ChangeLang(lang: any) {
    const selectedLanguage = lang.target.value;
    localStorage.setItem('lang', selectedLanguage);
    this.translateService.use(selectedLanguage);
    this.lang = selectedLanguage; // Mettre à jour la langue dans le composant
  }
  onSubmit(){
    this.authService.logout();
    window.location.href ='login'
  }

  scrollToTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

  toggleText() {
    this.showMore = !this.showMore;
  }
}
