import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Ministry } from 'src/app/models/Ministry';
import { AuthService } from 'src/app/services/auth.service';
import { MinistryService } from 'src/app/services/ministry.service';
import { NotificationService } from 'src/app/services/notification.service';


@Component({
  selector: 'app-ministries',
  templateUrl: './ministries.component.html',
  styleUrls: ['./ministries.component.css']
})
export class MinistriesComponent implements OnInit {
  @Input()
  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [5, 10, 50];

  currentItem:any
  listDetails: FormGroup | any;
  list_step=true
  currentIndex = -1;
  willbeUpdated = false
 


  items:any[]=[];
  authorities:any[]=[];
types:any[]=[
  
  'Culture' ,
    'Economy_Planning' ,
    'Education' ,
     'Environment',
    'StateDomains_LandAffairs',
    "Trade_ExportDevelopment",
     "Defence" ,
    "Finance" ,
    'ForeignAffairs',

   'HigherEducation_ScientificResearch',
    'Industry' ,
    'Interior' ,
   'Justice' ,
   'Regional_Development' ,
    'Religious_Affairs',
    'Transport',
]
auths:any[]=[
  
  'Validate ACID Request',
  'Consult ACID Folders'
]
  
  userId: any;
  
  users:any[]=[];

  

  constructor(private formBuilder: FormBuilder ,public authService: AuthService,
   public notificationService:NotificationService,private cdr: ChangeDetectorRef,
    public ministryService:MinistryService) { 
      

    this.listDetails = this.formBuilder.group({
      sector: ['', Validators.required],
      authorities: ['', Validators.required],
      
    });

 
  }
  get list() { return this.listDetails.controls; }

  getRequestParams( page: number, pageSize: number): any {
    let params: any = {};

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  ngOnInit(): void {
   
    this.retrieveLists()
  }
  retrieveLists(){

    const params = this.getRequestParams( this.page, this.pageSize);

    this.ministryService.getAll(params)
    .subscribe(
      response => {
        const { items, totalItems } = response;
        this.items = items;
        this.count = totalItems;
      
       
        
      },
      error => {
        console.log(error);
      });

  }

  

  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveLists();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveLists();
  }

  refreshList(): void {
    this.retrieveLists();
    this.currentItem = {};
    this.currentIndex = -1;
  }

  setActiveCompany(tutorial: Ministry, index: number): void {
    this.currentItem = tutorial;
    this.currentIndex = index;
    this.setDetails(this.currentItem)
    this.willbeUpdated=true
    this.authorities=[]
    if(tutorial.authorities!=null){
      this.authorities=tutorial.authorities
    }
    

  }

  deleteSelectedItem(id:any){
    this.ministryService.delete(id)
    .subscribe(
      response => {
       
        console.log(this.users)
        this.cdr.detectChanges();
        this.cancel()
        this.retrieveLists()
        
      },
      error => {
        console.log(error);
      });

  }
  setDetails(data:Ministry){
    this.listDetails.controls['sector'].setValue(data.sector);
    this.listDetails.controls['authorities'].setValue(data?.authorities?.[0]);
   
   
  }
  add(){
    let item:Ministry =new Ministry;
   
    item.sector=this.listDetails.get(["sector"]).value;
    item.authorities=[]
    item.authorities=this.authorities
    item.authorities.push(this.listDetails.get(["authorities"]).value)
    
    console.log(item)

   this.ministryService.add(item).subscribe({
      next: (data) => {
        console.log(data)
        this.retrieveLists()
        this.cancel()
       
      },
      error: (err) => {
        console.log(err)
        this.notificationService.showError(err.error.message,"");
        
      },})


  }

update(){

  let item:Ministry =this.currentItem;
  console.log(this.authorities)
  item.authorities=this.authorities
  item.authorities.push(this.listDetails.get(["authorities"]).value)
  item.sector=this.listDetails.get(["sector"]).value
  
  console.log(item)

 this.ministryService.update(item).subscribe({
    next: (data) => {
      console.log(data)
      this.cancel()
     this.retrieveLists()
    },
    error: (err) => {
      console.log(err)
      this.notificationService.showError(err.error.message,"");
      
    },})
}

addCode(){
  console.log(this.listDetails.get(["authorities"]).value)
  console.log(this.listDetails.get(["itemNo"]).value)
  let value=this.listDetails.get(["authorities"]).value
  this.authorities.push(value)
  this.listDetails.controls['authorities'].setValue("");
  
}

cancel(){
  this.listDetails.controls['sector'].setValue("");
  this.listDetails.controls['authorities'].setValue("");
  this.authorities=[]
}

}
