import { Company } from "./Company";
import { ProductDetails } from "./ProductDetails";

export class Invoice {
    id?: number;
    purchaseOrderNumber?: string;
    purchaseOrderDate?: Date;
    invoiceNumber?: string;
    invoiceDate?: Date;
    currency?: string;
    originPort?: string;
    destinationPort?: string;
    termsofPayment?: string;
    generalDescription?: string;
    incoTerm?: string;
    originCountry?: string;
    importer?: Company;
    exporter?: Company;
    productDetailsSet?:ProductDetails[];
}