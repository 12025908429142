<app-auth-navbar></app-auth-navbar>
<main class="profile-page">
  <section class="relative block h-500-px">
    <div
      class="absolute top-0 w-full h-full bg-center bg-cover"
      style="background-image: url('assets/images/slider/comp.jpg')"
    >
      <span
        id="blackOverlay"
        class="w-full h-full absolute opacity-50 bg-black"
      ></span>
    </div>
  </section>
  <section class="relative py-16 bg-blueGray-800">
    <div class="container mx-auto px-4">
      <div
        class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-xl rounded-lg -mt-64"
        style="opacity: 0.9; background-color: rgb(241, 242, 248)"
      >
        <div
          class="w-full lg:w-6/12 px-6 py-6"
          style="height: 100%; width: 100%"
        >
          <!--------------------------------------------------------------------->

          <div class="px-6">
            <div class="text-center">
              <div class="flex flex-wrap justify-center">
                <div
                  class="w-full lg:w-9/12 px-4"
                  style="width: 100%; height: 100%"
                >
                  <div class="flex flex-wrap mt-6">
                    <div class="w-full mb-24 px-4">
                      <!------------------------------------------------------>

                      <div class="flex-auto px-4 lg:px-10 py-10 pt-0 text-left">
                        <form
                          action="#"
                          method="post"
                          [formGroup]="listDetails"
                          novalidate
                        >
                          <div class="flex flex-wrap">
                            <!---------------------------------------     ministry ---------------------------------------------------------->

                            <div class="w-full lg:w-6/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'ministryOf' | translate }}*
                                </label>
                                <select
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  formControlName="ministry"
                                  placeholder="Type List "
                                >
                                  <option
                                    *ngFor="let minis of types"
                                    [ngValue]="minis"
                                  >
                                    {{ minis?.sector }}
                                  </option>
                                </select>
                              </div>
                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    (list_step &&
                                      listDetails.controls.ministry.errors &&
                                      listDetails.controls.ministry.dirty) ||
                                    listDetails.controls.ministry.touched
                                  "
                                >
                                  <div *ngIf="list.ministry.errors?.required">
                                    Type List is required
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!--------------------------------------------------------------------->
                            <!---------------------------------------     prouctCodes ---------------------------------------------------------->

                            <div class="w-full lg:w-3/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'productCodes' | translate }}*
                                </label>
                                <input
                                  type="number"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  formControlName="prouctCodes"
                                  placeholder="Prouct Codes"
                                />
                              </div>
                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    (list_step &&
                                      listDetails.controls.prouctCodes.errors &&
                                      listDetails.controls.prouctCodes.dirty) ||
                                    listDetails.controls.prouctCodes.touched
                                  "
                                >
                                  <div
                                    *ngIf="list.prouctCodes.errors?.required"
                                  >
                                    Prouct Codes is required
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!--------------------------------------------------------------------->
                            <div class="w-full lg:w-3/12 px-4 py-6">
                              <div class="relative w-full mb-3">
                                <button
                                  class="bg-black px-2 py-3 text-white active:bg-black font-bold text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none w-full mr-1 ease-linear transition-all duration-150"
                                  type="button"
                                  (click)="addCode()"
                                >
                                  <i class="fas fa-plus-circle">
                                    {{ 'addToProductCode' | translate }}</i
                                  >
                                </button>
                              </div>
                            </div>
                            <!---------------------------------------     productDescription ---------------------------------------------------------->

                            <div class="w-full lg:w-6/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'productDescription' | translate }}
                                </label>
                                <input
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  formControlName="productDescription"
                                  placeholder="Product Description "
                                />
                              </div>
                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    (list_step &&
                                      listDetails.controls.productDescription
                                        .errors &&
                                      listDetails.controls.productDescription
                                        .dirty) ||
                                    listDetails.controls.productDescription
                                      .touched
                                  "
                                >
                                  <div
                                    *ngIf="
                                      list.productDescription.errors?.required
                                    "
                                  >
                                    Product Description is required
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!--------------------------------------------------------------------->

                            <!------------------------------------------             ------------------------------------------------------->

                            <!--------------------------------------------------------------------->

                            <!---------------------------------------     itemNo ---------------------------------------------------------->

                            <div class="w-full lg:w-6/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'itemNo' | translate }}*
                                </label>
                                <input
                                  type="number"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  formControlName="itemNo"
                                  name="itemNo"
                                  placeholder="Item No"
                                />
                              </div>
                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    (list_step &&
                                      listDetails.controls.itemNo.errors &&
                                      listDetails.controls.itemNo.dirty) ||
                                    listDetails.controls.itemNo.touched
                                  "
                                >
                                  <div *ngIf="list.itemNo.errors?.required">
                                    Item No is required
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!--------------------------------------------------------------------->
                          </div>

                          <!--------------------------------------------------------------->

                          <div style="display: flex; justify-content: center">
                            <button
                              class="bg-red-600 text-white active:bg-red-700 font-bold text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                              type="button"
                              [ngStyle]="{
                                'background-color':
                                  listDetails.pristine || listDetails.invalid
                                    ? 'gray'
                                    : '#0d6efd',
                                'border-color':
                                  listDetails.pristine || listDetails.invalid
                                    ? 'gray'
                                    : '#0d6efd',
                                padding: '12px'
                              }"
                              (click)="add()"
                              [disabled]="
                                listDetails.pristine || listDetails.invalid
                              "
                            >
                            {{ 'add' | translate }}
                            </button>
                            <button
                              class="bg-red-600 text-white active:bg-red-700 font-bold text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                              type="button"
                              *ngIf="willbeUpdated"
                              [ngStyle]="{
                                'background-color':
                                  listDetails.pristine || listDetails.invalid
                                    ? 'gray'
                                    : '#14B8A6',
                                'border-color':
                                  listDetails.pristine || listDetails.invalid
                                    ? 'gray'
                                    : '#14B8A6',
                                padding: '12px'
                              }"
                              (click)="update()"
                              [disabled]="
                                listDetails.pristine || listDetails.invalid
                              "
                            >
                              update
                            </button>
                          </div>
                        </form>
                      </div>

                      <!---------------------------------------------------------------------->

                      <div class="block w-full overflow-x-auto">
                        <!-- Projects table -->
                        <table
                          class="items-center w-full bg-transparent border-collapse"
                        >
                          <thead>
                            <tr>
                              <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                [ngClass]="
                                  color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-red-800 text-red-300 border-red-700'
                                "
                              >
                              {{ 'ministryOf' | translate }}
                              </th>
                              <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                [ngClass]="
                                  color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-red-800 text-red-300 border-red-700'
                                "
                              >
                              {{ 'productCodes' | translate }}
                              </th>
                              <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                [ngClass]="
                                  color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-red-800 text-red-300 border-red-700'
                                "
                              >
                              {{ 'productDescription' | translate }}
                              </th>

                              <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                [ngClass]="
                                  color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-red-800 text-red-300 border-red-700'
                                "
                              >
                              {{ 'itemNo' | translate }}
                              </th>

                              <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                [ngClass]="
                                  color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-red-800 text-red-300 border-red-700'
                                "
                              >
                                :
                              </th>

                              <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                [ngClass]="
                                  color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-red-800 text-red-300 border-red-700'
                                "
                              ></th>
                            </tr>
                          </thead>
                          <tbody
                            *ngFor="
                              let item of items
                                | paginate
                                  : {
                                      itemsPerPage: pageSize,
                                      currentPage: page,
                                      totalItems: count
                                    };
                              let i = index
                            "
                            [class.active]="i == currentIndex"
                            (click)="setActiveCompany(item, i)"
                          >
                            <tr>
                              <td
                                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                              >
                                {{ item.ministry?.sector }}
                              </td>

                              <td
                                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                              >
                                <ul *ngFor="let pc of item.prouctCodes">
                                  <li>{{ pc }}</li>
                                </ul>
                              </td>
                              <td
                                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                              >
                                {{ item.productDescription }}
                              </td>

                              <td
                                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                              >
                                {{ item.itemNo }}
                              </td>

                              <td
                                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"
                              >
                                <a
                                  class="cursor-pointer"
                                  (click)="deleteSelectedItem(item.id)"
                                  ><img
                                    src="assets/images/icons/delete.png"
                                    alt="delete"
                                    style="width: 1cm; height: 1cm; float: left"
                                  />
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div class="list row">
                        <div class="col-md-12">
                          <pagination-controls
                            previousLabel="Prev"
                            nextLabel="Next"
                            [responsive]="true"
                            (pageChange)="handlePageChange($event)"
                          ></pagination-controls>
                        </div>
                      </div>

                      <!---------------------------------------------------------------------->

                      <!------------------------------------------------------->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
<app-footer></app-footer>
