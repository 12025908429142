<app-auth-navbar></app-auth-navbar>
<main class="profile-page">
  <section class="relative block h-500-px">
    <div
      class="absolute top-0 w-full h-full bg-center bg-cover"
      style="background-image: url('assets/images/slider/comp.jpg')"
    >
      <span
        id="blackOverlay"
        class="w-full h-full absolute opacity-50 bg-black"
      ></span>
    </div>
  </section>
  <section class="relative py-16 bg-blueGray-800">
    <div class="container mx-auto px-4">
      <div
        class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64"
        style="opacity: 0.9"
      >
        <div
          class="w-full lg:w-6/12 px-6 py-6"
          style="height: 100%; width: 100%"
        >
          <!--------------------------------------------------------------------->

          <div class="px-6">
            <div class="text-center">
              <div class="flex flex-wrap justify-center">
                <div
                  class="w-full lg:w-9/12 px-4"
                  style="width: 100%; height: 100%"
                >
                  <div class="flex flex-wrap mt-6">
                    <div class="w-full mb-24 px-4">
                      <div class="rounded-t mb-0 px-4 border-0">
                        <div class="py-6 px-3 mt-32 sm:mt-0">
                          <form
                            action="#"
                            method="post"
                            [formGroup]="searchForm"
                            novalidate
                          >
                            <div class="flex flex-wrap">
                              <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                  <label
                                    class="block text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                  >
                                  {{ 'origin_country' | translate }}
                                  </label>
                                  <select
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    id="originCountry"
                                    formControlName="originCountry"
                                    (change)="onchange()"
                                  >
                                    <option selected value="" disabled>
                                      {{ 'origin_country' | translate }}
                                    </option>
                                    <option
                                      *ngFor="let country of countryList"
                                      value="{{ country }}"
                                    >
                                      {{ country }}
                                    </option>
                                  </select>
                                </div>
                              </div>

                              <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                  <label
                                    class="block text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                  >
                                  {{ 'rne' | translate }}
                                  </label>
                                  <input
                                    type="text"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder="RNE"
                                    id="rne"
                                    formControlName="rne"
                                  />
                                </div>
                              </div>

                              <div class="w-full lg:w-4/12 px-4">
                                <div class="relative w-full mb-3">
                                  <label
                                    class="block text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                  >
                                  {{ 'company_name' | translate }}
                                  </label>
                                  <input
                                    type="text"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder="Company Name"
                                    id="companyName"
                                    formControlName="companyName"
                                  />
                                </div>
                              </div>
                            </div>
                            <input
                              class="bg-blueGray-600 text-white active:bg-blueGray-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                              type="reset"
                              value="Cancel"
                              (click)="cancel()"
                            />
                            <button
                              class="bg-black active:bg-blueGray-200 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                              type="button"
                              (click)="search()"
                            >
                              <i class="fas fa-search"></i>
                              {{ 'search' | translate }}
                            </button>
                          </form>

                          <div class="rounded-t mb-0 px-4 py-3 border-0">
                            <div class="flex flex-wrap items-center">
                              <div
                                class="relative w-full px-4 max-w-full flex-grow flex-1"
                              >
                                <h3
                                  class="px-6 align-middle border border-solid py-3 text-lg uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center titles"
                                  [ngClass]="
                                    color === 'light'
                                      ? 'text-blueGray-700'
                                      : 'text-white'
                                  "
                                >
                                {{ 'exporters' | translate }}
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="flex flex-wrap">
                        <!----------------------------------------------------->
                        <!----------------------------------------------------->

                        <div class="block w-full overflow-x-auto">
                          <!-- Projects table -->
                          <table
                            class="items-center w-full bg-transparent border-collapse"
                          >
                            <thead>
                              <tr>
                                <th
                                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                  [ngClass]="
                                    color === 'light'
                                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                      : 'bg-red-800 text-red-300 border-red-700'
                                  "
                                >
                                {{ 'name' | translate }}
                                </th>

                                <th
                                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                  [ngClass]="
                                    color === 'light'
                                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                      : 'bg-red-800 text-red-300 border-red-700'
                                  "
                                >
                                {{ 'attachments' | translate }}
                                </th>

                                <th
                                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                  [ngClass]="
                                    color === 'light'
                                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                      : 'bg-red-800 text-red-300 border-red-700'
                                  "
                                >
                                {{ 'commercial_registre' | translate }}
                                </th>

                                <th
                                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                  [ngClass]="
                                    color === 'light'
                                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                      : 'bg-red-800 text-red-300 border-red-700'
                                  "
                                >
                                {{ 'country' | translate }}
                                </th>

                                <th
                                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                  [ngClass]="
                                    color === 'light'
                                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                      : 'bg-red-800 text-red-300 border-red-700'
                                  "
                                >
                                {{ 'status' | translate }}
                                </th>

                                <th
                                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                  [ngClass]="
                                    color === 'light'
                                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                      : 'bg-red-800 text-red-300 border-red-700'
                                  "
                                >
                                {{ 'options' | translate }}
                                </th>
                              </tr>
                            </thead>

                            <tbody
                              *ngFor="
                                let company of companies
                                  | paginate
                                    : {
                                        itemsPerPage: pageSize,
                                        currentPage: page,
                                        totalItems: count
                                      };
                                let i = index
                              "
                              [class.active]="i == currentIndex"
                              (click)="setActiveCompany(company, i)"
                            >
                              <tr>
                                <td
                                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                >
                                  {{ company.raisonSocial }}
                                </td>

                                <td
                                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                >
                                  <a
                                    routerLink="/display-attachments/{{
                                      company.company_id
                                    }}"
                                    style="
                                      display: flex;
                                      justify-content: center;
                                    "
                                    ><img
                                      src="assets/images/icons/attach.png"
                                      alt="Attachments"
                                      style="
                                        width: 1cm;
                                        height: 1cm;
                                        float: left;
                                      "
                                    />
                                  </a>
                                </td>

                                <td
                                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                >
                                  {{ company.rne }}
                                </td>

                                <td
                                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                >
                                  {{ company.companyCountry }}
                                </td>

                                <td
                                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                >
                                  <p
                                    *ngIf="company.etat == 'REJECTED'"
                                    style="color: red"
                                  >
                                  {{ 'blacklisted' | translate }}
                                  </p>
                                  <p
                                    *ngIf="company.etat != 'REJECTED'"
                                    style="color: green"
                                  >
                                    {{ company.etat }}
                                  </p>
                                </td>

                                <td
                                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"
                                >
                                  <a
                                    class="cursor-pointer"
                                    (click)="
                                      openDialog(dialogTemplate, 'reject')
                                    "
                                    *ngIf="company.etat != 'REJECTED'"
                                    ><img
                                      src="assets/images/icons/blacklist.png"
                                      alt="update"
                                      style="
                                        width: 1cm;
                                        height: 1cm;
                                        float: left;
                                      "
                                    />
                                  </a>
                                  <a *ngIf="company.etat == 'REJECTED'"
                                    ><img
                                      src="assets/images/icons/blacklisted.png"
                                      alt="update"
                                      style="
                                        width: 1cm;
                                        height: 1cm;
                                        float: left;
                                      "
                                    />
                                  </a>
                                </td>
                              </tr>
                              <!-------------------------------------- DIALOG ----------------------------------------------------------------------------->
                              <ng-template #dialogTemplate>
                                <div mat-dialog-content>
                                  <form
                                    action="#"
                                    method="post"
                                    [formGroup]="dailogForm"
                                    novalidate
                                  >
                                    <div class="w-full lg:w-12/12 px-4">
                                      <div class="relative w-full mb-3">
                                        <label
                                          class="block text-white text-xs font-bold mb-2"
                                          htmlFor="grid-password"
                                        >
                                          {{ reasonComment }}
                                        </label>
                                        <textarea
                                          type="text"
                                          class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                          rows="4"
                                          placeholder="{{ reasonComment }}"
                                          id="comment"
                                          formControlName="comment"
                                        >
                                        </textarea>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                                <div mat-dialog-actions>
                                  <button
                                    mat-button
                                    class="bg-blueGray-400 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    [mat-dialog-close]="true"
                                  >
                                  {{ 'close' | translate }}
                                  </button>

                                  <button
                                    mat-button
                                    class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    (click)="reject(company)"
                                    *ngIf="isRejected"
                                  >
                                  {{ 'save' | translate }}
                                  </button>
                                </div>
                              </ng-template>

                              <!------------------------------------------------------------------------------------------------------------------------------------->
                            </tbody>
                          </table>

                          <div
                            class="list row"
                            style="display: flex; justify-content: center"
                          >
                            <div class="col-md-12">
                              <pagination-controls
                                previousLabel="Prev"
                                nextLabel="Next"
                                [responsive]="true"
                                (pageChange)="handlePageChange($event)"
                              ></pagination-controls>
                            </div>
                          </div>

                          <!---------------------------------------->
                        </div>

                        <!------------------------------------------------------->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!---------------------------------------------------------------------->
        </div>
      </div>
    </div>
  </section>
</main>
<app-footer></app-footer>
