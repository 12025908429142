import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AcidRequest } from 'src/app/models/AcidRequest';
import { AcidRequestService } from 'src/app/services/acidRequest.service';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import {  countries } from 'countries-list';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/User';

@Component({
  selector: 'app-admin-acid-requests',
  templateUrl: './admin-acid-requests.component.html',
  styleUrls: ['./admin-acid-requests.component.css']
})
export class AdminAcidRequestsComponent implements OnInit {
  @Input()
  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";
  acidRequests:AcidRequest[]=[]
  acidRequestsAll:AcidRequest[]=[]
  currentRequest: AcidRequest = new AcidRequest;
  currentIndex = -1;
  title = '';
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 50, 60];
  userId:any;
  searchForm: FormGroup | any;
  countryList: any[] = [];
  statusList:String[]=[
    'ACCEPTED',
    'REJECTED',
    'WAITING',
    'ADDITIONAL_INFORMATION',
    'WAITING_EXPORTER_ACCEPT'
    ]
  
    isMinistry=false
    ministryId:any;
    AcidConsultAuthority=false
    isAdmin=false
  constructor(public authService: AuthService,private fb: FormBuilder,public notificationService:NotificationService,
    private acidRequestService:AcidRequestService,public userService:UserService)
   { 
    this.userId=authService.currentUserValue.user_id 
    Object.values(countries).forEach((country: any) => {
      this.countryList.push( country.name);
      this.countryList=this.countryList.sort()
    });
    this.searchForm = this.fb.group({
      
      rne:['', [Validators.required ]],
      companyName:['', [Validators.required ]],
      originCountry:['', [Validators.required ]],
      invoiceDate:['', [Validators.required ]],
      acidNumber:['', [Validators.required ]],
      state:['', [Validators.required ]],
      
       
    });
  }

  ngOnInit(): void {
    this.userId=this.authService.currentUserValue.user_id 
    this.userService.getUserById(this.userId).subscribe(  (data:  User) => {
      
      if(data.roles){
        for(let role of data.roles){
          
        if(role.name=="MINISTRY"){
          this.isMinistry=true
          this.ministryId=data.ministry?.id
          if(data.ministry?.authorities?.includes('Consult ACID Folders')){
            this.AcidConsultAuthority=true

          }
        }
        }
        this.retrieveAcidRequests()
      }
     
    })

    
    
  }

  retrieveAcidRequests(): void {
   
    if(this.isMinistry){
      this.acidRequestService.getRequestsByMinistry(this.ministryId)
    .subscribe(
      data => {   
     
        this.acidRequests = data;
        console.log(data);
      },
      error => {
        console.log(error);
      });

    }else{
      this.acidRequestService.getRequests()
    .subscribe(
      response => {
        const { acidRequests, totalItems } = response;
        this.acidRequests = acidRequests;
        this.acidRequestsAll=acidRequests;
        this.count = totalItems;  
        console.log(acidRequests);
       
      },
      error => {
        console.log(error);
      });

    }
    
  }
  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveAcidRequests();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveAcidRequests();
  }

  refreshList(): void {
    this.retrieveAcidRequests();
    this.currentRequest = new AcidRequest;
    this.currentIndex = -1;
  }

  setActiveCompany(tutorial: AcidRequest, index: number): void {
    this.currentRequest = tutorial;
    this.currentIndex = index;
  }

  onchange(){
    this.acidRequests=this.acidRequestsAll
    let country=this.searchForm.controls['originCountry'].value
 
    this.acidRequests=this.acidRequests.filter(request => request.generalSegment?.invoice?.originCountry === country)

  }
  onchangeStatus(){
    this.acidRequests=this.acidRequestsAll
    let state=this.searchForm.controls['state'].value
     this.acidRequests=this.acidRequests.filter(request => request.state === state )

    

  }
  cancel(){
    this.acidRequests=this.acidRequestsAll
    window.location.href='admin/acid-requests'
  }


  search(){
    this.acidRequests=this.acidRequestsAll
    let rne=this.searchForm.controls['rne'].value
    let companyName=this.searchForm.controls['companyName'].value
    let invoiceDate=this.searchForm.controls['invoiceDate'].value
    let acidNumber=this.searchForm.controls['acidNumber'].value
    let state=this.searchForm.controls['state'].value
    let country=this.searchForm.controls['originCountry'].value
    
  

    if(!(rne == "")){
      this.acidRequests=this.acidRequests.filter(request => request.generalSegment?.invoice?.importer?.rne === rne || request.generalSegment?.invoice?.exporter?.rne === rne)

    }
    if(!(companyName == "")){
      this.acidRequests=this.acidRequests.filter(request => request.generalSegment?.invoice?.importer?.raisonSocial == companyName || request.generalSegment?.invoice?.exporter?.raisonSocial == companyName)

    }
    if(!(invoiceDate == "")){
      this.acidRequests=this.acidRequests.filter(request => request.generalSegment?.invoice?.invoiceDate?.getDate == invoiceDate )

    }
    if(!(acidNumber == "")){
      this.acidRequests=this.acidRequests.filter(request => request.acidNumber == acidNumber )

    }
    if(!(state == "")){
      this.acidRequests=this.acidRequests.filter(request => request.state == state )

    }
    if(!(country == "")){
      this.acidRequests=this.acidRequests.filter(request => request.generalSegment?.invoice?.originCountry === country)

  }

    }
  }
 


