<app-auth-navbar></app-auth-navbar>
<main class="profile-page">
  <section class="relative block h-500-px">
    <div
      class="absolute top-0 w-full h-full bg-center bg-cover"
      style="
        background-image: url('assets/images/slider/comp.jpg');
      "
    >
      <span
        id="blackOverlay"
        class="w-full h-full absolute opacity-50 bg-black"
      ></span>
    </div>
    <div
      class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
      style="transform: translateZ(0)"
    >
      <svg
        class="absolute bottom-0 overflow-hidden"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          class="text-blueGray-200 fill-current"
          points="2560 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>
  </section>
  <section class="relative py-16 bg-blueGray-200">
    <div class="container mx-auto px-4">
      <div
        class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64"
      >
        <div class="px-6">
          
          
          <div class="mt-10 py-10   text-center" >
            <div class="flex flex-wrap justify-center">
              <div class="w-full lg:w-9/12 px-4" style="width: 100%; height: 100%;">

                <div class="block w-full overflow-x-auto " style="display: flex;justify-content: center;" >
                  
                  <!-- Projects table -->
                   <table class="items-center w-full bg-transparent border-collapse w-full lg:w-6/12 px-4 " >
               
                      <tr >
                        
                      
              
                        <th
                      class=" px-6  align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                     
                    >
                   
                    Commercial Register
                    </th>
                    <td  class="w-full lg:w-4/12 px-4 text-left  align-middle border border-solid py-3 text-xs  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                      <div class="file-input-wrapper">
                          <label for="file-input" class="custom-file-input" style="display: flex;justify-content: left;">Choose a file</label>
                      <input class="" id="file-input" type="file" class="file-input" accept="application/pdf" (change)="onFileChange($event,'Commercial register')" >
                      <span id="file-name" class="file-name">{{uploadedFiles['Commercial register']?.name}}</span>
                      </div>
                    </td>
              
                    <td  class="w-full lg:w-3/12 px-4 text-left  align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    *ngIf="uploadedFiles['Commercial register']== null"
                    >
                     <a> <img   src="assets/images/icons/notice.png" alt="To upload" style="width: 30%; height:  30%;float: left;" ></a>
                    </td>
              
                    <td  class="w-full lg:w-3/12 px-4 text-left  align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    *ngIf="uploadedFiles['Commercial register']!=null"
                    >
                      <a> <img   src="assets/images/icons/checked.png" alt="checked" style="width: 30%; height:  30%;float: left;"></a>
                     </td>
              
                    
              
                  </tr>
              
                  <tr>
                        
                      
                        <th
                          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          
                        >
                        Tax ID
                        </th>
                        <td  class="px-6 align-middle border border-solid py-3 text-xs  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                          <div class="file-input-wrapper">
                              <label for="file-input" class="custom-file-input" style="display: flex;justify-content: left;">Choose a file</label>
                          <input class=" text-left" id="file-input" type="file" class="file-input"  accept="application/pdf" (change)="onFileChange($event,'Tax ID')">
                          <span id="file-name" class="file-name">{{uploadedFiles['Tax ID']?.name}}</span>
                      </div>
                        </td>
              
                        <td  class="w-full lg:w-3/12 px-4 text-left  align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    *ngIf="uploadedFiles['Tax ID']==null"
                    >
                     <a> <img   src="assets/images/icons/notice.png" alt="To upload" style="width: 30%; height:  30%;float: left;" ></a>
                    </td>
              
                    <td  class="w-full lg:w-3/12 px-4 text-left  align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    *ngIf="uploadedFiles['Tax ID']!=null"
                    >
                      <a> <img   src="assets/images/icons/checked.png" alt="checked" style="width: 30%; height:  30%;float: left;"></a>
                     </td>
                      </tr>
              
                      <tr>
                       
                        <th
                          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                         
                        >
                       CIN/Passport Manager
                        </th>
                        <td  class="px-6 align-middle border border-solid py-3 text-xs  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                          <div class="file-input-wrapper">
                          <label for="file-input" class="custom-file-input" style="display: flex;justify-content: left;">Choose a file</label>
                          <input class=" text-left " id="file-input" type="file" class="file-input" accept="application/pdf" (change)="onFileChange($event,'CIN Manager')" >
                          
                          <span id="file-name" class="file-name">{{uploadedFiles['CIN Manager']?.name}}</span>
                      </div>
                        </td>
              
              
                        <td  class="w-full lg:w-3/12 px-4 text-left  align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                        *ngIf="uploadedFiles['CIN Manager']==null"
                        >
                         <a> <img   src="assets/images/icons/notice.png" alt="To upload" style="width: 30%; height:  30%;float: left;" ></a>
                        </td>
                  
                        <td  class="w-full lg:w-3/12 px-4 text-left  align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                        *ngIf="uploadedFiles['CIN Manager']!=null"
                        >
                          <a> <img   src="assets/images/icons/checked.png" alt="checked" style="width: 30%; height:  30%;float: left;"></a>
                         </td>
                      </tr>
              
                      <tr>
                       
                          <th
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                           
                          >
                         Status
                          </th>
                          <td  class="px-6 align-middle border border-solid py-3 text-xs  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            <div class="file-input-wrapper">
                            <label for="file-input" class="custom-file-input" style="display: flex;justify-content: left;">Choose a file</label>
                            <input class=" text-left " id="file-input" type="file" class="file-input" accept="application/pdf" (change)="onFileChange($event,'Status')" >
                            
                            <span id="file-name" class="file-name">{{uploadedFiles['Status']?.name}}</span>
                        </div>
                          </td>
                
                
                          <td  class="w-full lg:w-3/12 px-4 text-left  align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          *ngIf="uploadedFiles['Status']==null"
                          >
                           <a> <img   src="assets/images/icons/notice.png" alt="To upload" style="width: 30%; height:  30%;float: left;" ></a>
                          </td>
                    
                          <td  class="w-full lg:w-3/12 px-4 text-left  align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          *ngIf="uploadedFiles['Status']!=null"
                          >
                            <a> <img   src="assets/images/icons/checked.png" alt="checked" style="width: 30%; height:  30%;float: left;"></a>
                           </td>
                        </tr>
              
                        
              <br>  
              
                    
                  </table>
              
                  
              
              <!---------------------------------------->
              
              
              
               
              
              
              </div>
              
              <div class="w-full lg:w-12/12 px-4 py-3" style="display: flex;justify-content: center;" 
              
              >
                  <input
                  class="bg-blueGray-600 active:bg-blueGray-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                  type="reset"
                  value="Cancel"
                 
                 
                >
                  <button
                  class="bg-black active:bg-blueGray-200 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                  type="button"
                 (click)="upload()"
                 
                >
                  Upload
                </button>
              
              
              </div>
                
                
                
                <!-------------------------------------------------------->
                
                <div class="flex flex-wrap mt-6" *ngFor="let item of combinedData">
                  <div class="w-full mb-24 px-4" 
                  >

                    <!--------------------     Attachment    ------------------------------------>

                    <div class="rounded-t mb-0 px-4 py-3 border-0">
                      <div class="relative w-full px-4 max-w-full flex-grow flex-1">        
                        <h3
                            class="px-6 align-middle border border-solid py-3 text-lg uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center"
                        >
                        {{item.att.fileData}}
                        <a (click)="delete(item.att.id)"><img   src="assets/images/icons/delete.png" alt="delete" style="width: 3%; height:  3%;float: right;"> </a>
                        <a (click)="onChange()"><img   src="assets/images/icons/exchange.png" alt="repalce" style="width:3%; height:  3%;float: right;"> </a>
                       </h3>
                       <div *ngIf="willBeReplaced">
                        <br>
                       
                            <label class="btn btn-default p-0">
                              <input type="file" accept="application/pdf" (change)="onFileChange($event,item?.att?.fileData)" />
                            </label>
                        

                        <button 
                        *ngIf="item.pdfsrc"
                         class="bg-lightBlue-500 text-white active:bg-teal-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
                         type="button"
                         (click)="replace(item.att.id)">
                          replace
                        </button>

                       

                       </div>
                      </div>
                    </div>
                    <embed [src]="item?.pdfsrc" type="application/pdf" width="100%" height="600px" />
                     <!--------------------     Attachment    ------------------------------------>

                  </div>
                </div>









                <!----------------------------------------------------->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
<app-footer></app-footer>
