import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Company } from 'src/app/models/Company';
import { CompanyDeclarantMemberDTO } from 'src/app/models/CompanyDeclarantMemberDTO';
import { User } from 'src/app/models/User';
import { AttachmentService } from 'src/app/services/attachment.service';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';
import {  countries } from 'countries-list';
@Component({
  selector: 'app-add-companyform',

  templateUrl: './add-companyform.component.html',
  //'./newFile.html',

 

  styleUrls: [
  './add-companyform.component.css'
   //'./newFile.css',
]
})
export class AddCompanyformComponent implements OnInit {



  personalDetails: FormGroup | any;
  addressDetails: FormGroup | any;
  educationalDetails: FormGroup | any;
  filesToUpload: FormGroup | any;
  personal_step = false;
  address_step = false;
  education_step = false;
  files_step = false;
  step =0;
  firstname ?:string;
  lastname?:string;
  email?:string;
  companyId: any;
  willBeUpdate:Boolean= false;
  companyToUpdate:Company=new Company
  userId:any
  user:User=new User;
  plceHolder:any="Expiry Date";
  typeIsSelected=false;
  companyAddedID:any;
  countryList: any[] = [];
/****************** Part of upload attachement ************************* */
  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  message = '';
  fileInfos?: Observable<any>;
  registrationNumberFile: FileList;
  nationalregister: FileList;
  evidencepermission: FileList;
  evidencepowerFile: FileList;


   file1=false;
   file2=false;
   file3=false;
   file4=false;

  /**************************************************************** */


  constructor(private route: ActivatedRoute,private formBuilder: FormBuilder ,public authService: AuthService,
    public companyService:CompanyService,public notificationService:NotificationService,
    public userService:UserService,public attachmentService:AttachmentService) {

      Object.values(countries).forEach((country: any) => {
        this.countryList.push( country.name);
        this.countryList=this.countryList.sort()
      }); 

    this.firstname=this.authService.currentUserValue.firstName;
    this.lastname=this.authService.currentUserValue.lastName;
    this.email=this.authService.currentUserValue.email;
    this.userId=this.authService.currentUserValue.user_id;
    



    this.personalDetails = this.formBuilder.group({
      activityType: ['', Validators.required],
      raisonSocial: ['', Validators.required],
      rne: ['', Validators.required],
      matriculeFiscal: ['', Validators.required],
      documentSubmissionCentre: [''],
      email: ['', [Validators.required ,  Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      phone: ['',Validators.required],
      address: ['',Validators.required],
      country: ['Tunisia',Validators.required],
      foodImporter: [false, Validators.required],
  });


  this.addressDetails = this.formBuilder.group({
      firstlastname: { value: this.firstname +' ' +this.lastname, disabled: true },
      applicantStatus: ['', Validators.required],
      typeofIdentification: ['', Validators.required],
      nationalID: ['', Validators.required],
      expiryDate: ['',Validators.required],
      email: { value: this.email, disabled: true },
      phone: ['',Validators.required],

  });

  this.educationalDetails = this.formBuilder.group({
    haveCustomerNumber: [false, Validators.required],
    
  });
  this.filesToUpload= this.formBuilder.group({

    registrationNumberField: ['', Validators.required],
    nationalregisterField: ['', Validators.required],
    evidencepermissionField: ['', Validators.required],
    evidencepowerFileField: ['', Validators.required],



  })
  


   }




   intializeForm(){
    
    this.educationalDetails = this.formBuilder.group({
      haveCustomerNumber: [true, Validators.required],
      
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      username: ['', Validators.required],
      typeofIdentification: ['', Validators.required],
      nationalID: ['', Validators.required],
      expiryDate: ['',Validators.required],
      email: ['', [Validators.required ,  Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      phone: ['',Validators.required],
      teamName: ['',Validators.required],
    });

   }

  ngOnInit() {
    
    this.firstname=this.authService.currentUserValue.firstName;
    this.lastname=this.authService.currentUserValue.lastName;
    this.email=this.authService.currentUserValue.email;

    this.route.paramMap.subscribe(params => {
      const companyIdParam = params.get('companyId');
      this.companyId = companyIdParam ? +companyIdParam : null;
      console.log("CompanyID for update: "+this.companyId)
      if(this.companyId != null){
        this.willBeUpdate=true;
        this.onUpdate(this.companyId)
      }
    });

    
  }

  get personal() { return this.personalDetails.controls; }
  
  get address() { return this.addressDetails.controls; }

  get education() { return this.educationalDetails.controls; }

  get filesUpload() { return this.filesToUpload.controls; }
  next(){

    if(this.step==1){
          this.personal_step = true;
          if (this.personalDetails.invalid) { 
            this.notificationService.showError('',"Invalid fields!");
            return  }
          this.step=this.step+1
    }
    else if(this.step==2){
      this.address_step = true;
      if (this.educationalDetails.invalid) { this.notificationService.showError('',"Invalid fields!") ;
      return }
          this.step++;
  }

    
    else if(this.step==3){
      this.address_step = true;
      if (this.addressDetails.invalid) { this.notificationService.showError('',"Invalid fields!") ;
      return }
          this.step++;
  }
  else if(this.step==4){
    this.files_step =true
    if (this.filesToUpload.invalid) { 
      this.notificationService.showError('',"Invalid fields!");
      return  }
    
          this.step++;
  }
  

  }

  previous(){
    this.step--
   
    if(this.step==1){
      this.address_step = false;
    }
    if(this.step==2){
      this.education_step = false;
    }
   
  }

  addCompany(){
  let companyDeclarantMemberDTO:CompanyDeclarantMemberDTO=new CompanyDeclarantMemberDTO;



  companyDeclarantMemberDTO.activityType=this.personalDetails.get(["activityType"]).value;
  //companyDeclarantMemberDTO.reference=this.personalDetails.get(["address"]).value;
  companyDeclarantMemberDTO.rne=this.personalDetails.get(["rne"]).value;
  companyDeclarantMemberDTO.matriculeFiscal=this.personalDetails.get(["matriculeFiscal"]).value;
  companyDeclarantMemberDTO.raisonSocial=this.personalDetails.get(["raisonSocial"]).value;
  companyDeclarantMemberDTO.documentSubmissionCentre=this.personalDetails.get(["documentSubmissionCentre"]).value;
  companyDeclarantMemberDTO.foodImporter=this.personalDetails.get(["foodImporter"]).value;
  companyDeclarantMemberDTO.companyAdress=this.personalDetails.get(["address"]).value;
  companyDeclarantMemberDTO.companyCountry=this.personalDetails.get(["country"]).value;
  companyDeclarantMemberDTO.companyPhone=this.personalDetails.get(["phone"]).value;
  companyDeclarantMemberDTO.companyEmail=this.personalDetails.get(["email"]).value;
  //companyDeclarantMemberDTO.logoCompany=this.addressDetails.get(["address"]).value;
  companyDeclarantMemberDTO.applicantStatus=this.addressDetails.get(["applicantStatus"]).value;
  companyDeclarantMemberDTO.haveCustomerNumber= this.educationalDetails.get(["haveCustomerNumber"]).value;

  companyDeclarantMemberDTO.typeofIdentificationDeclarant=this.addressDetails.get(["typeofIdentification"]).value;
  companyDeclarantMemberDTO.nationalIDDeclarant=this.addressDetails.get(["nationalID"]).value;
  companyDeclarantMemberDTO.expiryDateDeclarant=this.addressDetails.get(["expiryDate"]).value;
  //companyDeclarantMemberDTO.email=this.addressDetails.get(["email"]).value;
  companyDeclarantMemberDTO.phoneNumberDeclarant=this.addressDetails.get(["phone"]).value;


  if(this.educationalDetails.get(["haveCustomerNumber"]).value){


  companyDeclarantMemberDTO.firstName=this.educationalDetails.get(["firstname"]).value;
  companyDeclarantMemberDTO.lastName=this.educationalDetails.get(["lastname"]).value;
  companyDeclarantMemberDTO.username=this.educationalDetails.get(["username"]).value;
  companyDeclarantMemberDTO.team_name=this.educationalDetails.get(["teamName"]).value;
  companyDeclarantMemberDTO.typeofIdentification=this.educationalDetails.get(["typeofIdentification"]).value;
  companyDeclarantMemberDTO.nationalID=this.educationalDetails.get(["nationalID"]).value;
  companyDeclarantMemberDTO.expiryDate=this.educationalDetails.get(["expiryDate"]).value;
  companyDeclarantMemberDTO.email=this.educationalDetails.get(["email"]).value;
  companyDeclarantMemberDTO.phoneNumber=this.educationalDetails.get(["phone"]).value;

  }


 
 
    

this.companyService.addCompanyDeclarantMember(this.authService.currentUserValue.user_id,companyDeclarantMemberDTO).subscribe({
  next: (data) => {  
    this.companyAddedID=data.company_id
    console.log(this.companyAddedID)
    //this.notificationService.showSuccess('',"Company added!")
    this.next()
    
  },
  error: (err) => {
      this.notificationService.showError(err.error.message, '');
      console.log(err);
      stop;
    },
  
 
});
    

  }

  submit(){

    console.log(this.step)
    if(this.step==3){
      //this.education_step = true;
     // if (this.educationalDetails.invalid) { 
        //this.notificationService.showError('',"Invalid fields!")
       // return }
        /*************** add Company ******************** */
        this.addCompany()
        
        /****************Declarant ********************* */    
        //this.declarantDetails()
       /*************** add Member ******************** 
        if(this.educationalDetails.get(["haveCustomerNumber"]).value){
            this.addMember()
     }
     ************************************* */
     
    }
  }

  onUpdate(id:any){
    this.step=1

  

    this.companyService.getCompanyById(id).subscribe({
      next: (data:  Company) => { 
        if(data.manager) {
          if(data.manager.user_id==this.userId){
            this.companyToUpdate=data;
            this.personalDetails.controls['activityType'].setValue(data.activityType);
            this.personalDetails.controls['activityType'].disable();
    
    
            this.personalDetails.controls['rne'].setValue(data.rne);
            this.personalDetails.controls['rne'].disable();
    
            this.personalDetails.controls['matriculeFiscal'].setValue(data.matriculeFiscal);
            this.personalDetails.controls['matriculeFiscal'].disable();
    
            this.personalDetails.controls['raisonSocial'].setValue(data.raisonSocial);
            this.personalDetails.controls['raisonSocial'].disable();
            this.personalDetails.controls['documentSubmissionCentre'].setValue(data.documentSubmissionCentre);
            this.personalDetails.controls['documentSubmissionCentre'].disable();
            this.personalDetails.controls['foodImporter'].setValue(data.foodImporter);
            this.personalDetails.controls['foodImporter'].disable();
    
            this.personalDetails.controls['address'].setValue(data.companyAdress);
            this.personalDetails.controls['country'].setValue(data.companyCountry);
            this.personalDetails.controls['phone'].setValue(data.companyPhone);
            this.personalDetails.controls['email'].setValue(data.companyEmail);


          }else{
            this.notificationService.showError("", "Company not found!");
          }
        }
       

        
      },
      error: (err) => {
        console.log(err)
        this.notificationService.showError("", "Company not found!");
      },
    });
    

  }


  /*
  declarantDetails(){
    let user:User=new User
    this.userService.getUserById(this.userId).subscribe({
      next: (data:User) => {
        
        user=data
        user.typeofIdentification=this.addressDetails.get(["typeofIdentification"]).value;
        user.nationalID=this.addressDetails.get(["nationalID"]).value;
        user.expiryDate=this.addressDetails.get(["expiryDate"]).value;
        user.email=this.addressDetails.get(["email"]).value;
        user.phoneNumber=this.addressDetails.get(["phone"]).value;
        
    this.userService.updateUser(this.userId,user).subscribe({
      next: (data:User) => {
        console.log("User updated")
      },
      error: (err) => {
        console.log(err)
        this.notificationService.showError(err.error.message,"");
        stop;
      },})

      },
      error: (err) => {
        console.log(err)
        this.notificationService.showError("", "User not found!");
        stop;
      },
    })

    

  }

  addMember(){
    let user:User=new User;
    user.firstName=this.educationalDetails.get(["firstname"]).value;
    user.lastName=this.educationalDetails.get(["lastname"]).value;
    user.username=this.educationalDetails.get(["username"]).value;
    user.team_name=this.educationalDetails.get(["teamName"]).value;
    user.typeofIdentification=this.educationalDetails.get(["typeofIdentification"]).value;
    user.nationalID=this.educationalDetails.get(["nationalID"]).value;
    user.expiryDate=this.educationalDetails.get(["expiryDate"]).value;
    user.email=this.educationalDetails.get(["email"]).value;
    user.phoneNumber=this.educationalDetails.get(["phone"]).value;

    this.userService.addMember(this.userId,user).subscribe({
      next: (data:User) => {
        console.log("new Member"+user)
      },
      error: (err) => {
        console.log(err)
        this.notificationService.showError(err.error.message,"");
        
      },})


  }
  
*/
  update(){

    console.log(this.companyToUpdate)
    
    

    //this.companyToUpdate.raisonSocial=this.personalDetails.get(["raisonSocial"]).value;
    this.companyToUpdate.companyAdress=this.personalDetails.get(["address"]).value;
    this.companyToUpdate.companyCountry=this.personalDetails.get(["country"]).value;
    this.companyToUpdate.companyPhone=this.personalDetails.get(["phone"]).value;
    this.companyToUpdate.companyEmail=this.personalDetails.get(["email"]).value;
    //this.companyToUpdate.logoCompany=this.addressDetails.get(["address"]).value;
    this.companyService.updateCompany( this.companyToUpdate.company_id,this.companyToUpdate).subscribe({
      next: (data) => {
        this.notificationService.showSuccess("", "Company Updated!");
        window.location.href ='dashboard'

      },
      error: (err) => {
        console.log(err)
        this.notificationService.showError("", "Company not found!");
      },
    });
    
    
  }


  onchange(){
   

    if(this.addressDetails.get(["typeofIdentification"]).value=="CIN"){
      this.plceHolder="Issuance Date"
    }else{
      this.plceHolder="Expiry Date"
    }
      
  }

  onchangeMember(){
   

    if(this.educationalDetails.get(["typeofIdentification"]).value=="CIN"){
      this.plceHolder="Issuance Date"
    }else{
      this.plceHolder="Expiry Date"
    }
      
  }

  typeSelected(text:any){
    this.personalDetails.controls['activityType'].setValue(text);
            this.personalDetails.controls['activityType'].disable();
    this.typeIsSelected=true;
    this.step =1
  }


  /********************************* 
   * 
   * 
   * Attachments 
   * 
   * 
   * 
   * *************************************** */


  selectRegistrationNumberFile(event: any): void {
    this.registrationNumberFile= event.target.files;
  }
  selectNationalregister(event: any): void {
    this.nationalregister= event.target.files;
  }
  selectEvidencepermission(event: any): void {
    this.evidencepermission= event.target.files;
  }
  selectevidencepowerFile(event: any): void {
    this.evidencepowerFile= event.target.files;
  }




  upload(dataType:any,selectedFiles:FileList): void {
    this.progress = 0;
   console.log(selectedFiles)

    if (selectedFiles) {
      const file: File | null = selectedFiles.item(0);

      if (file) {
        
        //this.companyAddedID=34
        this.attachmentService.uploadFile(this.userId,this.companyAddedID ,dataType,file).subscribe({
          next: (event: any) => {
            console.log("Upload : "+event)
            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round(100 * event.loaded / event.total);
            } else if (event instanceof HttpResponse) {
              this.message = event.body.message;
              //this.fileInfos = this.uploadService.getFiles();
            }

            if(dataType=="Commercial register"){
              this.file1=true
            }
            if(dataType=="Tax ID"){
              this.file2=true

            }
            if(dataType=="CIN Manager"){
              this.file3=true

            }
            if(dataType=="Status"){
              this.file4=true

            }
            if(this.file1 && this.file2 && this.file3 && this.file4 ){
              this.notificationService.showSuccess('','Documents uploded!')
              this.file1=false;
              this.file2=false;
              this.file3=false;
              this.file4=false;
            
              window.location.href ='dashboard'
            }
           
          },
          error: (err: any) => {
            console.log(err);
            this.progress = 0;

            

            if (err.error && err.error.message) {
              this.message = err.error.message;
            } else {
              this.message = 'Could not upload the file!';
            }

            this.notificationService.showError('',this.message)

            
          }
        });
      }

      this.selectedFiles = undefined;
      //this.next()
    }
  }

  uploadAll(){
    
   
    let dataType:any

    if(this.registrationNumberFile){
      dataType="Commercial register"
      this.upload(dataType,this.registrationNumberFile)
      console.log(this.file1)
     
      

    }

    if(this.nationalregister){
      dataType="Tax ID"
      this.upload(dataType,this.nationalregister)
      console.log(this.file2)

    }

    if(this.evidencepermission){
      dataType="CIN Manager"
      this.upload(dataType,this.evidencepermission)
      console.log(this.file3)

    }

    if(this.evidencepowerFile){
      dataType="Status"
      this.upload(dataType,this.evidencepowerFile)
      console.log(this.file4)

    }

}




}
