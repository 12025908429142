
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { User } from '../models/User';
import { Invoice } from '../models/Invoice';
import { ProductDetails } from '../models/ProductDetails';
import { GeneralSegment } from '../models/GeneralSegment';
import { AcidRequest } from '../models/AcidRequest';

const api = environment.baseURL+"acid/";



@Injectable({
  providedIn: 'root'
})
export class AcidRequestService {

  constructor(private http: HttpClient) {}

  

  acidRequest(
    
    acidRequest:AcidRequest,
    company_id:any,
    existExporter:any

  ): Observable<any> {
    
    return this.http.post(
      api + 'acidRequest/'+company_id+'/'+existExporter ,acidRequest
     
    );
  }
  getRequestsByUserId(
    user_id:any
  ): Observable<any> {
    return this.http.get(
      api + 'all/'+user_id
    );
  }
  getRequestById(
    id:any
  ): Observable<any> {
    return this.http.get(
      api + 'getRequestById/'+id
    );
  }

  getRequests(
   
  ): Observable<any> {
    return this.http.get(
      api + 'getAllRequests'
    );
  }
  
  acceptRequest(
    acidRequest:any
  ): Observable<any> {
    return this.http.put(
      api + 'acceptRequest',acidRequest
    );
  }
  rejectRequest(
    acidRequest:any
  ): Observable<any> {
    return this.http.put(
      api + 'rejectRequest',acidRequest
    );
  }

  waitingExporterValidation(
    acidRequest:any
  ): Observable<any> {
    return this.http.put(
      api + 'waitingExporterValidation',acidRequest
    );
  }
  addtionalInformation(
    acidRequest:any
  ): Observable<any> {
    return this.http.put(
      api + 'addtionalInformation',acidRequest
    );
  }

  getRequestsByExporter(
    company_id:any
  ): Observable<any> {
    return this.http.get(
      api + 'getRequestsByExporter/'+company_id
    );
  }

  
  getRequestsByManagerId(
    managerId:any
  ): Observable<any> {
    return this.http.get(
      api + 'getRequestsByManagerId/'+managerId
    );
  }

  getRequestsByMinistry(
    ministryId:any
  ): Observable<any> {
    return this.http.get(
      api + 'ministry/getAll/'+ministryId
    );
  }
  
}
