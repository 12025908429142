
<section class="login p-fixed d-flex text-center bg-primary common-img-bg">
  <app-navbarg></app-navbarg>



  <main class="hero">
    <div class="auth-fluid">
      <div class="row">
        <div class="col-sm-12">
          <!-- Authentication card start -->
          <div class="login-card card-body auth-body" >
            
        
            <div class="text-center" style="display: flex; justify-content: center;">
              
              <img src="assets/images/auth/logo-dark.png" alt="TSN" style="height: 7%;width: 7%;">
            </div>
          </div>

                 <!-- form -->

                 <div class="flex flex-wrap" style="display: flex; justify-content: center;">
                  <div class="w-full lg:w-6/12 px-4" >
                    
                    
                    <div
                    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg  border-0  "
                    style="opacity: 0.95;background-color: rgb(241, 242, 248);"
                    
                  >
                    <div class="rounded-t  mb-0 px-6 py-6 " >
                      <div class="text-center flex justify-between">
                        <h6 class="text-blueGray-700 text-xl font-bold titles " >{{ 'sign_up' | translate }}</h6>
                        
                      </div>
                      <div class="text-right">
                        <a [routerLink]="['/login']" class="text-blueGray-400 text-sm mt-3 mb-6 font-bold " style="text-decoration: underline;">  Already have an account</a>
                      </div>
                    </div>
                    <div class="flex-auto px-4 lg:px-10 py-10 pt-0 text-left">
                      <form  action="#" method="post" [formGroup]="angForm" novalidate>
                        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase Subtitles">
                          <i class="far fa-user"></i>  {{ 'personal_data' | translate }} 
                        </h6>


                        <div class="flex flex-wrap">

            <!------------------------------------------        First name      ------------------------------------------------------->
                          <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                              <label
                                class="block  text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                              {{ 'firstname' | translate }}
                              </label>
                              <input type="text" 
                              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="First name"  id="firstName" formControlName="firstName" required="true">
                            </div>

                            <div class="text-xs" style="color: red;">
                              <div *ngIf="angForm.controls['firstName'].invalid && (angForm.controls['firstName'].dirty || angForm.controls['firstName'].touched)" class="text-danger-filed fs-13 mt-1">
                                          
                                <div *ngIf="angForm.controls['firstName'].errors.required">
                                  {{ 'firstname_required' | translate }}
                                </div>
                                <div *ngIf="angForm.controls['firstName'].errors.minlength">
                                  {{ 'firstname_minlength' | translate }}
                                </div>
                                <div *ngIf="angForm.controls['firstName'].errors.maxlength ">
                                  {{ 'firstname_maxlength' | translate }}
                                </div>
                                <div *ngIf="angForm.controls['firstName'].errors.pattern">
                                  {{ 'firstname_pattern' | translate }}
                                </div>
                              </div>
                            </div>

                          </div>


                          <!------------------------------------------------------------------------------------------------->

                          <!------------------------------------------        Lastname      ------------------------------------------------------->

                          <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                              <label
                                class="block  text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                              {{ 'lastname' | translate }}
                              </label>
                              <input type="text" 
                              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Lastname" id="lastName" formControlName="lastName" required="true">
                            </div>

                            <div class="text-xs" style="color: red;">
                              <div *ngIf="angForm.controls['lastName'].invalid && (angForm.controls['lastName'].dirty || angForm.controls['lastName'].touched)" class="text-danger-filed fs-13 mt-1">
                            
                                <div *ngIf="angForm.controls['lastName'].errors.required">
                                  {{ 'lastname_required' | translate }}
                                </div>
                                <div *ngIf="angForm.controls['lastName'].errors.minlength">
                                  {{ 'lastname_minlength' | translate }}
                                </div>
                                <div *ngIf="angForm.controls['lastName'].errors.maxlength ">
                                  {{ 'lastname_maxlength' | translate }}
                                </div>
                                <div *ngIf="angForm.controls['lastName'].errors.pattern">
                                  {{ 'lastname_pattern' | translate }}
                                </div>
                                 </div>
                                  
                            </div>

                          </div>



                          <!------------------------------------------------------------------------------------------------->

                          <!------------------------------------------        Date Of birth      ------------------------------------------------------->

                          <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                              <label
                                class="block  text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                              {{ 'date_of_birth' | translate }}
                              </label>
                              <input type="text" 
                              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Choose Date of birth" onfocus="(this.type='date')" id="birthday" formControlName="birthday" required="true">
                            </div>

                            <div class="text-xs" style="color: red;">
                              <div *ngIf="angForm.controls['birthday'].invalid && (angForm.controls['birthday'].dirty || angForm.controls['birthday'].touched)" class="text-danger-filed fs-13 mt-1">
                            
                                <div *ngIf="angForm.controls['birthday'].errors.required">
                                  {{ 'date_of_birth_required' | translate }} 
                                </div>
                                
                            </div>
                                  
                            </div>

                          </div>



                          <!------------------------------------------------------------------------------------------------->

                           <!------------------------------------------        Gender      ------------------------------------------------------->

                           <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                              <label
                                class="block  text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                              {{ 'gender' | translate }}
                              </label>
                              <select 
                              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              formControlName="gender"
                              >
                                
                                <option  value="Male"> {{ 'male' | translate }}</option>
                                <option value ="Female"> {{ 'female' | translate }}</option>
                              </select>
                            
                            </div>

                            <div class="text-xs" style="color: red;">
                              <div *ngIf="angForm.controls['gender'].invalid && (angForm.controls['gender'].dirty || angForm.controls['gender'].touched)" class="text-danger-filed fs-13 mt-1">
                            
                                <div *ngIf="angForm.controls['gender'].errors.required">
                                  {{ 'gender_required' | translate }}
                                </div>
                                
                            </div>
                                  
                            </div>

                          </div>



                          <!------------------------------------------------------------------------------------------------->
                          


                          
                          
                        </div>
                  
                        <hr class="mt-6 border-b-1 border-blueGray-300" />
                  
                        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase Subtitles">
                          <i class="far fa-address-book"></i> {{ 'contact_data' | translate }}
                        </h6>

                        <div class="flex flex-wrap">
                      <!---------------------------------------     Address ---------------------------------------------------------->

                          <div class="w-full lg:w-12/12 px-4">
                            <div class="relative w-full mb-3">
                              <label
                                class="block  text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                              {{ 'address' | translate }}
                              </label>
                              <input
                                type="text"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="Enter Full Address" id="address" formControlName="address"
                              />
                            </div>
                            
                            <div class="text-xs" style="color: red;">
                              <div *ngIf="angForm.controls['address'].invalid && (angForm.controls['address'].dirty || angForm.controls['address'].touched)" class="text-danger-filed fs-13 mt-1">
                            
                                <div *ngIf="angForm.controls['address'].errors.required">
                                  {{ 'address_required' | translate }}
                                </div>
                                <div *ngIf="angForm.controls['address'].errors.minlength">
                                  {{ 'address_minlength' | translate }}
                                </div>
                                
                                 </div>
                                  
                            </div>
                          </div>


                          <!-----------------------------------   Country   -------------------------------------------------------------->
                          <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                              <label
                                class="block  text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                              {{ 'country' | translate }}
                              </label>
                              <select 
                              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              id="country" formControlName="country"
                              >
                                
                              <option selected value="" disabled> Country</option>
                              <option *ngFor="let country of countryList" value={{country}}>{{country}}</option>
                              </select>
                            
                            </div>

                            <div class="text-xs" style="color: red;">
                              <div *ngIf="angForm.controls['country'].invalid && (angForm.controls['country'].dirty || angForm.controls['country'].touched)" >
                          
                                <div *ngIf="angForm.controls['country'].errors.required" class="text-danger-filed" >
                                  {{ 'country_required' | translate }}
                                </div>
                              </div>
                                  
                            </div>

                          </div>

                          <!-----------------------------------   Phone -------------------------------------------------------------->
                          <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                              <label
                                class="block  text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                              {{ 'phone_number' | translate }}
                              </label>
                              <input type="text" 
                              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Phone number"  id="phone" formControlName="phone" required="true">
                            </div>

                            <div class="text-xs" style="color: red;">
                              <div *ngIf="angForm.controls['phone'].invalid && (angForm.controls['phone'].dirty || angForm.controls['phone'].touched)" class="text-danger-filed fs-13 mt-1">
                            
                                <div *ngIf="angForm.controls['phone'].errors.required">
                                  {{ 'phone_required' | translate }}
                                </div>
                                
                            </div>
                                  
                            </div>

                          </div>


                          
                        </div>






                  
                        <hr class="mt-6 border-b-1 border-blueGray-300" />
                  
                        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase Subtitles">
                          <i class="fas fa-key"></i> {{ 'account_data' | translate }}
                        </h6>
                        <div class="flex flex-wrap">
                          <!---------------------------------------     Email ---------------------------------------------------------->

                          <div class="w-full lg:w-12/12 px-4">
                            <div class="relative w-full mb-3">
                              <label
                                class="block  text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                              {{ 'email' | translate }}
                              </label>
                              <input
                                type="text"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="Email Address" id="email" formControlName="email"
                              />
                            </div>
                            
                            <div class="text-xs" style="color: red;">
                              <div *ngIf="angForm.controls['email'].invalid && (angForm.controls['email'].dirty || angForm.controls['email'].touched)" class="text-danger-filed fs-13 mt-1">
                                <div *ngIf="angForm.controls['email'].errors.required">
                                  {{ 'email_required' | translate }}
                                </div>
                                <div *ngIf="angForm.controls['email'].errors.pattern">
                                  {{ 'email_pattern' | translate }}
                                 </div>
                              </div>
                                  
                            </div>
                          </div>


                          <!-----------------------------------   Username -------------------------------------------------------------->

                          <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                              <label
                                class="block  text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                              {{ 'username' | translate }}
                              </label>
                              <input type="text" 
                              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Username" id="username" formControlName="username" required="true">
                            </div>

                            <div class="text-xs" style="color: red;">
                              <div *ngIf="angForm.controls['username'].invalid && (angForm.controls['username'].dirty || angForm.controls['username'].touched)" class="text-danger-filed fs-13 mt-1">
                            
                                <div *ngIf="angForm.controls['username'].errors.required">
                                  {{ 'username_required' | translate }}
                                </div>
                                <div *ngIf="angForm.controls['username'].errors.minlength">
                                  {{ 'username_minlength' | translate }}
                                </div>
                                <div *ngIf="angForm.controls['username'].errors.maxlength ">
                                  {{ 'username_maxlength' | translate }}
                                </div>
                                 </div>
                                  
                            </div>

                          </div>

                          <!-----------------------------------   Password -------------------------------------------------------------->

                          <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                              <label
                                class="block  text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                              {{ 'password' | translate }}
                              </label>
                              <input type="password"   #inputPassword
                              class="input-group border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Password" formControlName="password"  id="password" required="true"
                              >
                              <!--span class="input-group-text">
                                <i class="fa" style="color: black;" [ngClass]="{'fa-eye-slash': toggleEye, 'fa-eye': !toggleEye}" (click)="toggleEyeIcon(inputPassword)"></i>
                              </span-->
                              
                            </div>

                            <div class="text-xs" style="color: red;">
                              <div *ngIf="angForm.controls['password'].invalid && (angForm.controls['password'].dirty || angForm.controls['password'].touched)" class="text-danger-filed fs-13 mt-1">
                            
                                <div *ngIf="angForm.controls['password'].errors.required">
                                  {{ 'password_required' | translate }}
                                                          </div>
                                <div *ngIf="angForm.controls['password'].errors.minlength">
                                  {{ 'password_minlength' | translate }}
                                                          </div>
                                                      </div>	
                                  
                            </div>

                          </div>



                          
                        </div>

                        <!------------------------------------------RECAPTCHA----------------------->
                        <hr class="mt-6 border-b-1 border-blueGray-300" />    
                        <br> 
                
                        <div class="w-full lg:w-6/12 px-4">
                          <div class="relative w-full mb-3">
                    <re-captcha formControlName="recaptcha" siteKey="6LeJQtYqAAAAALZpP8KadG2yw5AN3Fmc2SUroi06"></re-captcha>
                    <div *ngIf="angForm.controls['recaptcha'].invalid && (angForm.controls['recaptcha'].dirty || angForm.controls['recaptcha'].touched)" class="invalid-feedback">
                      <div *ngIf="angForm.controls['recaptcha'].errors?.['required']">This field is required.</div>
                    </div>
                  </div>
                </div>

                <!--------------------------------------------------------------->

                <div class="row m-t-25 text-left">
                  <div class="col-md-12">
                    <!--div class="checkbox-fade fade-in-primary" >
                      <label>
                        <input type="checkbox" value="">
                        <span class="cr"><i class="cr-icon icofont icofont-ui-check txt-primary"></i></span>
                        <span class="text-inverse">I read and accept <a href="javascript:;">Terms &amp; Conditions.</a></span>
                      </label>
                    </div-->
                   

                  </div>

                  
                  
                </div>
                <!--------------------------------------------------------------->

                        <div  style="display: flex; justify-content: center;">

                          <button
                        class="bg-red-600 text-white active:bg-red-700 font-bold  text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 
                        ease-linear transition-all duration-150
                        "
                        type="button"
                        [ngStyle]="{
                          'background-color':
                          angForm.pristine || angForm.invalid ? 'gray' :'#0d6efd'  , 'border-color': angForm.pristine || angForm.invalid ? 'gray' :'#0d6efd', 'padding': '12px'
                        }"
                        (click)="onSubmit()"
                        [disabled]="angForm.pristine || angForm.invalid " 
                      >
                      {{ 'sign_up_now' | translate }}
                      </button>
                        </div>
<br>

                        <div class="row">
                          <div class="col-md-10">
                            <p class="text-inverse text-left m-b-0 text-sm" style="color: rgb(46, 44, 44);">{{ 'thank_you' | translate }}</p>
                            <p class="text-inverse text-left text-sm" style="color: rgb(46, 44, 44);"><b>{{ 'mss_consulting' | translate }}</b></p>
                          </div>
                          
                        </div>

                        

                        
                      </form>
                    </div>
                  </div>
                  

                    
                  </div>
                </div>

                  <!-- end of form -->





          
          <!-- Authentication card end -->
        </div>
        <!-- end of col-sm-12 -->
      </div>
      <!-- end of row -->
    </div>
  </main>
    <!-- end of -->
  </section>
  
 
