import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  OnInit,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Company } from "src/app/models/Company";
import { User } from "src/app/models/User";
import { AuthService } from "src/app/services/auth.service";
import { CompanyService } from "src/app/services/company.service";
import { NotificationService } from "src/app/services/notification.service";
import { countries } from "countries-list";
import { data } from "currency-codes";
import { InvoiceService } from "src/app/services/invoice.service";
import { Invoice } from "src/app/models/Invoice";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import { DialogComponent } from "../../dialog/dialog.component";
import { createPopper } from "@popperjs/core";
import { ProductDetails } from "src/app/models/ProductDetails";
import { DataService } from "src/app/services/data.service";
import { GeneralSegment } from "src/app/models/GeneralSegment";
import { GeneralSegmentService } from "src/app/services/generalSegment.service";
import { AcidRequestService } from "src/app/services/acidRequest.service";
import { AcidRequest } from "src/app/models/AcidRequest";
import { ActivatedRoute } from "@angular/router";
import { UserService } from "src/app/services/user.service";
import * as XLSX from "xlsx";

@Component({
  selector: "app-add-acidfolder",
  templateUrl: "./add-acidfolder.component.html",
  styleUrls: ["./add-acidfolder.component.css"],
})
export class AddAcidfolderComponent implements OnInit, OnDestroy {
  // for steps
  steps = [
    "Step 1: Import Data",
    "Step 2: Foreign Exporter Data",
    "Step 3: Shipment Data",
    "Step 4: General Segment",
  ];

  currentStep = 0;

  acidForm: FormGroup | any;

  shipmentForm: FormGroup | any;
  step = 0;
  companies: Company[] = [];
  userId: any;
  company_id: any;
  currentCompany: Company;
  currentCompanyExporter: Company;
  searchedCompanyInstance: any = true;
  countryList: any[] = [];
  currencyList: any[] = [];
  popoverShow = false;
  products: ProductDetails[];
  existExporter = false;
  willBeConsulted = false;
  requestId: any;
  acidRequestDetails: AcidRequest;
  isAdmin = false;
  isRejected = false;
  isAddInfo = false;
  reasonComment: any;
  isReplied = false;
  invoiceSubtotalValue: number = 0;
  totalValue: number = 0;

  isMember = false;
  memberCompany: Company;

  incoterme: String[] = [
    "EXW",
    "FCA",
    "FAS",
    "FAS",
    "FOB",
    "CFR",
    "CIF",
    "CPT",
    "CIP",
    "DAF",
    "DES",
    "DEQ",
    "DDU",
    "DDP",
  ];

  payTerms: String[] = [
    "In advance",
    "Payment no later than 1 year",
    "Payment no later than 2 years",
    "Payment no later than 3 years",
    "Payment no later than 4 years",
    "Payment no later than 5 years",
    "Payment no later than 6 years",
    "Payment no later than 7 years",
    "Payment no later than 8 years",
    "Payment no later than 9 years",
    "Payment no later than 10 years",
    "Cash payment or no later than 30 days",
    "Payment in a period exceeding 10 years",
    "Payment no later than 2 months",
    "Payment no later than 3 months",
    "Payment no later than 6 months",
    "Operation without any payment of any kind",
    "Payment no later than 9 months",
    "Other",
  ];

  isMinistry = false;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public authService: AuthService,
    public companyService: CompanyService,
    public notificationService: NotificationService,
    private dialog: MatDialog,
    public dataService: DataService,
    private acidRequestService: AcidRequestService,
    private userService: UserService
  ) {
    this.products = [];
    this.userId = authService.currentUserValue.user_id;

    Object.values(countries).forEach((country: any) => {
      this.countryList.push(country.name);
      this.countryList = this.countryList.sort();
    });

    data.forEach((currency) => {
      this.currencyList.push(currency.code);
      this.currencyList = this.currencyList.sort();
    });

    this.userService.getUserById(this.userId).subscribe((data: User) => {
      if (data.roles) {
        for (let role of data.roles) {
          if (role.name == "ADMIN") {
            this.isAdmin = true;
          }
          if (role.name == "MEMBER") {
            this.isMember = true;
            this.typeSelected(data.companyId);
          }
          if (role.name == "MINISTRY") {
            this.isMinistry = true;
          }
        }
      }
    });

    this.acidForm = this.fb.group({
      /****************    IMPORTER DATA    ******************* */

      companyName: { value: "", disabled: true },
      rne: { value: "", disabled: true },
      address: { value: "", disabled: true },
      country: { value: "", disabled: true },
      phone: { value: "", disabled: true },
      fax: [""],
      webSite: [""],

      /****************     FOREIGN EXPORTER DATA    ******************* */
      regCode: ["", [Validators.required]],

      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ],
      ],
      /****** Search  ***/
      exporterName: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(20),
        ],
      ],
      exporterCountry: ["", [Validators.required]],
      exporterAddress: ["", [Validators.required, Validators.minLength(3)]],
      exporterPhone: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(20),
        ],
      ],
      exporterfax: [""],
      exporterwebSite: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(20),
        ],
      ],

      exporterPersonalName: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(20),
        ],
      ],
      exporterPersonalCIN: [""],
      exporterPersonalPhone: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(20),
        ],
      ],
      exporterPersonaEmail: [
        "",
        [
          Validators.email,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ],
      ],
      verificationLinkOfCommercialReg: [""],

      /****************     SHIPMENT DATA    ******************* */

      purchaseNumber: [""],
      purchaseNumberDate: [""],
      invoiceNumber: ["", [Validators.required]],
      invoiceDate: ["", [Validators.required]],
      currency: ["", [Validators.required]],
      originCountry: ["", [Validators.required]],
      originPort: [""],
      destinationPort: [""],
      termsofPayment: ["", [Validators.required]],
      generalDescription: ["", [Validators.required]],
      incoTerm: ["", [Validators.required]],

      // General Segment
      invoiceSubtotal: { value: this.invoiceSubtotalValue, disabled: true },
      freightCost: ["", [Validators.required]],
      insuranceCost: ["", [Validators.required]],
      otherCosts: ["", [Validators.required]],
      total: { value: this.totalValue, disabled: true },

      comment: ["", [Validators.required]],
    });
  }
  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.retrieveCompanies();
    this.dataService.getData().subscribe((data) => {
      this.products.push(data);
      this.invoiceSubtotalValue = this.invoiceSubtotalValue + data.totalPrice;
      this.acidForm.controls["invoiceSubtotal"].setValue(
        this.invoiceSubtotalValue
      );
      this.onChange();
    });

    this.route.paramMap.subscribe((params) => {
      const idParam = params.get("requestId");
      this.requestId = idParam ? +idParam : null;

      if (this.requestId != null) {
        this.willBeConsulted = true;
        this.details(this.requestId);
      }
    });
  }

  sendData(dataToSend: ProductDetails) {
    this.dataService.setData(dataToSend);
  }

  getRequestParams(mangerid: number): any {
    let params: any = {};
    params[`mangerid`] = mangerid;
    return params;
  }
  retrieveCompanies(): void {
    const params = this.getRequestParams(this.userId);

    /*this.companyService.getAllAcceptedCompaniesByManager(params)
    .subscribe(
      data => {
       
        this.companies = data;
        
        
      },
      error => {
        console.log(error);
      });*/

    this.companyService.getAllCompaniesByManager(params).subscribe(
      (data) => {
        this.companies = data;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  typeSelected(company: any) {
    this.step = 1;
    this.currentCompany = company;
    this.company_id = company.company_id;

    this.acidForm.controls["companyName"].setValue(
      this.currentCompany.raisonSocial
    );
    this.acidForm.controls["companyName"].disable();

    this.acidForm.controls["rne"].setValue(this.currentCompany.rne);
    this.acidForm.controls["rne"].disable();

    this.acidForm.controls["address"].setValue(
      this.currentCompany.companyAdress
    );
    this.acidForm.controls["address"].disable();

    this.acidForm.controls["country"].setValue(
      this.currentCompany.companyCountry
    );
    this.acidForm.controls["country"].disable();

    this.acidForm.controls["phone"].setValue(this.currentCompany.companyPhone);
    this.acidForm.controls["phone"].disable();

    this.acidForm.controls["fax"].setValue(this.currentCompany.fax);
    this.acidForm.controls["webSite"].setValue(this.currentCompany.webSite);
  }
  previous() {
    this.step = this.step - 1;
  }

  reset() {
    /****************     SHIPMENT DATA    ******************* */

    this.acidForm.controls["purchaseNumber"].setValue("");
    this.acidForm.controls["purchaseNumberDate"].setValue("");
    this.acidForm.controls["invoiceNumber"].setValue("");
    this.acidForm.controls["invoiceDate"].setValue("");
    this.acidForm.controls["currency"].setValue("");
    this.acidForm.controls["originCountry"].setValue("");
    this.acidForm.controls["originPort"].setValue("");
    this.acidForm.controls["destinationPort"].setValue("");
    this.acidForm.controls["termsofPayment"].setValue("");
    this.acidForm.controls["generalDescription"].setValue("");
    this.acidForm.controls["incoTerm"].setValue("");
    this.products = [];
  }

  /************************************************* */
  addAcidRequest() {
    /****** Importer */
    this.currentCompany.webSite = this.acidForm.get(["webSite"]).value;
    this.currentCompany.fax = this.acidForm.get(["fax"]).value;

    /****** Exporter */
    let exporterCompany: Company = new Company();
    let personal: User = new User();
    exporterCompany.rne = this.acidForm.get(["regCode"]).value;

    exporterCompany.companyEmail = this.acidForm.get(["email"]).value;
    exporterCompany.raisonSocial = this.acidForm.get(["exporterName"]).value;
    exporterCompany.companyCountry = this.acidForm.get([
      "exporterCountry",
    ]).value;
    exporterCompany.companyAdress = this.acidForm.get([
      "exporterAddress",
    ]).value;
    exporterCompany.companyPhone = this.acidForm.get(["exporterPhone"]).value;
    exporterCompany.fax = this.acidForm.get(["exporterfax"]).value;
    exporterCompany.verificationLinkOfCommercialReg = this.acidForm.get([
      "verificationLinkOfCommercialReg",
    ]).value;
    exporterCompany.webSite = this.acidForm.get(["exporterwebSite"]).value;
    personal.firstName = this.acidForm.get(["exporterPersonalName"]).value;
    personal.nationalID = this.acidForm.get(["exporterPersonalCIN"]).value;
    personal.phoneNumber = this.acidForm.get(["exporterPersonalPhone"]).value;
    personal.email = this.acidForm.get(["exporterPersonaEmail"]).value;
    exporterCompany.manager = personal;

    /****** Invoice  */
    let invoice = new Invoice();
    invoice.purchaseOrderNumber = this.acidForm.get(["purchaseNumber"]).value;
    invoice.purchaseOrderDate = this.acidForm.get(["purchaseNumberDate"]).value;
    invoice.invoiceNumber = this.acidForm.get(["invoiceNumber"]).value;
    invoice.invoiceDate = this.acidForm.get(["invoiceDate"]).value;
    invoice.currency = this.acidForm.get(["currency"]).value;
    invoice.originCountry = this.acidForm.get(["originCountry"]).value;
    invoice.originPort = this.acidForm.get(["originPort"]).value;
    invoice.destinationPort = this.acidForm.get(["destinationPort"]).value;
    invoice.termsofPayment = this.acidForm.get(["termsofPayment"]).value;
    invoice.generalDescription = this.acidForm.get([
      "generalDescription",
    ]).value;
    invoice.incoTerm = this.acidForm.get(["incoTerm"]).value;
    invoice.importer = this.currentCompany;
    if (this.existExporter) {
      invoice.exporter = this.currentCompanyExporter;
    } else {
      invoice.exporter = exporterCompany;
    }

    invoice.productDetailsSet = this.products;

    /****** General Segment  */
    let generalSegment: GeneralSegment = new GeneralSegment();

    // General Segment

    generalSegment.invoiceSubtotal = this.acidForm.get([
      "invoiceSubtotal",
    ]).value;
    generalSegment.freightCost = this.acidForm.get(["freightCost"]).value;
    generalSegment.insuranceCost = this.acidForm.get(["insuranceCost"]).value;
    generalSegment.otherCosts = this.acidForm.get(["otherCosts"]).value;
    generalSegment.total = this.acidForm.get(["total"]).value;
    generalSegment.invoice = invoice;

    let acidRequest: AcidRequest = new AcidRequest();
    acidRequest.userId = this.userId;
    acidRequest.generalSegment = generalSegment;
    console.log(acidRequest);

    this.acidRequestService
      .acidRequest(acidRequest, this.company_id, this.existExporter)
      .subscribe({
        next: (data) => {
          console.log(data);
          this.notificationService.showSuccess("", "Request added!");
          window.location.href = "request";
        },
        error: (err) => {
          console.log(err);
          this.notificationService.showError("", err.error.message);
        },
      });
  }
  search() {
    if (this.acidForm.controls["regCode"].value == "") {
      this.notificationService.showError("", "RNE is Required");
    } else if (this.acidForm.controls["email"].value == "") {
      this.notificationService.showError("", "Email is Required");
    } else {
      this.companyService
        .searchExporterCompany(
          this.acidForm.get(["regCode"]).value,
          this.acidForm.get(["email"]).value
        )
        .subscribe({
          next: (data) => {
            this.searchedCompanyInstance = data;

            if (data != false) {
              this.currentCompanyExporter = data;
              this.existExporter = true;
              this.searchedCompany();
            } else {
              this.notificationService.showWarning(
                "",
                "This Exporeter not found!"
              );
            }
          },
          error: (err) => {
            console.log(err);
            this.notificationService.showError("", err.error.detail);
          },
        });
    }
  }

  searchedCompany() {
    this.acidForm.controls["regCode"].disable();
    this.acidForm.controls["email"].disable();
    this.acidForm.controls["exporterName"].setValue(
      this.searchedCompanyInstance.raisonSocial
    );
    this.acidForm.controls["exporterName"].disable();
    this.acidForm.controls["exporterCountry"].setValue(
      this.searchedCompanyInstance.companyCountry
    );
    this.acidForm.controls["exporterCountry"].disable();
    this.acidForm.controls["exporterAddress"].setValue(
      this.searchedCompanyInstance.companyAdress
    );
    this.acidForm.controls["exporterAddress"].disable();
    this.acidForm.controls["exporterPhone"].setValue(
      this.searchedCompanyInstance.companyPhone
    );
    this.acidForm.controls["exporterPhone"].disable();
    this.acidForm.controls["exporterfax"].setValue(
      this.searchedCompanyInstance.fax
    );
    this.acidForm.controls["exporterwebSite"].setValue(
      this.searchedCompanyInstance.webSite
    );
    this.acidForm.controls["exporterwebSite"].disable();
    this.acidForm.controls["exporterPersonalName"].setValue(
      this.searchedCompanyInstance.manager?.firstName
    );
    this.acidForm.controls["exporterPersonalName"].disable();
    this.acidForm.controls["exporterPersonalCIN"].setValue(
      this.searchedCompanyInstance.manager?.nationalID
    );
    this.acidForm.controls["exporterPersonalPhone"].setValue(
      this.searchedCompanyInstance.manager?.phoneNumber
    );
    this.acidForm.controls["exporterPersonalPhone"].disable();
    this.acidForm.controls["exporterPersonaEmail"].setValue(
      this.searchedCompanyInstance.manager?.email
    );
    this.acidForm.controls["exporterPersonaEmail"].disable();
    this.acidForm.controls["verificationLinkOfCommercialReg"].setValue(
      this.searchedCompanyInstance.verificationLinkOfCommercialReg
    );

    if (this.searchedCompanyInstance.verificationLinkOfCommercialReg) {
      this.acidForm.controls["verificationLinkOfCommercialReg"].disable();
    }
  }

  /******************************************************************************
   *
   *                              ADD PRODUCTS
   *
   *****************************************************************************/
  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "800px"; // Set the desired width
    // Add more configuration options if needed

    const dialogRef: MatDialogRef<DialogComponent> = this.dialog.open(
      DialogComponent,
      {
        ...dialogConfig,
        data: { itemNumber: this.products.length + 1 }, // Pass the data using the 'data' property
      }
    );
  }
  /*********************************************** */
  onSubmit() {
    if (this.acidForm.controls["companyName"].value == "") {
      this.notificationService.showError("", "Company Name is Required");
    } else if (this.acidForm.controls["rne"].value == "") {
      this.notificationService.showError("", "RNE is Required");
    } else if (this.acidForm.controls["address"].value == "") {
      this.notificationService.showError("", "Address is Required");
    } else if (this.acidForm.controls["country"].value == "") {
      this.notificationService.showError("", "Country is Required");
    } else if (this.acidForm.controls["phone"].value == "") {
      this.notificationService.showError("", "Phone is Required");
    } else if (this.acidForm.controls["regCode"].value == "") {
      this.notificationService.showError("", "RNE is Required");
    } else if (this.acidForm.controls["email"].value == "") {
      this.notificationService.showError("", "Exporter Email is Required");
    } else if (this.acidForm.controls["exporterName"].value == "") {
      this.notificationService.showError("", "Exporter Name is Required");
    } else if (this.acidForm.controls["exporterCountry"].value == "") {
      this.notificationService.showError("", "Exporter Country is Required");
    } else if (this.acidForm.controls["exporterAddress"].value == "") {
      this.notificationService.showError("", "Exporter Address is Required");
    } else if (this.acidForm.controls["exporterCountry"].value == "") {
      this.notificationService.showError("", "Exporter Country is Required");
    } else if (this.acidForm.controls["exporterPhone"].value == "") {
      this.notificationService.showError("", "Exporter Phone is Required");
    } else if (this.acidForm.controls["exporterwebSite"].value == "") {
      this.notificationService.showError("", "Exporter webSite is Required");
    } else if (this.acidForm.controls["exporterPersonalName"].value == "") {
      this.notificationService.showError(
        "",
        "Exporter Personal Name is Required"
      );
    } else if (this.acidForm.controls["exporterPersonalPhone"].value == "") {
      this.notificationService.showError(
        "",
        "Exporter Personal Phone is Required"
      );
    } else if (this.acidForm.controls["exporterPersonaEmail"].value == "") {
      this.notificationService.showError(
        "",
        "Exporter Personal Email is Required"
      );
    } else if (this.acidForm.controls["invoiceNumber"].value == "") {
      this.notificationService.showError("", "Invoice Number is Required");
    } else if (this.acidForm.controls["invoiceDate"].value == "") {
      this.notificationService.showError("", "invoice Date is Required");
    } else if (this.acidForm.controls["currency"].value == "") {
      this.notificationService.showError("", "Currency is Required");
    } else if (this.acidForm.controls["originCountry"].value == "") {
      this.notificationService.showError("", "Origin Country is Required");
    } else if (this.acidForm.controls["termsofPayment"].value == "") {
      this.notificationService.showError(
        "",
        "Terms of Payment Port is Required"
      );
    } else if (this.acidForm.controls["incoTerm"].value == "") {
      this.notificationService.showError("", "Inco Term is Required");
    } else if (this.acidForm.controls["generalDescription"].value == "") {
      this.notificationService.showError(
        "",
        "General Description  is Required"
      );
    } else if (this.acidForm.controls["invoiceSubtotal"].value == "") {
      this.notificationService.showError("", "Invoice Subtotal is Required");
    } else if (this.acidForm.controls["freightCost"].value == "") {
      this.notificationService.showError("", "Freight Cost is Required");
    } else if (this.acidForm.controls["insuranceCost"].value == "") {
      this.notificationService.showError("", "Insurance Cost is Required");
    } else if (this.acidForm.controls["otherCosts"].value == "") {
      this.notificationService.showError("", "Other Costs is Required");
    } else {
      this.addAcidRequest();
    }
  }

  details(id: any) {
    this.step = 1;
    this.acidRequestService.getRequestById(id).subscribe({
      next: (data) => {
        this.acidRequestDetails = data;
        if (
          this.acidRequestDetails.state == "ACCEPTED" ||
          this.acidRequestDetails.state == "REJECTED"
        ) {
          this.isReplied = true;
        }

        this.showDetails();
      },
      error: (err) => {
        console.log(err);
        this.notificationService.showError("", err.error.message);
      },
    });
  }

  showDetails() {
    /********************iMPORTER********************** */

    this.acidForm.controls["companyName"].setValue(
      this.acidRequestDetails.generalSegment?.invoice?.importer?.raisonSocial
    );
    this.acidForm.controls["companyName"].disable();
    this.acidForm.controls["rne"].setValue(
      this.acidRequestDetails.generalSegment?.invoice?.importer?.rne
    );
    this.acidForm.controls["rne"].disable();
    this.acidForm.controls["address"].setValue(
      this.acidRequestDetails.generalSegment?.invoice?.importer?.companyAdress
    );
    this.acidForm.controls["address"].disable();
    this.acidForm.controls["country"].setValue(
      this.acidRequestDetails.generalSegment?.invoice?.importer?.companyCountry
    );
    this.acidForm.controls["country"].disable();
    this.acidForm.controls["phone"].setValue(
      this.acidRequestDetails.generalSegment?.invoice?.importer?.companyPhone
    );
    this.acidForm.controls["phone"].disable();
    this.acidForm.controls["fax"].setValue(
      this.acidRequestDetails.generalSegment?.invoice?.importer?.fax
    );
    this.acidForm.controls["fax"].disable();
    this.acidForm.controls["webSite"].setValue(
      this.acidRequestDetails.generalSegment?.invoice?.importer?.webSite
    );
    this.acidForm.controls["webSite"].disable();
    /********************EXPORTER********************** */
    this.acidForm.controls["regCode"].setValue(
      this.acidRequestDetails.generalSegment?.invoice?.exporter?.rne
    );
    this.acidForm.controls["regCode"].disable();

    this.acidForm.controls["email"].setValue(
      this.acidRequestDetails.generalSegment?.invoice?.exporter?.companyEmail
    );
    this.acidForm.controls["email"].disable();
    this.acidForm.controls["exporterName"].setValue(
      this.acidRequestDetails.generalSegment?.invoice?.exporter?.raisonSocial
    );
    this.acidForm.controls["exporterName"].disable();
    this.acidForm.controls["exporterCountry"].setValue(
      this.acidRequestDetails.generalSegment?.invoice?.exporter?.companyCountry
    );
    this.acidForm.controls["exporterCountry"].disable();
    this.acidForm.controls["exporterAddress"].setValue(
      this.acidRequestDetails.generalSegment?.invoice?.exporter?.companyAdress
    );
    this.acidForm.controls["exporterAddress"].disable();
    this.acidForm.controls["exporterPhone"].setValue(
      this.acidRequestDetails.generalSegment?.invoice?.exporter?.companyPhone
    );
    this.acidForm.controls["exporterPhone"].disable();
    this.acidForm.controls["exporterfax"].setValue(
      this.acidRequestDetails.generalSegment?.invoice?.exporter?.fax
    );
    this.acidForm.controls["exporterfax"].disable();
    this.acidForm.controls["exporterwebSite"].setValue(
      this.acidRequestDetails.generalSegment?.invoice?.exporter?.webSite
    );
    this.acidForm.controls["exporterwebSite"].disable();
    this.acidForm.controls["exporterPersonalName"].setValue(
      this.acidRequestDetails.generalSegment?.invoice?.exporter?.manager
        ?.firstName
    );
    this.acidForm.controls["exporterPersonalName"].disable();
    this.acidForm.controls["exporterPersonalCIN"].setValue(
      this.acidRequestDetails.generalSegment?.invoice?.exporter?.manager
        ?.nationalID
    );
    this.acidForm.controls["exporterPersonalCIN"].disable();
    this.acidForm.controls["exporterPersonalPhone"].setValue(
      this.acidRequestDetails.generalSegment?.invoice?.exporter?.manager
        ?.phoneNumber
    );
    this.acidForm.controls["exporterPersonalPhone"].disable();
    this.acidForm.controls["exporterPersonaEmail"].setValue(
      this.acidRequestDetails.generalSegment?.invoice?.exporter?.manager?.email
    );
    this.acidForm.controls["exporterPersonaEmail"].disable();
    this.acidForm.controls["verificationLinkOfCommercialReg"].setValue(
      this.acidRequestDetails.generalSegment?.invoice?.exporter
        ?.verificationLinkOfCommercialReg
    );
    /******************** SHIPMENT DATA********************** */
    this.acidForm.controls["purchaseNumber"].setValue(
      this.acidRequestDetails.generalSegment?.invoice?.purchaseOrderNumber
    );
    this.acidForm.controls["purchaseNumber"].disable();
    this.acidForm.controls["purchaseNumberDate"].setValue(
      this.acidRequestDetails.generalSegment?.invoice?.purchaseOrderDate
    );
    this.acidForm.controls["purchaseNumberDate"].disable();
    this.acidForm.controls["invoiceNumber"].setValue(
      this.acidRequestDetails.generalSegment?.invoice?.invoiceNumber
    );
    this.acidForm.controls["invoiceNumber"].disable();
    this.acidForm.controls["invoiceDate"].setValue(
      this.acidRequestDetails.generalSegment?.invoice?.invoiceDate
    );
    this.acidForm.controls["invoiceDate"].disable();
    this.acidForm.controls["currency"].setValue(
      this.acidRequestDetails.generalSegment?.invoice?.currency
    );
    this.acidForm.controls["currency"].disable();
    this.acidForm.controls["originCountry"].setValue(
      this.acidRequestDetails.generalSegment?.invoice?.originCountry
    );
    this.acidForm.controls["originCountry"].disable();
    this.acidForm.controls["originPort"].setValue(
      this.acidRequestDetails.generalSegment?.invoice?.originPort
    );
    this.acidForm.controls["originPort"].disable();
    this.acidForm.controls["destinationPort"].setValue(
      this.acidRequestDetails.generalSegment?.invoice?.destinationPort
    );
    this.acidForm.controls["destinationPort"].disable();
    this.acidForm.controls["termsofPayment"].setValue(
      this.acidRequestDetails.generalSegment?.invoice?.termsofPayment
    );
    this.acidForm.controls["termsofPayment"].disable();
    this.acidForm.controls["generalDescription"].setValue(
      this.acidRequestDetails.generalSegment?.invoice?.generalDescription
    );
    this.acidForm.controls["generalDescription"].disable();
    this.acidForm.controls["incoTerm"].setValue(
      this.acidRequestDetails.generalSegment?.invoice?.incoTerm
    );
    this.acidForm.controls["incoTerm"].disable();

    /******************** SHIPMENT DATA********************** */
    if (
      this.acidRequestDetails.generalSegment?.invoice?.productDetailsSet !==
      undefined
    ) {
      this.products =
        this.acidRequestDetails.generalSegment?.invoice?.productDetailsSet;
    }

    /******************** SHIPMENT DATA********************** */

    this.acidForm.controls["invoiceSubtotal"].setValue(
      this.acidRequestDetails.generalSegment?.invoiceSubtotal
    );
    this.acidForm.controls["invoiceSubtotal"].disable();
    this.acidForm.controls["freightCost"].setValue(
      this.acidRequestDetails.generalSegment?.freightCost
    );
    this.acidForm.controls["freightCost"].disable();
    this.acidForm.controls["insuranceCost"].setValue(
      this.acidRequestDetails.generalSegment?.insuranceCost
    );
    this.acidForm.controls["insuranceCost"].disable();
    this.acidForm.controls["otherCosts"].setValue(
      this.acidRequestDetails.generalSegment?.otherCosts
    );
    this.acidForm.controls["otherCosts"].disable();
    this.acidForm.controls["total"].setValue(
      this.acidRequestDetails.generalSegment?.total
    );
    this.acidForm.controls["total"].disable();
  }

  accept() {
    this.acidRequestService.acceptRequest(this.acidRequestDetails).subscribe({
      next: (data) => {
        this.acidRequestDetails = data;

        window.location.href = "admin/acid-requests";
      },
      error: (err) => {
        console.log(err);
        this.notificationService.showError("", err.error.message);
      },
    });
  }

  reject() {
    this.acidRequestDetails.rejectionReason =
      this.acidForm.controls["comment"].value;
    this.acidRequestService.rejectRequest(this.acidRequestDetails).subscribe({
      next: (data) => {
        this.acidRequestDetails = data;

        window.location.href = "admin/acid-requests";
      },
      error: (err) => {
        console.log(err);
        this.notificationService.showError("", err.error.message);
      },
    });
  }

  waitingExporterValidation() {
    this.acidRequestService
      .waitingExporterValidation(this.acidRequestDetails)
      .subscribe({
        next: (data) => {
          this.acidRequestDetails = data;

          window.location.href = "admin/acid-requests";
        },
        error: (err) => {
          console.log(err);
          this.notificationService.showError("", err.error.message);
        },
      });
  }

  addtionalInformation() {
    this.acidRequestDetails.comment = this.acidForm.controls["comment"].value;
    this.acidRequestService
      .addtionalInformation(this.acidRequestDetails)
      .subscribe({
        next: (data) => {
          this.acidRequestDetails = data;

          window.location.href = "admin/acid-requests";
        },
        error: (err) => {
          console.log(err);
          this.notificationService.showError("", err.error.message);
        },
      });
  }

  showdiv(reponse: any) {
    if (reponse == "reject") {
      this.isAddInfo = false;
      this.isRejected = true;
      this.reasonComment = "Reject reason";
    } else if (reponse == "addInfo") {
      this.isRejected = false;
      this.isAddInfo = true;
      this.reasonComment = "Comment";
    }
  }

  onChange() {
    this.totalValue =
      this.acidForm.get(["invoiceSubtotal"]).value +
      this.acidForm.get(["freightCost"]).value +
      this.acidForm.get(["insuranceCost"]).value +
      this.acidForm.get(["otherCosts"]).value;
    this.acidForm.controls["total"].setValue(this.totalValue);
  }
  // for steps

  resetStep(): void {
    this.currentStep = 0;
  }

  nextStep(): void {
    if (this.currentStep < this.steps.length - 1) {
      this.currentStep++;
    }
  }

  previousStep(): void {
    if (this.currentStep > 0) {
      this.currentStep--;
    }
  }

  goToStep(index: number): void {
    if (index <= this.currentStep) {
      this.currentStep = index;
    }
  }

  ACID_PRODUCTS_DATA: any[] = [];

  onFileChange(event: any) {
    const target: DataTransfer = <DataTransfer>event.target;

    if (target.files.length !== 1) {
      throw new Error("Cannot use multiple files");
    }

    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: "binary" });

      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      if (ws["F1"] != undefined) {
        this.acidForm.controls["regCode"].setValue(ws["F1"]["v"]);
      }
      if (ws["C5"] != undefined) {
        this.acidForm.controls["exporterName"].setValue(ws["C5"]["v"]);
      }
      if (ws["C6"] != undefined) {
        this.acidForm.controls["exporterAddress"].setValue(ws["C6"]["v"]);
      }
      if (ws["F3"] != undefined) {
        this.acidForm.controls["exporterwebSite"].setValue(ws["F3"]["v"]);
      }
      if (ws["E4"] != undefined) {
        this.acidForm.controls["exporterPersonalName"].setValue(ws["E4"]["v"]);
      }
      if (ws["C8"] != undefined) {
        this.shipmentForm.controls["purchaseNumber"].setValue(ws["C8"]["v"]);
      }
      if (ws["E8"] != undefined) {
        this.shipmentForm.controls["purchaseNumberDate"].setValue(
          ws["E8"]["v"]
        );
      }
      if (ws["C9"] != undefined) {
        this.acidForm.controls["invoiceNumber"].setValue(ws["C9"]["v"]);
      }
      if (ws["E9"] != undefined) {
        this.acidForm.controls["invoiceDate"].setValue(ws["E9"]["v"]);
      }
      if (ws["C10"] != undefined) {
        this.acidForm.controls["currency"].setValue(ws["C10"]["v"]);
      }
      if (ws["E10"] != undefined) {
        this.acidForm.controls["incoTerm"].setValue(ws["E10"]["v"]);
      }
      if (ws["G7"] != undefined) {
        this.acidForm.controls["originPort"].setValue(ws["G7"]["v"]);
      }
      if (ws["G8"] != undefined) {
        this.acidForm.controls["destinationPort"].setValue(ws["G8"]["v"]);
      }
      if (ws["G10"] != undefined) {
        this.acidForm.controls["NumberOfItem"].setValue(ws["G10"]["v"]);
      }

      for (let i = 13; i < 13 + parseInt(ws["G10"]["v"]); i++) {
        if (ws["A" + i] !== undefined) {
          const products: any = {
            itemNo: ws["A" + i]["v"],
            productCode: ws["B" + i]["v"],
            tradeMarkOwner: ws["C" + i]["v"],
            brandName: ws["D" + i]["v"],
            model: ws["E" + i]["v"],
            commodityCode: ws["F" + i]["v"],
            countryOfOrigin: ws["G" + i]["v"],
            descriptionOfGoods: ws["H" + i]["v"],
            quantity: ws["I" + i]["v"],
            quantityUnit: ws["J" + i]["v"],
            expiryDate: ws["K" + i] ? ws["I" + i]["v"] : "",
            unitPrice: ws["L" + i]["v"],
            grossWeight: ws["M" + i]["v"],
            netWeight: ws["N" + i]["v"],
            weightUnit: ws["O" + i]["v"],
            totalPrice: ws["P" + i]["v"],
          };

          this.ACID_PRODUCTS_DATA.push(products);
          console.log(
            " this.ACID_PRODUCTS_DATA " +
              JSON.stringify(this.ACID_PRODUCTS_DATA)
          );
        }
      }

      if (ws["P15"] != undefined) {
        this.acidForm.controls["invoiceSubtotal"].setValue(ws["P15"]["v"]);
      }

      if (ws["P16"] != undefined) {
        this.acidForm.controls["freightCost"].setValue(ws["P16"]["v"]);
      }
      if (ws["P17"] != undefined) {
        this.acidForm.controls["insuranceCost"].setValue(ws["P17"]["v"]);
      }
      if (ws["P18"] != undefined) {
        this.acidForm.controls["otherCosts"].setValue(ws["P18"]["v"]);
      }
      if (ws["P19"] != undefined) {
        this.acidForm.controls["total"].setValue(ws["P19"]["v"]);
      }
      
      this.products = this.ACID_PRODUCTS_DATA;

      // Iterate over each product and add it using the service
      /* this.products.forEach((product) => {
        this.acidForm.addProduct(product).subscribe({
          next: (data) => {
            this.notificationService.showSuccess("", `Product "${data.name}" added successfully!`);
            this.sendData(data); // If you need to send the newly added product data
          },
          error: (err) => {
            this.notificationService.showError("", err.error.message || "Failed to add product.");
          },
        });
      });
*/

      setTimeout(() => {
        // this.dataSource = new MatTableDataSource(this.ACID_PRODUCTS_DATA);
        this.notificationService.showSuccess("", "Upload successful");
      }, 300);
    };
  }
}
