// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseURL:'https://aci.pts-ly-demo.tn:9197/api/',
  //baseURL:'http://localhost:9197/api/',
  qrDataInvoce: 'https://aci.pts-ly-demo.tn/verify-acid/',
  qrDataPdfCompany:'https://aci.pts-ly-demo.tn/pdf-companys/',
  recaptcha: {
    siteKey: '6LeOQdYqAAAAAIQakUIc37RUxl66Z6pAbZQ6Qh97',
   //  siteKey: '6Lf5oaQqAAAAAIzZjpoJe43IKPN0TCSesLlHLKjX',
    
  },
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
