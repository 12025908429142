





<app-auth-navbar></app-auth-navbar>
<main class="profile-page">
    <section class="relative block h-500-px">
      <div
        class="absolute top-0 w-full h-full bg-center bg-cover"
        style="
          background-image: url('assets/images/slider/comp.jpg');
        "
      >
        <span
          id="blackOverlay"
          class="w-full h-full absolute opacity-50 bg-black"
        ></span>
      </div>
    
    </section>
    <section class="relative py-16 bg-blueGray-800">
        <div class="container mx-auto px-4">
          <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64" style="opacity: 0.9;">
            <div class="w-full lg:w-6/12 px-6 py-6" style="height: 100%;width: 100%;">

                

             
                <!--------------------------------------------------------------------->

                
    
                
                      
                        <div class="px-6">
                          
                       
                          <div class="  text-center" >
                            <div class="flex flex-wrap justify-center" >
                              <div class="w-full lg:w-9/12 px-4" style="width: 100%; height: 100%;">
                                <div class="flex flex-wrap mt-6" >
                                  <div class="w-full mb-24 px-4" >

                                    

                                    
                
                  <div class="rounded-t mb-0 px-4  border-0 " >
                    <div class="rounded-t mb-0 px-4 py-3 border-0">
                        <div class="flex flex-wrap items-center">
                          <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3
                              class="px-6 align-middle border border-solid py-3 text-lg uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center titles"
                            
                            >
                            {{ 'mode.title' | translate }}
                            </h3>
                          </div>
                        </div>
                      </div>
<div style="justify-content: center;display: flex;">
    <img src="assets/images/auto.png" alt="Your GIF Alt Text" style="height: 300px;" *ngIf="isAutomatic">
    <img src="assets/images/manual.png" alt="Your GIF Alt Text" style="height: 300px;" *ngIf="isAutomatic==false">
</div>
              
          
          <div style="justify-content: center;display: flex;" *ngIf="isAutomatic==false">
            <h3
          class="px-6 align-middle border-0 border-solid py-3 text-lg   whitespace-nowrap font-semibold text-center subtitles"
       
        > Actual Mode 
            </h3>
          <h3
          class="px-6 align-middle border-0 border-solid py-3 text-lg   whitespace-nowrap font-semibold text-center subtitles"
         style="color: #F97316;"
         
        >
          {{mode}}
        </h3>
          </div>  
          
          <div style="justify-content: center;display: flex;"  *ngIf="isAutomatic">
            <h3
            class="px-6 align-middle border-0 border-solid py-3 text-lg   whitespace-nowrap font-semibold text-center subtitles"
            
          > {{ 'mode.current' | translate }}
              </h3>
            <h3
            class="px-6 align-middle border-0 border-solid py-3 text-lg   whitespace-nowrap font-semibold text-center subtitles"
           style="color: #10B981;"
           
          >
          {{mode}}
          </h3>
            </div> 
                    <button class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-base px-8 py-3 rounded shadow-md hover:shadow-lg outline-none 
                    focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
                    style="padding: 20px;"
                    type="button"
                    (click)="auto()"

                    >
                    {{ 'mode.auto' | translate }}
                      </button>

                      <button class="bg-orange-500 text-white active:bg-orange-600 font-bold uppercase text-base px-8 py-3 rounded shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      style="padding: 20px;"
                      type="button"
                      (click)="manual()"
                      >
                      {{ 'mode.manual' | translate }}
                      </button>

                    

                    </div>
<!----------------------------------------------------->
                    


                              <!------------------------------------------------------->


                              <!----------------------------------------------------->
                    

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
           
              
    
    
    
                <!---------------------------------------------------------------------->
                
            </div>
          </div>
        </div>
      </section>




    </main>
    <app-footer></app-footer>
 
