<app-navbarg></app-navbarg>
<div class="hero">
    <div class="titre-block">
        <span class="sous-titre">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="20" height="20" style="fill: rgb(0, 0, 0);margin-left:10px;">
            <path d="M527.9 224H480v-48c0-26.5-21.5-48-48-48H272l-64-64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h400c16.5 0 31.9-8.5 40.7-22.6l79.9-128c20-31.9-3-73.4-40.7-73.4zM48 118c0-3.3 2.7-6 6-6h134.1l64 64H426c3.3 0 6 2.7 6 6v42H152c-16.8 0-32.4 8.8-41.1 23.2L48 351.4zm400 282H72l77.2-128H528z"/>
            </svg>
            Advance Information System
        </span>
        <span class="titre-form">Advance Cargo Information System</span>
    </div>
</div>
<div class="page">
    <div class="text">
      <h4>What is Advance Cargo Information System?</h4>
      <P>Advance Cargo Information (ACI) is a new customs procedure that requires cargo data or documents (Pro-forma invoice and draft bill of lading-if exist) at least 48 hours before cargo shipment from export country, to enable stakeholders to monitor any risk to the state through the Risk Management System (RMS), with the highest priority given to the security of citizens</P>
      <img src="../../assets/images/detaille.jpg" alt=""><br><br>
      <h4>What are the Advantages of ACI?</h4>
      <p>Protecting the Tunisian citizens from unidentified goods or goods from unidentified origins<br>
         Reducing release time and hence the cost of releasing goods<br>
         Using electronic data and documents, thereby dispensing with paper documents
      </p>
      <br>
      <h4>Who does ACI impact?</h4>
      <p>
        Importers<br>
        Exporters and suppliers (foreign)<br>
        Clearance companies<br>
        Sea/air shipping companies
      </p><br>
      <h4>When will ACI be applied?</h4>
      <p>ACI Application Phases</p>
      <ul>
        <li><h5>Phase 1 -</h5> Pilot as of 1st April 2021</li>
        <li><h5>Phase 2 -</h5> Mandatory Operation Phase as of 1st October, 2021</li>
      </ul>
      <br>
      <h4>How to prepare for ACI</h4>
      <p>- The importer register on the online portal www.aci..........<br>
         - Obtain the electronic signature pad from competent authorities to access the Portal<br>
         - List advance cargo data and acquire ACID prior to the start of import operations<br>
         - Submit cargo data and documents (commercial invoice and final or initial bill of lading) prior to shipment<br>
         - Notify overseas exporters dealing with electronic invoice to send all shipment documents to (ACI Portal) platform upon vessel’s departure from the port of export -

      </p>
      <br>
      <h4>
        How the Block-chain Technology works
      </h4>
      <p>Block-chain is a simple and innovative method to pass information from person A to person B in a fully automated and secure manner. One Party initiates the transaction by creating a block. That block is verified by thousands, perhaps millions, of computers distributed throughout the network. The verified block is then added to a chain stored in the network, creating a unique record associated with other records. To forge a single record, the entire chain has to be forged on millions of computers, which is practically impossible.</p>
       <p>To provide clarity and help you understand the ACI process and what participating parties need to do - please start by reading the ACI Guidelines </p>
    </div>
    <div class="side">
        <h4 style="font-weight: 500;">Related pages</h4>
        <div class="liste">
            <ul>
                <li><h6>Documents required to create an ACI file</h6>   <h6><i class="fa-solid fa-angle-right icon-style" style="font-size: 20px;color: #7166F5;"></i></h6></li>
                <li><h6>Invoices Structured Data Form </h6><h6><i class="fa-solid fa-angle-right icon-style" style="font-size: 20px;color: #7166F5;"></i></h6></li>
                <li><h6>Digital Signature </h6><h6><i class="fa-solid fa-angle-right icon-style" style="font-size: 20px;color: #7166F5;"></i></h6></li>
            </ul>
        </div>
    </div>
</div>
