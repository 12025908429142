import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BlackList } from 'src/app/models/BlackList';
import { AuthService } from 'src/app/services/auth.service';
import { BlackListService } from 'src/app/services/blackList.service';
import { NotificationService } from 'src/app/services/notification.service';
import {  countries } from 'countries-list';
import { Company } from 'src/app/models/Company';
@Component({
  selector: 'app-exporter-black-list',
  templateUrl: './exporter-black-list.component.html',
  styleUrls: ['./exporter-black-list.component.css']
})
export class ExporterBlackListComponent implements OnInit {

  @Input()
  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [5, 10, 50];

  currentItem:any
  listDetails: FormGroup | any;
  list_step=true
  currentIndex = -1;
  willbeUpdated = false


  items:any[]=[];
  countryList: any[] = [];

  oldRNE=""
  userId: any;
 
  constructor(private formBuilder: FormBuilder ,public authService: AuthService,
   public notificationService:NotificationService,private cdr: ChangeDetectorRef,
    public blackListService:BlackListService) { 
      Object.values(countries).forEach((country: any) => {
        this.countryList.push( country.name);
        this.countryList=this.countryList.sort()
      });

    this.listDetails = this.formBuilder.group({
      rne: ['', Validators.required],
      startDate: [''],
      endDate: [''],
      country: ['',Validators.required],
      company: ['',Validators.required],
      
    });

 
  }
  get list() { return this.listDetails.controls; }

  getRequestParams( page: number, pageSize: number): any {
    let params: any = {};

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  ngOnInit(): void {
   
    this.retrieveLists()
  }
  retrieveLists(){

    const params = this.getRequestParams( this.page, this.pageSize);

    this.blackListService.getAll(params)
    .subscribe(
      response => {
        const { items, totalItems } = response;
        this.items = items;
        this.count = totalItems;
      
       
        
      },
      error => {
        console.log(error);
      });

  }

  

  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveLists();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveLists();
  }

  refreshList(): void {
    this.retrieveLists();
    this.currentItem = {};
    this.currentIndex = -1;
  }

  setActiveCompany(tutorial: BlackList, index: number): void {
    this.currentItem = tutorial;
    this.currentIndex = index;
    this.setDetails(this.currentItem)
    this.willbeUpdated=true
    this.oldRNE=tutorial.rne
    

  }

  deleteSelectedItem(id:any){
    this.blackListService.delete(id)
    .subscribe(
      response => {
       
        this.cdr.detectChanges();
        this.retrieveLists()
        
      },
      error => {
        console.log(error);
      });

  }
  setDetails(data:BlackList){
    this.listDetails.controls['rne'].setValue(data.rne);
    this.listDetails.controls['startDate'].setValue(data.startDate);
    this.listDetails.controls['endDate'].setValue(data.endDate); 
    this.listDetails.controls['country'].setValue(data.country); 
    this.listDetails.controls['company'].setValue(data.company?.raisonSocial); 
   
   
  }
  add(){
    let item:BlackList =new BlackList;
    let company:Company=new Company;
    item.rne=this.listDetails.get(["rne"]).value;
    
    item.startDate=this.listDetails.get(["startDate"]).value;
    item.endDate=this.listDetails.get(["endDate"]).value;
    item.country=this.listDetails.get(["country"]).value;

    company.raisonSocial=this.listDetails.get(["company"]).value;
    company.rne=this.listDetails.get(["rne"]).value
    company.companyCountry=this.listDetails.get(["country"]).value;
    item.company=company;
    console.log(item)

   this.blackListService.add(item).subscribe({
      next: (data) => {
       
        this.retrieveLists()
        
       
      },
      error: (err) => {
        console.log(err)
        this.notificationService.showError(err.error.message,"");
      },})


  }

update(){
 
  let item:BlackList =this.currentItem;
  
  let company:Company=new Company;
  item.startDate=this.listDetails.get(["startDate"]).value
  item.rne=this.listDetails.get(["rne"]).value
  item.endDate=this.listDetails.get(["endDate"]).value;
  item.country=this.listDetails.get(["country"]).value;
 
  if(this.currentItem.company != null || item.rne == this.oldRNE){
    company= item.company
  }
  company.raisonSocial=this.listDetails.get(["company"]).value;
  company.rne=this.listDetails.get(["rne"]).value;
  company.companyCountry=this.listDetails.get(["country"]).value;
  item.company=company;

 this.blackListService.update(item).subscribe({
    next: (data) => {
     
     this.retrieveLists()
    },
    error: (err) => {
      console.log(err)
      this.notificationService.showError(err.error.message,"");
      
    },})
}




}
