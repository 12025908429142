<section class="login">
  <div class="auth-fluid">
      <div class="auth-container">
          <div class="login-card">
              <form class="auth-form">
                  <div class="logo-container">
                      <img src="assets/images/auth/logo-dark.png" alt="TSN" class="logo-img">
                  </div>

                  <div class="auth-box">
                      <div class="icon-container">
                          <img src="assets/images/icons/unauthorized.png" alt="Unauthorized" class="status-icon">
                      </div>

                      <div class="message-container">
                          <h1 class="error-text">Unauthorized!</h1>
                          <p class="info-text">You should login.</p>
                      </div>

                      <button type="button" class="auth-button" [routerLink]="['/login']">
                          Go To Sign in
                      </button>
                  </div>
              </form>
          </div>
      </div>
  </div>
</section>

  


