<section class="login p-fixed d-flex text-center bg-primary common-img-bg">
  <div class="auth-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="login-card card-body auth-body">
          <form class="md-float-material">
            <div class="text-center-v">
              <img src="assets/images/auth/logo-dark.png" alt="TSN" class="logo-img">
            </div>

            <div class="auth-box">
              <div class="text-center-v">
                <img src="assets/images/icons/cross.png" alt="accept.png" class="icon-img">
              </div>

              <div class="message-container">
                <div class="col-md-12">
                  <h1 class="error-text">Invalid!</h1>
                </div>
                <p class="message-text">You should validate your email.</p>
              </div>

              <button type="button" class="btn-go-to-signup" [routerLink]="['/signup']">Go To Sign Up</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
