import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'app-mode',
  templateUrl: './mode.component.html',
  styleUrls: ['./mode.component.css']
})
export class ModeComponent implements OnInit {
   isAutomatic =false
   mode:any
   userId:any
   newmode:any
  constructor(public authService: AuthService,public configurationService:ConfigurationService) {
    this.userId=authService.currentUserValue.user_id 
   }

  ngOnInit(): void {
    this.configurationService.getMode(this.userId)
    .subscribe(
      response => {
        this.mode=response.mode
        localStorage.setItem("mode",this.mode)
        if(this.mode == "AUTO"){
          this.isAutomatic=true

        }
       
      },
      error => {
        console.log(error);
      });
  }
  manual(){
    this.newmode="MANUAL"
    this.configurationService.setMode(this.userId,this.newmode)
    .subscribe(
      response => {
        
        this.isAutomatic=false
        this.mode=response.message
        localStorage.setItem("mode",this.mode)
        window.location.reload();
      },
      error => {
        console.log(error);
      });
  }
  auto(){
    this.newmode="AUTO"
    this.configurationService.setMode(this.userId,this.newmode)
    .subscribe(
      response => {
        
        this.isAutomatic=true
        this.mode=response.message
        localStorage.setItem("mode",this.mode)
        window.location.reload();
      },
      error => {
        console.log(error);
      });
  }



}
