import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'

]
})
export class ResetPasswordComponent implements OnInit {
  angForm: FormGroup | any;
  
  userId: any;


  constructor(public userService: UserService,public authService: AuthService,public notification: NotificationService,private fb: FormBuilder) {
    
 
    this.userId=authService.currentUserValue.user_id
    console.log(this.userId)
    this.angForm = this.fb.group({
      currentpassword: ['', [Validators.required, Validators.minLength(6)] ],
      password: ['', [Validators.required, Validators.minLength(6)] ],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)] ],
      
       
    });

   }

  ngOnInit(): void {
   

    
  }

  toggleEye: boolean = true;
  
  toggleEyeIcon(inputPassword:any) {
    this.toggleEye = !this.toggleEye;
    
    inputPassword.type = inputPassword.type === 'password' ? 'text' : 'password';

    
  }

  confirmPassword(password:string , confirm_pass:string){
    if(password.match(confirm_pass)){
     
      console.log('matched')

    }else{
      this.notification.showError('','Enter matched passwords !')
    }

  }
  onSubmit(){

  
      let currentpassword=this.angForm.get(["currentpassword"]).value
      let password =this.angForm.get(["password"]).value
      let confirm_pass =this.angForm.get(["confirmPassword"]).value
      
      if( password == confirm_pass){

        
        
        this.userService.resetPassMember(this.userId,currentpassword,password).subscribe(
          {
            next: (data) => {
              console.log(data)

              this.notification.showSuccess(
                'Password updated!',
                ''
              );
              window.location.href='home'
  
              
            },
            error: (err) => {
              console.log(err);
  
              this.notification.showError(
                '',
                err.error.message
              );
            }}
        );
      } else{
        this.notification.showError('','Enter matched passwords !')
      }

  

   

    

    

  }

}
