<app-auth-navbar></app-auth-navbar>
<main class="profile-page">
  <section class="relative block h-500-px">
    <div
      class="absolute top-0 w-full h-full bg-center bg-cover"
      style="
        background-image: url('assets/images/slider/comp.jpg');
      "
    >
      <span
        id="blackOverlay"
        class="w-full h-full absolute opacity-50 bg-black"
      ></span>
    </div>
    <div
      class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
      style="transform: translateZ(0)"
    >
      <svg
        class="absolute bottom-0 overflow-hidden"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          class="text-blueGray-200 fill-current"
          points="2560 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>
  </section>
  <section class="relative py-16 bg-blueGray-200">
    <div class="container mx-auto px-4">
      <div
        class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64"
      >
        <div class="px-6">
          <div class="flex flex-wrap justify-center">
            <div class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
             
            </div>
            <div
              class="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center"
            >
              
            </div>
           
          </div>
          <div class="text-center mt-12">
            <h3
              class="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2 titles"
            >
            {{ firstname }} {{ lastname }}
            </h3>
            <div
              class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold "
            >
              <i
                class="fas fa-mail-bulk mr-2 text-lg text-blueGray-400 lowercase"
              ></i>
              {{email}}
            </div>
            
          </div>
          <div class="mt-10  border-t border-blueGray-200 text-center" >
            <div class="flex flex-wrap justify-center">
              <div class="w-full lg:w-9/12 px-4" style="width: 100%; height: 100%;">
                
                
                <!-------------------------------------------------------->
                
                <div class="flex flex-wrap mt-6" >
                  <div class="w-full mb-24 px-4" >
                    <div class="px-3 mt-32 sm:mt-0 lg:text-right">
                      <button
                        class="bg-black active:bg-blueGray-200 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        [routerLink]="['/addCompany']"
                       
                      >
      
                      <i class="fas fa-plus-circle" ></i>
                      {{ 'add_company' | translate }}
                      </button>
                    </div>
                    <app-card-table ></app-card-table>
                  </div>
                  
                </div>









                <!----------------------------------------------------->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
<app-footer></app-footer>
