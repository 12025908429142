<div   *ngIf="!acidRequest;">
  <app-navbarg></app-navbarg>
    <div class="hero">
      <div class="text-hero">
    
        <p>{{ 'validator.check_acid' | translate }}</p>
      </div>
    </div>
    <div class="validator">


        <div class="partie1">
            <div class="titre-block">
                <span class="sous-titre">{{ 'validator.aci_request_validation' | translate }}</span>
                <span class="titre-form">{{ 'validator.read_instructions' | translate }}</span>
            </div>
            <form (ngSubmit)="getAcidRequestData(form)" #form="ngForm" novalidate>

                <div class="input-group">
                  <label for="acid">{{ 'validator.acid' | translate }} *</label>
                  <input type="text"  id="acid"  class="form-control" [(ngModel)]="acidNumber" name="acidNumber" placeholder="Type the Acid number" required>
                  <div *ngIf="form.submitted && !acidNumber"  class="error-message">
                    This field is required
                  </div>
                </div>
              
                <div class="input-group">
                  <label for="matriculeFiscal">{{ 'validator.importer_tax_id' | translate }}*</label>
                  <input type="text" id="matriculeFiscal" class="form-control"  placeholder="Importer Matricule Fiscal" [(ngModel)]="matriculeFiscal" name="matriculeFiscal" required>
                  <div *ngIf="form.submitted && !matriculeFiscal"  class="error-message">
                    This field is required
                  </div>
                </div>
              
                <div class="input-group">
                  <label for="rne">{{ 'validator.exporter_rne' | translate }} *</label>
                  <input type="text" id="rne" class="form-control" [(ngModel)]="rne" name="rne" placeholder="Exporter RNE *" required>
                  <div *ngIf="form.submitted && !rne"  class="error-message">
                    This field is required
                  </div>
                </div>
              
                <div class="input-group">
                  <label for="companyCountry">{{ 'validator.exporter_country' | translate }} *</label>
                  <select
                    id="companyCountry"
                    class="form-control"
                    [(ngModel)]="companyCountry"
                    name="companyCountry"
                    required
                  >
                    <option
                      *ngFor="let country of countryList"
                      [value]="country"
                    >
                      {{ country }}
                    </option>
                  </select>
                  <div *ngIf="form.submitted && !companyCountry"  class="error-message">
                    This field is required
                  </div>
                </div>
                
                <!-- reCAPTCHA -->
                <div class="input-group">
                  <re-captcha formControlName="recaptcha" siteKey="6LcAjZImAAAAAHZuw6J69eaJO5VDydZDihnd1-0i" class="w-full lg:w-4/6 px-4"></re-captcha>
               
                </div>
                <div class="footer-valid">
                <button type="submit">Verify&ensp;<i class="fas fa-check-circle"></i></button>
                </div>
              </form>
              
        </div>

        <div class="partie2">
            <div class="titre-block">
                <span class="titre">{{ 'validator.what_is_service' | translate }}</span>
                <span class="sous-titre">{{ 'validator.acid_number_check' | translate }} </span>
                <p>{{ 'validator.service_description' | translate }} </p>
                <div class="alert">
                    <p>{{ 'validator.system_description' | translate }} </p>
                </div>
              
            </div>
            <br>
            <hr>
            <div class="image-block">
              <img src="../../assets/images/valid.png" alt="">
            </div>
        </div>
    </div>
</div>
 


<div class="aci-project-container" *ngIf="acidRequest;">
    <button
      (click)="generatePDF()"
      style="display: block; margin: 20px auto; padding: 8px 16px; background-color: #0056b3; color: #fff; border: none; font-size: 12px; cursor: pointer;">
      {{ 'downloadPDF' | translate }}
    </button>
    
    <div id="acid" style="max-width: 800px; margin: 0 auto; padding: 40px; background-color: #fff; font-family: 'Arial', sans-serif; color: #333; box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); line-height: 1.4; font-size: 12px;">
      <header>
        <h1>MSS Consulting Services – ACID Project</h1>
      </header>
  
      <section class="notification-section">
        <p>{{ 'dear' | translate }} <strong>{{ acidRequest.generalSegment.invoice.importer.manager.firstName }} {{ acidRequest.generalSegment.invoice.importer.manager.lastName }}</strong>,</p>
        <p>{{ 'notification' | translate }}</p>
        <h2>[ACID : {{ acidRequest.acidNumber}}]</h2>
        <p>{{ 'createdAt' | translate }}: {{ acidRequest.generalSegment.invoice.purchaseOrderDate | date:'short' }}</p>
      </section>
  
      <section class="data-section">
        <div class="data-card">
          <h3>{{ 'importerData' | translate }}</h3>
          <p><strong>{{ 'importerName' | translate }}:</strong> {{ acidRequest.generalSegment.invoice.importer.raisonSocial }}</p>
          <p><strong>{{ 'importerTax' | translate }}:</strong> {{ acidRequest.generalSegment.invoice.importer.matriculeFiscal }}</p>
          <p><strong>{{ 'address' | translate }}:</strong> {{ acidRequest.generalSegment.invoice.importer.companyAdress }}</p>
          <p><strong>{{ 'country' | translate }}:</strong> {{ acidRequest.generalSegment.invoice.importer.companyCountry }}</p>
          <p><strong>{{ 'phoneNumber' | translate }}:</strong> {{ acidRequest.generalSegment.invoice.importer.companyPhone }}</p>
        </div>
  
        <div class="data-card">
          <h3>{{ 'exporterData' | translate }}</h3>
          <p><strong>{{ 'exporterName' | translate }}:</strong> {{ acidRequest.generalSegment.invoice.exporter.raisonSocial }}</p>
          <p><strong>{{ 'exporterNumber' | translate }}:</strong> {{ acidRequest.generalSegment.invoice.exporter.rne }}</p>
          <p><strong>{{ 'phoneNumber' | translate }}:</strong> {{ acidRequest.generalSegment.invoice.exporter.companyPhone }}</p>
          <p><strong>{{ 'address' | translate }}:</strong> {{ acidRequest.generalSegment.invoice.exporter.companyAdress }}</p>
          <p><strong>{{ 'country' | translate }}:</strong> {{ acidRequest.generalSegment.invoice.exporter.companyCountry }}</p>
        </div>
  
        <div class="data-card">
          <h3>{{ 'shipmentData' | translate }}</h3>
          <p><strong>{{ 'purchaseOrderNumber' | translate }}:</strong> {{ acidRequest.generalSegment.invoice.purchaseOrderNumber }}</p>
          <p><strong>{{ 'invoiceDate' | translate }}:</strong> {{ acidRequest.generalSegment.invoice.invoiceDate | date:'short' }}</p>
          <p><strong>{{ 'invoiceType' | translate }}:</strong> Proforma Invoice</p>
          <p><strong>{{ 'shippingPort' | translate }}:</strong> {{ acidRequest.generalSegment.invoice.originPort }}</p>
          <p><strong>{{ 'destinationPort' | translate }}:</strong> {{ acidRequest.generalSegment.invoice.destinationPort }}</p>
          <p><strong>{{ 'generalDescription' | translate }}:</strong> {{ acidRequest.generalSegment.invoice.generalDescription }}</p>
          <p><strong>{{ 'paymentTerms' | translate }}:</strong> {{ acidRequest.generalSegment.invoice.termsofPayment }}</p>
          <p><strong>{{ 'currency' | translate }}:</strong> {{ acidRequest.generalSegment.invoice.currency }}</p>
        </div>
  
        <div class="data-card">
          <h3>{{ 'costData' | translate }}</h3>
          <p><strong>{{ 'subtotal' | translate }}:</strong> {{ acidRequest.generalSegment.invoiceSubtotal | currency }}</p>
          <p><strong>{{ 'freightCost' | translate }}:</strong> {{ acidRequest.generalSegment.freightCost | currency }}</p>
          <p><strong>{{ 'insuranceCost' | translate }}:</strong> {{ acidRequest.generalSegment.insuranceCost | currency }}</p>
          <p><strong>{{ 'otherCosts' | translate }}:</strong> {{ acidRequest.generalSegment.otherCosts | currency }}</p>
          <p><strong>{{ 'total' | translate }}:</strong> {{ acidRequest.generalSegment.total | currency }}</p>
        </div>
      </section>
  
      <footer>
        <p>{{ 'footerMessage' | translate }}</p>
        <p><strong>{{ 'acidNumber' | translate }}:</strong> {{ acidRequest.generalSegment.invoice.invoiceNumber }}</p>
        <p><strong>{{ 'importerTaxNumber' | translate }}:</strong> {{ acidRequest.generalSegment.invoice.importer.matriculeFiscal }}</p>
        <p><strong>{{ 'exporterNumber' | translate }}:</strong> {{ acidRequest.generalSegment.invoice.exporter.rne }}</p>
        <p>{{ 'customsAuthority' | translate }}</p>
        <p>{{ 'thankYouMessage' | translate }}</p>
      </footer>
  
      <div style="text-align: center; margin-top: 5px;">
   
        <p>{{ 'scanQRCode' | translate }}</p>
        <img *ngIf="qrCodeImage" [src]="qrCodeImage" alt="QR Code" style="display: block; margin: 0 auto;" />
      </div>
    </div>
  </div>

