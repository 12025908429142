<div class="navi" [ngClass]="{'scrolled': isScrolled}">
    <ul [ngClass]="{'ul1': lang === 'ab', 'ul': lang !== 'ab'}">
      
      <li class="date-heure">{{ currentDateTime | date:'EEEE d MMMM y, HH:mm:ss' }}</li>
      <li>
        <a href="">{{ 'Site-Map' | translate }}</a>
      </li>
      <li>
          <a href="">{{ 'Qui sommes-nous' | translate }}</a>
      </li>
      <li>
        <a href="">{{ 'Contactez-nous' | translate }}</a>
      </li>
    </ul>
    <ul class="groupe-item">
      <li class="nav-item2">
        <a   href="#"><i class="fab fa-facebook"></i></a>
      </li>
      <li class="nav-item2">
        <a  href="#">
          <i class="fab fa-linkedin"></i></a>
      </li>
      <li class="nav-item2">
        <a   href="#">
          <i class="fab fa-twitter"></i></a>
      </li>
      <li class="nav-item2">
        <a   href="#">
           </a>
      </li>
    </ul>
</div>
  
  <header class="navbar">
    
    <div class="logo">
      <img src="../../../assets/images/auth/logo-nav3.png" alt="">
      <span> <span class="logo-text">ACID</span> PORTAL</span>
    </div>
  
    <div class="hamburger-icon" (click)="toggleMenu()">
        <span> <span class="logo-text">ACID</span> PORTAL&ensp;<i class="fas fa-bars"></i> </span>
    </div>
  
    <ul class="nav-items" [ngClass]="{'active': isMenuOpen}">
        <li class="flex items-center" ><a [routerLink]="['/']"> {{ 'home' | translate }}</a></li>
        <li class="flex items-center">
          <a [routerLink]="['/companys']">
             {{ 'companys' | translate }}
          </a>
        </li>
        <li class="">
          <a [routerLink]="['/verify-acid']" >
            {{ 'validator.verif_acid' | translate }}
          </a>
        </li>
        <li class="flex items-center">
          <a [routerLink]="['/download']">
            {{ 'Documentation' | translate }}
          </a>
        </li>
    </ul>
  
    <div class="navbar-links" [ngClass]="{'active': isMenuOpen}">
      <select [ngClass]="{'select1': lang === 'ab', 'select': lang !== 'ab'}" id="language" (change)="ChangeLang($event)" [value]="lang">
        <option value="en" [selected]="lang === 'en'">🇬🇧&ensp;English</option>
        <option value="fr" [selected]="lang === 'fr'">🇫🇷&ensp;French</option>
        <option value="ab" [selected]="lang === 'ab'">🇸🇦&ensp;عربي</option>
      </select>
      
      <button [ngClass]= "{'btn1ar': lang === 'ab', 'btn2': lang !== 'ab'}"
      [routerLink]="['/login']"><i class="fas fa-user-circle" style="font-size: 22px;"></i>&ensp;{{ 'login' | translate }}</button>
      <button  [ngClass]="{'btn1ar': lang === 'ab', 'btn3': lang !== 'ab'}"
      [routerLink]="['/signup']"><i class="fas fa-user-edit"></i>&ensp;{{ 'join_us' | translate }}</button>
  
    </div>
  </header>

