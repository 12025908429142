
import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Company } from 'src/app/models/Company';
import { CompanyService } from 'src/app/services/company.service';
import { NotificationService } from 'src/app/services/notification.service';
import {  countries } from 'countries-list';
import { MatDialog } from '@angular/material/dialog';
import { createPopper } from '@popperjs/core';
import { NgIf } from '@angular/common';
@Component({
  selector: 'app-exporters',
  templateUrl: './exporters.component.html',
  styleUrls: ['./exporters.component.css']
})
export class ExportersComponent implements OnInit {

  @ViewChild('dialogTemplate') dialogTemplate!: TemplateRef <any>;
  popoverShow = false;
  @ViewChild('btnRef',{ static: false }) btnRef:ElementRef;
  popper = document.createElement("div");



  companies: Company[] = [];
  companiesAll: Company[] = [];
  currentCompany: Company = {};
  currentIndex = -1;
  title = '';
  searchForm: FormGroup | any;
  dailogForm: FormGroup | any;
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 30, 50];
  myid!:any;
  companyId:any;
  countryList: any[] = [];
    isRejected=false
    isAddInfo=true
    reasonComment:any;
    isSended=false;
   
  @Input()
  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  constructor(public companyService: CompanyService,private fb: FormBuilder,public notificationService:NotificationService,
    private dialog: MatDialog) {
    Object.values(countries).forEach((country: any) => {
      this.countryList.push( country.name);
      this.countryList=this.countryList.sort()
    });

    this.searchForm = this.fb.group({
      
      rne:['', [Validators.required ]],
      companyName:['', [Validators.required ]],
      originCountry:['', [Validators.required ]],
      companyType:['', [Validators.required ]],
      

    });

    this.dailogForm = this.fb.group({
      comment:['', [Validators.required ]],
    });
   }

  ngOnInit(): void {
    this.retrieveCompaniesAdmin();
   
    
  }

  getRequestParamsAdmin( page: number, pageSize: number): any {
    let params: any = {};
    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  retrieveCompaniesAdmin(): void {
    const params = this.getRequestParamsAdmin( this.page, this.pageSize);

    this.companyService.getAllExporters(params)
    .subscribe(
      response => {
        const { companies, totalItems } = response;
        this.companies = companies;
        this.companiesAll = companies;
        this.count = totalItems;
        
        
      },
      error => {
        console.log(error);
      });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveCompaniesAdmin();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveCompaniesAdmin();
  }

  refreshList(): void {
    this.retrieveCompaniesAdmin();
    this.currentCompany = {};
    this.currentIndex = -1;
  }

  setActiveCompany(tutorial: Company, index: number): void {
    this.currentCompany = tutorial;
    this.currentIndex = index;
  }
  cancel(){
    this.companies=this.companiesAll
    this.retrieveCompaniesAdmin()
  }


  search(){
    this.companies=this.companiesAll
    let rne=this.searchForm.controls['rne'].value
    let companyName=this.searchForm.controls['companyName'].value
    let companyType=this.searchForm.controls['companyType'].value
    let country=this.searchForm.controls['originCountry'].value
    
  
  

    if(!(rne == "")){
      this.companies=this.companies.filter(request => request?.rne === rne || request.rne === rne)

    }
    if(!(companyName == "")){
      this.companies=this.companies.filter(request => request?.raisonSocial == companyName || request?.raisonSocial == companyName)

    }
 
    if(!(companyType == "")){
      this.companies=this.companies.filter(request => request.companyType == companyType )

    }
    if(!(country == "")){
      this.companies=this.companies.filter(request => request?.companyCountry === country)

  }

    }

    onchange(){
      this.companies=this.companiesAll
      let country=this.searchForm.controls['originCountry'].value
      this.companies=this.companies.filter(request => request.companyCountry === country)
    }

reject(company:any){
  company.comment=this.dailogForm.controls['comment'].value
  this.companyService.reject( company).subscribe({
    next: (data) => {
      this.notificationService.showSuccess("", "Company BlackListed!");
      this.dialog.closeAll()
      this.retrieveCompaniesAdmin() 
    },
    error: (err) => {
      console.log(err)
      this.notificationService.showError("", err.error.message);
    },
  });
}




/************************************* */


openDialog(dialogTemplate: TemplateRef<any>,response:any): void {
  const dialogRef = this.dialog.open(dialogTemplate);
  if(response == "reject"){
    this.isAddInfo=false
    this.isRejected=true
    this.reasonComment="Reject reason"
  }else if(response == "addInfo"){
    this.isRejected=false
    this.isAddInfo=true
    this.reasonComment="Comment"
  }


  dialogRef.afterClosed().subscribe(result => {
    console.log('Dialog closed with result:', result);
  });
}


/************************************************************* */
toggleTooltip(sended:any){
  if(sended){
this.isSended=true
  }else{
    this.isSended=false
  }
  //console.log(this.isSended)
  if(this.popoverShow){
    this.popoverShow = false;
    this.destroyPopper();
  } else {
    this.popoverShow = true;
    this.createPoppper();
  }
}
destroyPopper(){
  this.popper?.parentNode?.removeChild(this.popper);
}
createPoppper(){
  createPopper(this.btnRef.nativeElement, this.popper, {
    placement: "left"
  });
  if(this.isSended==true){
    this.popper.innerHTML = `<div class="bg-blueGray-600 border-0 ml-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg">
<div>


  <div class="bg-teal-600 text-white opacity-75 font-semibold p-3 mb-0 border-b border-solid border-blueGray-100 uppercase rounded-t-lg"   *ngIf="isSended==false">
 Login details is already sended!
  </div>
  
</div>
</div>`;



  }else{

    this.popper.innerHTML = `<div class="bg-blueGray-600 border-0 ml-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg">
<div>
<div class="bg-lightBlue-600 text-white opacity-75 font-semibold p-3 mb-0 border-b border-solid border-blueGray-100 uppercase rounded-t-lg" *ngIf="isSended">
Send login details to the exporter! 
  </div>


  
</div>
</div>`;

  }
  this.btnRef.nativeElement.parentNode.insertBefore(this.popper, this.btnRef.nativeElement.nextSibling);

}


}
