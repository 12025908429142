import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { ValidComponent } from './verification/valid/valid.component';
import { InvalidComponent } from './verification/invalid/invalid.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AuthGuard } from './services/auth.guard';
import { UnauthorizedComponent } from './verification/unauthorized/unauthorized.component';
import { HomepageComponent } from './homepage/homepage.component';
import { AddCompanyComponent } from './components/importer/add-company/add-company.component';

import { AddMemberComponent } from './components/importer/add-member/add-member.component';
import { DisplayMembersComponent } from './components/importer/display-members/display-members.component';
import { ResetPasswordComponent } from './verification/reset-password/reset-password.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ManagerDashboardComponent } from './components/manager-dashboard/manager-dashboard.component';
import { DisplayAttachmentsComponent } from './components/display-attachments/display-attachments.component';
import { DisplayAcidfoldersComponent } from './components/display-acidfolders/display-acidfolders.component';
import { AddAcidfolderComponent } from './components/importer/add-acidfolder/add-acidfolder.component';
import { AddCompanyformComponent } from './components/importer/add-companyform/add-companyform.component';
import { DisplayAcidrequestsComponent } from './components/display-acidrequests/display-acidrequests.component';
import { AdminAcidRequestsComponent } from './components/admin/admin-acid-requests/admin-acid-requests.component';
import { AdminCompaniesComponent } from './components/admin/admin-companies/admin-companies.component';
import { ExporterCompanyuploadsComponent } from './components/exporter/exporter-companyuploads/exporter-companyuploads.component';
import { UpdateExporterComponent } from './components/exporter/update-exporter/update-exporter.component';
import { AdminCompaniesExportersComponent } from './components/admin/admin-companies-exporters/admin-companies-exporters.component';
import { AcidAttachmentsComponent } from './components/acid-attachments/acid-attachments.component';
import { ModeComponent } from './components/admin/mode/mode.component';
import { AddAccountComponent } from './components/plateformadminstraion/add-account/add-account.component';
import { AddListComponent } from './components/plateformadminstraion/add-list/add-list.component';
import { ExporterBlackListComponent } from './components/plateformadminstraion/exporter-black-list/exporter-black-list.component';
import { ExportersComponent } from './components/plateformadminstraion/exporters/exporters.component';
import { MinistriesComponent } from './components/plateformadminstraion/ministries/ministries.component';
import { TestComponent } from './test/test.component';
import { CompanyListComponent } from './components/company-list/company-list.component';
import { PdfCompanyComponent } from './components/pdf-company/pdf-company.component';
import { VerifytofaComponent } from './components/verifytofa/verifytofa.component';
import { ValidatorComponent  } from './validator/validator.component';
import { DetailleComponent  } from './detaille/detaille.component';
import { VerifyAcidComponent } from './components/verify-acid/verify-acid.component';
import { VerifyAcidWidhIdComponent } from './components/verify-acid-widh-id/verify-acid-widh-id.component';
import { UserListForDoubleAuthComponent } from './components/user-list-for-double-auth/user-list-for-double-auth.component';
import { AnalyseComponent } from './components/analyse/analyse.component';
import { DownloadComponent } from './components/download/download.component';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { SignatureComponent } from './components/signature/signature.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  {
    path: 'home',
    component: HomepageComponent,
  },
  {
    path: 'validator',
    component: ValidatorComponent,
  },

  {
    path: 'signup',
    component: SignupComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'valid',
    pathMatch: 'full',
    component: ValidComponent,
  },
  {
    path: 'invalid',
    pathMatch: 'full',
    component: InvalidComponent,
  },
  {
    path: 'unauthorized',
    pathMatch: 'full',
    component: UnauthorizedComponent,
  },
 
  {
    path: 'password',
    component: ResetPasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'addCompany',
    component: AddCompanyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'update-company/:companyId',
    component: AddCompanyComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'delete-company/:companyId',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'add-member',
    component: AddMemberComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'members',
    component: DisplayMembersComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'delete-user/:userId',
    component: DisplayMembersComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'update-user/:userId',
    component: AddMemberComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'managerdashboard',
    component: ManagerDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'display-attachments/:companyId',
    component: DisplayAttachmentsComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'acid',
    component: DisplayAcidfoldersComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'addAcid',
    component: AddAcidfolderComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'request',
    component: DisplayAcidrequestsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'request/:requestId',
    component: AddAcidfolderComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/importers',
    component: AdminCompaniesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/acid-requests',
    component: AdminAcidRequestsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/exporters',
    component: AdminCompaniesExportersComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'exporter/company',
    component: ExporterCompanyuploadsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'exporter/update/:id',
    component: UpdateExporterComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'details/:acidNumber',
    component: AcidAttachmentsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/mode',
    component: ModeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'platform/account',
    component: AddAccountComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'platform/lists',
    component: AddListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'platform/blackList',
    component: ExporterBlackListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'platform/exporters',
    component: ExportersComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'platform/ministries',
    component: MinistriesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'companys',
    component: CompanyListComponent
  },
  {
    path: 'pdf-companys/:id',
    component: PdfCompanyComponent
  },
  
  {
    path: 'test',
    component: TestComponent,

  },
  {
    path: 'verify-2fa',
    component: VerifytofaComponent,

  },
  {
    path: 'verify-acid',
    component: VerifyAcidComponent,

  },
  {
    path: 'verify-acid/:idAcid',
    component: VerifyAcidWidhIdComponent,

  },
  {
    path: 'platform/users-for-double-auth',
    component: UserListForDoubleAuthComponent,

  },
  {
    path: 'analyse',
    component: AnalyseComponent,
  },
  {
    path: 'download',
    component: DownloadComponent,
  },
  {
    path: 'invoices',
    component: InvoicesComponent,
  },
  {
    path: 'advantages',
    component: DownloadComponent,
  },
  {
    path: 'signature',
    component: SignatureComponent,
  }, 
  {
    path: 'detaille',
    component: DetailleComponent,
  }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { 
  
}
