import { Invoice } from "./Invoice";

export class GeneralSegment {

    id?: number;
   
    invoiceSubtotal?: number;
    freightCost?: number;
    insuranceCost?: number;
    otherCosts?: number;
    total?: number;
    invoice:Invoice;

    
 }
