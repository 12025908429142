import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { User } from '../models/User';
import { Invoice } from '../models/Invoice';
import { ProductDetails } from '../models/ProductDetails';

const api = environment.baseURL+"products/";



@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient) {}

  

  addProduct(
    
    product:ProductDetails

  ): Observable<any> {
    
    return this.http.post(
      api + 'add' ,product
     
    );
  }

  


  
}
