import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { data } from 'currency-codes';
import { Ministry } from 'src/app/models/Ministry';
import { Role } from 'src/app/models/Role';
import { SignupRequest } from 'src/app/models/SignupRequest';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth.service';
import { MinistryService } from 'src/app/services/ministry.service';
import { NotificationService } from 'src/app/services/notification.service';
import { RoleService } from 'src/app/services/role.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.css']
})
export class AddAccountComponent implements OnInit {
  @Input()
  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [5, 10, 50];
  currentuser:User
  educationalDetails: FormGroup | any;
  education_step=true
  currentIndex = -1;
  willbeUpdated = false


  roles:any[]=[];
  userId: any;
  userToUpdate=new User;
  users:any[]=[];
  isMinistry=false
  ministries:any[]=[];
  minis:any;
  ministryControl = new FormControl();
  
  constructor(private formBuilder: FormBuilder ,public authService: AuthService,
   public notificationService:NotificationService,private cdr: ChangeDetectorRef,
    public userService:UserService,public roleService:RoleService,public ministryService:MinistryService) { 
      

    this.educationalDetails = this.formBuilder.group({
      firstname: [''],
      lastname: [''],
      username: ['', Validators.required],
      email: ['', [Validators.required ,  Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      phone: [''],
      role: ['',Validators.required],
      Fullname: [''],
      ministry: [''],
    });

    this.educationalDetails.get('ministry').valueChanges.subscribe((selectedMinistry:any) => {
      this.minis = selectedMinistry;
      console.log(this.minis)
    });
  }
  get education() { return this.educationalDetails.controls; }

  getRequestParams( page: number, pageSize: number): any {
    let params: any = {};

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  ngOnInit(): void {
    this.getRoles()
    this.retrieveUsers()
    this.getMinistries()
  }
  retrieveUsers(){

    const params = this.getRequestParams( this.page, this.pageSize);

    this.userService.getAllUsers(params)
    .subscribe(
      response => {
        const { members, totalItems } = response;
        this.users = members;
        this.count = totalItems;
      
       
        
      },
      error => {
        console.log(error);
      });

  }

  getRoles(){
    this.roleService.getAllRoles()
    .subscribe(
      response => {
        for(let role of response){
          //console.log(role)
          this.roles.push(role.name)
        }
      
        
        
      },
      error => {
        console.log(error);
      });

  }
  getMinistries(){
    this.ministryService.getAllWP()
    .subscribe(
      response => {
        this.ministries=response
        console.log(this.ministries);

      },
      error => {
        console.log(error);
      });

  }
 

  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveUsers();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveUsers();
  }

  refreshList(): void {
    this.retrieveUsers();
    this.currentuser = {};
    this.currentIndex = -1;
  }

  setActiveCompany(tutorial: User, index: number): void {
    this.willbeUpdated=true
    this.currentuser = tutorial;
    this.currentIndex = index;
    this.setDetails(this.currentuser)
    

  }

  deleteSelectedUser(user_id:any){
    this.userService.deleteSelectedUser(user_id)
    .subscribe(
      response => {
       
        console.log(this.users)
        this.cdr.detectChanges();
       this.retrieveUsers()
       this.notificationService.showSuccess("Account deleted ! ","")
        
      },
      error => {
        console.log(error);
      });

  }
  setDetails(data:any){
    this.educationalDetails.controls['firstname'].setValue(data.firstName);
    this.educationalDetails.controls['lastname'].setValue(data.lastName);
    this.educationalDetails.controls['username'].setValue(data.username); 
    this.educationalDetails.controls['email'].setValue(data.email); 
    this.educationalDetails.controls['phone'].setValue(data.phoneNumber); 
    
    this.educationalDetails.controls['role'].setValue(data.roles[0].name); 
  }
  addAccount(){
    let user:User =new User;
   
    user.firstName=this.educationalDetails.get(["firstname"]).value;
    user.lastName=this.educationalDetails.get(["lastname"]).value;
    user.username=this.educationalDetails.get(["username"]).value;
    user.email=this.educationalDetails.get(["email"]).value;
    user.phoneNumber=this.educationalDetails.get(["phone"]).value;
    let role= this.educationalDetails.get(["role"]).value
    user.role=[]
    user.role.push(role)
    
    console.log(this.educationalDetails.get(["ministry"]).value)
    if(this.isMinistry)  {
      user.ministry =this.minis;
      console.log(user.ministry)
    }
  
   this.userService.addUser(user).subscribe({
      next: (data) => {
        
        this.refreshList()
        this.notificationService.showSuccess('',"New " +role +" added he should verify his email!");
       
      },
      error: (err) => {
        console.log(err)
        this.notificationService.showError(err.error.message,"");
        
      },})


  }

updat(){
  let user=this.currentuser
  console.log(user);
  user.firstName=this.educationalDetails.get(["firstname"]).value;
  user.lastName=this.educationalDetails.get(["lastname"]).value;
  user.username=this.educationalDetails.get(["username"]).value;
  user.email=this.educationalDetails.get(["email"]).value;
  user.phoneNumber=this.educationalDetails.get(["phone"]).value;
  user.role=[]
  user.role.push(this.educationalDetails.controls['role'].value)
  console.log(user);

  this.userService.updateUserByAdmin(user)
    .subscribe(
      response => {
       this.notificationService.showSuccess("","UserUpdated")
        
        this.retrieveUsers();
        
      },
      error => {
        console.log(error);
      });
}

onChangeRole(){
  this.isMinistry=false
  let role=this.educationalDetails.controls['role'].value
  if(role == "MINISTRY"){
    this.isMinistry=true
  }
  
}


}
