import { Component, OnChanges, OnInit } from '@angular/core';
import { AcidRequest } from 'src/app/models/AcidRequest';
import { User } from 'src/app/models/User';
import { AcidRequestService } from 'src/app/services/acidRequest.service';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-display-acidrequests',
  templateUrl: './display-acidrequests.component.html',
  styleUrls: ['./display-acidrequests.component.css']
})
export class DisplayAcidrequestsComponent implements OnInit {



  acidRequests:AcidRequest[]=[]
  userId:any;
  mangerId:any
  isManager=false
  isMember=false
  ministryId:any;
  isMinistry=false
  constructor(public authService: AuthService,public notificationService:NotificationService,
    private acidRequestService:AcidRequestService,public userService:UserService)
   { 
    


  }

  ngOnInit(): void {

    this.userId=this.authService.currentUserValue.user_id 
    this.userService.getUserById(this.userId).subscribe(  (data:  User) => {
      if(data.roles){
        for(let role of data.roles){
          if(role.name=="MANAGER"){
            this.isManager=true
            this.retrieveAcidRequestsByManagerId()
          
        }if(role.name=="MEMBER"){
          this.isMember=true
          this.retrieveAcidRequests()
        }if(role.name=="MINISTRY"){
          this.isMinistry=true
          this.ministryId=data.ministry?.id
          this.retrieveAcidRequestsByMinistry()
        }
        }
      }
     
    })
  }

  retrieveAcidRequests(): void {
    this.acidRequestService.getRequestsByUserId(this.userId)
    .subscribe(
      data => {   
     
        this.acidRequests = data;
       
      },
      error => {
        console.log(error);
      });
  }

  retrieveAcidRequestsByManagerId(): void {
    this.acidRequestService.getRequestsByManagerId(this.userId)
    .subscribe(
      data => {   
     
        this.acidRequests = data;
        console.log(data);
      },
      error => {
        console.log(error);
      });
  }

  retrieveAcidRequestsByMinistry(): void {
    this.acidRequestService.getRequestsByMinistry(this.ministryId)
    .subscribe(
      data => {   
     
        this.acidRequests = data;
        console.log(data);
      },
      error => {
        console.log(error);
      });
  }


}
