import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  educationalDetails: FormGroup | any;
  education_step=true

  userId: any;
  willBeUpdate:Boolean= false;
  userToUpdate=new User;
  actualUser:User;
 isMember=false
 plceHolder:any="Expiry Date";



  constructor(private formBuilder: FormBuilder ,public authService: AuthService,
   public notificationService:NotificationService,
    public userService:UserService) { 
      this.userId= this.authService.currentUserValue.user_id

    this.educationalDetails = this.formBuilder.group({
      haveCustomerNumber: [true, Validators.required],
      
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      phone: ['',Validators.required],
      birthday: ['', [Validators.required] ],
      address: ['', [Validators.required]],
      country: ['', [Validators.required] ],
      //password: ['', [Validators.required, Validators.minLength(6)] ],
      gender: ['', [Validators.required] ],
      
    });

    

 
  }
  get education() { return this.educationalDetails.controls; }

  ngOnInit(): void {

    this.onUpdate(this.userId)
  }

  onUpdate(id:any){
    this.userService.getUserById(id).subscribe({
      next: (data:  User) => {  
        if(data.roles){
          for(let role of data.roles){
           
            if(role.name=="MEMBER"){
              
              this.isMember=true
            }
            
          }
  
        }
      
       
          this.userToUpdate=data;
        this.educationalDetails.controls['firstname'].setValue(data.firstName);
        this.educationalDetails.controls['lastname'].setValue(data.lastName);
        this.educationalDetails.controls['phone'].setValue(data.phoneNumber); 
        this.educationalDetails.controls['birthday'].setValue(data.dateOfBirth); 
        this.educationalDetails.controls['address'].setValue(data.address); 
        this.educationalDetails.controls['country'].setValue(data.country); 
        this.educationalDetails.controls['gender'].setValue(data.gender); 
        if( this.userToUpdate.typeofIdentification=="CIN"){

        this.plceHolder="Issuance Date"
    }else{
      this.plceHolder="Expiry Date"
    }

        

        

        
      },
      error: (err) => {
        console.log(err)
        this.notificationService.showError("", "User not found!");
      },
    });
    

  }
  

  onSubmit(){
    this.userToUpdate.firstName=this.educationalDetails.get(["firstname"]).value;
    this.userToUpdate.lastName=this.educationalDetails.get(["lastname"]).value;
    this.userToUpdate.phoneNumber=this.educationalDetails.get(["phone"]).value;
    //this.userToUpdate.dateOfBirth=this.educationalDetails.get(["birthday"]).value;
    this.userToUpdate.address=this.educationalDetails.get(["address"]).value;
    this.userToUpdate.country=this.educationalDetails.get(["country"]).value;
    this.userToUpdate.gender=this.educationalDetails.get(["gender"]).value;
    this.userService.updateUser( this.userToUpdate.user_id,this.userToUpdate).subscribe({
      next: (data) => {
        this.notificationService.showSuccess("", "User Updated!");
        window.location.href ='dashboard'

      },
      error: (err) => {
        console.log(err)
        this.notificationService.showError("", "User not found!");
      },
    });

  }

}
