import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { createPopper } from "@popperjs/core";
import { Role } from 'src/app/models/Role';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-pages-dropdown',
  templateUrl: './pages-dropdown.component.html',
  styleUrls: ['./pages-dropdown.component.css']
})
export class PagesDropdownComponent implements OnInit {

  dropdownPopoverShow = false;
  @ViewChild("btnDropdownRef", { static: false }) btnDropdownRef: ElementRef;
  @ViewChild("popoverDropdownRef", { static: false })
  popoverDropdownRef: ElementRef;
  isImporter=false
  isMember=false

  constructor(public authService: AuthService,private userService:UserService){
    let currentuser = this.authService.currentUserValue;
    

    this.userService.getUserById(currentuser.user_id).subscribe(  (data:  User) => {
      if(data.roles){
        for(let role of data.roles){
          if(role.name=="MANAGER" || role.name=="SIMPLEUSER"){
            this.isImporter=true
          }if(role.name=="MEMBER" ){
            this.isMember=true
          }
        }
      }
     
    })

    
  
  }
  ngOnInit() {}
  toggleDropdown(event:Event) {
    event.preventDefault();
    if (this.dropdownPopoverShow) {
      this.dropdownPopoverShow = false;
    } else {
      this.dropdownPopoverShow = true;
      this.createPoppper();
    }
  }
  createPoppper() {
    createPopper(
      this.btnDropdownRef.nativeElement,
      this.popoverDropdownRef.nativeElement,
      {
        placement: "bottom-start",
      }
    );
  }
  onSubmit(){
    this.authService.logout();
    window.location.href ='login'
  }

}
