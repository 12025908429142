<app-navbarg></app-navbarg>
<div class="hero">
    <div class="text-hero">
      <span>{{'guide' | translate}}</span>
      <p>{{'guide-desc' | translate}}</p>
    </div>
</div>
<div class="organisation">
    <div class="liste-card">
        <div class="card">
            <div class="image">
                <img  src="assets/images/pdf.png" class="card-img-top" alt="Office account">
            </div>
            <h5>ACI Guidelines 2025</h5>
            <div class="footer-card">
                <button  (click)="downloadFile()">
                    <i class="fas fa-download"></i>&ensp;Télecharger
                </button>
            </div>
        </div>
        <div class="card">
            <div class="image">
                <img  src="assets/images/pdf.png" class="card-img-top" alt="Office account">
            </div>
            <h5>ACI Guidelines 2025</h5>
            <div class="footer-card">
                <button (click)="downloadFile()">
                    <i class="fas fa-download"></i>&ensp;Télecharger
                </button>
            </div>
        </div>

    </div>
</div>
<br>
