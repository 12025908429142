
export class CompanyDeclarantMemberDTO {
  
    activityType?: string;
    dateInscription?: Date;
    reference?: string;
    etat?: string;
    rne?: string;
    matriculeFiscal?: string;
    raisonSocial?: string;
    documentSubmissionCentre?: string;
    foodImporter?: boolean;
    applicantStatus?: string;
    companyPhone?: string;
    companyEmail?: string;
    logoCompany?: string;
    haveCustomerNumber?: boolean; 
    companyAdress?: string;
    companyCountry?: string;

    typeofIdentificationDeclarant?: string;
    expiryDateDeclarant?: Date;
    nationalIDDeclarant?: string;
    phoneNumberDeclarant?: string;

 
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    username?: string;
    email?: string;
    password?: string;
    activated?:boolean;
    team_name?:string;
    typeofIdentification?:string;
    expiryDate?:Date;
    nationalID?:string;

  
  }