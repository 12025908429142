import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Company } from 'src/app/models/Company';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.css']
})
export class AddMemberComponent implements OnInit {
  educationalDetails: FormGroup | any;
  education_step=true
  managerId:any;
  companies: Company[] = [];
  plceHolder:any="Expiry Date";
  userId: any;
  willBeUpdate:Boolean= false;
  userToUpdate=new User;


  constructor(private route: ActivatedRoute,private formBuilder: FormBuilder ,public authService: AuthService,
    public companyService:CompanyService,public notificationService:NotificationService,
    public userService:UserService) { 
      this.managerId= this.authService.currentUserValue.user_id

    this.educationalDetails = this.formBuilder.group({
      haveCustomerNumber: [true, Validators.required],
      
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      username: ['', Validators.required],
      typeofIdentification: ['', Validators.required],
      nationalID: ['', Validators.required],
      expiryDate: ['',Validators.required],
      email: ['', [Validators.required ,  Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      phone: ['',Validators.required],
      teamName: ['',Validators.required],
      companyname:['',Validators.required]
    });

 
  }

  ngOnInit(): void {
    this.retrieveCompanies()
    this.route.paramMap.subscribe(params => {
      const companyIdParam = params.get('userId');
      this.userId = companyIdParam ? +companyIdParam : null;
      
      if(this.userId != null){
       
        this.willBeUpdate=true;
        this.onUpdate(this.userId)
      }
    });
   
    
  }


  get education() { return this.educationalDetails.controls; }

 

  getRequestParams(mangerid: number): any {
    let params: any = {};
      params[`mangerid`] = mangerid;
    return params;
  }
  retrieveCompanies(): void {
    
    const params = this.getRequestParams(this.managerId)

    this.companyService.getAllCompaniesByManager(params)
    .subscribe(
      data => {
       
        this.companies = data;
        
        
      },
      error => {
        console.log(error);
      });
  }

  addMember(){
    let user:User=new User;
    user.firstName=this.educationalDetails.get(["firstname"]).value;
    user.lastName=this.educationalDetails.get(["lastname"]).value;
    user.username=this.educationalDetails.get(["username"]).value;
    user.team_name=this.educationalDetails.get(["teamName"]).value;
    user.typeofIdentification=this.educationalDetails.get(["typeofIdentification"]).value;
    user.nationalID=this.educationalDetails.get(["nationalID"]).value;
    user.expiryDate=this.educationalDetails.get(["expiryDate"]).value;
    user.email=this.educationalDetails.get(["email"]).value;
    user.phoneNumber=this.educationalDetails.get(["phone"]).value;
    let companyId=this.educationalDetails.get(["companyname"]).value;
  

    this.userService.addMember(companyId,user).subscribe({
      next: (data:User) => {
       
        this.notificationService.showSuccess('',"New Member added he should verify his email!");
        window.location.href ='members'
      },
      error: (err) => {
        console.log(err)
        this.notificationService.showError(err.error.message,"");
        
      },})


  }

  onUpdate(id:any){

  

    this.userService.getUserById(id).subscribe({
      next: (data:  User) => {  
       


        if(data.manager) {
          if(data.manager.user_id==this.managerId){
          this.userToUpdate=data;
        this.educationalDetails.controls['firstname'].setValue(data.firstName);
        this.educationalDetails.controls['lastname'].setValue(data.lastName);
        this.educationalDetails.controls['username'].setValue(data.username);
        this.educationalDetails.controls['typeofIdentification'].setValue(data.typeofIdentification);
        this.educationalDetails.controls['nationalID'].setValue(data.nationalID);
        this.educationalDetails.controls['expiryDate'].setValue(data.expiryDate); 
        this.educationalDetails.controls['email'].setValue(data.email); 
        this.educationalDetails.controls['phone'].setValue(data.phoneNumber); 
        this.educationalDetails.controls['teamName'].setValue(data.team_name); 
        this.educationalDetails.controls['companyname'].setValue(data.companyId); 

          }else{
            this.notificationService.showError("","This Member is not a part of your team !")
          }
          
        }
        

        
      },
      error: (err) => {
        console.log(err)
        this.notificationService.showError("", "User not found!");
      },
    });
    

  }
  

  update(){

    let company_id:any;
    let company_id_to_send;

 

    console.log(this.userToUpdate)
   
    this.userToUpdate.firstName=this.educationalDetails.get(["firstname"]).value;
    this.userToUpdate.lastName=this.educationalDetails.get(["lastname"]).value;
    this.userToUpdate.username=this.educationalDetails.get(["username"]).value;
    this.userToUpdate.typeofIdentification=this.educationalDetails.get(["typeofIdentification"]).value;
    this.userToUpdate.nationalID=this.educationalDetails.get(["nationalID"]).value;
    this.userToUpdate.expiryDate=this.educationalDetails.get(["expiryDate"]).value;
    this.userToUpdate.email=this.educationalDetails.get(["email"]).value;
    this.userToUpdate.phoneNumber=this.educationalDetails.get(["phone"]).value;
    this.userToUpdate.team_name=this.educationalDetails.get(["teamName"]).value;
    this.userToUpdate.companyId=this.educationalDetails.get(["companyname"]).value;
    console.log(typeof this.userToUpdate.companyId)
    
    if (typeof this.userToUpdate.companyId === "string"){
      company_id=this.educationalDetails.get(["companyname"]).value;
      console.log("it is number "+company_id)
    }else{
      if(this.userToUpdate.companyId)
      company_id=this.userToUpdate.companyId.company_id
      console.log(company_id)

    }

    this.userService.updateMember( this.userToUpdate.user_id,company_id,this.userToUpdate).subscribe({
      next: (data) => {
        this.notificationService.showSuccess("", "Member Updated!");
        window.location.href ='members'

      },
      error: (err) => {
        console.log(err)
        this.notificationService.showError("", "Member not found!");
      },
    });
  }

  onchange(){
    if(this.educationalDetails.get(["typeofIdentification"]).value=="CIN"){
      this.plceHolder="Issuance Date"
    }else{
      this.plceHolder="Expiry Date"
    }
      
  }
}
