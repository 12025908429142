import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-auth-navbar',
  templateUrl: './auth-navbar.component.html',
  styleUrls: ['./auth-navbar.component.css']
})

export class AuthNavbarComponent implements OnInit {

  navbarOpen = false;
  isManager = false;
  isSignedIn = false;
  forTest=false
  isAdmin = false;
  isExporter = false;
  isSimpleUser=false
  isMember=false
  isPlateformAdmin =false
  isMinistry =false
  mode:any
  currentuser:any
  firstName:any;
  lastName: any;
  roles: any;
  lang:string ='';
  isDropdownOpen: boolean = false;
  isDropdownOpen2: boolean = false;


  
  constructor(private translateService:TranslateService, public authService: AuthService,private userService:UserService,public configurationService:ConfigurationService) {
    this.currentuser = this.authService.currentUserValue;
   if(this.currentuser.user_id){
     this.isSignedIn=true
     this.firstName = this.currentuser.username;
     this.lastName = this.currentuser.lastName;
     this.roles = this.currentuser.roles;
     console.log("this.userName :"+this.firstName)
     console.log("this.userName :"+this.lastName)
     console.log("roles"+this.roles)
   }

   this.userService.getUserById(this.currentuser.user_id).subscribe(  (data:  User) => {
     if(data.roles){
       for(let role of data.roles){
         if(role.name=="MANAGER"){
           this.isManager=true
         }if(role.name=="SIMPLEUSER"){
           this.isSimpleUser=true
         }if(role.name=="ADMIN"){
           this.isAdmin=true
           this.getMode();

         }if(role.name=="EXPORTER"){
         this.isExporter=true
       }if(role.name=="MEMBER"){
         this.isMember=true
       }if(role.name=="PLATEFORMADMIN"){
         this.isPlateformAdmin=true
       }if(role.name=="MINISTRY"){
         this.isMinistry=true
       }
       }
     }
    
   })
 
  console.log("isSimpleUser :"+this.isSimpleUser)
 }


  ngOnInit(): void {
        this.lang = localStorage.getItem('lang') || 'en';
     
  }
  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
    
  }

  

  getMode(){
    this.configurationService.getMode(this.currentuser.user_id)
    .subscribe(
      response => {
        this.mode=response.mode
        localStorage.setItem("mode",this.mode)
      },
      error => {
        console.log(error);
      });
  }

  setNavbarOpen() {
    this.navbarOpen = !this.navbarOpen;
  }
  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  toggleDropdown2() {
    this.isDropdownOpen2 = !this.isDropdownOpen2;
  }
  ChangeLang(lang:any){
    const selectedLanguage = lang.target.value;

    localStorage.setItem('lang',selectedLanguage);

    this.translateService.use(selectedLanguage);

  }
  onSubmit(){
    this.authService.logout();
    window.location.href ='home'
  }

}
