import { Component, OnInit, HostListener } from '@angular/core';
import { AuthService } from '../../services/auth.service';

import { TranslateService } from '@ngx-translate/core';
import { interval } from 'rxjs';
declare var AOS: any;

@Component({
  selector: 'app-navbarg',
  templateUrl: './navbarg.component.html',
  styleUrls: ['./navbarg.component.css']
})
export class NavbargComponent implements OnInit {
  currentDateTime: Date = new Date();
  isSignedIn = false;
  formattedDate: string = '';
  navbarOpen = false;
  lang: string = 'en';
  isMenuOpen: boolean = false;
  isScrolled = false;
  isShow: boolean; 
  topPosToStartShowing = 100; 
  currentImageIndex: number = 0;
  isImageVisible: boolean = true;  
  constructor(private translateService: TranslateService, public authService: AuthService) {
    if (this.authService.currentUserValue.user_id) {
      this.isSignedIn = true;
    }
  }


  ngOnInit(): void {
    AOS.init();  // Initialisation de AOS
    const storedLang = localStorage.getItem('lang');
    if (storedLang) {
      this.lang = storedLang;
      this.translateService.use(this.lang);
    }
    interval(1000).subscribe(() => {
      this.currentDateTime = new Date();
      this.formatDateInArabic();
    });
  }
  formatDateInArabic() {
    this.formattedDate = new Intl.DateTimeFormat('ar-EG', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    }).format(this.currentDateTime);
  }
  setNavbarOpen() {
    this.navbarOpen = !this.navbarOpen;
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }



  ChangeLang(lang: any) {
    const selectedLanguage = lang.target.value;
    localStorage.setItem('lang', selectedLanguage);
    this.translateService.use(selectedLanguage);
    this.lang = selectedLanguage; // Mettre à jour la langue dans le composant
  }
  onSubmit(){
    this.authService.logout();
    window.location.href ='login'
  }
  downloadFile() {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.href = '../../assets/ACI Guidelines 2025.pdf'; 
    link.download = 'ACI Guidelines 2025.pdf'; 
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.isScrolled = window.scrollY > 50;
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.isShow = scrollPosition >= this.topPosToStartShowing;
  }
  
  scrollToTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }
  
}
