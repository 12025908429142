<app-auth-navbar></app-auth-navbar>
<main class="profile-page">
  <section class="relative block h-500-px">
    <div
      class="absolute top-0 w-full h-full bg-center bg-cover"
      style="background-image: url('assets/images/slider/comp.jpg')"
    >
      <span
        id="blackOverlay"
        class="w-full h-full absolute opacity-50 bg-black"
      ></span>
    </div>
  </section>
  <section class="relative py-16 bg-blueGray-800">
    <div class="container mx-auto px-4">
      <div
        class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-xl rounded-lg -mt-64"
        style="opacity: 0.9; background-color: rgb(241, 242, 248)"
      >
        <div
          class="w-full lg:w-6/12 px-6 py-6"
          style="height: 100%; width: 100%"
        >
          <!--------------------------------------------------------------------->

          <div class="px-6">
            <div class="text-center">
              <div class="flex flex-wrap justify-center">
                <div
                  class="w-full lg:w-9/12 px-4"
                  style="width: 100%; height: 100%"
                >
                  <div class="flex flex-wrap mt-6">
                    <div class="w-full mb-24 px-4">
                      <!------------------------------------------------------>

                      <div class="flex-auto px-4 lg:px-10 py-10 pt-0 text-left">
                        <form
                          action="#"
                          method="post"
                          [formGroup]="educationalDetails"
                          novalidate
                        >
                          <div class="flex flex-wrap">
                            <!---------------------------------------     Role ---------------------------------------------------------->

                            <div class="w-full lg:w-4/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'role' | translate }}*
                                </label>
                                <select
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  formControlName="role"
                                  (change)="onChangeRole()"
                                >
                                  <option value="" selected disabled>
                                    {{ 'role' | translate }}
                                  </option>
                                  <option
                                    *ngFor="let rol of roles"
                                    value="{{ rol }}"
                                  >
                                    {{ rol }}
                                  </option>
                                </select>
                              </div>
                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    (education_step &&
                                      educationalDetails.controls.role.errors &&
                                      educationalDetails.controls.role.dirty) ||
                                    educationalDetails.controls.role.touched
                                  "
                                >
                                  <div *ngIf="education.role.errors?.required">
                                    Role is required
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!--------------------------------------------------------------------->
                            <!---------------------------------------     Firstname ---------------------------------------------------------->

                            <div
                              class="w-full lg:w-4/12 px-4"
                              *ngIf="isMinistry == false"
                            >
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'firstname' | translate }}
                                </label>
                                <input
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  formControlName="firstname"
                                  placeholder="{{ 'firstname' | translate }} "
                                />
                              </div>
                            </div>
                            <!--------------------------------------------------------------------->
                            <!---------------------------------------     Lastname ---------------------------------------------------------->

                            <div
                              class="w-full lg:w-4/12 px-4"
                              *ngIf="isMinistry == false"
                            >
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'lastname' | translate }}
                                </label>
                                <input
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  formControlName="lastname"
                                  placeholder="Lastname"
                                />
                              </div>
                            </div>
                            <!--------------------------------------------------------------------->

                            <!---------------------------------------     Firstname ---------------------------------------------------------->

                            <div
                              class="w-full lg:w-4/12 px-4"
                              *ngIf="isMinistry"
                            >
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'fullName' | translate }}
                                </label>
                                <input
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  formControlName="firstname"
                                  placeholder="Fullname"
                                />
                              </div>
                            </div>
                            <!--------------------------------------------------------------------->
                            <!---------------------------------------     Lastname ---------------------------------------------------------->

                            <div
                              class="w-full lg:w-4/12 px-4"
                              *ngIf="isMinistry"
                            >
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'ministry' | translate }}*
                                </label>
                                <select
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  formControlName="ministry"
                                  placeholder="Ministry"
                                >
                                  <option value="" selected disabled>
                                    {{ 'ministryOf' | translate }}
                                  </option>
                                  <option
                                    *ngFor="let minis of ministries"
                                    [ngValue]="minis"
                                  >
                                    {{ minis?.sector }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <!--------------------------------------------------------------------->

                            <!---------------------------------------     Username ---------------------------------------------------------->

                            <div class="w-full lg:w-4/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'username' | translate }}*
                                </label>
                                <input
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  formControlName="username"
                                  placeholder="Username "
                                />
                              </div>
                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    (education_step &&
                                      educationalDetails.controls.username
                                        .errors &&
                                      educationalDetails.controls.username
                                        .dirty) ||
                                    educationalDetails.controls.username.touched
                                  "
                                >
                                  <div
                                    *ngIf="education.username.errors?.required"
                                  >
                                    Username is required
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!--------------------------------------------------------------------->

                            <!--------------------------------------------------------------------->
                            <!---------------------------------------     Email ---------------------------------------------------------->

                            <div class="w-full lg:w-4/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'email' | translate }}*
                                </label>
                                <input
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  formControlName="email"
                                  name="email"
                                  placeholder=" Email"
                                />
                              </div>
                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    (education_step &&
                                      educationalDetails.controls.email
                                        .errors &&
                                      educationalDetails.controls.email
                                        .dirty) ||
                                    educationalDetails.controls.email.touched
                                  "
                                >
                                  <div *ngIf="education.email.errors?.required">
                                    Email is required
                                  </div>
                                  <div *ngIf="education.email.errors?.pattern">
                                    Email must be a valid email address.
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!--------------------------------------------------------------------->
                            <!---------------------------------------     Phone ---------------------------------------------------------->

                            <div class="w-full lg:w-4/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'phone' | translate }}
                                </label>
                                <input
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  formControlName="phone"
                                  name="phone"
                                  placeholder="Phone"
                                />
                              </div>
                            </div>
                            <!--------------------------------------------------------------------->
                          </div>

                          <!--------------------------------------------------------------->

                          <div style="display: flex; justify-content: center">
                            <button
                              class="bg-red-600 text-white active:bg-red-700 font-bold text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                              type="button"
                              [ngStyle]="{
                                'background-color':
                                  educationalDetails.pristine ||
                                  educationalDetails.invalid
                                    ? 'gray'
                                    : '#0d6efd',
                                'border-color':
                                  educationalDetails.pristine ||
                                  educationalDetails.invalid
                                    ? 'gray'
                                    : '#0d6efd',
                                padding: '12px'
                              }"
                              (click)="addAccount()"
                              [disabled]="
                                educationalDetails.pristine ||
                                educationalDetails.invalid
                              "
                            >
                            {{ 'addAccount' | translate }}
                            </button>
                            <button
                              class="bg-red-600 text-white active:bg-red-700 font-bold text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                              type="button"
                              *ngIf="willbeUpdated"
                              [ngStyle]="{
                                'background-color':
                                  educationalDetails.pristine ||
                                  educationalDetails.invalid
                                    ? 'gray'
                                    : '#14B8A6',
                                'border-color':
                                  educationalDetails.pristine ||
                                  educationalDetails.invalid
                                    ? 'gray'
                                    : '#14B8A6',
                                padding: '12px'
                              }"
                              (click)="updat()"
                              [disabled]="
                                educationalDetails.pristine ||
                                educationalDetails.invalid
                              "
                            >
                            {{ 'update' | translate }}
                            </button>
                          </div>
                        </form>
                      </div>

                      <!---------------------------------------------------------------------->

                      <div class="block w-full overflow-x-auto">
                        <!-- Projects table -->
                        <table
                          class="items-center w-full bg-transparent border-collapse"
                        >
                          <thead>
                            <tr>
                              <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                [ngClass]="
                                  color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-red-800 text-red-300 border-red-700'
                                "
                              >
                              {{ 'firstname' | translate }}
                              </th>
                              <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                [ngClass]="
                                  color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-red-800 text-red-300 border-red-700'
                                "
                              >
                              {{ 'lastname' | translate }}
                              </th>
                              <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                [ngClass]="
                                  color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-red-800 text-red-300 border-red-700'
                                "
                              >
                              {{ 'username' | translate }}
                              </th>

                              <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                [ngClass]="
                                  color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-red-800 text-red-300 border-red-700'
                                "
                              >
                              {{ 'email' | translate }}                              </th>
                              <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                [ngClass]="
                                  color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-red-800 text-red-300 border-red-700'
                                "
                              >
                              {{ 'phone' | translate }}
                              </th>
                              <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                [ngClass]="
                                  color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-red-800 text-red-300 border-red-700'
                                "
                              >
                              {{ 'role' | translate }}
                              </th>
                              <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                [ngClass]="
                                  color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-red-800 text-red-300 border-red-700'
                                "
                              >
                              {{ 'activeStatus' | translate }}
                              </th>
                              <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                [ngClass]="
                                  color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-red-800 text-red-300 border-red-700'
                                "
                              >
                                :
                              </th>

                              <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                [ngClass]="
                                  color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-red-800 text-red-300 border-red-700'
                                "
                              ></th>
                            </tr>
                          </thead>
                          <tbody
                            *ngFor="
                              let member of users
                                | paginate
                                  : {
                                      itemsPerPage: pageSize,
                                      currentPage: page,
                                      totalItems: count
                                    };
                              let i = index
                            "
                            [class.active]="i == currentIndex"
                            (click)="setActiveCompany(member, i)"
                          >
                            <tr>
                              <td
                                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                              >
                                {{ member.firstName }}
                              </td>

                              <td
                                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                              >
                                {{ member.lastName }}
                              </td>
                              <td
                                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                              >
                                {{ member.username }}
                              </td>

                              <td
                                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                              >
                                {{ member.email }}
                              </td>
                              <td
                                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                              >
                                {{ member.phoneNumber }}
                              </td>

                              <td
                                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                              >
                                <ul *ngFor="let role of member.roles">
                                  <li>{{ role?.name }}</li>
                                </ul>
                              </td>

                              <td
                                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                              >
                                {{ member.activated }}
                              </td>

                              <td
                                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"
                              >
                                <a
                                  class="cursor-pointer"
                                  (click)="deleteSelectedUser(member.user_id)"
                                  ><img
                                    src="assets/images/icons/delete.png"
                                    alt="delete"
                                    style="width: 1cm; height: 1cm; float: left"
                                  />
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div class="list row">
                        <div class="col-md-12">
                          <pagination-controls
                            previousLabel="Prev"
                            nextLabel="Next"
                            [responsive]="true"
                            (pageChange)="handlePageChange($event)"
                          ></pagination-controls>
                        </div>
                      </div>

                      <!---------------------------------------------------------------------->

                      <!------------------------------------------------------->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
<app-footer></app-footer>
