import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Company } from '../models/Company';
import { Observable } from 'rxjs';

import { User } from '../models/User';

const api = environment.baseURL+"users/";



@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) {}

  getAllUsersByManager(params: any) {
    return this.http.get<any>(api+'getAllCompaniesByManager', { params });
  }

  getUserById(
    user_id:any
  ): Observable<any> {
    return this.http.get(
      api + 'getUserById/'+user_id
     
    );
  }
  
  updateUser(
    user_id:any,
    user:User
  ): Observable<any> {
    console.log(user_id)
    return this.http.put(
      api + 'update/' +user_id,user
     
    );
  }

  addMember(
    company_id:any,
    user:User
  ): Observable<any> {
    console.log(company_id)
    return this.http.post(
      api + 'add/' +company_id,user
     
    );
  }

  /***********GET */

  getAllMembersByManagerPagination(params: any) {
    return this.http.get<any>(api+'getAllMembersByManagerPagination', { params });
  }

  getAllMembersByManager(params: any) {
    return this.http.get<any>(api+'getAllMembersByManager', { params});
  }

  /**************DELETE */

  deleteUser(
    user_id:any,
    manager_id:any
  ): Observable<any> {
    return this.http.delete(
      api + 'deleteUser/'+user_id+'/'+manager_id
     
    );
  }
  
  /**************UPDATE */

  updateMember(
    user_id:any,
    company_id:any,
    user:User
  ): Observable<any> {
    console.log(user_id)
    return this.http.put(
      api + 'updateUser/' +user_id+'/'+company_id ,user
     
    );
  }

  /******************Reset password member */

  resetPassMember(
    user_id:any,
    currentpassword:any,
    password:any,
  ): Observable<any> {
    console.log(user_id)
    return this.http.put(
      api + 'resetpassword/' +user_id +'/'+currentpassword,password
     
    );
  }

  
  getAllUsers(params: any) {
    return this.http.get<any>(api+'getAllUsers', { params });
  }

  deleteSelectedUser(
    user_id:any,
    
  ): Observable<any> {
    return this.http.delete(
      api + 'deleteSelectedUser/'+user_id
      
    );
  }

  /********************************* ADMIN ********************************** */

  addUser(
    //role:String,
    user:User
  ): Observable<any> {
    
    return this.http.post(
      api + 'addUser' ,user
     
    );
  }
  updateUserByAdmin(
    //role:String,
    user:User
  ): Observable<any> {
    
    return this.http.put(
      api + 'updateUserByAdmin' ,user
     
    );
  }
  /********************************************************************* */

  getUsers(page: number = 0, size: number = 7, sort: string[] = ['id', 'desc']): Observable<any> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString())
      .set('sort', sort.join(','));

    return this.http.get<any>(api+"getAllUsers", { params });
  }


  updateTwoFactorAuth(userId: number, twoFactorAuthEnabled: boolean): Observable<User> {
    console.log("api+`/${userId}/two-factor : ", api + userId + "/two-factor")
    return this.http.put<User>(api + userId + "/two-factor", {
      twoFactorAuthEnabled
    });
  }
  
}
