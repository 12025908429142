import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.css']
})
export class SignatureComponent implements OnInit {


  constructor() { }

  ngOnInit(): void {
  }

  relatedPages = [
    { title: 'Documents required to create an ACI file', link: '#' },
    { title: 'Invoices Structured Data Form', link: '#' },
    { title: 'Advance Cargo Information (ACI) Phase 1 Steps', link: '#' }
  ];
}
