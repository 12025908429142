import { Component, OnInit, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { interval } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
declare var AOS: any;
@Component({
  selector: 'app-analyse',
  templateUrl: './analyse.component.html',
  styleUrls: ['./analyse.component.css']
})
export class AnalyseComponent implements OnInit {
  currentDateTime: Date = new Date();
  isSignedIn = false;
  formattedDate: string = '';
  navbarOpen = false;
  lang: string = 'en';
  isMenuOpen: boolean = false;
  isScrolled = false;
  constructor(private translateService: TranslateService, public authService: AuthService) {
    if (this.authService.currentUserValue.user_id) {
      this.isSignedIn = true;
    }
  }

  ngOnInit(): void {
    AOS.init();  // Initialisation de AOS
    const storedLang = localStorage.getItem('lang');
    if (storedLang) {
      this.lang = storedLang;
      this.translateService.use(this.lang);
    }
    interval(1000).subscribe(() => {
      this.currentDateTime = new Date();
      this.formatDateInArabic();
    });  
  }
// Fonction pour formater la date en arabe
formatDateInArabic() {
  this.formattedDate = new Intl.DateTimeFormat('ar-EG', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  }).format(this.currentDateTime);
}

  setNavbarOpen() {
    this.navbarOpen = !this.navbarOpen;
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.isScrolled = window.scrollY > 50;
  }

  ChangeLang(lang: any) {
    const selectedLanguage = lang.target.value;
    localStorage.setItem('lang', selectedLanguage);
    this.translateService.use(selectedLanguage);
    this.lang = selectedLanguage; // Mettre à jour la langue dans le composant
  }

}
