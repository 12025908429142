<app-auth-navbar></app-auth-navbar>


<div  *ngIf="isSignedIn">
  <div class="back">
    <div class="background-image"></div>
    <div class="texte-back">
      <h3>{{ 'bonjour-admin' | translate }}</h3>
    </div>
    <div class="liste-card">
    
      
      <a [routerLink]="['/request']" class="card-link">
      <div class="card">
        <div class="image">
        <img src="assets/images/request.png" alt="">
        </div>
        <div class="footer-card">
          <h4>Acid Requests</h4>
        </div>
      </div>
      </a>

      <div class="or"></div>

      <a [routerLink]="['/acid']" class="card-link">
        <div class="card">
            <div class="image">
              <img src="assets/images/dossier.png" alt="">
            </div>
            <div class="footer-card">
              <h4>Acid Folder</h4>
            </div>
        </div>
        </a>
    </div>
  </div>
  <div class="belgacem"></div>
</div>


<div  *ngIf="isSignedIn == false">

  <app-navbarg></app-navbarg>



  <div class="hero">
    <div class="block1">
      <div [ngClass]="{'texte2': lang === 'ab', 'texte': lang !== 'ab'}" data-aos="fade-up" data-aos-duration="500" data-aos-delay="300">
        <h4>{{ 'welcome_title' | translate }}</h4>
        <p>{{"hero_detaille" | translate }}</p>


        <div class="see-more">
          <button [routerLink]="['/detaille']">
            <i class="fa-solid fa-info-circle"></i>&ensp;See more
          </button>
        </div>
        
      <div class="div-v">
        <div class="related-container">
          <h2 class="related-title">Related pages</h2>
          <ul class="related-list">
            <li><a href="/analyse">{{ 'Analyse' | translate }} <span>&rsaquo;</span></a></li>
              <li><a href="/invoices">Invoices Structured Data Form <span>&rsaquo;</span></a></li>
              <li><a href="/signature">Digital Signature <span>&rsaquo;</span></a></li>
              <li><a href="#">Advance Cargo Information (ACI) Phase 1 Steps <span>&rsaquo;</span></a></li>
          </ul>
      </div>
    </div>
      </div>
      <div class="footer2"></div>
    </div>
    <div class="block2">
      <div class="shape" [class.hidden]="!isImageVisible">
        <img [src]="imageSrc" alt="Image changing every second" [class.hidden]="!isImageVisible">
      </div>
    </div>
  </div>


<div class="about">
  <div class="block12" data-aos="fade-up" data-aos-duration="800" data-aos-delay="500">
    <img src="../../assets/images/about_img01.png" alt="">
  </div>
  <div class="block13" data-aos="fade-up" data-aos-duration="800" data-aos-delay="700">
    <div class="text">
      <h4  [ngClass]="{'section-title2': lang === 'ab', 'section-title': lang !== 'ab'}" >{{ 'title-about' | translate }}</h4>
      <p [ngClass]="{'desc': lang === 'ab'}">
        {{ 'desc-about' | translate }} 

      </p>
    </div> <br>
    <div  [ngClass]="{'liste2': lang === 'ab', 'liste': lang !== 'ab'}">
        <div  [ngClass]="{'partie1AR': lang === 'ab', 'partie1': lang !== 'ab'}">
        <ul>
          <li><i class="fas fa-check-circle" style="color: #6a5ee5; font-size: 21px;"></i>&ensp;{{ 'about-1' | translate }} </li>
          <li><i class="fas fa-check-circle" style="color: #6a5ee5; font-size: 21px;"></i>&ensp;{{ 'about-2' | translate }} </li>
          <li><i class="fas fa-check-circle" style="color: #6a5ee5; font-size: 21px;"></i>&ensp;{{ 'about-3' | translate }} </li>
          <li><i class="fas fa-check-circle" style="color: #6a5ee5; font-size: 21px;"></i>&ensp;{{ 'about-4' | translate }} </li>
        </ul>
        </div>
        <div class="partie2">
          <div class="text-rating">
            <div class="liste-user">
              <div class="user">
                <img src="../../assets/images/avatar-1.jpg" alt="">
              </div>
              <div class="user">
                <img src="../../assets/images/avatar-2.jpg" alt="">
              </div>
              <div class="user">
                <img src="../../assets/images/avatar-3.jpg" alt="">
              </div>
              <div class="user">
                <img src="../../assets/images/avatar-7.jpg" alt="">
              </div>
            </div>
            <div class="rating">
              <i class="fas fa-star filled"></i>
              <i class="fas fa-star filled"></i>
              <i class="fas fa-star filled"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
            </div>
            <span>{{ 'client' | translate }} 4.7 (1,567 {{ 'review' | translate }})</span>
          </div>
          <div class="experience">
            <div class="nbr"><span>25</span></div>
            <div class="anne"><span>{{ 'experience' | translate }}</span></div>
          </div>
        </div>
    </div>
  </div>
</div>



<div class="etape">
  <div class="texte-etape" data-aos="fade-up" data-aos-duration="800" data-aos-delay="500">
    <h4> <i class="fas fa-shoe-prints"></i>&ensp;{{ 'step' | translate }}</h4>
    <p>{{ 'step2' | translate }}</p>
  </div>
  
  <div class="image">
  <div class="image-etape" data-aos="fade-up" data-aos-duration="800" data-aos-delay="700">
    <img *ngIf="lang === 'en'" src="../../assets/images/etape-eng.png" alt="etape">
    <img *ngIf="lang === 'ab'" src="../../assets/images/etape-eng.png" alt="etape">
    <img *ngIf="lang === 'fr'" src="../../assets/images/etape4.png" alt="etape">
  </div>
  </div>
</div>


<div class="contact-globale">
  <div class="contact">
    <div class="partie1-contact" data-aos="fade-up" data-aos-duration="800" data-aos-delay="500">
      <img src="../../assets/images/contact.png" alt="">
    </div>
    <div class="partie2-contact" data-aos="fade-up" data-aos-duration="800" data-aos-delay="700">
      <div class="title-contact">
        <div class="sous-titre-contact">
          <span>Contact US</span>
        </div>
        <h4>{{ 'contact-title' | translate }}</h4>
        <div class="icon-liste">
          <div class="image-icon">
            <img src="../../assets/images/mapi.png" alt="icon-phone" style="margin-top: 10px;">
          </div>
          <div class="block-icon">
            <h5>{{ 'adresse' | translate }}</h5>
            <span>Rue Apollo XI, Tunis 1080</span>
          </div>
        </div>
        <div class="icon-liste">
          <div class="image-icon">
            <img src="../../assets/images/Phone_icon.png" alt="icon-phone" style="margin-top: 10px;">
          </div>
          <div class="block-icon">
            <h5>{{ 'tel' | translate }}</h5>
            <span>(+216) 98 35 93 58</span>
          </div>
        </div>
        <div class="icon-liste">
          <div class="image-icon">
            <img src="../../assets/images/email.png" alt="icon-phone">
          </div>
          <div class="block-icon">
            <h5>E-mail</h5>
            <span>karim.gharbi&#64;mss.tn</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<footer>
    <div class="content">
      <div class="top">
        <div class="logo-details">
            <img src="../../assets/images/auth/logo-dark2.png" alt="Logo" />
            <h4>ACID PORTAL</h4>
        </div>
        <div class="media-icons">
          <a href="#"><i class="fab fa-facebook-f"></i></a>
          <a href="#"><i class="fab fa-twitter"></i></a>
          <a href="#"><i class="fab fa-instagram"></i></a>
          <a href="#"><i class="fab fa-linkedin-in"></i></a>
        </div>
      </div>
      <div class="link-boxes">
        <ul class="box_desc">
          <li class="link_name">DESCRIPTION</li>
          <p>We enable our stakeholders to have a trouble free experience by submitting all the documentations needed 
            for import, export & transit of goods in a single point of entry.</p>
        </ul>
            <ul class="box">
            <li class="link_name">Useful Links</li>
            <li><a href="#"><i class="fas fa-forward" style="color: #7166F5;"></i>&ensp;Companies</a></li>
            <li><a href="#"><i class="fas fa-forward" style="color: #7166F5;"></i>&ensp;join us</a></li>
            <li><a href="#"><i class="fas fa-forward" style="color: #7166F5;"></i>&ensp;Login</a></li>

            </ul>
        <ul class="box input-box">
          <li class="link_name">Subscribe</li>
          <li><input type="text" placeholder="Enter your email"></li>
          <li><input type="button" value="Subscribe"></li>
        </ul>
      </div>
    </div>
    <div class="bottom-details">
      <div class="bottom_text">
        <span class="copyright_text">Copyright © 2024 <a href="#" class="nom_societe">MSS Consulting.</a>All rights reserved</span>
        <span class="policy_terms">
          <a href="#">Privacy policy</a>
          <a href="#">Terms & condition</a>
        </span>
      </div>
    </div>
  </footer>
  <div class="scroll-to-top" [class.show]="isShow" (click)="scrollToTop()">
    <img  src="../../assets/images/scroll-up.png" width="20px"/>
  </div>

