import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

import { User } from "../models/User";
import { SignupRequest } from "../models/SignupRequest";
import { LoginRequest } from "../models/LoginRequest";

const AUTH_API = environment.baseURL + "auth/";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public currentUser: Observable<User>;
  private currentUserSubject: BehaviorSubject<User>;

  constructor(private http: HttpClient) {
    const userJson = localStorage.getItem("currentUser");
    this.currentUserSubject = new BehaviorSubject<User>(
      userJson !== null ? JSON.parse(userJson) : new User()
    );
    this.currentUser = userJson !== null ? JSON.parse(userJson) : new User();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(loginRequest: LoginRequest) {
    return this.http.post<any>(AUTH_API + "signin", loginRequest).pipe(
      map((user: any) => {
        localStorage.setItem("currentUser", JSON.stringify(user));
        return user;
      })
    );
  }

  register(signupRequest: SignupRequest): Observable<any> {
    return this.http.post(AUTH_API + "signup", signupRequest, httpOptions);
  }

  logout() {
    localStorage.removeItem("currentUser");
    this.currentUserSubject.next(new User());
    return this.http.post(AUTH_API + "signout", httpOptions);
  }

  verify2FACode(verifyRequest: any): Observable<any> {
    return this.http
      .post<any>(`${AUTH_API}verfier-2f`, verifyRequest, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      })
      .pipe(
        map((response: any) => {
          localStorage.setItem("currentUser", JSON.stringify(response));
          console.log("currentUser", JSON.stringify(response));

          return response;
        })
      );
  }
}
