





<app-auth-navbar></app-auth-navbar>
<main class="profile-page">
    <section class="relative block h-500-px">
      <div
        class="absolute top-0 w-full h-full bg-center bg-cover"
        style="
          background-image: url('assets/images/slider/comp.jpg');
        "
      >
        <span
          id="blackOverlay"
          class="w-full h-full absolute opacity-50 bg-black"
        ></span>
      </div>
    
    </section>
    <section class="relative py-16 bg-blueGray-800">
        <div class="container mx-auto px-4">
          <div class="relative flex flex-col min-w-0 break-words  w-full mb-6 shadow-xl rounded-lg -mt-64" style="opacity: 0.9; background-color: rgb(241, 242, 248);">
            <div class="w-full lg:w-6/12 px-6 py-6" style="height: 100%;width: 100%;">

                

             
                <!--------------------------------------------------------------------->

                
    
                
                      
                        <div class="px-6">
                          
                       
                          <div class="  text-center" >
                            <div class="flex flex-wrap justify-center" >
                              <div class="w-full lg:w-9/12 px-4" style="width: 100%; height: 100%;">
                                <div class="flex flex-wrap mt-6" >
                                  <div class="w-full mb-24 px-4" >



                                    <!------------------------------------------------------>


                                    <div class="flex-auto px-4 lg:px-10 py-10 pt-0 text-left">
                                        <form  action="#" method="post" [formGroup]="educationalDetails" novalidate>
                                        
                    
                                          <div class="flex flex-wrap">
                                            <!---------------------------------------     Firstname ---------------------------------------------------------->
                    
                                            <div class="w-full lg:w-12/12 px-4">
                                              <div class="relative w-full mb-3">
                                                <label
                                                  class="block  text-blueGray-600 text-xs font-bold mb-2"
                                                  htmlFor="grid-password"
                                                >
                                                Firstname*
                                                </label>
                                                <input
                                                  type="text"
                                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                  formControlName="firstname" placeholder="Firstname "
                                                />
                                              </div>
                                              <div class="text-xs" style="color: red;">
                                                <div *ngIf="education_step && educationalDetails.controls.firstname.errors && educationalDetails.controls.firstname.dirty || educationalDetails.controls.firstname.touched">
                                                  <div *ngIf="education.firstname.errors?.required">Firstname is required</div>
                                              </div>
                                              </div>
                                            </div>
                                            <!--------------------------------------------------------------------->
                                            <!---------------------------------------     Lastname ---------------------------------------------------------->
                    
                                            <div class="w-full lg:w-12/12 px-4">
                                              <div class="relative w-full mb-3">
                                                <label
                                                  class="block  text-blueGray-600 text-xs font-bold mb-2"
                                                  htmlFor="grid-password"
                                                >
                                                Lastname*
                                                </label>
                                                <input
                                                  type="text"
                                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                  formControlName="lastname" placeholder="Lastname"
                                                />
                                              </div>
                                              <div class="text-xs" style="color: red;">
                                                <div *ngIf="education_step && educationalDetails.controls.lastname.errors && educationalDetails.controls.lastname.dirty || educationalDetails.controls.lastname.touched">
                                                  <div *ngIf="education.lastname.errors?.required">Lastname is required</div>
                                              </div>
                                              </div>
                                            </div>
                                            <!--------------------------------------------------------------------->
                                            <!---------------------------------------     Username ---------------------------------------------------------->
                    
                                            <div class="w-full lg:w-12/12 px-4">
                                              <div class="relative w-full mb-3">
                                                <label
                                                  class="block  text-blueGray-600 text-xs font-bold mb-2"
                                                  htmlFor="grid-password"
                                                >
                                                Username*
                                                </label>
                                                <input
                                                  type="text"
                                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                  formControlName="username" placeholder="Username "
                                                />
                                              </div>
                                              <div class="text-xs" style="color: red;">
                                                <div *ngIf="education_step && educationalDetails.controls.username.errors && educationalDetails.controls.username.dirty || educationalDetails.controls.username.touched">
                                                  <div *ngIf="education.username.errors?.required">Username is required</div>
                                              </div>
                                              </div>
                                            </div>
                                            <!--------------------------------------------------------------------->

                                            <!------------------------------------------        Type of Identification      ------------------------------------------------------->

                           <div class="w-full lg:w-12/12 px-4">
                            <div class="relative w-full mb-3">
                              <label
                                class="block  text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                              Type of Identification*
                              </label>
                              <select 
                              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              formControlName="typeofIdentification" aria-placeholder="Type of Identification" (change)=" onchange()"
                              >
                                
                              <option  value="" selected disabled>Type of Identification</option>
                              <option value="CIN">CIN</option>
                              <option value="Passport">Passport</option>
                              
                              </select>
                            
                            </div>

                            <div class="text-xs" style="color: red;">
                              <div *ngIf="education_step && educationalDetails.controls.typeofIdentification.errors && educationalDetails.controls.typeofIdentification.dirty || educationalDetails.controls.typeofIdentification.touched">
                                <div *ngIf="education.typeofIdentification.errors?.required">Type of Identification is required</div>
                            </div>
                                  
                            </div>

                          </div>



                          <!------------------------------------------------------------------------------------------------->
                                            <!---------------------------------------     National ID ---------------------------------------------------------->
                    
                                            <div class="w-full lg:w-12/12 px-4">
                                              <div class="relative w-full mb-3">
                                                <label
                                                  class="block  text-blueGray-600 text-xs font-bold mb-2"
                                                  htmlFor="grid-password"
                                                >
                                                National ID*
                                                </label>
                                                <input
                                                  type="text"
                                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                  formControlName="nationalID"  placeholder="National ID"
                                                />
                                              </div>
                                              <div class="text-xs" style="color: red;">
                                                <div *ngIf="education_step && educationalDetails.controls.nationalID.errors && educationalDetails.controls.nationalID.dirty || educationalDetails.controls.nationalID.touched">
                                                  <div *ngIf="education.nationalID.errors?.required">National ID is required</div>
                                              </div>
                                              </div>
                                            </div>
                                            <!--------------------------------------------------------------------->


                                            <!---------------------------------------     Date ---------------------------------------------------------->
                    
                                            <div class="w-full lg:w-12/12 px-4">
                                              <div class="relative w-full mb-3">
                                                <label
                                                  class="block  text-blueGray-600 text-xs font-bold mb-2"
                                                  htmlFor="grid-password"
                                                >
                                                {{plceHolder}}*
                                                </label>
                                                <input
                                                  type="text"
                                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                  formControlName="expiryDate"  placeholder={{plceHolder}} onfocus="(this.type='date')"
                                                />
                                              </div>
                                              <div class="text-xs" style="color: red;">
                                                <div *ngIf="education_step && educationalDetails.controls.expiryDate.errors && educationalDetails.controls.expiryDate.dirty || educationalDetails.controls.expiryDate.touched">
                                                  <div *ngIf="education.expiryDate.errors?.required">{{plceHolder}}  is required</div>
                                              </div>
                                              </div>
                                            </div>
                                            <!--------------------------------------------------------------------->
                                            <!---------------------------------------     Email ---------------------------------------------------------->
                    
                                            <div class="w-full lg:w-12/12 px-4">
                                              <div class="relative w-full mb-3">
                                                <label
                                                  class="block  text-blueGray-600 text-xs font-bold mb-2"
                                                  htmlFor="grid-password"
                                                >
                                                Email*
                                                </label>
                                                <input
                                                  type="text"
                                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                  formControlName="email" name="email" placeholder="Member Email"
                                                />
                                              </div>
                                              <div class="text-xs" style="color: red;">
                                                <div *ngIf="education_step && educationalDetails.controls.email.errors && educationalDetails.controls.email.dirty || educationalDetails.controls.email.touched">
                                                  <div *ngIf="education.email.errors?.required">Email is required</div>
                                                  <div *ngIf="education.email.errors?.pattern"> Email must be a valid email address.</div>
                                               </div>
                                              </div>
                                            </div>
                                            <!--------------------------------------------------------------------->
                                            <!---------------------------------------     Phone ---------------------------------------------------------->
                    
                                            <div class="w-full lg:w-12/12 px-4">
                                              <div class="relative w-full mb-3">
                                                <label
                                                  class="block  text-blueGray-600 text-xs font-bold mb-2"
                                                  htmlFor="grid-password"
                                                >
                                                Phone*
                                                </label>
                                                <input
                                                  type="text"
                                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                  formControlName="phone" name="phone" placeholder="Member Phone"
                                                />
                                              </div>
                                              <div class="text-xs" style="color: red;">
                                                <div *ngIf="education_step && educationalDetails.controls.phone.errors && educationalDetails.controls.phone.dirty || educationalDetails.controls.phone.touched">
                                                  <div *ngIf="education.phone.errors?.required"> Phone is required</div>
                                              </div>
                                              </div>
                                            </div>
                                            <!--------------------------------------------------------------------->
                                            <!---------------------------------------     Team Name ---------------------------------------------------------->
                    
                                            <div class="w-full lg:w-12/12 px-4">
                                              <div class="relative w-full mb-3">
                                                <label
                                                  class="block  text-blueGray-600 text-xs font-bold mb-2"
                                                  htmlFor="grid-password"
                                                >
                                                Team Name*
                                                </label>
                                                <input
                                                  type="text"
                                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                  formControlName="teamName" name="teamName" placeholder="Team Name"
                                                />
                                              </div>
                                              <div class="text-xs" style="color: red;">
                                                <div *ngIf="education_step && educationalDetails.controls.teamName.errors && educationalDetails.controls.teamName.dirty || educationalDetails.controls.teamName.touched">
                                                  <div *ngIf="education.teamName.errors?.required"> Team Name is required</div>
                                              </div>
                                              </div>
                                            </div>
                                            <!--------------------------------------------------------------------->
                                            <!------------------------------------------        Company      ------------------------------------------------------->

                           <div class="w-full lg:w-12/12 px-4">
                            <div class="relative w-full mb-3">
                              <label
                                class="block  text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                              Company*
                              </label>
                              <select 
                              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              formControlName="companyname"
                              >
                                
                              <option  value="" selected disabled>
                                Company
                               </option>
                               <option *ngFor="let company of companies; let i = index" [value]="company.company_id">
                                 {{ company.company_id }} - {{ company.raisonSocial }}
                               </option>
                              
                              </select>
                            
                            </div>

                            <div class="text-xs" style="color: red;">
                              <div *ngIf="education_step && educationalDetails.controls.companyname.errors && educationalDetails.controls.companyname.dirty || educationalDetails.controls.companyname.touched">
                                <div *ngIf="education.companyname.errors?.required">Company</div>
                            </div>
                                  
                            </div>

                          </div>



                          <!------------------------------------------------------------------------------------------------->
                    
                    
                                          
                                           
                    
                    
                    
                                            
                                          </div>
                    
                                          
                    
                                  
                                  <!--------------------------------------------------------------->
                    
                                  

                                  <div  style="display: flex; justify-content: center;" *ngIf="willBeUpdate">

                                    <button
                                  class="bg-red-600 text-white active:bg-red-700 font-bold  text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                  type="button"
                                  [ngStyle]="{
                                    'background-color':
                                    educationalDetails.pristine || educationalDetails.invalid ? 'gray' :'#0d6efd'  , 'border-color': educationalDetails.pristine || educationalDetails.invalid ? 'gray' :'#0d6efd', 'padding': '12px'
                                  }"
                                  (click)="update()"
                                  [disabled]="educationalDetails.pristine || educationalDetails.invalid "
                                >
                                Update
                                </button>
                                  </div>

                                  <div  style="display: flex; justify-content: center;" *ngIf="willBeUpdate==false">

                                    <button
                                  class="bg-red-600 text-white active:bg-red-700 font-bold  text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                  type="button"
                                  [ngStyle]="{
                                    'background-color':
                                    educationalDetails.pristine || educationalDetails.invalid ? 'gray' :'#0d6efd'  , 'border-color': educationalDetails.pristine || educationalDetails.invalid ? 'gray' :'#0d6efd', 'padding': '12px'
                                  }"
                                  (click)="addMember()"
                                  [disabled]="educationalDetails.pristine || educationalDetails.invalid "
                                >
                                Add Member
                                </button>
                                  </div>




                                        </form>
                                      </div>

                                    
                
                 

                  
                                <!------------------------------------------------------->
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
           
              
    
    
    
                <!---------------------------------------------------------------------->
                
            </div>
          </div>
        </div>
      </section>




    </main>
    <app-footer></app-footer>
 
