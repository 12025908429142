import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { NotificationService } from '../services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { interval } from 'rxjs';
import { RecaptchaModule } from 'ng-recaptcha'; 
import { CountryService } from '../country.service';  
import { Country } from '../country.service';  

declare var AOS: any;

@Component({
  selector: 'app-validator',
  templateUrl: './validator.component.html',
  styleUrls: ['./validator.component.css']
})
export class ValidatorComponent implements OnInit {

  currentDateTime: Date = new Date();
  captchaResponse: string | null = null;
  angForm: FormGroup;
  countries: Country[] = [];

  isSignedIn: boolean = false;
  lang: string = 'en';
  isMenuOpen: boolean = false;

  constructor(
    private translateService: TranslateService,
    public authService: AuthService,
    private fb: FormBuilder,  // Injection correcte du FormBuilder
    private notificationService: NotificationService,
    private countryService: CountryService  // Ajout de l'injection du service
  ) {
    this.angForm = this.fb.group({
      acid: ['', Validators.required],
      taxId: ['', Validators.required],
      registryType: ['', Validators.required],
      exporterNo: ['', Validators.required],
      exporterCountry: ['', Validators.required],
      recaptcha: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    AOS.init();
    const storedLang = localStorage.getItem('lang');
    if (storedLang) {
      this.lang = storedLang;
      this.translateService.use(this.lang);
    }

    interval(1000).subscribe(() => {
      this.currentDateTime = new Date();
    });

    this.countryService.getCountries().subscribe(
      (data: Country[]) => {
        this.countries = data;  
        console.log('Pays récupérés:', this.countries);  
      },
      (error) => {
        console.error('Erreur lors du chargement des pays', error);  
      }
    );
  }

  // Fonction appelée lorsque le CAPTCHA est résolu
  onCaptchaResolved(response: string) {
    this.captchaResponse = response;
    console.log('Captcha resolved:', response);
  }

  // Fonction de soumission du formulaire
  onSubmit(): void {
    if (this.angForm.valid) {
      if (!this.captchaResponse) {
        this.notificationService.showError('Please complete the CAPTCHA.', '');
        return;
      }
    } else {
      this.notificationService.showError('Please fill out all fields correctly.', '');
    }
  }
  

  // Fonction pour changer la langue
  ChangeLang(event: any): void {
    const selectedLanguage = event.target.value;
    localStorage.setItem('lang', selectedLanguage);
    this.translateService.use(selectedLanguage);
    this.lang = selectedLanguage;  // Mettre à jour la langue
  }

  // Fonction pour basculer l'état du menu
  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    // Code pour gérer le défilement si nécessaire
  }
}
