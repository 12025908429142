import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Attachment } from 'src/app/models/Attachment';
import { User } from 'src/app/models/User';
import { AttachmentService } from 'src/app/services/attachment.service';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-acid-attachments',
  templateUrl: './acid-attachments.component.html',
  styleUrls: ['./acid-attachments.component.css']
})
export class AcidAttachmentsComponent implements OnInit {

  acidNumber:any;
  fileInfos?: Observable<any>;
  pdfSrcEvidencePower:any;
  pdfSrcs:any[] = [];
  attachments: Attachment[] = [];
  fileElement:any;
  userId:any;
  
  willBeReplaced=false
  additionalFiles?: FileList[] ;
  uploadedFiles: { [key: string]: any } = {};
 
  combinedData: { pdfsrc: any, att: Attachment }[] = [];
  isExporter=false

  constructor(private route: ActivatedRoute ,public userService:UserService, public attachmentService:AttachmentService,
    public notificationService:NotificationService,private sanitizer: DomSanitizer,
    public authService:AuthService) 
    {
      this.userId=authService.currentUserValue.user_id

      this.userService.getUserById(this.userId).subscribe(  (data:  User ) => {
        if(data.roles){
          for(let role of data.roles){
            if(role.name=="EXPORTER"){
            this.isExporter=true
            
          }
          }
        }
       
      })

      this.uploadedFiles = {
        'Invoice': null,
        'Bill of lading': null,
        'Certificate of origin': null,
        'Other': null,
        
      };
      
   }

  ngOnInit(): void {

    

    this.route.paramMap.subscribe(params => {
      
      const companyIdParam = params.get('acidNumber');
      
      this.acidNumber = companyIdParam 
      
      if(this.acidNumber != null){
        this.getAttachmentsByAcidNumber()
      }
      
    });
  }

  getAttachmentsByAcidNumber(): void {

    this.attachmentService.getAttachmentsByAcidNumber(this.acidNumber)
    .subscribe(
      data => {
        this.attachments = data;
        console.log(this.attachments)
       
        this.loadFileStatus(this.attachments)
      },
      error => {
        console.log(error);
      });
  }
  /************************************************************** */

  


  
  loadFileStatus(files:any) {
    this.pdfSrcs = [];
    for(let file of files){
      

      this.attachmentService.loadFile(file.fileUrl).subscribe(
        (response: ArrayBuffer) => {
          const fileBlob = new Blob([response], { type: 'application/pdf' });
          const fileUrl = URL.createObjectURL(fileBlob);
          this.pdfSrcEvidencePower = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl) as SafeResourceUrl;
          let pdfSrc= this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl) as SafeResourceUrl;
          this.pdfSrcs.push(pdfSrc)
          this.combinedData.push({ pdfsrc: pdfSrc, att: file });
        },
        (error: any) => {
          console.error('Error loading PDF:', error);
        }
      );
      
    }
    
   
    
  }

  delete(id:any){

    this.attachmentService.deleteAttachement(id).subscribe(
      (response: any) => {
        this.notificationService.showSuccess("Attachement Deleted","")
        window.location.href ='details/'+this.acidNumber
        
      },
      (error: any) => {
        console.error('Error loading PDF:', error);
      }
    );


  }

  upload(): void {
    let message:any;
    const keysArray = Object.keys(this.uploadedFiles);

    if (this.uploadedFiles) {
      for(let key of keysArray){

      let file = this.uploadedFiles[key];
      console.log(key)

      if (file) {
        
        //this.companyAddedID=34
        this.attachmentService.uploadAcidFile(this.acidNumber,key,file).subscribe({
          next: (event: any) => {
            
            if (event.type === HttpEventType.UploadProgress) {
             
            } else if (event instanceof HttpResponse) {
              message = event.body.message;
              //this.fileInfos = this.uploadService.getFiles();
            }

            window.location.href ='details/'+this.acidNumber
        
           
          },
          error: (err: any) => {
            console.log(err);
           

            

            if (err.error && err.error.message) {
              message = err.error.message;
            } else {
             message = 'Could not upload the file!';
            }

            this.notificationService.showError('',message)
            this.notificationService.showError('',err.responseMessage)
          }
        });
      }
      
    
    }}}

  

  replace(id:any){
    this.attachmentService.deleteAttachement(id).subscribe(
      (response: any) => {
      
        console.log("Done! , Attachement deleted")
        this.upload()
        window.location.href ='details/'+this.acidNumber
      },
      (error: any) => {
        this.notificationService.showError("Error loading PDF","")
        console.error('Error loading PDF:', error);
      }
    );
    
  }

  onChange(){
    this.willBeReplaced=true
  }
  
  

  
  
/*************************************** */

onFileChange(event: any,fileType:any): void {
  const inputElement: HTMLInputElement = event.target;
  if (inputElement.files) {
    for (let i = 0; i < inputElement.files.length; i++) {
      
      const file: File = inputElement.files[i];
    
      this.uploadedFiles[fileType] = file;

    }
  }

 
}

}
