import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from 'src/app/services/company.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as QRCode from 'qrcode';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-pdf-company',
  templateUrl: './pdf-company.component.html',
  styleUrls: ['./pdf-company.component.css']
})
export class PdfCompanyComponent implements OnInit {
  
  companyId: number;
  company: any;

  qrCodeImage: string = ''; 
  showQRCode: boolean = true;
   qrDataPdfCompany= environment.qrDataPdfCompany; 
  constructor(
    private route: ActivatedRoute,
    private companyService: CompanyService  
  ) {}

  ngOnInit(): void {
 
    this.route.params.subscribe(params => {
      this.companyId = +params['id'];  

      this.generateQRCode(); 
      this.getCompanyDetails(this.companyId);
    });

 
  }

  getCompanyDetails(companyId: number): void {
    this.companyService.getCompanyById(companyId).subscribe(
      (data) => {
        this.company = data;  
        console.log(this.company); 
      },
      (error) => {
        console.error('Error fetching company details:', error);
      }
    );
  }

  
  generatePDF() {
    const element = document.getElementById('invoice');
    if (element) {
      html2canvas(element).then((canvas) => {
        const imgData = canvas.toDataURL('image/png'); // Génère l'image en base64
        const pdf = new jsPDF('p', 'mm', 'a4'); // Initialise le PDF en portrait et format A4
        
        // Calcul des dimensions pour s'adapter à la page A4
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
        
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight); // Ajoute l'image au PDF
        // Génération du nom dynamique avec la date
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split('T')[0]; // Format YYYY-MM-DD
        const fileName = `certif_${formattedDate}.pdf`; // Exemple : devis_2024-11-15.pdf
  
        pdf.save(fileName);
      });
    }
  }

   generateQRCode() {
     
    this.qrDataPdfCompany + this.companyId; 
      QRCode.toDataURL(this.qrDataPdfCompany, { width: 200 }, (err: any, url: string) => {
        if (err) {
          console.error('Erreur lors de la génération du QR code :', err);
          return;
        }
        this.qrCodeImage = url; 
      });
    }
}
