import { Component, HostListener, OnInit } from '@angular/core';
import { VerifyAcidService } from 'src/app/services/verify-acid.service';
import { environment } from 'src/environments/environment';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as QRCode from 'qrcode';
import { interval } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { countries } from "countries-list";
import { NgForm } from '@angular/forms';

declare var AOS: any;

@Component({
  selector: 'app-verify-acid',
  templateUrl: './verify-acid.component.html',
  styleUrls: ['./verify-acid.component.css']
})
export class VerifyAcidComponent implements OnInit {
  acidRequest: any = null; // Initialisation
  acidNumber: string = '';
  matriculeFiscal: string = '';
  rne: string = '';
  companyCountry: string = '';
  recaptchaResolved: boolean = false;

  errorMessage: string = '';
  qrDataInvoce = environment.qrDataInvoce;
  qrCodeImage: string = '';
  qrDataInvoceWidhId: string = '';

   currentDateTime: Date = new Date();
    captchaResponse: string | null = null;

  
    isSignedIn: boolean = false;
    lang: string = 'en';
    isMenuOpen: boolean = false;

    countryList: any[] = [];

  constructor(private verifyAcidService: VerifyAcidService, private translateService: TranslateService,) {}

  ngOnInit(): void {
    // Le QR code sera généré uniquement si les données sont disponibles
    this.qrDataInvoceWidhId = this.qrDataInvoce; // Valeur initiale
        AOS.init();
        const storedLang = localStorage.getItem('lang');
        if (storedLang) {
          this.lang = storedLang;
          this.translateService.use(this.lang);
        }
    
        interval(1000).subscribe(() => {
          this.currentDateTime = new Date();
        });
    
        

           Object.values(countries).forEach((country: any) => {
              this.countryList.push(country.name);
              this.countryList = this.countryList.sort();
            });

            console.log(this.countryList)
  }

    // Fonction appelée lorsque le CAPTCHA est résolu
    onCaptchaResolved(response: string) {
      this.captchaResponse = response;
      console.log('Captcha resolved:', response);
    }

  


  getAcidRequestData(form: NgForm): void {


    if (!this.acidNumber || !this.matriculeFiscal || !this.rne || !this.companyCountry) {
      this.errorMessage = 'Veuillez remplir tous les champs nécessaires.';
      return;
    }

    this.verifyAcidService.getAcidRequest(this.acidNumber, this.matriculeFiscal, this.rne, this.companyCountry).subscribe(
      (data) => {
        this.acidRequest = data;
        this.qrDataInvoceWidhId = `${this.qrDataInvoce}${this.acidRequest.acidNumber}`;
        this.errorMessage = '';
        console.log('Données récupérées :', this.acidRequest);

        // Génère le QR code avec les nouvelles données
        this.generateQRCode();
      },
      (error) => {
        console.error('Erreur lors de la récupération des données :', error);
        this.errorMessage = 'Erreur lors de la récupération des données. Veuillez réessayer.';
      }
    );
  }

  generatePDF(): void {
    const element = document.getElementById('acid');
    if (!element) {
      console.error("L'élément avec l'ID 'acid' est introuvable.");
      return;
    }

    html2canvas(element).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split('T')[0];
      const fileName = `acid_${formattedDate}.pdf`;

      pdf.save(fileName);
    }).catch((error) => {
      console.error('Erreur lors de la génération du PDF :', error);
    });
  }

  generateQRCode(): void {
    if (!this.qrDataInvoceWidhId) {
      console.warn("Impossible de générer un QR code : les données sont manquantes.");
      return;
    }

    QRCode.toDataURL(this.qrDataInvoceWidhId, { width: 200 }, (err: any, url: string) => {
      if (err) {
        console.error('Erreur lors de la génération du QR code :', err);
        return;
      }
      this.qrCodeImage = url;
    });
  }



    // Fonction pour changer la langue
    ChangeLang(event: any): void {
      const selectedLanguage = event.target.value;
      localStorage.setItem('lang', selectedLanguage);
      this.translateService.use(selectedLanguage);
      this.lang = selectedLanguage;  // Mettre à jour la langue
    }
  
    // Fonction pour basculer l'état du menu
    toggleMenu(): void {
      this.isMenuOpen = !this.isMenuOpen;
    }
  
    @HostListener('window:scroll', [])
    onWindowScroll() {
      // Code pour gérer le défilement si nécessaire
    }
}
