
<app-auth-navbar></app-auth-navbar>
<main class="profile-page">
  <section class="relative block h-500-px">
    <div
      class="absolute top-0 w-full h-full bg-center bg-cover"
      style="
        background-image: url('assets/images/slider/comp.jpg');
      "
    >
      <span
        id="blackOverlay"
        class="w-full h-full absolute opacity-50 bg-black"
      ></span>
    </div>
    
  </section>

<section class="relative block py-24 lg:pt-0 bg-blueGray-800">
    <div class="container mx-auto px-6 py-6">
      <div class="flex flex-wrap justify-center lg:-mt-64 -mt-48">
        <div class="w-full lg:w-6/12 px-6 py-6" style="height: 100%;width: 100%;opacity: 0.89;">
         
            <!--------------------------------------------------------------------->

            <div class="container py-5">
  
                <div class="row"> 
                  <div class="col-md-12 col-md-offset-3">
                    <!-------------------------------------------------------------------------------------------------------->

                    <form  id="msform">
                        <fieldset>

                            <h2 class="fs-title" style="display: flex; justify-content: center;">{{ 'profile' | translate }}</h2>


                        </fieldset>
                    </form>
                     
                    <form  id="msform">
                        <fieldset>

                            <h3 style="color: rgb(29, 26, 26); font-size: 14px;">
                                <div>
                                    <tbody>
                                        <tr>
                                            <td  class="fs-title" style="font-size: 14px;"> <h2> {{ 'email' | translate }} </h2></td>
                                            <td> <p style="font-size: 16px; color: #221372; font-family: 'Courier New', Courier, monospace ;"> {{userToUpdate.email}} </p> </td>
                                            <td> </td>
                                        </tr>
                                        
                                        <tr>
                                            <td  class="fs-title" style="font-size: 14px;"> <h2> {{ 'idType' | translate }}</h2></td>
                                            <td> <p style="font-size: 16px; color: #221372; font-family: 'Courier New', Courier, monospace ;">{{userToUpdate.typeofIdentification}} </p> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td  class="fs-title" style="font-size: 14px;"> <h2> {{ 'nationalId' | translate }} </h2></td>
                                            <td> <p style="font-size: 16px; color: #221372; font-family: 'Courier New', Courier, monospace ;"> {{userToUpdate.nationalID}}  </p> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td  class="fs-title" style="font-size: 14px;"> <h2>  {{plceHolder}} </h2></td>
                                            <td> <p style="font-size: 16px; color: #221372; font-family: 'Courier New', Courier, monospace ;">  {{userToUpdate.expiryDate}} </p> </td>
                                            <td> </td>
                                        </tr>
                                        <tr *ngIf="isMember">
                                            <td  class="fs-title" style="font-size: 14px;"> <h2> {{ 'teamName' | translate }} </h2></td>
                                            <td> <p style="font-size: 16px; color: #221372; font-family: 'Courier New', Courier, monospace ;"> {{userToUpdate.team_name}} </p> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td  class="fs-title" style="font-size: 14px;"> <h2> {{ 'username' | translate }} </h2></td>
                                            <td> <p style="font-size: 16px; color: #221372; font-family: 'Courier New', Courier, monospace ;"> {{userToUpdate.username}} </p> </td>
                                            <td> </td>
                                        </tr>

                                        <tr *ngIf="isMember">
                                            <td  class="fs-title" style="font-size: 14px;"> <h2>{{ 'companyName' | translate }} </h2></td>
                                            <td> <p style="font-size: 16px; color: #221372; font-family: 'Courier New', Courier, monospace ;"> {{userToUpdate.team_name}} </p> </td>
                                            <td> </td>
                                        </tr>

                                        
                                    </tbody>
                                   
                                    

                                </div>

                               
                                

                               
                                
                              </h3>


                        </fieldset>
                    </form>
                     
                             
                              
            
            
            
            <!-------------------------------------------------------------------------------------------------------->
                       <form [formGroup]="educationalDetails" id="msform">
                          <fieldset>
                            
                              

                           
                              
                        
            
                          
                            
                            <input type="text" formControlName="firstname" placeholder="{{ 'firstname' | translate }} "/>
                              <div *ngIf="education_step && educationalDetails.controls.firstname.errors && educationalDetails.controls.firstname.dirty || educationalDetails.controls.firstname.touched">
                                  <div *ngIf="education.firstname.errors?.required">{{ 'firstnameRequired' | translate }}</div>
                              </div>
                              <input type="text" formControlName="lastname" placeholder="{{ 'lastname' | translate }}"/>
                              <div *ngIf="education_step && educationalDetails.controls.lastname.errors && educationalDetails.controls.lastname.dirty || educationalDetails.controls.lastname.touched">
                                  <div *ngIf="education.lastname.errors?.required">{{ 'lastnameRequired' | translate }}</div>
                              </div>
                              
                              <input type="text" formControlName="phone" name="phone" placeholder="{{ 'phone' | translate }}"/>
                            <div *ngIf="education_step && educationalDetails.controls.phone.errors && educationalDetails.controls.phone.dirty || educationalDetails.controls.phone.touched">
                              <div *ngIf="education.phone.errors?.required"> {{ 'phoneRequired' | translate }}</div>
                          </div>
                        <div>

                            <input type="text" formControlName="address" name="address" placeholder="{{ 'address' | translate }}"/>
                <div *ngIf="education_step && educationalDetails.controls.address.errors && educationalDetails.controls.address.dirty ||educationalDetails.controls.address.touched">
                  <div *ngIf="education.address.errors?.required">{{ 'addressRequired' | translate }}</div>
                  
              </div>
              <select class="form-select form-select-lg  form-field"   formControlName="country" aria-placeholder="country">
                <option value="" disabled selected>{{ 'country' | translate }}</option>
                <option value="Tunisia">{{ 'tunisia' | translate }}</option>
                <option value="Canada">{{ 'canada' | translate }}</option>
                <option value="America">{{ 'america' | translate }}</option>
              </select>


            
                    <!---------------------------------- Female -------------------------------------------------------->
                    <div class="input-group" style=" display: flex; justify-content: center;" >
                        <input class="form-check-input" type="radio" name="gender"  value="Male"  formControlName="gender"  id="male" style="width: 0.5px; height: 0.5px;"  checked>
                        <label  for="gender" style="color: #615f5f;font-size: 14px;">
                          {{ 'male' | translate }}
                        </label>
                        <input class="form-check-input" type="radio" name="gender"  value="Female" formControlName="gender" style="width: 0.5px; height: 0.5px;" id="female">
                        <label c for="gender" style="color: #615f5f; font-size: 14px;">
                          {{ 'female' | translate }}
                        </label>
                     
                    </div>
    
                    <!---------------------------------- Terms -------------------------------------------------------->

              
                <div *ngIf="education_step && educationalDetails.controls.country.errors && educationalDetails.controls.country.dirty ||educationalDetails.controls.country.touched">
                  <div *ngIf="education.country.errors?.required">{{ 'countryRequired' | translate }}</div>
                  
              </div>

                          <input (click)="onSubmit()"type="submit" name="submit" class="submit action-button" value="Update"/>

                        </div>
                        
            
                    
                              
                              
                          </fieldset>
                      </form>
                     <!--------------------------------------------------------------------------->

                     
                  </div>
              </div>
              </div>
             



            <!---------------------------------------------------------------------->
            
        </div>
      </div>
    </div>
  </section>
</main>
<app-footer></app-footer>