import { Component, OnInit } from '@angular/core';
import { AcidRequest } from 'src/app/models/AcidRequest';
import { Company } from 'src/app/models/Company';
import { User } from 'src/app/models/User';
import { AcidRequestService } from 'src/app/services/acidRequest.service';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-display-acidfolders',
  templateUrl: './display-acidfolders.component.html',
  styleUrls: ['./display-acidfolders.component.css']
})
export class DisplayAcidfoldersComponent implements OnInit {

  acidRequests:AcidRequest[]=[]
  acidRequestWithAcid:AcidRequest[]=[]
  userId:any;

  isExporter=false
  acidFoldersExporter:AcidRequest[]=[]
  exporterCompanyId:any;
  currentEporter:Company;

  constructor(public authService: AuthService,public userService:UserService,public notificationService:NotificationService,
    private acidRequestService:AcidRequestService,public companyService:CompanyService)
   { 
    this.userId=authService.currentUserValue.user_id 
    this.userService.getUserById(this.userId).subscribe(  (data:  User) => {
      if(data.roles){
        for(let role of data.roles){
          if(role.name=="EXPORTER"){
          this.isExporter=true
          this.companyService.getCompanyByExporterLogin(this.userId).subscribe(  (data:  Company) => {
            
            this.currentEporter=data
            
            this.retrieveAcidFoldersExporter(data.company_id)
          });
        }
        }
      }
     
    })

    
  }

  ngOnInit(): void {
    this.retrieveAcidRequests()
    
    
  }

  retrieveAcidRequests(): void {
    this.acidRequestService.getRequestsByManagerId(this.userId)
    .subscribe(
      data => {   
        
        this.acidRequests = data;
        for (let acid of  this.acidRequests){
          
          if(acid.acidNumber ){
            this.acidRequestWithAcid.push(acid)
          }
        }
       // console.log(this.acidRequestWithAcid)
      },
      error => {
        console.log(error);
      });
  }

  retrieveAcidFoldersExporter(exporterCompanyId:any): void {
    this.acidRequestService.getRequestsByExporter(exporterCompanyId)
    .subscribe(
      data => {   
        
        for (let acid of  data){
          
          if(acid.acidNumber ){
            this.acidFoldersExporter.push(acid)
          }
        }
        
      },
      error => {
        console.log(error);
      });
  }


  

}
