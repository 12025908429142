<div class="verification-container">
  <h2 class="verification-title">Vérification du code 2FA</h2>
  
  <form (ngSubmit)="onVerify2FACode()" class="verification-form">
    <label for="username">Nom d'utilisateur</label>
    <input type="text" id="username" [(ngModel)]="username" name="username" required class="input-field">
    
    <label for="password">Mot de passe</label>
    <input type="password" id="password" [(ngModel)]="password" name="password" required class="input-field">

    <label for="verificationCode">Code de vérification</label>
    <input type="text" id="verificationCode" [(ngModel)]="verificationCode" name="verificationCode" required class="input-field">

    <button type="submit" class="submit-button">Vérifier</button>
  </form>

  <p class="message">{{ message }}</p>
</div>

