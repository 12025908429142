import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Company } from '../models/Company';
import { Observable } from 'rxjs';

import { User } from '../models/User';

const api = environment.baseURL+"role/";



@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private http: HttpClient) {}

  getAllRoles() {
    return this.http.get<any>(api+'getRoles');
  }

 

  
  
}
