import { AfterViewInit, Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProductDetails } from 'src/app/models/ProductDetails';
import { DataService } from 'src/app/services/data.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent{
  @Input() itemNumber: number;
  productForm: FormGroup | any;
  productDetails:ProductDetails;
 
  
  totalPriceValue:number=0
  netWeight:number=0

  unities:String[]=[
    'g','kg','l','ml' ,
    'cl' ,
    'tonne' ,
    
   ]

 
  constructor(public dialogRef: MatDialogRef<DialogComponent>,private fb: FormBuilder,
    public productService: ProductService,public notificationService: NotificationService,
    public dataService: DataService,@Inject(MAT_DIALOG_DATA) public data: any
    ) {

      this.itemNumber = data.itemNumber;
    
    this.productForm = this.fb.group({
     
      itemNo: { value: this.itemNumber, disabled: true },
      productCode: ['', [Validators.required ]],
      commodityCode: ['', [Validators.required ]],
      descriptionOfGoods :['', [Validators.required ]],
      tradeMarkOwner:['', [Validators.required ]],
      brandName:['', [Validators.required ]],
      model:['', [Validators.required ]],
      
      quantity:['', [Validators.required ]],
      quantityUnit:['kg', [Validators.required ]],
      unitPrice:['', [Validators.required ]],
      grossWeight:['', [Validators.required ]],
      netWeight: { value: this.netWeight, disabled: true },
      weightUnit:['', [Validators.required ]],
      totalPrice: { value: this.totalPriceValue, disabled: true },
    });

 


    
   }

   sendData(dataToSend:ProductDetails) {
    this.dataService.setData(dataToSend);
  }
  
   save(){
if(this.productForm.get(["itemNo"]).value == "")
{
  this.notificationService.showError('','itemNo is Required')
}else if(this.productForm.get(["productCode"]).value == "")
{
  this.notificationService.showError('','productCode is Required')
}

else if(this.productForm.get(["commodityCode"]).value == "")
{
  this.notificationService.showError('','commodityCode is Required')
}

else if(this.productForm.get(["descriptionOfGoods"]).value == "")
{
  this.notificationService.showError('','descriptionOfGoods is Required')
}

else if(this.productForm.get(["tradeMarkOwner"]).value == "")
{
  this.notificationService.showError('','tradeMarkOwner is Required')
}else if(this.productForm.get(["brandName"]).value == "")
{
  this.notificationService.showError('','brandName is Required')
  
}else if(this.productForm.get(["model"]).value == "")
{
  this.notificationService.showError('','model is Required')
}else if(this.productForm.get(["quantity"]).value == "")
{
  this.notificationService.showError('','quantity is Required')
}else if(this.productForm.get(["quantityUnit"]).value == "")
{
  this.notificationService.showError('','quantityUnit is Required')
}else if(this.productForm.get(["unitPrice"]).value == "")
{
  this.notificationService.showError('','unitPrice is Required')
}else if(this.productForm.get(["grossWeight"]).value == "")
{
  this.notificationService.showError('','grossWeight is Required')
}else if(this.productForm.get(["netWeight"]).value == "")
{
  this.notificationService.showError('','netWeight is Required')
}else if(this.productForm.get(["weightUnit"]).value == "")
{
  this.notificationService.showError('','weightUnit is Required')
}else{



    
    let product=new ProductDetails
    product.itemNo=this.productForm.get(["itemNo"]).value
    product.productCode=this.productForm.get(["productCode"]).value
    product.commodityCode=this.productForm.get(["commodityCode"]).value
    product.descriptionOfGoods=this.productForm.get(["descriptionOfGoods"]).value
    product.tradeMarkOwner=this.productForm.get(["tradeMarkOwner"]).value
    product.brandName=this.productForm.get(["brandName"]).value
    product.model=this.productForm.get(["model"]).value
    product.quantity=this.productForm.get(["quantity"]).value
    product.quantityUnit=this.productForm.get(["quantityUnit"]).value
    product.unitPrice=this.productForm.get(["unitPrice"]).value
    product.grossWeight=this.productForm.get(["grossWeight"]).value
    product.netWeight=this.productForm.get(["netWeight"]).value
    product.weightUnit=this.productForm.get(["weightUnit"]).value
    product.totalPrice=this.productForm.get(["totalPrice"]).value
    
  
   

 this.productService.addProduct( product ).subscribe({
      next: (data) => {
        this.productDetails=data
        this.notificationService.showSuccess("", "product added!");
        this.sendData(data)
        this.dialogRef.close();
       // this.item=this.item+1
      },
      error: (err) => {
        console.log(err)
        this.notificationService.showError("", err.error.message);
      },
    });
  
  }}
  onChange(){

    this.totalPriceValue=this.productForm.get(["quantity"]).value * this.productForm.get(["unitPrice"]).value
    this.productForm.controls['totalPrice'].setValue(this.totalPriceValue);
    this.netWeight=this.productForm.get(["quantity"]).value * this.productForm.get(["weightUnit"]).value
    this.productForm.controls['netWeight'].setValue(this.netWeight);
   
  }
  



}
