<div *ngIf="company">
  <button (click)="generatePDF()" style="display: block; margin: 20px auto; padding: 8px 16px; background-color: #0056b3; color: #fff; border: none; font-size: 12px; cursor: pointer;">تحميل بصيغة PDF</button>

  <div class="certificate-container" id="invoice"  style="max-width: 800px; margin: 0 auto; padding: 40px; background-color: #fff; font-family: 'Arial', sans-serif; color: #333; box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); line-height: 1.4; font-size: 12px;">
    <div class="certificate-header">
      <img src="assets/images/pts.jpeg" alt="Logo" class="certificate-logo" />
      <div *ngIf="company.companyType==='IMPORTER'">
        <h1>بطاقة تسجيل مورد</h1>
      </div>
      
      <div *ngIf="company.companyType==='EXPORTER'">
        <h1>بطاقة تسجيل مصدر</h1>
      </div>
    
      <p><strong>اسم الشركة:  </strong> {{ company.raisonSocial }}</p>
      <p><strong>رقم القيد بالسجل التجاري:  </strong> <span class="badge">{{ company.rne }}</span></p>
      <hr class="hr">
    </div>
    <div class="certificate-info">
      <h4>معلومات الشركة</h4>
      <div class="line"></div>
    
      <div class="info-item">
        <span class="label-ar">عنوان الشركة:</span>
        <span class="value">{{ company.companyAdress }}</span>
        <span class="label-en">Company Address:</span>
      </div>
      
      <div class="info-item">
        <span class="label-ar">المعرّف الجبائي:</span>
        <span class="value">{{ company.matriculeFiscal }}</span>
        <span class="label-en">Tax Identification Number:</span>
      </div>
    
      <div class="info-item">
        <span class="label-ar">رقم الهاتف:</span>
        <span class="value">{{ company.companyPhone }}</span>
        <span class="label-en">Phone Number:</span>
      </div>
    
      <div class="info-item">
        <span class="label-ar">البريد الإلكتروني:</span>
        <span class="value">{{ company.companyEmail }}</span>
        <span class="label-en">Email Address:</span>
      </div>
    
      <div class="info-item">
        <span class="label-ar">نشاط الشركة:</span>
        <span class="value">{{ company.companyType }}</span>
        <span class="label-en">Company Activity:</span>
      </div>
    </div>
    
    <div class="certificate-info">
      <h4>معلومات المسير</h4>
      <div class="line"></div>
    
      <div class="info-item">
        <span class="label-ar">اسم:</span>
        <span class="value">{{ company.manager.firstName }} {{ company.manager.lastName }}</span>
        <span class="label-en">Name:</span>
      </div>
    
      <div class="info-item">
        <span class="label-ar">البريد الإلكتروني:</span>
        <span class="value">{{ company.manager.email }}</span>
        <span class="label-en">Email:</span>
      </div>
    
      <div class="info-item">
        <span class="label-ar">هاتف:</span>
        <span class="value">{{ company.manager.phoneNumber }}</span>
        <span class="label-en">Phone:</span>
      </div>
    
      <div class="info-item">
        <span class="label-ar">رقم الهوية الوطنية:</span>
        <span class="value">{{ company.manager.nationalID }}</span>
        <span class="label-en">National ID:</span>
      </div>
    </div>
    
    <div class="certificate-info">
      <h4>رابط التحقق</h4>
      <div class="line"></div>
    
      <div class="info-item">
        <span class="label-ar">رابط التحقق من السجل التجاري:</span>
        <span class="value">{{ company.verificationLinkOfCommercialReg }}</span>
        <span class="label-en">Verification Link:</span>
      </div>
    </div>
    
      
    
    
    <div >
      <h3 class="qr-code-titre">رمز QR للتحقق من الشهادة</h3>
      <div class="qr-code-container">
      
        <img *ngIf="qrCodeImage" [src]="qrCodeImage" alt="QR Code" />
      </div>
    </div>
    <hr>
    <div class="certificate-footer">
      <p>تم إصدار هذه البطاقة لإثبات التسجيل من قبل المؤسسات المسجلة عبر البوابة الإلكترونية للخدمات التجارية</p>
    </div>
  </div>

  <div *ngIf="!company">
    <p>جارٍ التحميل...</p>
  </div>
</div>
