
<app-auth-navbar></app-auth-navbar>
<main class="profile-page">
  <section class="relative block h-500-px">
    <div
      class="absolute top-0 w-full h-full bg-center bg-cover"
      style="
        background-image: url('assets/images/slider/comp.jpg');
      "
    >
      <span
        id="blackOverlay"
        class="w-full h-full absolute opacity-50 bg-black"
      
       
      ></span>
    </div>
  
  </section>

<section class="relative block py-24 lg:pt-0 bg-blueGray-800">
    <div class="container mx-auto px-6 py-6">
      <div class="flex flex-wrap justify-center lg:-mt-64 -mt-48">
        <div class="w-full lg:w-6/12 px-6 py-6" style="height: 100%;width: 100%;opacity: 0.9;">
         
            <!--------------------------------------------------------------------->

            <div class="container py-5">
  
              <div class="row"> 
                <div class="col-md-12 col-md-offset-3">
          
                  
                  <!-------------------------------------------------------------------------------------------------------->
                  <app-add-companyform ></app-add-companyform>


                <!-------------->


















                <!------------------------------------------------------------------------------------------------------------>
                </div>
            </div>
            </div>
          



            <!---------------------------------------------------------------------->
            
        </div>
      </div>
    </div>
  </section>
</main>
<app-footer></app-footer>