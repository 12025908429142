import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const api = environment.baseURL+'acid/acid-request/search';
const api2 = environment.baseURL+'acid/request';

@Injectable({
  providedIn: 'root'
})
export class VerifyAcidService {

 // private apiUrl = 'http://localhost:9197/api/acid/acid-request';

 constructor(private http: HttpClient) {}

 /*  getAcidRequest(id: string): Observable<any> {
     return this.http.get(`${this.apiUrl}/${id}`);
   }*/
 
     getAcidRequest(acidNumber: string, matriculeFiscal: string, rne: string, companyCountry: string): Observable<any> {
       const params = new HttpParams()
         .set('acidNumber', acidNumber)
         .set('matriculeFiscal', matriculeFiscal)
         .set('rne', rne)
         .set('companyCountry', companyCountry);
   
       return this.http.get(api, { params });
     }
 
     getAcidRequestByNumber(acidNumber: string): Observable<any> {
       return this.http.get(api2+`/${acidNumber}`);
     }
}
