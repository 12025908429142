import { Component, OnInit , HostListener} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationService } from '../services/notification.service';
import { AuthService } from '../services/auth.service';
import { LoginRequest } from '../models/LoginRequest';
import { UserService } from '../services/user.service';
import { User } from '../models/User';
import { TranslateService } from '@ngx-translate/core';
import { interval } from 'rxjs';

export interface Verify2FACodeRequest {
  username: any;
  password: any;
  verificationCode: any;
}


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css',
  ]
})


export class LoginComponent implements OnInit {
  angForm: FormGroup | any;
  button_load= false;
  text_load: string = 'Sign In';
  isLoggedIn = false;
  isLoginFailed = false;
  isScrolled = false;
  isMenuOpen: boolean = false;
  currentDateTime: Date = new Date();
  errorMessage = '';
  roles: string[] = [];
  token: string|undefined;
  lang:string ='';
  formattedDate: string = '';
  step1=true;
  step2=false;
  username: string = '';
  password: string = '';
  otpCode: string = '';
  verificationCode: string = '';
  message: string = '';

  constructor(private translateService:TranslateService,public authService: AuthService,public notification: NotificationService,private userService:UserService,
  
    public router: Router,private fb: FormBuilder) {
    this.angForm = this.fb.group({
      
      email: ['', [Validators.required ]],
      password: ['', [Validators.required, Validators.minLength(6)] ],
      recaptcha: new FormControl('', Validators.required),
      
       
    });
   }

   toggleEye: boolean = true;
  
   toggleEyeIcon(inputPassword:any) {
     this.toggleEye = !this.toggleEye;
     
     inputPassword.type = inputPassword.type === 'password' ? 'text' : 'password';
 
     
   }
 
  ngOnInit(): void {
    interval(1000).subscribe(() => {
      this.currentDateTime = new Date();
    });
    const storedLang = localStorage.getItem('lang');
    if (storedLang) {
      this.lang = storedLang;
      this.translateService.use(this.lang);
    }
  }
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

 
  onSubmit(): void {
    this.text_load = "Loading ...";
    const loginRequest: LoginRequest = {
      username: this.angForm.get(["email"]).value,
      password: this.angForm.get(["password"]).value,
    };
    
    // Type assertion to ensure loginRequest.username and password are treated as strings
    this.username = loginRequest.username as string;
    this.password = loginRequest.password as string;

    this.authService.login(loginRequest).subscribe({
      next: (data) => {
        if (data.user_id) {
          this.text_load = "Sign IN";
          this.isLoginFailed = false;
          this.isLoggedIn = true;
          window.location.href = "home";
          this.notification.showInfo("Bienvenue!", "");
        } else {
          this.text_load = "Sign IN";
          this.isLoginFailed = false;
          this.isLoggedIn = true;
          this.step1 = false;
          this.step2 = true;  // Show OTP step
        }
      },
      error: (err) => {
        this.text_load = "Sign IN";
        this.isLoginFailed = true;
        this.notification.showError("Mot de passe incorrect", "");
      },
    });
}


  onSubmitOtp() {
    console.log('OTP Code:', this.otpCode);
  }
  onVerify2FACode(): void {
    const verifyRequest: Verify2FACodeRequest = {
      username: this.username,  // Use stored username
      password: this.password,  // Use stored password
      verificationCode: this.otpCode
    };

    this.authService.verify2FACode(verifyRequest).subscribe(
      response => {
        this.message = 'Authentification réussie!';
        window.location.href = 'home'; 
        this.notification.showInfo('Authentification réussie!', '');
      },
      error => {
        this.message = 'Erreur de vérification du code 2FA.';
        console.error(error);
      }
    );
}



  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.isScrolled = window.scrollY > 50;
  }
  formatDateInArabic() {
    this.formattedDate = new Intl.DateTimeFormat('ar-EG', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    }).format(this.currentDateTime);
  }
 
  ChangeLang(lang:any){
    const selectedLanguage = lang.target.value;
    localStorage.setItem('lang', selectedLanguage);
    this.translateService.use(selectedLanguage);
    this.lang = selectedLanguage; // Mettre à jour la langue dans le composant

  }  

}
