





<app-auth-navbar></app-auth-navbar>
<main class="profile-page">
    <section class="relative block h-500-px">
      <div
        class="absolute top-0 w-full h-full bg-center bg-cover"
        style="
          background-image: url('assets/images/slider/comp.jpg');
        "
      >
        <span
          id="blackOverlay"
          class="w-full h-full absolute opacity-50 bg-black"
        ></span>
      </div>
    
    </section>
    <section class="relative py-16 bg-blueGray-800">
        <div class="container mx-auto px-4">
          <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64" style="opacity: 0.9;">
            <div class="w-full lg:w-6/12 px-6 py-6" style="height: 100%;width: 100%;">

                

             
                <!--------------------------------------------------------------------->

                
    
                
                      
                        <div class="px-6">
                          
                       
                          <div class="  text-center" >
                            <div class="flex flex-wrap justify-center" >
                              <div class="w-full lg:w-9/12 px-4" style="width: 100%; height: 100%;">
                                <div class="flex flex-wrap mt-6" >
                                  <div class="w-full mb-24 px-4" >

                                    
                
                  <div class="rounded-t mb-0 px-4  border-0 ">
                
                    <div class="py-6 px-3 mt-32 sm:mt-0 lg:text-right" *ngIf="isMinistry==false">
                      <button
                        class="bg-black active:bg-blueGray-200 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        [routerLink]="['/addAcid']"
                       
                      >
                      <i class="fas fa-plus-circle" ></i>
                      {{ 'new_request' | translate }}
                      </button>
                    </div>

                    </div>

                    <div class="flex flex-wrap">
                    
                 
                 
                                <!----------------------------------------------------->
                                    <!----------------------------------------------------->
                
                
                                <div  class="w-full lg:w-4/12 px-4" *ngFor="let request of acidRequests">
                                    <div class="hover:-mt-4 relative flex flex-col min-w-0 break-words  w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150" 
                                    style=" border: 2px solid rgb(194, 188, 188); /* Définit une bordure de 2 pixels avec une couleur noire */
                                    padding: 10px;" ><br><br>
                                      <a  class="relative flex flex-col">
                                        <div
                                        style="display: flex; justify-content: center;"
                                        >
                                          <img alt="..." style="width: 50%;height: 50%;" src="assets/images/icons/request.png"/></div>
                      
                                      </a>
                                      <div  style="display: flex; justify-content: center; ">
                                        <h6 style="font-size: 110%;color: #6d6363; text-align: center;">{{ 'invoice_number' | translate }}: {{request.generalSegment?.invoice?.invoiceNumber}}
                                         </h6>

                                        </div>
                                
                                         <div  style="display: flex; justify-content: center; ">
                                         <h6 style="font-size: 110%;color: #6d6363; text-align: center;"> {{ 'company' | translate }}: {{request.generalSegment?.invoice?.importer?.raisonSocial}}
                                        </h6>
                      
                                      </div>

                                      <div  style="display: flex; justify-content: center; ">
                                        <h6 style="font-size: 110%;color: #6d6363; text-align: center;">{{ 'user' | translate }}: {{request.user?.username}}
                                       </h6>
                     
                                     </div>

                                  

                                      <div  style="display: flex; justify-content: center; ">
                                        <h6 style="font-size: 110%;color: #234970; text-align: center;"> {{request.state}}
                                       </h6>
                     
                                     </div>
                                   
                                      <div  style="display: flex; justify-content: center;">
                                        <button 
                                        class="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
                                        type="button"
                                        routerLink="/request/{{request.id}}"
                                        >
                                        {{ 'details' | translate }}
                                        </button>
                                      </div><br><br>
                                    </div>
                                    </div>
                      
                
                
                
                
                
                
                
                
                                <!------------------------------------------------------->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
           
              
    
    
    
                <!---------------------------------------------------------------------->
                
            </div>
          </div>
        </div>
      </section>




    </main>
    <app-footer></app-footer>
 
