import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Company } from '../models/Company';
import { Observable } from 'rxjs';
import { CompanyDTO } from '../models/CompanyDTO';
import { CompanyDeclarantMemberDTO } from '../models/CompanyDeclarantMemberDTO';

const api = environment.baseURL+"company/";



@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private http: HttpClient) {}
  getAllCompanies(params: any) {
    return this.http.get<any>(api+'getAllCompanies', { params });
  }
  getAllCompaniesByManagerPagination(params: any) {
    return this.http.get<any>(api+'getAllCompaniesByManagerPagination', { params });
  }

  getAllCompaniesByManager(params: any) {
    return this.http.get<any>(api+'getAllCompaniesByManager', { params});
  }

  getAllAcceptedCompaniesByManager(params: any) {
    return this.http.get<any>(api+'getAllAcceptedCompaniesByManager', { params});
  }

  addCompany(
    company:CompanyDTO
  ): Observable<any> {
    return this.http.post(
      api + 'add',company
     
    );
  }

  addCompanyDeclarantMember(
    manager_id:any,
    company:CompanyDeclarantMemberDTO
  ): Observable<any> {
    return this.http.post(
      api + 'addCompanyDeclarantMember/'+manager_id,company
     
    );
  }


  deleteCompany(
    company_id:any,
    manager_id:any

  ): Observable<any> {
    return this.http.delete(
      api + 'deleteCompany/'+company_id+'/'+manager_id
     
    );
  }
  updateCompany(
    company_id:any,
    company:Company
  ): Observable<any> {
    console.log(company_id)
    return this.http.put(
      api + 'updateCompany/' +company_id,company
     
    );
  }

  getCompanyById(
    company_id:any
  ): Observable<any> {
    return this.http.get(
      api + 'getCompanyById/'+company_id
     
    );
  }

  /************************************************************************************************************************/
    /************************************************************************************************************************/
    /**************************************           EXPORTER PART             *********************************************/
    /************************************************************************************************************************/
    /************************************************************************************************************************/

  addExporterCompany(
    company:Company
  ): Observable<any> {
    return this.http.post(
      api + 'addExporterCompany',company
     
    );
  }
  searchExporterCompany(
    rne:any,
    email:any,
  
  ): Observable<any> {
    const params = new HttpParams()
    .set('rne', rne)
    .set('email', email);

    
    return this.http.get(
      api + 'searchExporterCompany' ,{params}
      
     
    );
  }


  getCompanyByExporterLogin(
    userId:any
  ): Observable<any> {
    return this.http.get(
      api + 'getCompanyByExporterLogin/'+userId
     
    );
  }


   /************************************************************************************************************************/
    /************************************************************************************************************************/
    /**************************************           ADMIN PART             *********************************************/
    /************************************************************************************************************************/
    /************************************************************************************************************************/
    accept(
      company:any
    ): Observable<any> {
      return this.http.put(
        api + 'accept',company
      );
    }
    reject(
      company:any
    ): Observable<any> {
      return this.http.put(
        api + 'reject',company
      );
    }
  
    addtionalInformation(
      company:any
    ): Observable<any> {
      return this.http.put(
        api + 'addtionalInformation',company
      );
    }
    getAllExporters(params: any) {
      return this.http.get<any>(api+'getAllExporters', { params });
    }
    getAllImporters(params: any) {
      return this.http.get<any>(api+'getAllImporters', { params });
    }
    sendLoginToExporetr(
      exporter:any
    ): Observable<any> {
      return this.http.put(
        api + 'sendLoginToExporetr',exporter
      );
    }

}
