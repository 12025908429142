import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { User } from '../models/User';
import { Invoice } from '../models/Invoice';

const api = environment.baseURL+"conf/";



@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(private http: HttpClient) {}

  getMode(
    
    admin_id:any

  ): Observable<any> {
    
    return this.http.get(
      api + 'getMode/' +admin_id
     
    );
  }

  setMode(
    
    admin_id:any,
    mode:any

  ): Observable<any> {
    const params = new HttpParams().set('mode', mode);
    return this.http.put(
      api + 'setMode/' +admin_id ,null,{params}
     
    );
  }

  


  
}
