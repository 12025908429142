import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-list-for-double-auth',
  templateUrl: './user-list-for-double-auth.component.html',
  styleUrls: ['./user-list-for-double-auth.component.css']
})
export class UserListForDoubleAuthComponent implements OnInit {

  users: any[] = [];
  currentPage: number = 0;
  totalPages: number = 1;
  pageSize: number = 7;
  searchText: string = '';
  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.loadUsers();
  }

  loadUsers(page: number = 0): void {
    this.userService.getUsers(page, this.pageSize).subscribe((response) => {
      this.users = response.members;
      this.totalPages = response.totalPages;
      this.currentPage = response.currentPage;
      console.log(this.users);  // Affichez toute la réponse ici
    });
  }

  changePage(page: number): void {
    if (page >= 0 && page < this.totalPages) {
      this.currentPage = page;
      this.loadUsers(page);
    }
  }
  

  updateTwoFactorAuth(userId: number): void {
    console.log("ID de l'utilisateur passé : ", userId); // Vérifiez ce qui est passé ici
    const user = this.users.find(u => u.user_id === userId);

    if (user) {
      // Inversez le statut actuel
      const updatedStatus = !user.twoFactorAuthEnabled;
      console.log("affiche", userId, updatedStatus);
      // Appelez le service pour mettre à jour le statut
      this.userService.updateTwoFactorAuth(userId, updatedStatus).subscribe(
        () => {
          // Mettez à jour l'utilisateur localement après une réponse réussie
          user.twoFactorAuthEnabled = updatedStatus;
          console.log(`Mise à jour réussie pour l'utilisateur ${userId}`);
        },
        (error) => {
          console.error(`Erreur lors de la mise à jour pour l'utilisateur ${userId}:`, error);
        }
      );
    }
  }
  
}
