import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Company } from '../models/Company';
import { Observable } from 'rxjs';
import { CompanyDTO } from '../models/CompanyDTO';
import { User } from '../models/User';

const api = environment.baseURL+"attachments/";



@Injectable({
  providedIn: 'root'
})
export class AttachmentService {

  constructor(private http: HttpClient) {}

 
  uploadFile(
    user_id:any,
    company_id:any,
    dataType:any,
    file:File
  ): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('dataType', dataType);
    return this.http.post(
      api + 'file/' +user_id+'/'+company_id,formData, {
        reportProgress: true,
        responseType: 'json'
      }
     
    );
  }

 
  

  loadFile(
    filename:any
  ): Observable<any> {
    return this.http.get(
      api + 'files/'+filename , { responseType: 'arraybuffer' }
    );
  }

  getAttachmentsByCompany(
    company_id:any
  ): Observable<any> {
    return this.http.get(
      api + 'attachmentsByCompany/'+company_id
     
    );
  }
 

  deleteAttachement(
    id:any,

  ): Observable<any> {
    return this.http.delete(
      api + 'detleteAttachment/'+id
     
    );
  }
  

  uploadAcidFile(
    acidNumber:any,
    dataType:any,
    file:File
  ): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('dataType', dataType);
    return this.http.post(
      api + 'acidFile/' +acidNumber,formData, {
        reportProgress: true,
        responseType: 'json'
      }
     
    );
  }

  getAttachmentsByAcidNumber(
    acidNumber:any
  ): Observable<any> {
    return this.http.get(
      api + 'attachmentsByAcidNumber/'+acidNumber
     
    );
  }
  
}
