import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VerifyAcidService } from 'src/app/services/verify-acid.service';
import * as QRCode from 'qrcode';
import { environment } from 'src/environments/environment';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-verify-acid-widh-id',
  templateUrl: './verify-acid-widh-id.component.html',
  styleUrls: ['./verify-acid-widh-id.component.css']
})
export class VerifyAcidWidhIdComponent implements OnInit {

  acidNumber!: string; 
  acidRequest: any; 
  error: string | null = null; 

  title = 'QR Code Verify ACID';
  qrCodeImage: string = '';
  qrDataInvoce = environment.qrDataInvoce; 
  qrDataInvoceWidhId: string = '';

  constructor(private verifyAcidService: VerifyAcidService, private route: ActivatedRoute) {}

  ngOnInit(): void {
 
    this.acidNumber = this.route.snapshot.paramMap.get('idAcid') || '';
    console.log('ACID Number:', this.acidNumber);

   
    if (this.acidNumber) {
      this.fetchAcidRequest();
    } else {
      this.error = 'Numéro ACID invalide ou manquant.';
      console.error(this.error);
    }
  }

  fetchAcidRequest(): void {
    
    this.verifyAcidService.getAcidRequestByNumber(this.acidNumber).subscribe({
      next: (data) => {
        this.acidRequest = data;
        console.log('Acid Request Data:', this.acidRequest);

        
        this.qrDataInvoceWidhId = `${this.qrDataInvoce}${this.acidRequest.acidNumber}`;
        console.log('QR Data URL:', this.qrDataInvoceWidhId);

      
        this.generateQRCode();
      },
      error: (err) => {
        this.error = 'Erreur lors de la récupération des données.';
        console.error('API Error:', err);
      }
    });
  }

  generateQRCode(): void {
    if (!this.qrDataInvoceWidhId) {
      console.error('URL pour le QR Code non valide.');
      return;
    }

    // Générer le QR Code à partir de l'URL complète
    QRCode.toDataURL(this.qrDataInvoceWidhId, { width: 200 }, (err: any, url: string) => {
      if (err) {
        console.error('Erreur lors de la génération du QR code :', err);
        return;
      }
      this.qrCodeImage = url;
      console.log('QR Code généré avec succès.');
    });
  }



  generatePDF(): void {
    const element = document.getElementById('acid');
    if (!element) {
      console.error("L'élément avec l'ID 'acid' est introuvable.");
      return;
    }

    html2canvas(element).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split('T')[0];
      const fileName = `acid_${formattedDate}.pdf`;

      pdf.save(fileName);
    }).catch((error) => {
      console.error('Erreur lors de la génération du PDF :', error);
    });
  }
}
