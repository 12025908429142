





<app-auth-navbar></app-auth-navbar>
<main class="profile-page">
    <section class="relative block h-500-px">
      <div
        class="absolute top-0 w-full h-full bg-center bg-cover"
        style="
          background-image: url('assets/images/slider/comp.jpg');
        "
      >
        <span
          id="blackOverlay"
          class="w-full h-full absolute opacity-50 bg-black"
        ></span>
      </div>
    
    </section>
    <section class="relative py-16 bg-blueGray-800">
        <div class="container mx-auto px-4">
          <div class="relative flex flex-col min-w-0 break-words  w-full mb-6 shadow-xl rounded-lg -mt-64" style="opacity: 0.9; background-color: rgb(241, 242, 248);">
            <div class="w-full lg:w-6/12 px-6 py-6" style="height: 100%;width: 100%;">

                

             
                <!--------------------------------------------------------------------->

                
    
                
                      
                        <div class="px-6">
                          
                       
                          <div class="  text-center" >
                            <div class="flex flex-wrap justify-center" >
                              <div class="w-full lg:w-9/12 px-4" style="width: 100%; height: 100%;">
                                <div class="flex flex-wrap mt-6" >

                                 




                                 



                                    <!------------------------------------------------------>


<!---------------------------------------------------------------- form --------------------------------------------------------------------------------------------------------->

                    
                    <div class="w-full mb-24 px-4" >
                    
                    
                    <div class="flex-auto px-4 lg:px-10 py-10 pt-0 text-left">
                      <form  action="#" method="post" [formGroup]="exporterForm" novalidate>
                        


                        
                  
                       
                        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase titles">
                          <i class="fas fa-shopping-cart"></i>  Exporter Data
                        </h6>

                        <div class="flex flex-wrap">
                      <!---------------------------------------     regCode ---------------------------------------------------------->
                     
                        

                          <div class="w-full lg:w-4/12 px-4">
                            <div class="relative w-full mb-3">
                              <label
                                class="block  text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                              Commercial Registre*
                              </label>
                              <input
                                type="text"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="Code" id="regCode" formControlName="regCode"
                              />
                            </div>
                            
                            <div class="text-xs" style="color: red;">
                              <div *ngIf="exporterForm.controls['regCode'].invalid && (exporterForm.controls['regCode'].dirty || exporterForm.controls['regCode'].touched)" class="text-danger-filed fs-13 mt-1">
                            
                                <div *ngIf="exporterForm.controls['regCode'].errors.required">
                                  Commercial Registre is required.
                                </div>
                               
                                
                                 </div>
                                  
                            </div>
                          </div>


                          
                          <!---------------------------------------     Email ---------------------------------------------------------->

                          <div class="w-full lg:w-4/12 px-4">
                            <div class="relative w-full mb-3">
                              <label
                                class="block  text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                              Email*
                              </label>
                              <input
                                type="text"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="Email Address" id="email" formControlName="email"
                              />
                            </div>
                            
                            <div class="text-xs" style="color: red;">
                              <div *ngIf="exporterForm.controls['email'].invalid && (exporterForm.controls['email'].dirty || exporterForm.controls['email'].touched)" class="text-danger-filed fs-13 mt-1">
                                <div *ngIf="exporterForm.controls['email'].errors.required">
                                  Email is required.
                                </div>
                                <div *ngIf="exporterForm.controls['email'].errors.pattern">
                                  Email must be a valid email address.
                                 </div>
                              </div>
                                  
                            </div>
                          </div>

                          <!-----------------------------------    -------------------------------------------------------------->
                          <div class="w-full lg:w-4/12 px-4">
                            <div class="relative w-full mb-3">
                              <label
                                class="block  text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                              Status
                              </label>
                              <p  class="border-0 px-3 py-3  text-blueGray-600 bg-white rounded text-sm "
                              > {{currentExporter?.etat}}</p>
                            </div>
                            
                            
                          </div>
                           

                     
                         

                            <!------------------------------------------        exporterName      ------------------------------------------------------->

                            <div class="w-full lg:w-6/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block  text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                Exporter Company Name*
                                </label>
                                <input type="text" 
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder=" Exporter Company Name" id="exporterName" formControlName="exporterName" required="true">
                              </div>
  
                              <div class="text-xs" style="color: red;">
                                <div *ngIf="exporterForm.controls['exporterName'].invalid && (exporterForm.controls['exporterName'].dirty || exporterForm.controls['exporterName'].touched)" class="text-danger-filed fs-13 mt-1">
                              
                                  <div *ngIf="exporterForm.controls['exporterName'].errors.required">
                                    Exporter Company Name is required.
                                  </div>
                                  <div *ngIf="exporterForm.controls['exporterName'].errors.minlength">
                                    Exporter Company Name must be at least 3 characters.
                                  </div>
                                  <div *ngIf="exporterForm.controls['exporterName'].errors.maxlength ">
                                    Exporter Company Name must contain a maximum 20 characters.
                                  </div>
                                 
                                   </div>
                                    
                              </div>
  
                            </div>
                            <!-----------------------------------  Exporter Country-------------------------------------------------------------->
                          <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                              <label
                                class="block  text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                             Exporter Country*
                              </label>
                              <select 
                              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              id="exporterCountry" formControlName="exporterCountry"
                              >
                                
                              <option selected value="" disabled>Exporter Country</option>
                              <option *ngFor="let country of countryList" value={{country}}>{{country}}</option>
                              </select>
                            
                            </div>

                            <div class="text-xs" style="color: red;">
                              <div *ngIf="exporterForm.controls['exporterCountry'].invalid && (exporterForm.controls['exporterCountry'].dirty || exporterForm.controls['exporterCountry'].touched)" >
                          
                                <div *ngIf="exporterForm.controls['exporterCountry'].errors.required" class="text-danger-filed" >
                                 Exporter Country is required.
                                </div>
                              </div>
                                  
                            </div>

                          </div>

                           <!---------------------------------------     Exporter Address ---------------------------------------------------------->

                           <div class="w-full lg:w-12/12 px-4">
                            <div class="relative w-full mb-3">
                              <label
                                class="block  text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                              Exporter Address*
                              </label>
                              <input
                                type="text"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="Enter Full Address" id="exporterAddress" formControlName="exporterAddress"
                              />
                            </div>
                            
                            <div class="text-xs" style="color: red;">
                              <div *ngIf="exporterForm.controls['exporterAddress'].invalid && (exporterForm.controls['exporterAddress'].dirty || exporterForm.controls['exporterAddress'].touched)" class="text-danger-filed fs-13 mt-1">
                            
                                <div *ngIf="exporterForm.controls['exporterAddress'].errors.required">
                                  Exporter Address is required.
                                </div>
                                <div *ngIf="exporterForm.controls['exporterAddress'].errors.minlength">
                                  Exporter Address must be at least 3 characters.
                                </div>
                                
                                 </div>
                                  
                            </div>
                          </div>
                           <!------------------------------------------        exporterPhone      ------------------------------------------------------->

                           <div class="w-full lg:w-4/12 px-4">
                            <div class="relative w-full mb-3">
                              <label
                                class="block  text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                              Exporter Phone*
                              </label>
                              <input type="text" 
                              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder=" Exporter Phone" id="exporterPhone" formControlName="exporterPhone" required="true">
                            </div>

                            <div class="text-xs" style="color: red;">
                              <div *ngIf="exporterForm.controls['exporterPhone'].invalid && (exporterForm.controls['exporterPhone'].dirty || exporterForm.controls['exporterPhone'].touched)" class="text-danger-filed fs-13 mt-1">
                            
                                <div *ngIf="exporterForm.controls['exporterPhone'].errors.required">
                                  Exporter Phone is required.
                                </div>
                                <div *ngIf="exporterForm.controls['exporterPhone'].errors.minlength">
                                  Exporter Phone must be at least 3 characters.
                                </div>
                                <div *ngIf="exporterForm.controls['exporterPhone'].errors.maxlength ">
                                  Exporter Phone must contain a maximum 20 characters.
                                </div>
                               
                                 </div>
                                  
                            </div>

                          </div>
                           <!------------------------------------------        exporterfax      ------------------------------------------------------->

                           <div class="w-full lg:w-4/12 px-4">
                            <div class="relative w-full mb-3">
                              <label
                                class="block  text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                              Exporter Fax
                              </label>
                              <input type="text" 
                              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder=" Exporter Fax" id="exporterfax" formControlName="exporterfax" required="true">
                            </div>

                            

                          </div>
                           <!------------------------------------------        exporterwebSite      ------------------------------------------------------->

                           <div class="w-full lg:w-4/12 px-4">
                            <div class="relative w-full mb-3">
                              <label
                                class="block  text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                              Exporter Web Site*
                              </label>
                              <input type="text" 
                              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder=" Exporter Company Name" id="exporterwebSite" formControlName="exporterwebSite" required="true">
                            </div>

                            <div class="text-xs" style="color: red;">
                              <div *ngIf="exporterForm.controls['exporterwebSite'].invalid && (exporterForm.controls['exporterwebSite'].dirty || exporterForm.controls['exporterwebSite'].touched)" class="text-danger-filed fs-13 mt-1">
                            
                                <div *ngIf="exporterForm.controls['exporterwebSite'].errors.required">
                                  Exporter Web Site is required.
                                </div>
                                <div *ngIf="exporterForm.controls['exporterwebSite'].errors.minlength">
                                  Exporter Web Site must be at least 3 characters.
                                </div>
                                <div *ngIf="exporterForm.controls['exporterwebSite'].errors.maxlength ">
                                  Exporter Web Site must contain a maximum 20 characters.
                                </div>
                               
                                 </div>
                                  
                            </div>

                          </div>
                           <!---------------------------------------     Exporter Address ---------------------------------------------------------->

                           <div class="w-full lg:w-12/12 px-4">
                            <div class="relative w-full mb-3">
                              <label
                                class="block  text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                              Verification Link Of Commercial Registre
                              </label>
                              <input
                                type="text"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="Enter Full verification Link Of Commercial Registre" id="verificationLinkOfCommercialReg" formControlName="verificationLinkOfCommercialReg"
                              />
                            </div>
                            
                          
                          </div>
  
  
  
                            <!------------------------------------------------------------------------------------------------->
                          

                          <!------ /----------------------------------------------/ ----->
              </div>

              <!--------------------------------------------------------->

              <h6 class="text-blueGray-400 text-xs mt-3 mb-6 font-bold uppercase Subtitles">
                Personal Contact
              </h6>
              <div class="flex flex-wrap">
               <!------------------------------------------        exporterPersonalName      ------------------------------------------------------->

               <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block  text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                  Exporter Personal Name*
                  </label>
                  <input type="text" 
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Personal Name" id="exporterPersonalName" formControlName="exporterPersonalName" required="true">
                </div>

                <div class="text-xs" style="color: red;">
                  <div *ngIf="exporterForm.controls['exporterPersonalName'].invalid && (exporterForm.controls['exporterPersonalName'].dirty || exporterForm.controls['exporterPersonalName'].touched)" class="text-danger-filed fs-13 mt-1">
                
                    <div *ngIf="exporterForm.controls['exporterPersonalName'].errors.required">
                      Exporter Personal Name is required.
                    </div>
                    <div *ngIf="exporterForm.controls['exporterPersonalName'].errors.minlength">
                      Exporter Personal Name must be at least 3 characters.
                    </div>
                    <div *ngIf="exporterForm.controls['exporterPersonalName'].errors.maxlength ">
                      Exporter Personal Name must contain a maximum 20 characters.
                    </div>
                   
                     </div>
                      
                </div>

              </div>

              <!------------------------------------------        exporterPersonalCIN      ------------------------------------------------------->

              <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block  text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                  Exporter Personal CIN
                  </label>
                  <input type="text" 
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Personal CIN" id="exporterPersonalCIN" formControlName="exporterPersonalCIN" required="true">
                </div>

                
              </div>
               <!------------------------------------------        exporterPersonalPhone      ------------------------------------------------------->

               <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block  text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                  Exporter Personal Phone*
                  </label>
                  <input type="text" 
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Personal Phone" id="exporterPersonalPhone" formControlName="exporterPersonalPhone" required="true">
                </div>

                <div class="text-xs" style="color: red;">
                  <div *ngIf="exporterForm.controls['exporterPersonalPhone'].invalid && (exporterForm.controls['exporterPersonalPhone'].dirty || exporterForm.controls['exporterPersonalPhone'].touched)" class="text-danger-filed fs-13 mt-1">
                
                    <div *ngIf="exporterForm.controls['exporterPersonalPhone'].errors.required">
                      Exporter Personal Phone is required.
                    </div>
                    <div *ngIf="exporterForm.controls['exporterPersonalPhone'].errors.minlength">
                      Exporter Personal Phone must be at least 3 characters.
                    </div>
                    <div *ngIf="exporterForm.controls['exporterPersonalPhone'].errors.maxlength ">
                      Exporter Personal Phone must contain a maximum 20 characters.
                    </div>
                   
                     </div>
                      
                </div>

              </div>

               <!---------------------------------------   Exporter Personal  Email ---------------------------------------------------------->

               <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block  text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                  Exporter Personal Email*
                  </label>
                  <input
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Personal Email " id="exporterPersonaEmail" formControlName="exporterPersonaEmail"
                  />
                </div>
                
                <div class="text-xs" style="color: red;">
                  <div *ngIf="exporterForm.controls['exporterPersonaEmail'].invalid && (exporterForm.controls['exporterPersonaEmail'].dirty || exporterForm.controls['exporterPersonaEmail'].touched)" class="text-danger-filed fs-13 mt-1">
                    <div *ngIf="exporterForm.controls['email'].errors.required">
                      Email is required.
                    </div>
                    
                    <div *ngIf="exporterForm.controls['exporterPersonaEmail'].errors.pattern">
                      Exporter Personal Email must be a valid email address.
                     </div>
                  </div>
                      
                </div>
              </div>

              <!--------------------------------------------------------------->
             </div>

                        <div  style="display: flex; justify-content: center;">

                          

                          <button
                        class="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold  text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button"
                       
                        (click)="update()"

                        
                       
                      >
                  
                      Update
                      </button>

                      

                      


                      
                        </div>
<br>

                      

                        

                        
                      </form>
                    </div>
                 

                    
              

                  <!-- end of form -->


                                    
                
                 

                  
                                <!------------------------------------------------------->
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>               
            </div>
          </div>
        </div>
      </section>




    </main>
    <app-footer></app-footer>
 

