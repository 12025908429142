<div class="aci-container">
    <div class="aci-header">
      <span class="aci-icon">📂</span>
      <span class="aci-title">Advance Information System</span>
    </div>
  
    <h1 class="aci-main-title">Invoices Structured Data Form</h1>
  
    <div class="aci-content">
      <p class="aci-text">
        In reference to Decrees no. xxxxxxxxx,on the Advance Cargo
        Information (ACI) system, which specifies the controls and procedures
        that must be adhered to regarding ACI system, as its mandatory
        implementation will take effect on xx/xx/202x for shipments arriving
        at seaports;
      </p>
      <p class="aci-text">
        Following recently successive meetings held with members of trade
        community to discuss the mechanisms of implementing ACI system
        regarding the method of sending shipment documents from the
        exporter to the importer;
      </p>
      <p class="aci-text">
        With reference to Article xx of Ministerial Decree no. xxxxxxxxxx,
        which   stipulates   that   the   foreign   exporter   or   producer   must
        electronically send shipment data and documents to ACID PORTAL
        platform;
      </p>
  
      <p class="aci-text">
        It has been noted from the aforementioned meetings that a large
        percentage of foreign exporters are currently not ready to implement
        the mechanisms of the ACI pre-registration system regarding the
        method of sending the electronic invoice to the importer.
      </p>
      <p class="aci-text">
        The foreign exporter downloads the Invoice MS EXCEL Template,
        required by the ACI system, and directly inputs the invoice data into
        the table cells.
      </p>
      
      <div class="aci-links-container">
        <a href="/" class="aci-link" target="_blank">Download the MS Excel Invoice Template</a>
        <a href="/" class="aci-link" target="_blank">View Acid Portal File Field and Sample PDF Invoice</a>
      </div>
    </div>
  
    <aside class="aci-sidebar">
      <h3 class="aci-sidebar-title">Related Pages</h3>
      <ul class="aci-links">
        <li><a href="/analyse" class="aci-link">Analyse <span>&rsaquo;</span></a></li>
        <li><a href="/invoices" class="aci-link">Invoices Structured Data Form <span>&rsaquo;</span></a></li>
        <li><a href="#" class="aci-link">Digital Signature <span>&rsaquo;</span></a></li>
        <li><a href="#" class="aci-link">Advance Cargo Information (ACI) Phase 1 Steps <span>&rsaquo;</span></a></li>
      </ul>
    </aside>
  </div>
  