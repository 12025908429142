<div
  
>

<div class="search-container">
  <input 
    type="text" 
    [(ngModel)]="searchText" 
    placeholder="Search items..." 
    class="search-input"
  />
</div>


<div class="companies-container">
  <p class="companies-title">
    Companies :   {{companies.length}}
  </p>
</div>


  <div class="rounded-t mb-0 px-4 py-3 border-0">
    <div class="flex flex-wrap items-center">
      <div class="relative w-full px-4 max-w-full flex-grow flex-1">
        <h3
          class="px-6 align-middle border border-solid py-3 text-lg uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center titles"
          [ngClass]="color === 'light' ? 'text-blueGray-700' : 'text-white'"
        >
        {{ 'companies' | translate }}
        </h3>
      </div>
    </div>
  </div>
  <div class="block w-full overflow-x-auto" >
    <!-- Projects table -->
    <table class="items-center w-full bg-transparent border-collapse" >
      <thead>
        <tr>
          
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            [ngClass]="
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-red-800 text-red-300 border-red-700'
            "
          >
          {{ 'activity_type' | translate }}
          </th>
          
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            [ngClass]="
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-red-800 text-red-300 border-red-700'
            "
          >
          
          {{ 'name' | translate }}
          </th>

          <th
        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
        [ngClass]="
          color === 'light'
            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
            : 'bg-red-800 text-red-300 border-red-700'
        "
      >
      {{ 'attachments' | translate }}
      </th>


          <th
          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
          [ngClass]="
            color === 'light'
              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
              : 'bg-red-800 text-red-300 border-red-700'
          "
        >
        {{ 'rne' | translate }}

        <th
          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
          [ngClass]="
            color === 'light'
              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
              : 'bg-red-800 text-red-300 border-red-700'
          "
        >
        {{ 'tax_id' | translate }}

        </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            [ngClass]="
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-red-800 text-red-300 border-red-700'
            "
          >
          {{ 'status' | translate }}
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            [ngClass]="
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-red-800 text-red-300 border-red-700'
            "
          >
          {{ 'comment_rejection_reason' | translate }}
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            [ngClass]="
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-red-800 text-red-300 border-red-700'
            "
          >
          {{ 'email' | translate }}
          </th>

          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            [ngClass]="
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-red-800 text-red-300 border-red-700'
            "
          >
          {{ 'phone' | translate }}
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            [ngClass]="
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-red-800 text-red-300 border-red-700'
            "
          >
          {{ 'address' | translate }}
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            [ngClass]="
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-red-800 text-red-300 border-red-700'
            "
          >
          {{ 'country' | translate }}
          </th>
         
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            [ngClass]="
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-red-800 text-red-300 border-red-700'
            "
          >
          {{ 'inscription_date' | translate }}
          </th>
          <th
          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
          [ngClass]="
            color === 'light'
              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
              : 'bg-red-800 text-red-300 border-red-700'
          "
        >
        {{ 'applicant_status' | translate }}
        </th>
        <th
          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
          [ngClass]="
            color === 'light'
              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
              : 'bg-red-800 text-red-300 border-red-700'
          "
        >
        {{ 'document_submission_centre' | translate }}
        </th>
        <th
          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
          [ngClass]="
            color === 'light'
              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
              : 'bg-red-800 text-red-300 border-red-700'
          "
        >
        {{ 'food_importer' | translate }}
        </th>
        <th
          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
          [ngClass]="
            color === 'light'
              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
              : 'bg-red-800 text-red-300 border-red-700'
          "
        >
        {{ 'declarant_username' | translate }}
        </th>

        
       

        <th
        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
        [ngClass]="
          color === 'light'
            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
            : 'bg-red-800 text-red-300 border-red-700'
        "
      >
      {{ 'options' | translate }}
      </th>




          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            [ngClass]="
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-red-800 text-red-300 border-red-700'
            "
          ></th>
        </tr>
      </thead>
     
      <tbody  *ngFor="
      let company of companies  | filter: searchText | paginate : {
              itemsPerPage: pageSize,
              currentPage: page,
              totalItems: count
            };
      let i = index
    "
    [class.active]="i == currentIndex"
        (click)="setActiveCompany(company, i)"

        
    
    
    >
        <tr>
         

          

          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
          {{company.activityType}}
          </td>
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
          {{company.raisonSocial}}
          </td>

          <td
    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
   
    
  >


    <a routerLink="/display-attachments/{{company.company_id}}"  style="display: flex;justify-content: center;"><img   src="assets/images/icons/attach.png" alt="Attachments" 
      style="width: 30%; height:  30%;float: right;">
     </a>
  
 
  </td>

          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
          {{company.rne}}
          </td>
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
          {{company.matriculeFiscal}}
          </td>
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
          {{company.etat}}
          </td>
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
          {{company.comment}}
          </td>
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
          {{company.companyEmail}}
          </td>
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
          {{company.companyPhone}}
          </td>
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
          {{company.companyAdress}}
          </td>
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
          {{company.companyCountry}}
          </td>
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
          {{company.dateInscription}}
          </td>
          <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
        {{company.applicantStatus}}
        </td>
        <td
        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
      >
      {{company.documentSubmissionCentre}}
      </td>
      <td
      class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
    >
    {{company.foodImporter}}
    </td>
    <td
      class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
      *ngIf="company.manager"
    >
    
    {{company.manager.username}}
    </td>

    


          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"
            
          >

          
          
         
          <a routerLink="/display-attachments/{{company.company_id}}" *ngIf="company?.etat == 'ADDITIONAL_INFORMATION'">
            <img   src="assets/images/icons/addinformation.png" alt="Add information" style="width: 30%; height:  30%;float: right;"> </a>
         
          <a routerLink="/delete-company/{{company.company_id}}"><img   src="assets/images/icons/delete.png" alt="delete" style="width: 30%; height:  30%;float: right;"> </a>
          <a  routerLink="/update-company/{{company.company_id}}"><img   src="assets/images/icons/pencil.png" alt="update" style="width: 30%; height:  30%;float: right;"> </a>

          </td>
        </tr>
       
         
             
        
      </tbody>

      
    </table>
  </div>
</div>


<!---------------------------------------->

<div class="list row">


  <div class="col-md-12">
    <pagination-controls
      previousLabel="Prev"
      nextLabel="Next"
      [responsive]="true"
      (pageChange)="handlePageChange($event)"
    ></pagination-controls>
  </div>

 


  <!---div class="mt-3" style="font-size: 14px;">


    Items
    <select (change)="handlePageSizeChange($event)">
      <option *ngFor="let size of pageSizes" [ngValue]="size">
        {{ size }}
      </option>
    </select>
  </div---->
</div>
