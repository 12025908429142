import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignupComponent } from './signup/signup.component';
import { Routes } from '@angular/router';
import { SpinnerComponent } from './spinner/spinner.component';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import {  ReactiveFormsModule } from '@angular/forms';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { ValidComponent } from './verification/valid/valid.component';
import { InvalidComponent } from './verification/invalid/invalid.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { UnauthorizedComponent } from './verification/unauthorized/unauthorized.component';
import { ProfileComponent } from './components/profile/profile.component';
import { AuthNavbarComponent } from './components/navbars/auth-navbar/auth-navbar.component';
import { PagesDropdownComponent } from './components/dropdowns/pages-dropdown/pages-dropdown.component';
import { FooterComponent } from './components/footers/footer/footer.component';
import { CardTableComponent } from './components/cards/card-table/card-table.component';
import { HomepageComponent } from './homepage/homepage.component';

import { HttpRequestInterceptor } from './helpers/http-request.interceptor';
import { AddCompanyComponent } from './components/importer/add-company/add-company.component';

import { NgxPaginationModule } from 'ngx-pagination';

import { AddMemberComponent } from './components/importer/add-member/add-member.component';
import { DisplayMembersComponent } from './components/importer/display-members/display-members.component';
import { ResetPasswordComponent } from './verification/reset-password/reset-password.component';
import { ManagerDashboardComponent } from './components/manager-dashboard/manager-dashboard.component';
import { RECAPTCHA_SETTINGS,  RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings  } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { AddCompanyformComponent } from './components/importer/add-companyform/add-companyform.component';
import { DisplayAttachmentsComponent } from './components/display-attachments/display-attachments.component';
import { DisplayAcidfoldersComponent } from './components/display-acidfolders/display-acidfolders.component';
import { AddAcidfolderComponent } from './components/importer/add-acidfolder/add-acidfolder.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogComponent } from './components/dialog/dialog.component';
import { DisplayAcidrequestsComponent } from './components/display-acidrequests/display-acidrequests.component';
import { DataService } from './services/data.service';
import { AdminAcidRequestsComponent } from './components/admin/admin-acid-requests/admin-acid-requests.component';
import { AdminCompaniesComponent } from './components/admin/admin-companies/admin-companies.component';
import { ExporterCompanyuploadsComponent } from './components/exporter/exporter-companyuploads/exporter-companyuploads.component';
import { UpdateExporterComponent } from './components/exporter/update-exporter/update-exporter.component';
import { AdminCompaniesExportersComponent } from './components/admin/admin-companies-exporters/admin-companies-exporters.component';
import { AcidAttachmentsComponent } from './components/acid-attachments/acid-attachments.component';
import { ModeComponent } from './components/admin/mode/mode.component';
import { AddAccountComponent } from './components/plateformadminstraion/add-account/add-account.component';
import { AddListComponent } from './components/plateformadminstraion/add-list/add-list.component';
import { ExporterBlackListComponent } from './components/plateformadminstraion/exporter-black-list/exporter-black-list.component';
import { ExportersComponent } from './components/plateformadminstraion/exporters/exporters.component';
import { MinistriesComponent } from './components/plateformadminstraion/ministries/ministries.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TestComponent } from './test/test.component';
import { CompanyListComponent } from './components/company-list/company-list.component';
import { FormsModule } from '@angular/forms';
import { PdfCompanyComponent } from './components/pdf-company/pdf-company.component'; 


import { VerifytofaComponent } from './components/verifytofa/verifytofa.component';
import { ValidatorComponent } from './validator/validator.component';
import { VerifyAcidComponent } from './components/verify-acid/verify-acid.component';
import { VerifyAcidWidhIdComponent } from './components/verify-acid-widh-id/verify-acid-widh-id.component';
import { UserListForDoubleAuthComponent } from './components/user-list-for-double-auth/user-list-for-double-auth.component';
import { NavbargComponent } from './components/navbarg/navbarg.component';
import { AnalyseComponent } from './components/analyse/analyse.component';
import { DownloadComponent } from './components/download/download.component';
import { AdvantagesComponent } from './components/advantages/advantages.component';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { SignatureComponent } from './components/signature/signature.component';
import { DetailleComponent } from './detaille/detaille.component';


export function HttpLoaderFactory(http:HttpClient){
  return new TranslateHttpLoader(http);
}



@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    SpinnerComponent,
    LoginComponent,
    ValidComponent,
    InvalidComponent,
    DashboardComponent,
    UnauthorizedComponent,
    ProfileComponent,
    AuthNavbarComponent,
    PagesDropdownComponent,
    FooterComponent,
    CardTableComponent,
    HomepageComponent,
    AddCompanyComponent,
    

  
    AddMemberComponent,
       DisplayMembersComponent,
       ResetPasswordComponent,
       ManagerDashboardComponent,
       AddCompanyformComponent,
       DisplayAttachmentsComponent,
       DisplayAcidfoldersComponent,
       AddAcidfolderComponent,
       DialogComponent,
       DisplayAcidrequestsComponent,
       AdminAcidRequestsComponent,
       AdminCompaniesComponent,
       ExporterCompanyuploadsComponent,
       UpdateExporterComponent,
       AdminCompaniesExportersComponent,
       AcidAttachmentsComponent,
       ModeComponent,
       AddAccountComponent,
       AddListComponent,
       ExporterBlackListComponent,
       ExportersComponent,
       MinistriesComponent,
       TestComponent,
       CompanyListComponent,
       PdfCompanyComponent,
       VerifytofaComponent,
       ValidatorComponent,
       VerifyAcidComponent,
       VerifyAcidWidhIdComponent,
       UserListForDoubleAuthComponent,
       NavbargComponent,
       AnalyseComponent,
       DownloadComponent,
       AdvantagesComponent,
       InvoicesComponent,
       SignatureComponent,
       DetailleComponent,
       
  ],
  imports: [
    BrowserModule,
  
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
   BrowserAnimationsModule,
   Ng2SearchPipeModule,
   FormsModule,
   Ng2SearchPipeModule,
   
   ToastrModule.forRoot({
    // Toast options
    timeOut: 3000,
    positionClass: 'toast-top-right',
    preventDuplicates: true,
  }),
  NgxPaginationModule,
  RecaptchaModule,
  RecaptchaFormsModule,
  MatDialogModule,
  TranslateModule.forRoot(
    {
    loader:{
      provide:TranslateLoader,
      useFactory:HttpLoaderFactory,
      deps:[HttpClient]
    }
  }
  )
    
  ],
  providers: [HttpClient,
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    },
   
    DataService
     
    ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class AppModule { }
