import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Company } from 'src/app/models/Company';
import { User } from 'src/app/models/User';
import { AttachmentService } from 'src/app/services/attachment.service';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-exporter-companyuploads',
  templateUrl: './exporter-companyuploads.component.html',
  styleUrls: ['./exporter-companyuploads.component.css']
})
export class ExporterCompanyuploadsComponent implements OnInit {
  
  additionalFiles?: FileList[] ;
  uploadedFiles: { [key: string]: any } = {};
  currentEporter:Company;
  userId:any;

  constructor(private route: ActivatedRoute,public notificationService:NotificationService,
    public companyService:CompanyService,public authService:AuthService ,public userService:UserService,
    public attachmentService:AttachmentService ) { 
       this.userId = authService.currentUserValue.user_id
      this.companyService.getCompanyByExporterLogin(this.userId).subscribe(  (data:  Company) => {
        this.currentEporter=data
      });

    this.uploadedFiles = {
      'Commercial register': null,
      'Tax ID': null,
      'CIN Manager': null,
      'Status': null,
      
    };
  }

  ngOnInit(): void {
   
  }
 

  createFileList(files: File[]): FileList {
    const dataTransfer = new DataTransfer();
    for (const file of files) {
      dataTransfer.items.add(file);
    }
    return dataTransfer.files;
  }

  onFileChange(event: any,fileType:string): void {
    const inputElement: HTMLInputElement = event.target;
    if (inputElement.files) {
      for (let i = 0; i < inputElement.files.length; i++) {
        
        const file: File = inputElement.files[i];
      
        this.uploadedFiles[fileType] = file;
        console.log('File name:', file.name);
        console.log('File size:', file.size);
        // You can perform further actions with the file (e.g., upload, process, etc.)
      }
    }

    console.log(this.uploadedFiles)
  }

  upload(): void {
    let message:any;
    const keysArray = Object.keys(this.uploadedFiles);

    if (this.uploadedFiles) {
      for(let key of keysArray){

      let file = this.uploadedFiles[key];
      console.log(key)

      if (file) {
        
        //this.companyAddedID=34
        this.attachmentService.uploadFile(this.userId,this.currentEporter.company_id, key ,file).subscribe({
          next: (event: any) => {
            console.log("Upload : "+event)
            if (event.type === HttpEventType.UploadProgress) {
             
            } else if (event instanceof HttpResponse) {
              message = event.body.message;
              //this.fileInfos = this.uploadService.getFiles();
            }

            window.location.href ='display-attachments/'+this.currentEporter?.company_id
            
           
          },
          error: (err: any) => {
            console.log(err);
           

            

            if (err.error && err.error.message) {
              message = err.error.message;
            } else {
             message = 'Could not upload the file!';
            }

            this.notificationService.showError('',message)
            this.notificationService.showError('',err.responseMessage)
          }
        });
      }
     
    }}}
    

}
