import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AttachmentService } from 'src/app/services/attachment.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Attachment } from 'src/app/models/Attachment';
import { NotificationService } from 'src/app/services/notification.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-display-attachments',
  templateUrl: './display-attachments.component.html',
  styleUrls: ['./display-attachments.component.css']
})
export class DisplayAttachmentsComponent implements OnInit {

  companyId:any;
  fileInfos?: Observable<any>;
  pdfSrcRegistrationNumber:any;
  pdfSrcNationalRegister:any;
  pdfSrcEvidencePermission:any;
  pdfSrcEvidencePower:any;
  attachments: Attachment[] = [];
  registrationNumber:any;
  nationalRegister:any;
  evidencePermission:any;
  evidencePower:any;
  userId:any;
  selectedFiles?: FileList;
  willBeReplaced=false

  /********************************** */
  pdfSrcs:any[] = [];
  fileElement:any;
  combinedData: { pdfsrc: any, att: Attachment }[] = [];
  uploadedFiles: { [key: string]: any } = {};

  constructor(private route: ActivatedRoute , public attachmentService:AttachmentService,
    public notificationService:NotificationService,private sanitizer: DomSanitizer,
    public authService:AuthService) {
      this.userId=authService.currentUserValue.user_id

      this.uploadedFiles = {
        'Commercial register': null,
        'Tax ID': null,
        'CIN Manager': null,
        'Status': null,
        
      };
   }

  ngOnInit(): void {

   

    this.route.paramMap.subscribe(params => {
      const companyIdParam = params.get('companyId');
      this.companyId = companyIdParam ? +companyIdParam : null;
      
      if(this.companyId != null){
        this.getAttachmentsByCompany()
      }
      
    });
  }

  getAttachmentsByCompany(): void {

    this.attachmentService.getAttachmentsByCompany(this.companyId)
    .subscribe(
      data => {
        this.attachments = data;
        this.loadFiles(this.attachments)
       
        //this.loadpdfs()
      },
      error => {
        console.log(error);
      });
  }
  

 

  delete(id:any){

    this.attachmentService.deleteAttachement(id).subscribe(
      (response: any) => {
        this.notificationService.showSuccess("Attachement Deleted","")
        window.location.href ='display-attachments/'+this.companyId
       
      },
      (error: any) => {
        console.error('Error loading PDF:', error);
      }
    );


  }


  replace(id:any){
    this.attachmentService.deleteAttachement(id).subscribe(
      (response: any) => {
      
        console.log("Done! , Attachement deleted")
        this.upload()
        window.location.href ='display-attachments/'+this.companyId
      },
      (error: any) => {
        this.notificationService.showError("Error loading PDF","")
        console.error('Error loading PDF:', error);
      }
    );
    
  }

  onChange(){
    this.willBeReplaced=true
  }
  
  onFileChange(event: any,fileType:any): void {
    const inputElement: HTMLInputElement = event.target;
    if (inputElement.files) {
      for (let i = 0; i < inputElement.files.length; i++) {
        
        const file: File = inputElement.files[i];
      
        this.uploadedFiles[fileType] = file;
  
      }
    }
  
   
  }
  
  loadFiles(files:any) {
    this.pdfSrcs = [];
    for(let file of files){
      

      this.attachmentService.loadFile(file.fileUrl).subscribe(
        (response: ArrayBuffer) => {
          const fileBlob = new Blob([response], { type: 'application/pdf' });
          const fileUrl = URL.createObjectURL(fileBlob);
          this.pdfSrcEvidencePower = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl) as SafeResourceUrl;
          let pdfSrc= this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl) as SafeResourceUrl;
          this.pdfSrcs.push(pdfSrc)
          this.combinedData.push({ pdfsrc: pdfSrc, att: file });
        },
        (error: any) => {
          console.error('Error loading PDF:', error);
        }
      );
      
    }
    
    
    
  }

  upload(): void {
    let message:any;
    const keysArray = Object.keys(this.uploadedFiles);

    if (this.uploadedFiles) {
      for(let key of keysArray){

      let file = this.uploadedFiles[key];
      console.log(key)

      if (file) {
        
        //this.companyAddedID=34
        this.attachmentService.uploadFile(this.userId,this.companyId, key ,file).subscribe({
          next: (event: any) => {
            console.log("Upload : "+event)
            if (event.type === HttpEventType.UploadProgress) {
             
            } else if (event instanceof HttpResponse) {
              message = event.body.message;
              //this.fileInfos = this.uploadService.getFiles();
            }

            window.location.href ='display-attachments/'+this.companyId
            
           
          },
          error: (err: any) => {
            console.log(err);
           

            

            if (err.error && err.error.message) {
              message = err.error.message;
            } else {
             message = 'Could not upload the file!';
            }

            this.notificationService.showError('',message)
            this.notificationService.showError('',err.responseMessage)
          }
        });
      }
     
    }}}



  /**************************************************************************************
   * 
   * 
   * 
   * 
   *  loadpdfs(){
    let fileNameRegisterNumber :any;
    let fileNameNationalRegister :any;
    let fileNameEvidencePermission :any;
    let fileNameEvidencePower :any;

    this.attachments.forEach(element => {
      if(element.fileData === "Commercial register"){
      
        fileNameRegisterNumber=element.fileUrl
        this.registrationNumber=element.id
      }
      if(element.fileData === "Tax ID"){
       
        fileNameNationalRegister=element.fileUrl
        this.nationalRegister=element.id
      }
      if(element.fileData === "CIN Manager"){
       
        fileNameEvidencePermission=element.fileUrl
        this.evidencePermission=element.id
      }
      if(element.fileData === "Status"){

        fileNameEvidencePower=element.fileUrl
        this.evidencePower=element.id
      }
      
    });


    this.loadFile(fileNameRegisterNumber);
    this.loadFileTaxID(fileNameNationalRegister);
    this.loadFileCin(fileNameEvidencePermission);
    this.loadFileStatus(fileNameEvidencePower);

  }


  loadFile(fileName:any) {
    
    this.attachmentService.loadFile(fileName).subscribe(
      (response: ArrayBuffer) => {
        const fileBlob = new Blob([response], { type: 'application/pdf' });
        const fileUrl = URL.createObjectURL(fileBlob);
        this.pdfSrcRegistrationNumber = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl) as SafeResourceUrl;
      },
      (error: any) => {
        console.error('Error loading PDF:', error);
      }
    );
  }
  loadFileTaxID(fileName:any) {
    
    this.attachmentService.loadFile(fileName).subscribe(
      (response: ArrayBuffer) => {
        const fileBlob = new Blob([response], { type: 'application/pdf' });
        const fileUrl = URL.createObjectURL(fileBlob);
        this.pdfSrcNationalRegister = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl) as SafeResourceUrl;
      },
      (error: any) => {
        console.error('Error loading PDF:', error);
      }
    );
  }
  loadFileCin(fileName:any) {
    
    this.attachmentService.loadFile(fileName).subscribe(
      (response: ArrayBuffer) => {
        const fileBlob = new Blob([response], { type: 'application/pdf' });
        const fileUrl = URL.createObjectURL(fileBlob);
        this.pdfSrcEvidencePermission = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl) as SafeResourceUrl;
      },
      (error: any) => {
        console.error('Error loading PDF:', error);
      }
    );
  }
  loadFileStatus(fileName:any) {
    
    this.attachmentService.loadFile(fileName).subscribe(
      (response: ArrayBuffer) => {
        const fileBlob = new Blob([response], { type: 'application/pdf' });
        const fileUrl = URL.createObjectURL(fileBlob);
        this.pdfSrcEvidencePower = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl) as SafeResourceUrl;
      },
      (error: any) => {
        console.error('Error loading PDF:', error);
      }
    );
  }
   */

}
