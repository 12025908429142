<app-auth-navbar></app-auth-navbar>
<main class="profile-page">
  <section class="relative block h-500-px">
    <div
      class="absolute top-0 w-full h-full bg-center bg-cover"
      style="background-image: url('assets/images/slider/comp.jpg')"
    >
      <span
        id="blackOverlay"
        class="w-full h-full absolute opacity-50 bg-black"
      ></span>
    </div>
  </section>
  <section class="relative py-16 bg-blueGray-800">
    <div class="container mx-auto px-4">
      <div
        class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64"
        style="opacity: 0.9"
      >
        <div
          class="w-full lg:w-6/12 px-6 py-6"
          style="height: 100%; width: 100%"
        >
          <!--------------------------------------------------------------------->

          <div class="px-6">
            <div class="text-center">
              <div class="flex flex-wrap justify-center">
                <div
                  class="w-full lg:w-9/12 px-4"
                  style="width: 100%; height: 100%"
                >
                  <div class="flex flex-wrap mt-6">
                    <div class="w-full mb-24 px-4">
                      <div class="rounded-t mb-0 px-4 border-0">
                        <div class="py-6 px-3 mt-32 sm:mt-0">
                          <form action="#" method="post">
                            <div class="flex flex-wrap">
                              <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                  <label
                                    class="block text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                  >
                                  {{ 'companyName' | translate }}
                                  </label>
                                  <p>{{ currentEporter?.raisonSocial }}</p>
                                </div>
                              </div>

                              <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                  <label
                                    class="block text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                  >
                                  {{ 'originCountry' | translate }}
                                  </label>
                                  <p>{{ currentEporter?.companyCountry }}</p>
                                </div>
                              </div>

                              <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                  <label
                                    class="block text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                  >
                                  {{ 'commercialRegister' | translate }}
                                  </label>
                                  <p>{{ currentEporter?.rne }}</p>
                                </div>
                              </div>

                              <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                  <label
                                    class="block text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                  >
                                  {{ 'status' | translate }}
                                  </label>

                                  <p>{{ currentEporter?.etat }}</p>
                                </div>
                              </div>

                              <div
                                class="w-full lg:w-12/12 px-4"
                                *ngIf="
                                  currentEporter?.verificationLinkOfCommercialReg
                                "
                              >
                                <div class="relative w-full mb-3">
                                  <label
                                    class="block text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                  >
                                  </label>

                                  <a
                                    href="{{
                                      currentEporter?.verificationLinkOfCommercialReg
                                    }}"
                                    style="color: blue"
                                  >
                                  {{ 'verificationLinkOfCommercialRegister' | translate }}</a>
                                </div>
                              </div>
                            </div>
                            <div
                              class="flex flex-wrap"
                              style="display: flex; justify-content: center"
                            >
                              <button
                                class="bg-black active:bg-blueGray-200 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                *ngIf="
                                  !(
                                    currentEporter?.etat == 'ACCEPTED' ||
                                    currentEporter?.etat == 'REJECTED'
                                  )
                                "
                                routerLink="/exporter/update/{{
                                  currentEporter?.company_id
                                }}"
                              >
                                <i class="fas fa-pencil-alt"></i>
                                {{ 'update' | translate }}
                              </button>

                              <button
                                class="bg-black active:bg-blueGray-200 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                *ngIf="
                                  (!currentEporter?.verificationLinkOfCommercialReg ||
                                    currentEporter?.verificationLinkOfCommercialReg ===
                                      '') &&
                                  !(
                                    currentEporter?.etat == 'ACCEPTED' ||
                                    currentEporter?.etat == 'REJECTED'
                                  )
                                "
                                routerLink="/display-attachments/{{
                                  currentEporter?.company_id
                                }}"
                              >
                                <i class="fas fa-paperclip"></i>
                                Attachments
                              </button>
                            </div>
                          </form>

                          <div
                            class="rounded-t mb-0 px-4 py-3 border-0"
                            *ngIf="
                              (!currentEporter?.verificationLinkOfCommercialReg ||
                                currentEporter?.verificationLinkOfCommercialReg ===
                                  '') &&
                              !(
                                currentEporter?.etat == 'ACCEPTED' ||
                                currentEporter?.etat == 'REJECTED'
                              )
                            "
                          >
                            <div class="flex flex-wrap items-center">
                              <div
                                class="relative w-full px-4 max-w-full flex-grow flex-1"
                              >
                                <h3
                                  class="px-6 align-middle border border-solid py-3 text-lg uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center titles"
                                >
                                {{ 'companyAttachments' | translate }}
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="flex flex-wrap">
                        <!----------------------------------------------------->
                        <!----------------------------------------------------->

                        <div
                          class="block w-full overflow-x-auto"
                          style="display: flex; justify-content: center"
                          *ngIf="
                            (!currentEporter?.verificationLinkOfCommercialReg ||
                              currentEporter?.verificationLinkOfCommercialReg ===
                                '') &&
                            !(
                              currentEporter?.etat == 'ACCEPTED' ||
                              currentEporter?.etat == 'REJECTED'
                            )
                          "
                        >
                          <!-- Projects table -->
                          <table
                            class="items-center w-full bg-transparent border-collapse w-full lg:w-6/12 px-4"
                          >
                            <tr>
                              <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                              >
                              {{ 'commercialRegister' | translate }}
                              </th>
                              <td
                                class="w-full lg:w-4/12 px-4 text-left align-middle border border-solid py-3 text-xs border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                              >
                                <div class="file-input-wrapper">
                                  <label
                                    for="file-input"
                                    class="custom-file-input"
                                    style="display: flex; justify-content: left"
                                    >{{ 'chooseAFile' | translate }}</label
                                  >
                                  <input
                                    class=""
                                    id="file-input"
                                    type="file"
                                    class="file-input"
                                    accept="application/pdf"
                                    (change)="
                                      onFileChange(
                                        $event,
                                        'Commercial register'
                                      )
                                    "
                                  />
                                  <span id="file-name" class="file-name">{{
                                    uploadedFiles["Commercial register"]?.name
                                  }}</span>
                                </div>
                              </td>

                              <td
                                class="w-full lg:w-3/12 px-4 text-left align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                *ngIf="
                                  uploadedFiles['Commercial register'] == null
                                "
                              >
                                <a>
                                  <img
                                    src="assets/images/icons/notice.png"
                                    alt="To upload"
                                    style="
                                      width: 30%;
                                      height: 30%;
                                      float: left;
                                    "
                                /></a>
                              </td>

                              <td
                                class="w-full lg:w-3/12 px-4 text-left align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                *ngIf="
                                  uploadedFiles['Commercial register'] != null
                                "
                              >
                                <a>
                                  <img
                                    src="assets/images/icons/checked.png"
                                    alt="checked"
                                    style="
                                      width: 30%;
                                      height: 30%;
                                      float: left;
                                    "
                                /></a>
                              </td>
                            </tr>

                            <tr>
                              <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                              >
                              {{ 'taxId' | translate }}
                              </th>
                              <td
                                class="px-6 align-middle border border-solid py-3 text-xs border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                              >
                                <div class="file-input-wrapper">
                                  <label
                                    for="file-input"
                                    class="custom-file-input"
                                    style="display: flex; justify-content: left"
                                    >{{ 'chooseAFile' | translate }}</label
                                  >
                                  <input
                                    class="text-left"
                                    id="file-input"
                                    type="file"
                                    class="file-input"
                                    accept="application/pdf"
                                    (change)="onFileChange($event, 'Tax ID')"
                                  />
                                  <span id="file-name" class="file-name">{{
                                    uploadedFiles["Tax ID"]?.name
                                  }}</span>
                                </div>
                              </td>

                              <td
                                class="w-full lg:w-3/12 px-4 text-left align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                *ngIf="uploadedFiles['Tax ID'] == null"
                              >
                                <a>
                                  <img
                                    src="assets/images/icons/notice.png"
                                    alt="To upload"
                                    style="
                                      width: 30%;
                                      height: 30%;
                                      float: left;
                                    "
                                /></a>
                              </td>

                              <td
                                class="w-full lg:w-3/12 px-4 text-left align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                *ngIf="uploadedFiles['Tax ID'] != null"
                              >
                                <a>
                                  <img
                                    src="assets/images/icons/checked.png"
                                    alt="checked"
                                    style="
                                      width: 30%;
                                      height: 30%;
                                      float: left;
                                    "
                                /></a>
                              </td>
                            </tr>

                            <tr>
                              <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                              >
                              {{ 'cinPassportManager' | translate }}
                              </th>
                              <td
                                class="px-6 align-middle border border-solid py-3 text-xs border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                              >
                                <div class="file-input-wrapper">
                                  <label
                                    for="file-input"
                                    class="custom-file-input"
                                    style="display: flex; justify-content: left"
                                    >{{ 'chooseAFile' | translate }}</label
                                  >
                                  <input
                                    class="text-left"
                                    id="file-input"
                                    type="file"
                                    class="file-input"
                                    accept="application/pdf"
                                    (change)="
                                      onFileChange($event, 'CIN Manager')
                                    "
                                  />

                                  <span id="file-name" class="file-name">{{
                                    uploadedFiles["CIN Manager"]?.name
                                  }}</span>
                                </div>
                              </td>

                              <td
                                class="w-full lg:w-3/12 px-4 text-left align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                *ngIf="uploadedFiles['CIN Manager'] == null"
                              >
                                <a>
                                  <img
                                    src="assets/images/icons/notice.png"
                                    alt="To upload"
                                    style="
                                      width: 30%;
                                      height: 30%;
                                      float: left;
                                    "
                                /></a>
                              </td>

                              <td
                                class="w-full lg:w-3/12 px-4 text-left align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                *ngIf="uploadedFiles['CIN Manager'] != null"
                              >
                                <a>
                                  <img
                                    src="assets/images/icons/checked.png"
                                    alt="checked"
                                    style="
                                      width: 30%;
                                      height: 30%;
                                      float: left;
                                    "
                                /></a>
                              </td>
                            </tr>

                            <tr>
                              <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                              >
                                {{ 'status' | translate }}
                              </th>
                              <td
                                class="px-6 align-middle border border-solid py-3 text-xs border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                              >
                                <div class="file-input-wrapper">
                                  <label
                                    for="file-input"
                                    class="custom-file-input"
                                    style="display: flex; justify-content: left"
                                    >{{ 'chooseAFile' | translate }}</label
                                  >
                                  <input
                                    class="text-left"
                                    id="file-input"
                                    type="file"
                                    class="file-input"
                                    accept="application/pdf"
                                    (change)="onFileChange($event, 'Status')"
                                  />

                                  <span id="file-name" class="file-name">{{
                                    uploadedFiles["Status"]?.name
                                  }}</span>
                                </div>
                              </td>

                              <td
                                class="w-full lg:w-3/12 px-4 text-left align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                *ngIf="uploadedFiles['Status'] == null"
                              >
                                <a>
                                  <img
                                    src="assets/images/icons/notice.png"
                                    alt="To upload"
                                    style="
                                      width: 30%;
                                      height: 30%;
                                      float: left;
                                    "
                                /></a>
                              </td>

                              <td
                                class="w-full lg:w-3/12 px-4 text-left align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                *ngIf="uploadedFiles['Status'] != null"
                              >
                                <a>
                                  <img
                                    src="assets/images/icons/checked.png"
                                    alt="checked"
                                    style="
                                      width: 30%;
                                      height: 30%;
                                      float: left;
                                    "
                                /></a>
                              </td>
                            </tr>

                            <br />
                          </table>

                          <!---------------------------------------->
                        </div>

                        <div
                          class="w-full lg:w-12/12 px-4 py-3"
                          style="display: flex; justify-content: center"
                          *ngIf="
                            (!currentEporter?.verificationLinkOfCommercialReg ||
                              currentEporter?.verificationLinkOfCommercialReg ===
                                '') &&
                            !(
                              currentEporter?.etat == 'ACCEPTED' ||
                              currentEporter?.etat == 'REJECTED'
                            )
                          "
                        >
                          <input
                            class="bg-blueGray-600 active:bg-blueGray-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                            type="reset"
                            value="Cancel"
                          />
                          <button
                            class="bg-black active:bg-blueGray-200 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            (click)="upload()"
                          >
                          {{ 'upload' | translate }}
                          </button>
                        </div>

                        <!------------------------------------------------------->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!---------------------------------------------------------------------->
        </div>
      </div>
    </div>
  </section>
</main>
<app-footer></app-footer>
