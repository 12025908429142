<div class="aci-container">
    <div class="aci-header">
        <span class="aci-icon">📂</span>
        <span>Advance Information System</span>
    </div>

    <h1 class="aci-main-title">Digital Signature</h1>

    <div class="aci-content">
        <h2 class="aci-subtitle">What is e-Token?</h2>
        <p class="aci-text">An electronic method that allows documents to be signed...</p>
        <p class="aci-text">e-Token is used within automation systems...</p>
        <p class="aci-text">e-Token is used in online financial, commercial, and administrative transactions...</p>

        <h2 class="aci-subtitle">What are the advantages of e-Token?</h2>
        <ul class="aci-list">
            <li>Establishing the identity of the signatory with undeniable accuracy</li>
            <li>Securing data within the documents...</li>
            <li>Preventing the unauthorized handling of electronically signed documents.</li>
        </ul>
    </div>

    <div class="aci-image">
        <img src="../../assets/signature.png" alt="Digital Signature">
    </div>

    <div class="aci-sidebar">
        <h3 class="aci-sidebar-title">Related Pages</h3>
        <ul class="aci-links">
            <li *ngFor="let page of relatedPages">
                <a [href]="page.link" class="aci-link">{{ page.title }}</a>
            </li>
        </ul>
    </div>
</div>
