import { Component, OnInit , HostListener} from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { User } from '../models/User';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../services/notification.service';
import { SignupRequest } from '../models/SignupRequest';
import { ReCaptchaV3Service } from 'ngx-captcha';
import {  countries } from 'countries-list';
import { TranslateService } from '@ngx-translate/core';
import { interval } from 'rxjs';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css',

 ]
})
export class SignupComponent implements OnInit {

  angForm: FormGroup | any;
  isSuccessful = false;
  isSignUpFailed = false;
  errorMessage = '';
  token: string|undefined;
  isScrolled = false;
  currentDateTime: Date = new Date();
  countryList: any[] = [];
  isMenuOpen: boolean = false;
  lang: string = 'en';
  constructor(private translateService:TranslateService, public authService: AuthService,public notification: NotificationService,
  
    public router: Router,private fb: FormBuilder) {

      Object.values(countries).forEach((country: any) => {
        this.countryList.push( country.name);
        this.countryList=this.countryList.sort()
      });
      
      this.token = undefined;
      this.angForm = this.fb.group({
        firstName: ['', [Validators.required ,Validators.minLength(3),Validators.maxLength(20),Validators.pattern('^[a-zA-Z ]*$')]],
        lastName: ['', [Validators.required, Validators.minLength(3),Validators.maxLength(20),Validators.pattern('^[a-zA-Z ]*$')]],
        birthday: ['', [Validators.required] ],
        address: ['', [Validators.required, Validators.minLength(3),Validators.maxLength(20)]],
        country: ['', [Validators.required] ],
        email: ['', [Validators.required ,  Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
        password: ['', [Validators.required, Validators.minLength(6)] ],
        phone: ['', [Validators.required, Validators.minLength(5)] ],
        username: ['', [Validators.required, Validators.minLength(3),Validators.maxLength(20)]],
        gender: ['', [Validators.required] ],
        recaptcha: new FormControl('', Validators.required),
         
      });
     }

     toggleEye: boolean = true;
  
  toggleEyeIcon(inputPassword:any) {
		this.toggleEye = !this.toggleEye;
		
		inputPassword.type = inputPassword.type === 'password' ? 'text' : 'password';

		
	}
  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.isScrolled = window.scrollY > 50;
  }
  ngOnInit(): void {
    interval(1000).subscribe(() => {
      this.currentDateTime = new Date();
    });
    

  }
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }
  onSubmit(): void {
    let signupRequest:SignupRequest=new SignupRequest;


    /************************************************ */
    signupRequest.address=this.angForm.get(["address"]).value;
    signupRequest.country=this.angForm.get(["country"]).value;
    signupRequest.firstName=this.angForm.get(["firstName"]).value;
    signupRequest.lastName=this.angForm.get(["lastName"]).value;
    signupRequest.email=this.angForm.get(["email"]).value;
    signupRequest.phoneNumber=this.angForm.get(["phone"]).value;
    signupRequest.password=this.angForm.get(["password"]).value;
    signupRequest.username=this.angForm.get(["username"]).value;
    signupRequest.gender=this.angForm.get(["gender"]).value;
    signupRequest.dateOfBirth=this.angForm.get(["birthday"]).value;
    


    /************************************* */
      console.log(signupRequest)

    /************************************** */
    this.authService
      .register(signupRequest)
      .subscribe({
        next: (data) => {
          console.log("data signup"+data);
          this.notification.showSuccess(
            'You have successfully registered you should verify your email to activate your account',
            ''
          );
          this.router.navigate(['/login']);
        },
        error: (err) => {
          
            this.notification.showError(err.error.message, '');
            //this.router.navigate(['/signup']);
            console.log(err);
            this.errorMessage = err.error.message;
            this.isSignUpFailed = true;
            console.log(this.isSignUpFailed);
          
          },
        
       
      });

      console.debug(`Token [${this.token}] generated`);

      
  }

   
  ChangeLang(lang:any){
    const selectedLanguage = lang.target.value;

    localStorage.setItem('lang',selectedLanguage);

    this.translateService.use(selectedLanguage);

  }  

}
