import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detaille',
  templateUrl: './detaille.component.html',
  styleUrls: ['./detaille.component.css']
})
export class DetailleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
