import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {  countries } from 'countries-list';
import { Company } from 'src/app/models/Company';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { NotificationService } from 'src/app/services/notification.service';
@Component({
  selector: 'app-update-exporter',
  templateUrl: './update-exporter.component.html',
  styleUrls: ['./update-exporter.component.css']
})
export class UpdateExporterComponent implements OnInit {
  exporterForm: FormGroup | any;
  countryList: any[] = [];
  companyId:any
  currentExporter:Company
  constructor(private route: ActivatedRoute,private fb: FormBuilder,public authService: AuthService,
    public companyService:CompanyService,public notificationService:NotificationService,) { 

    Object.values(countries).forEach((country: any) => {
      this.countryList.push( country.name);
      this.countryList=this.countryList.sort()
    });

    this.exporterForm = this.fb.group({

      /****************     FOREIGN EXPORTER DATA    ******************* */
      regCode: ['', [Validators.required] ],
     
      email: ['', [Validators.required ,  Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
          /****** Search  ***/
     exporterName: ['', [Validators.required ,Validators.minLength(3),Validators.maxLength(20)]],
     exporterCountry: ['', [Validators.required]],
     exporterAddress: ['', [Validators.required ,Validators.minLength(3)]],
     exporterPhone: ['', [Validators.required ,Validators.minLength(3),Validators.maxLength(20)]],
     exporterfax: [''],
     exporterwebSite: ['', [Validators.required ,Validators.minLength(3),Validators.maxLength(20)]],

     exporterPersonalName: ['', [Validators.required ,Validators.minLength(3),Validators.maxLength(20)]],
     exporterPersonalCIN: [''],
     exporterPersonalPhone: ['', [Validators.required ,Validators.minLength(3),Validators.maxLength(20)]],
     exporterPersonaEmail: ['', [  Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
     verificationLinkOfCommercialReg:  [''],
    })
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const idParam = params.get('id');
      this.companyId = idParam ? +idParam : null;
     
      if(this.companyId != null){
        this.companyService.getCompanyById(this.companyId)
    .subscribe(
      data => {
       
        this.currentExporter = data;
        console.log(data)
        this.details()
      },
      error => {
        console.log(error);
      });
       
        
      }
  })

}
  update(){
    this.currentExporter.rne= this.exporterForm.get(['regCode']).value
    this.currentExporter.companyEmail=this.exporterForm.get(['email']).value
    this.currentExporter.raisonSocial=this.exporterForm.get(['exporterName']).value
    this.currentExporter.companyCountry=this.exporterForm.get(['exporterCountry']).value
    this.currentExporter.companyAdress=this.exporterForm.get(['exporterAddress']).value
    this.currentExporter.companyPhone=this.exporterForm.get(['exporterPhone']).value
    this.currentExporter.fax=this.exporterForm.get(['exporterfax']).value
    this.currentExporter.webSite=this.exporterForm.get(['exporterwebSite']).value
     this.currentExporter.verificationLinkOfCommercialReg=this.exporterForm.get(['verificationLinkOfCommercialReg']).value
    let personal:User=new User
    personal.firstName=this.exporterForm.get(["exporterPersonalName"]).value
    personal.nationalID=this.exporterForm.get(["exporterPersonalCIN"]).value
    personal.phoneNumber=this.exporterForm.get(["exporterPersonalPhone"]).value
    personal.email=this.exporterForm.get(["exporterPersonaEmail"]).value
    this.currentExporter.manager=personal
    this.companyService.updateCompany(this.companyId,this.currentExporter)
    .subscribe(
      data => {
       
        this.currentExporter = data;
        this.details()
        window.location.href ='exporter/company'
      },
      error => {
        console.log(error);
      });



  }


  details(){

/********************EXPORTER********************** */
this.exporterForm.controls['regCode'].setValue(this.currentExporter?.rne);
this.exporterForm.controls['email'].setValue(this.currentExporter?.companyEmail);
this.exporterForm.controls['exporterName'].setValue(this.currentExporter?.raisonSocial);
this.exporterForm.controls['exporterCountry'].setValue(this.currentExporter?.companyCountry);
this.exporterForm.controls['exporterAddress'].setValue(this.currentExporter?.companyAdress);
this.exporterForm.controls['exporterPhone'].setValue(this.currentExporter?.companyPhone);
this.exporterForm.controls['exporterfax'].setValue(this.currentExporter?.fax); 
this.exporterForm.controls['exporterwebSite'].setValue(this.currentExporter?.webSite);
this.exporterForm.controls['exporterPersonalName'].setValue(this.currentExporter?.manager?.firstName);
this.exporterForm.controls['exporterPersonalCIN'].setValue(this.currentExporter?.manager?.nationalID);  
this.exporterForm.controls['exporterPersonalPhone'].setValue(this.currentExporter?.manager?.phoneNumber);
this.exporterForm.controls['exporterPersonaEmail'].setValue(this.currentExporter?.manager?.email);
this.exporterForm.controls['verificationLinkOfCommercialReg'].setValue(this.currentExporter?.verificationLinkOfCommercialReg);
  }

}
