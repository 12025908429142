<app-auth-navbar></app-auth-navbar>
<main class="profile-page">
  <section class="relative block h-500-px">
    <div
      class="absolute top-0 w-full h-full bg-center bg-cover"
      style="background-image: url('assets/images/slider/comp.jpg')"
    >
      <span
        id="blackOverlay"
        class="w-full h-full absolute opacity-50 bg-black"
      ></span>
    </div>
  </section>
  <section class="relative py-16 bg-blueGray-800">
    <div class="container mx-auto px-4">
      <div
        class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-xl rounded-lg -mt-64"
        style="opacity: 0.9; background-color: rgb(241, 242, 248)"
      >
        <div
          class="w-full lg:w-6/12 px-6 py-6"
          style="height: 100%; width: 100%"
        >
          <!--------------------------------------------------------------------->

          <div class="px-6">
            <div class="text-center">
              <div class="flex flex-wrap justify-center">
                <div
                  class="w-full lg:w-9/12 px-4"
                  style="width: 100%; height: 100%"
                >
                  <div class="flex flex-wrap mt-6">
                    <div class="w-full mb-24 px-4" *ngIf="step == 0">
                      <form>
                        <!------------------------------------------------------------------>

                        <!-- fieldsets -->

                        <h2
                          class="block text-blueGray-600 text-lg font-bold mb-2 py-2"
                        >
                          {{ "company" | translate }}
                        </h2>

                        <section class="block relative z-1">
                          <div class="justify-center flex flex-wrap">
                            <div class="w-full lg:w-12/12 px-4">
                              <div class="flex flex-wrap">
                                <div
                                  class="w-full lg:w-4/12 px-4"
                                  *ngFor="let company of companies"
                                >
                                  <div
                                    class="hover: relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150"
                                    style="
                                      border: 2px solid rgb(194, 188, 188); /* Définit une bordure de 2 pixels avec une couleur noire */
                                      padding: 10px;
                                    "
                                  >
                                    <a
                                      (click)="typeSelected('{{company}}')"
                                      class="relative flex flex-col"
                                    >
                                      <div
                                        class="justify-center flex flex-wrap"
                                      >
                                        <img
                                          alt="..."
                                          style="width: 50%; height: 50%"
                                          src="assets/images/icons/company.png"
                                        />
                                      </div>
                                    </a>

                                    <div
                                      style="
                                        display: flex;
                                        justify-content: center;
                                      "
                                    >
                                      <h2
                                        class="block text-blueGray-600 text-xs font-bold mb-2"
                                      >
                                        {{ company.raisonSocial }}
                                      </h2>
                                    </div>
                                    <div
                                      style="
                                        display: flex;
                                        justify-content: center;
                                      "
                                    >
                                      <h2
                                        class="block text-blueGray-600 text-xs mb-2"
                                      >
                                      {{ 'rne' | translate }}: {{ company.rne }}
                                      </h2>
                                    </div>

                                    <div
                                      style="
                                        display: flex;
                                        justify-content: center;
                                      "
                                    >
                                      <input
                                        (click)="typeSelected(company)"
                                        type="button"
                                        class="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                        value="Acid request"
                                      />
                                    </div>

                                    <br />
                                    <br />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </form>
                    </div>

                    <!------------------------------------------------------>

                    <!---------------------------------------------------------------- form --------------------------------------------------------------------------------------------------------->

                    <div class="w-full mb-24 px-4" *ngIf="step == 1">
                      <div class="luxury-content">
                      <!-- Stepper Header -->
    <div class="luxury-header">
      <div
        class="luxury-step"
        *ngFor="let step of steps; let i = index"
        [class.active]="i === currentStep"
        [class.completed]="i < currentStep"
        (click)="goToStep(i)"
      >
        <div class="luxury-circle">
          <span *ngIf="i >= currentStep">{{ i + 1 }}</span>
          <mat-icon *ngIf="i < currentStep">check</mat-icon>
        </div>
        <div class="luxury-label">
          <span>{{ step }}</span>
        </div>
      </div>
      <div class="luxury-line"></div>
    </div>


                      <div class="flex-auto px-4 lg:px-10 py-10 pt-0 text-left">
                        <form
                          action="#"
                          method="post"
                          [formGroup]="acidForm"
                          novalidate
                        >
                        <ng-container *ngIf="currentStep === 0">              
                          <h6
                            class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase titles"
                          >
                            <i class="fas fa-building"></i> {{ 'import_data' | translate }}
                          </h6>

                          <div class="flex flex-wrap">
                            <!------------------------------------------        companyName      ------------------------------------------------------->
                            <div class="w-full lg:w-6/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'company_name' | translate }}
                                </label>
                                <input
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  placeholder="Company Name"
                                  id="companyName"
                                  formControlName="companyName"
                                  required="true"
                                />
                              </div>

                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    acidForm.controls['companyName'].invalid &&
                                    (acidForm.controls['companyName'].dirty ||
                                      acidForm.controls['companyName'].touched)
                                  "
                                >
                                  <div
                                    *ngIf="
                                      acidForm.controls['companyName'].errors
                                        .required
                                    "
                                  >
                                  {{ 'company_name_required' | translate }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!------------------------------------------------------------------------------------------------->

                            <!------------------------------------------        rne      ------------------------------------------------------->

                            <div class="w-full lg:w-6/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'commercial_registre' | translate }}
                                </label>
                                <input
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  placeholder="RNE"
                                  id="rne"
                                  formControlName="rne"
                                  required="true"
                                />
                              </div>

                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    acidForm.controls['rne'].invalid &&
                                    (acidForm.controls['rne'].dirty ||
                                      acidForm.controls['rne'].touched)
                                  "
                                  class="text-danger-filed fs-13 mt-1"
                                >
                                  <div
                                    *ngIf="
                                      acidForm.controls['rne'].errors.required
                                    "
                                  >
                                  {{ 'commercial_registre_required' | translate }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="w-full lg:w-12/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'address' | translate }}
                                </label>
                                <input
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  placeholder="Enter Full Address"
                                  id="address"
                                  formControlName="address"
                                />
                              </div>

                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    acidForm.controls['address'].invalid &&
                                    (acidForm.controls['address'].dirty ||
                                      acidForm.controls['address'].touched)
                                  "
                                  class="text-danger-filed fs-13 mt-1"
                                >
                                  <div
                                    *ngIf="
                                      acidForm.controls['address'].errors
                                        .required
                                    "
                                  >
                                  {{ 'address_required' | translate }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-----------------------------------   Country-------------------------------------------------------------->
                            <div class="w-full lg:w-6/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'country' | translate }}
                                </label>
                                <select
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  id="country"
                                  formControlName="country"
                                >
                                  <option selected value="" disabled>
                                    Country
                                  </option>
                                  <option
                                    *ngFor="let country of countryList"
                                    value="{{ country }}"
                                  >
                                    {{ country }}
                                  </option>
                                </select>
                              </div>

                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    acidForm.controls['country'].invalid &&
                                    (acidForm.controls['country'].dirty ||
                                      acidForm.controls['country'].touched)
                                  "
                                >
                                  <div
                                    *ngIf="
                                      acidForm.controls['country'].errors
                                        .required
                                    "
                                    class="text-danger-filed"
                                  >
                                  {{ 'country_required' | translate }}

                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-----------------------------------   Phone -------------------------------------------------------------->
                            <div class="w-full lg:w-6/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'phone_number' | translate }}
                                </label>
                                <input
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  placeholder="Phone number"
                                  id="phone"
                                  formControlName="phone"
                                  required="true"
                                />
                              </div>

                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    acidForm.controls['phone'].invalid &&
                                    (acidForm.controls['phone'].dirty ||
                                      acidForm.controls['phone'].touched)
                                  "
                                  class="text-danger-filed fs-13 mt-1"
                                >
                                  <div
                                    *ngIf="
                                      acidForm.controls['phone'].errors.required
                                    "
                                  >
                                  {{ 'phone_required' | translate }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!------------------------------------------------------------------------------------------------->

                            <!-----------------------------------   Web site -------------------------------------------------------------->
                            <div class="w-full lg:w-6/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'website' | translate }}
                                </label>
                                <input
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  placeholder="Web site"
                                  id="webSite"
                                  formControlName="webSite"
                                  required="true"
                                />
                              </div>
                            </div>

                            <!------------------------------------------------------------------------------------------------->

                            <!-----------------------------------   fax -------------------------------------------------------------->
                            <div class="w-full lg:w-6/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'fax' | translate }}
                                </label>
                                <input
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  placeholder="Fax"
                                  id="fax"
                                  formControlName="fax"
                                  required="true"
                                />
                              </div>
                            </div>

                            <!------------------------------------------------------------------------------------------------->

                            <!------------------------------------------------------------------------------------------------->
                          </div>
                          <input
                          style="display: none"
                          type="file"
                          #fileInput
                          (change)="onFileChange($event)"
                          name="uploadFile"
                          multiple="multiple"
                          #hiddenfileinput
                        />
                        <button
                          type="button"
                          class="luxury-btn"
                          (click)="hiddenfileinput.click()"
                        >
                          <label class="labupload"> Upload </label>
                        </button>
                        
                          <button class="luxury-btn" (click)="nextStep()">Next</button>
                        
                        </ng-container>

                        

                          <ng-container *ngIf="currentStep === 1">
                          <h6
                            class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase titles"
                          >
                            <i class="fas fa-shopping-cart"></i> {{ 'foreign' | translate }}
                            {{ 'exporter_data' | translate }}
                          </h6>

                          <div class="flex flex-wrap">
                            <!---------------------------------------     regCode ---------------------------------------------------------->

                            <div class="w-full lg:w-4/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'commercial_registre' | translate }}
                                </label>
                                <input
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  placeholder="Code"
                                  id="regCode"
                                  formControlName="regCode"
                                />
                              </div>

                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    acidForm.controls['regCode'].invalid &&
                                    (acidForm.controls['regCode'].dirty ||
                                      acidForm.controls['regCode'].touched)
                                  "
                                  class="text-danger-filed fs-13 mt-1"
                                >
                                  <div
                                    *ngIf="
                                      acidForm.controls['regCode'].errors
                                        .required
                                    "
                                  >
                                  {{ 'commercial_registre_required' | translate }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!---------------------------------------     Email ---------------------------------------------------------->

                            <div class="w-full lg:w-4/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'email' | translate }}
                                </label>
                                <input
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  placeholder="Email Address"
                                  id="email"
                                  formControlName="email"
                                />
                              </div>

                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    acidForm.controls['email'].invalid &&
                                    (acidForm.controls['email'].dirty ||
                                      acidForm.controls['email'].touched)
                                  "
                                  class="text-danger-filed fs-13 mt-1"
                                >
                                  <div
                                    *ngIf="
                                      acidForm.controls['email'].errors.required
                                    "
                                  >
                                  {{ 'email_required' | translate }}
                                  </div>
                                  <div
                                    *ngIf="
                                      acidForm.controls['email'].errors.pattern
                                    "
                                  >
                                  {{ 'email_valid' | translate }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-----------------------------------    -------------------------------------------------------------->

                            <div class="w-full lg:w-4/12 px-4">
                              <div class="relative w-full mb-3">
                                <br />

                                <button
                                  class="bg-blueGray-800 text-white active:bg-blueGray-700 font-bold text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                  type="button"
                                  (click)="search()"
                                  *ngIf="willBeConsulted == false"
                                  style="padding: 13px"
                                >
                                {{ 'search' | translate }}
                                </button>
                              </div>
                            </div>

                            <!------------------------------------------        exporterName      ------------------------------------------------------->

                            <div class="w-full lg:w-6/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'exporter_company_name' | translate }}
                                </label>
                                <input
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  placeholder=" Exporter Company Name"
                                  id="exporterName"
                                  formControlName="exporterName"
                                  required="true"
                                />
                              </div>

                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    acidForm.controls['exporterName'].invalid &&
                                    (acidForm.controls['exporterName'].dirty ||
                                      acidForm.controls['exporterName'].touched)
                                  "
                                  class="text-danger-filed fs-13 mt-1"
                                >
                                  <div
                                    *ngIf="
                                      acidForm.controls['exporterName'].errors
                                        .required
                                    "
                                  >
                                  {{ 'exporter_company_name_required' | translate }}
                                  </div>
                                  <div
                                    *ngIf="
                                      acidForm.controls['exporterName'].errors
                                        .minlength
                                    "
                                  >
                                  {{ 'exporter_company_name_min_length' | translate }}
                                  </div>
                                  <div
                                    *ngIf="
                                      acidForm.controls['exporterName'].errors
                                        .maxlength
                                    "
                                  >
                                  {{ 'exporter_company_name_max_length' | translate }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-----------------------------------  Exporter Country-------------------------------------------------------------->
                            <div class="w-full lg:w-6/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'exporter_country' | translate }}
                                </label>
                                <select
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  id="exporterCountry"
                                  formControlName="exporterCountry"
                                >
                                  <option selected value="" disabled>
                                    Exporter Country
                                  </option>
                                  <option
                                    *ngFor="let country of countryList"
                                    value="{{ country }}"
                                  >
                                    {{ country }}
                                  </option>
                                </select>
                              </div>

                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    acidForm.controls['exporterCountry']
                                      .invalid &&
                                    (acidForm.controls['exporterCountry']
                                      .dirty ||
                                      acidForm.controls['exporterCountry']
                                        .touched)
                                  "
                                >
                                  <div
                                    *ngIf="
                                      acidForm.controls['exporterCountry']
                                        .errors.required
                                    "
                                    class="text-danger-filed"
                                  >
                                    Exporter Country is required.
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!---------------------------------------     Exporter Address ---------------------------------------------------------->

                            <div class="w-full lg:w-12/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'exporter_address' | translate }}
                                </label>
                                <input
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  placeholder="Enter Full Address"
                                  id="exporterAddress"
                                  formControlName="exporterAddress"
                                />
                              </div>

                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    acidForm.controls['exporterAddress']
                                      .invalid &&
                                    (acidForm.controls['exporterAddress']
                                      .dirty ||
                                      acidForm.controls['exporterAddress']
                                        .touched)
                                  "
                                  class="text-danger-filed fs-13 mt-1"
                                >
                                  <div
                                    *ngIf="
                                      acidForm.controls['exporterAddress']
                                        .errors.required
                                    "
                                  >
                                  {{ 'exporter_address_required' | translate }}
                                  </div>
                                  <div
                                    *ngIf="
                                      acidForm.controls['exporterAddress']
                                        .errors.minlength
                                    "
                                  >
                                  {{ 'exporter_address_min_length' | translate }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!------------------------------------------        exporterPhone      ------------------------------------------------------->

                            <div class="w-full lg:w-4/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'exporter_phone' | translate }}
                                </label>
                                <input
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  placeholder=" Exporter Phone"
                                  id="exporterPhone"
                                  formControlName="exporterPhone"
                                  required="true"
                                />
                              </div>

                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    acidForm.controls['exporterPhone']
                                      .invalid &&
                                    (acidForm.controls['exporterPhone'].dirty ||
                                      acidForm.controls['exporterPhone']
                                        .touched)
                                  "
                                  class="text-danger-filed fs-13 mt-1"
                                >
                                  <div
                                    *ngIf="
                                      acidForm.controls['exporterPhone'].errors
                                        .required
                                    "
                                  >
                                  {{ 'exporter_phone_required' | translate }}
                                  </div>
                                  <div
                                    *ngIf="
                                      acidForm.controls['exporterPhone'].errors
                                        .minlength
                                    "
                                  >
                                  {{ 'exporter_phone_min_length' | translate }}
                                  </div>
                                  <div
                                    *ngIf="
                                      acidForm.controls['exporterPhone'].errors
                                        .maxlength
                                    "
                                  >
                                  {{ 'exporter_phone_max_length' | translate }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!------------------------------------------        exporterfax      ------------------------------------------------------->

                            <div class="w-full lg:w-4/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'exporter_fax' | translate }}
                                </label>
                                <input
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  placeholder=" Exporter Fax"
                                  id="exporterfax"
                                  formControlName="exporterfax"
                                  required="true"
                                />
                              </div>
                            </div>
                            <!------------------------------------------        exporterwebSite      ------------------------------------------------------->

                            <div class="w-full lg:w-4/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'exporter_web_site' | translate }}
                                </label>
                                <input
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  placeholder=" Exporter Company Name"
                                  id="exporterwebSite"
                                  formControlName="exporterwebSite"
                                  required="true"
                                />
                              </div>

                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    acidForm.controls['exporterwebSite']
                                      .invalid &&
                                    (acidForm.controls['exporterwebSite']
                                      .dirty ||
                                      acidForm.controls['exporterwebSite']
                                        .touched)
                                  "
                                  class="text-danger-filed fs-13 mt-1"
                                >
                                  <div
                                    *ngIf="
                                      acidForm.controls['exporterwebSite']
                                        .errors.required
                                    "
                                  >
                                    Exporter Web Site is required.
                                  </div>
                                  <div
                                    *ngIf="
                                      acidForm.controls['exporterwebSite']
                                        .errors.minlength
                                    "
                                  >
                                  {{ 'exporter_web_site_required' | translate }}
                                  </div>
                                  <div
                                    *ngIf="
                                      acidForm.controls['exporterwebSite']
                                        .errors.maxlength
                                    "
                                  >
                                  {{ 'exporter_web_site_max_length' | translate }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!---------------------------------------     Exporter Address ---------------------------------------------------------->

                            <div class="w-full lg:w-12/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'verification_link_commercial_registre' | translate }}
                                </label>
                                <input
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  placeholder="Enter Full Address"
                                  id="verificationLinkOfCommercialReg"
                                  formControlName="verificationLinkOfCommercialReg"
                                />
                              </div>
                            </div>

                            <!------------------------------------------------------------------------------------------------->

                            <!------ /----------------------------------------------/ ----->
                          </div>

                          <!--------------------------------------------------------->

                          <h6
                            class="text-blueGray-400 text-xs mt-3 mb-6 font-bold uppercase Subtitles"
                          >
                          {{ 'personal_contact' | translate }}
                          </h6>
                          <div class="flex flex-wrap">
                            <!------------------------------------------        exporterPersonalName      ------------------------------------------------------->

                            <div class="w-full lg:w-3/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'exporter_personal_name' | translate }}
                                </label>
                                <input
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  placeholder="Personal Name"
                                  id="exporterPersonalName"
                                  formControlName="exporterPersonalName"
                                  required="true"
                                />
                              </div>

                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    acidForm.controls['exporterPersonalName']
                                      .invalid &&
                                    (acidForm.controls['exporterPersonalName']
                                      .dirty ||
                                      acidForm.controls['exporterPersonalName']
                                        .touched)
                                  "
                                  class="text-danger-filed fs-13 mt-1"
                                >
                                  <div
                                    *ngIf="
                                      acidForm.controls['exporterPersonalName']
                                        .errors.required
                                    "
                                  >
                                  {{ 'exporter_personal_name_required' | translate }}
                                  </div>
                                  <div
                                    *ngIf="
                                      acidForm.controls['exporterPersonalName']
                                        .errors.minlength
                                    "
                                  >
                                  {{ 'exporter_personal_name_min_characters' | translate }}
                                  </div>
                                  <div
                                    *ngIf="
                                      acidForm.controls['exporterPersonalName']
                                        .errors.maxlength
                                    "
                                  >
                                  {{ 'exporter_personal_name_max_characters' | translate }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!------------------------------------------        exporterPersonalCIN      ------------------------------------------------------->

                            <div class="w-full lg:w-3/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'exporter_personal_cin' | translate }}
                                </label>
                                <input
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  placeholder="Personal CIN"
                                  id="exporterPersonalCIN"
                                  formControlName="exporterPersonalCIN"
                                  required="true"
                                />
                              </div>
                            </div>
                            <!------------------------------------------        exporterPersonalPhone      ------------------------------------------------------->

                            <div class="w-full lg:w-3/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'exporter_personal_phone' | translate }}
                                </label>
                                <input
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  placeholder="Personal Phone"
                                  id="exporterPersonalPhone"
                                  formControlName="exporterPersonalPhone"
                                  required="true"
                                />
                              </div>

                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    acidForm.controls['exporterPersonalPhone']
                                      .invalid &&
                                    (acidForm.controls['exporterPersonalPhone']
                                      .dirty ||
                                      acidForm.controls['exporterPersonalPhone']
                                        .touched)
                                  "
                                  class="text-danger-filed fs-13 mt-1"
                                >
                                  <div
                                    *ngIf="
                                      acidForm.controls['exporterPersonalPhone']
                                        .errors.required
                                    "
                                  >
                                  {{ 'exporter_personal_phone_required' | translate }}
                                  </div>
                                  <div
                                    *ngIf="
                                      acidForm.controls['exporterPersonalPhone']
                                        .errors.minlength
                                    "
                                  >
                                  {{ 'exporter_personal_phone_min_length' | translate }}
                                  </div>
                                  <div
                                    *ngIf="
                                      acidForm.controls['exporterPersonalPhone']
                                        .errors.maxlength
                                    "
                                  >
                                  {{ 'exporter_personal_phone_max_length' | translate }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!---------------------------------------   Exporter Personal  Email ---------------------------------------------------------->

                            <div class="w-full lg:w-3/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'exporter_personal_email' | translate }}*
                                </label>
                                <input
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  placeholder="Personal Email "
                                  id="exporterPersonaEmail"
                                  formControlName="exporterPersonaEmail"
                                />
                              </div>

                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    acidForm.controls['exporterPersonaEmail']
                                      .invalid &&
                                    (acidForm.controls['exporterPersonaEmail']
                                      .dirty ||
                                      acidForm.controls['exporterPersonaEmail']
                                        .touched)
                                  "
                                  class="text-danger-filed fs-13 mt-1"
                                >
                                  <div
                                    *ngIf="
                                      acidForm.controls['email'].errors.required
                                    "
                                  >
                                  {{ 'exporter_personal_email_required' | translate }}
                                  </div>

                                  <div
                                    *ngIf="
                                      acidForm.controls['exporterPersonaEmail']
                                        .errors.pattern
                                    "
                                  >
                                  {{ 'exporter_personal_email_invalid' | translate }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!--------------------------------------------------------------->
                          </div>

                          <!--- /*******************************************************/  -->
                          <button class="luxury-btn secondary" (click)="previousStep()">Back</button>
                          <button class="luxury-btn" (click)="nextStep()">Next</button>
                        </ng-container>
                         
                          <ng-container *ngIf="currentStep === 2">
                          <h6
                            class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase titles"
                          >
                            <i class="fas fa-dolly-flatbed"></i> Shipment data
                          </h6>
                          <div class="flex flex-wrap">
                            <!---------------------------------------   purchaseNumber ---------------------------------------------------------->

                            <div class="w-full lg:w-6/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'purchase_order_number' | translate }}
                                </label>
                                <input
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  placeholder="Purchase Number "
                                  id="purchaseNumber"
                                  formControlName="purchaseNumber"
                                />
                              </div>

                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    acidForm.controls['purchaseNumber']
                                      .invalid &&
                                    (acidForm.controls['purchaseNumber']
                                      .dirty ||
                                      acidForm.controls['purchaseNumber']
                                        .touched)
                                  "
                                  class="text-danger-filed fs-13 mt-1"
                                >
                                  <div
                                    *ngIf="
                                      acidForm.controls['purchaseNumber'].errors
                                        .required
                                    "
                                  >
                                  {{ 'purchase_order_number_required' | translate }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!---------------------------------------   purchaseNumberDate ---------------------------------------------------------->

                            <div class="w-full lg:w-6/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'purchase_order_date' | translate }}
                                </label>
                                <input
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  placeholder="Purchase Number Date"
                                  id="purchaseNumberDate"
                                  formControlName="purchaseNumberDate"
                                  onfocus="(this.type='date')"
                                />
                              </div>

                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    acidForm.controls['purchaseNumberDate']
                                      .invalid &&
                                    (acidForm.controls['purchaseNumberDate']
                                      .dirty ||
                                      acidForm.controls['purchaseNumberDate']
                                        .touched)
                                  "
                                  class="text-danger-filed fs-13 mt-1"
                                >
                                  <div
                                    *ngIf="
                                      acidForm.controls['purchaseNumberDate']
                                        .errors.required
                                    "
                                  >
                                  {{ 'purchase_order_date_required' | translate }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!---------------------------------------   invoiceNumber ---------------------------------------------------------->

                            <div class="w-full lg:w-4/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'invoice_number' | translate }}*
                                </label>
                                <input
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  placeholder="Invoice Number "
                                  id="invoiceNumber"
                                  formControlName="invoiceNumber"
                                />
                              </div>

                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    acidForm.controls['invoiceNumber']
                                      .invalid &&
                                    (acidForm.controls['invoiceNumber'].dirty ||
                                      acidForm.controls['invoiceNumber']
                                        .touched)
                                  "
                                  class="text-danger-filed fs-13 mt-1"
                                >
                                  <div
                                    *ngIf="
                                      acidForm.controls['invoiceNumber'].errors
                                        .required
                                    "
                                  >
                                  {{ 'invoice_number_required' | translate }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!---------------------------------------   Invoice Date ---------------------------------------------------------->

                            <div class="w-full lg:w-4/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'invoiceDate' | translate }}
                                </label>
                                <input
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  placeholder="Invoice Date"
                                  id="invoiceDate"
                                  formControlName="invoiceDate"
                                  onfocus="(this.type='date')"
                                />
                              </div>

                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    acidForm.controls['invoiceDate'].invalid &&
                                    (acidForm.controls['invoiceDate'].dirty ||
                                      acidForm.controls['invoiceDate'].touched)
                                  "
                                  class="text-danger-filed fs-13 mt-1"
                                >
                                  <div
                                    *ngIf="
                                      acidForm.controls['invoiceDate'].errors
                                        .required
                                    "
                                  >
                                  {{ 'invoiceDateRequired' | translate }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!---------------------------------------   Currency ---------------------------------------------------------->

                            <div class="w-full lg:w-4/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'currency' | translate }}
                                </label>

                                <select
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  id="currency"
                                  formControlName="currency"
                                >
                                  <option selected value="" disabled>
                                    {{ 'currencyRequired' | translate }} 
                                  </option>
                                  <option
                                    *ngFor="let currency of currencyList"
                                    value="{{ currency }}"
                                  >
                                    {{ currency }}
                                  </option>
                                </select>
                              </div>

                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    acidForm.controls['currency'].invalid &&
                                    (acidForm.controls['currency'].dirty ||
                                      acidForm.controls['currency'].touched)
                                  "
                                  class="text-danger-filed fs-13 mt-1"
                                >
                                  <div
                                    *ngIf="
                                      acidForm.controls['currency'].errors
                                        .required
                                    "
                                  >
                                  {{ 'currencyRequired' | translate }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-----------------------------------   Inco Term -------------------------------------------------------------->
                            <div class="w-full lg:w-12/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'incoTerm' | translate }}
                                </label>
                                <select
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  id="incoTerm"
                                  formControlName="incoTerm"
                                >
                                  <option selected value="" disabled>
                                    {{ 'incoTermPlaceholder' | translate }} 
                                  </option>
                                  <option
                                    *ngFor="let inco of incoterme"
                                    value="{{ inco }}"
                                  >
                                    {{ inco }}
                                  </option>
                                </select>
                              </div>

                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    acidForm.controls['incoTerm'].invalid &&
                                    (acidForm.controls['incoTerm'].dirty ||
                                      acidForm.controls['incoTerm'].touched)
                                  "
                                >
                                  <div
                                    *ngIf="
                                      acidForm.controls['incoTerm'].errors
                                        .required
                                    "
                                    class="text-danger-filed"
                                  >
                                  {{ 'incoTermRequired' | translate }} 
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!---------------------------------------   origin Country ---------------------------------------------------------->

                            <div class="w-full lg:w-4/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'originCountry' | translate }} 
                                </label>
                                <select
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  id="originCountry"
                                  formControlName="originCountry"
                                >
                                  <option selected value="" disabled>
                                    {{ 'originCountryPlaceholder' | translate }}
                                  </option>
                                  <option
                                    *ngFor="let country of countryList"
                                    value="{{ country }}"
                                  >
                                    {{ country }}
                                  </option>
                                </select>
                              </div>

                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    acidForm.controls['originCountry']
                                      .invalid &&
                                    (acidForm.controls['originCountry'].dirty ||
                                      acidForm.controls['originCountry']
                                        .touched)
                                  "
                                  class="text-danger-filed fs-13 mt-1"
                                >
                                  <div
                                    *ngIf="
                                      acidForm.controls['originCountry'].errors
                                        .required
                                    "
                                  >
                                  {{ 'originCountryRequired' | translate }} 
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!---------------------------------------   origin Port ---------------------------------------------------------->

                            <div class="w-full lg:w-4/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'originPort' | translate }}
                                </label>
                                <input
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  placeholder="Origin Port  "
                                  id="originPort"
                                  formControlName="originPort"
                                />
                              </div>
                            </div>

                            <!---------------------------------------   destination Port ---------------------------------------------------------->

                            <div class="w-full lg:w-4/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'destinationPort' | translate }}
                                </label>
                                <input
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  placeholder="Destination Port  "
                                  id="destinationPort"
                                  formControlName="destinationPort"
                                />
                              </div>
                            </div>
                            <!---------------------------------------   Terms of Payment ---------------------------------------------------------->

                            <div class="w-full lg:w-12/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'termsOfPayment' | translate }}*
                                </label>
                                <select
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  id="termsofPayment"
                                  formControlName="termsofPayment"
                                >
                                  <option selected value="" disabled>
                                    {{ 'termsOfPayment' | translate }}*
                                  </option>
                                  <option
                                    *ngFor="let pay of payTerms"
                                    value="{{ pay }}"
                                  >
                                    {{ pay }}
                                  </option>
                                </select>
                              </div>

                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    acidForm.controls['termsofPayment']
                                      .invalid &&
                                    (acidForm.controls['termsofPayment']
                                      .dirty ||
                                      acidForm.controls['termsofPayment']
                                        .touched)
                                  "
                                  class="text-danger-filed fs-13 mt-1"
                                >
                                  <div
                                    *ngIf="
                                      acidForm.controls['termsofPayment'].errors
                                        .required
                                    "
                                  >
                                  {{ 'termsOfPaymentRequired' | translate }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!---------------------------------------   General Description ---------------------------------------------------------->

                            <div class="w-full lg:w-12/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'generalDescription' | translate }}*
                                </label>
                                <textarea
                                  type="text"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  rows="4"
                                  placeholder="General Description"
                                  id="generalDescription"
                                  formControlName="generalDescription"
                                >
                                </textarea>
                              </div>

                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    acidForm.controls['generalDescription']
                                      .invalid &&
                                    (acidForm.controls['generalDescription']
                                      .dirty ||
                                      acidForm.controls['generalDescription']
                                        .touched)
                                  "
                                  class="text-danger-filed fs-13 mt-1"
                                >
                                  <div
                                    *ngIf="
                                      acidForm.controls['generalDescription']
                                        .errors.required
                                    "
                                  >
                                  {{ 'generalDescriptionRequired' | translate }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!------------------------ List of products -------------------------------------------->

                            <div
                              class="w-full lg:w-12/12 px-4"
                              *ngIf="willBeConsulted == false"
                            >
                              <div
                                class="relative w-full mb-3"
                                *ngFor="let product of products"
                              >
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                  <i class="fab fa-product-hunt"></i>
                                  {{ product.itemNo }}
                                </label>
                              </div>
                            </div>

                            <!--------------------------------------------------------------------------------------->

                            <button
                              class="bg-blueGray-500 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              type="button"
                              style="background-color: gray"
                              (click)="reset()"
                              *ngIf="willBeConsulted == false"
                            >
                              <i class="fas fa-times-circle"> {{ 'cancel' | translate }}</i>
                            </button>

                            <button
                              class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              mat-button
                              (click)="openDialog()"
                              type="button"
                              #btnRef
                              *ngIf="willBeConsulted == false"
                            >
                              <i class="fas fa-plus"> {{ 'add' | translate }}</i>
                            </button>
                            <!--------------------------------------------------------------->
                          </div>

                          <!-------------------------------------------------------------------------------------
               --------------------------------------------------------------------------------------
               ---------------------------------------------    Products ----------------------------
               ------------------------------------------------------------------------------------->
               <div *ngIf="willBeConsulted">
                       
                <h6
                  class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase titles"
                >
                  <i class="fab fa-product-hunt"></i> Products
                </h6>
                <div
                  class="flex flex-wrap"
                  *ngFor="let product of products"
                >
                  <div class="w-full lg:w-12/12 px-4">
                    <div class="relative w-full mb-3">
              
                    </div>
                  </div>

                  <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                      <label
                        class="block text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        ItemNo
                      </label>
                      <input
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value="{{ product.itemNo }}"
                      />
                    </div>
                  </div>
                  <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                      <label
                        class="block text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                      {{ 'productCode' | translate }}
                      </label>
                      <input
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value="{{ product.productCode }}"
                      />
                    </div>
                  </div>
                  <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                      <label
                        class="block text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                      {{ 'commodityCode' | translate }}
                      </label>
                      <input
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value="{{ product.commodityCode }}"
                      />
                    </div>
                  </div>
                  <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                      <label
                        class="block text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                      {{ 'tradeMarkOwner' | translate }}
                      </label>
                      <input
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value="{{ product.tradeMarkOwner }}"
                      />
                    </div>
                  </div>
                  <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                      <label
                        class="block text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                      {{ 'brandName' | translate }}
                      </label>
                      <input
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value="{{ product.brandName }}"
                      />
                    </div>
                  </div>
                  <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                      <label
                        class="block text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                      {{ 'model' | translate }}
                      </label>
                      <input
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value="{{ product.model }}"
                      />
                    </div>
                  </div>
                  <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                      <label
                        class="block text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                      {{ 'quantity' | translate }}
                      </label>
                      <input
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value="{{ product.quantity }}"
                      />
                    </div>
                  </div>
                  <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                      <label
                        class="block text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                      {{ 'quantityUnit' | translate }}
                      </label>
                      <input
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value="{{ product.quantityUnit }}"
                      />
                    </div>
                  </div>
                  <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                      <label
                        class="block text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                      {{ 'unitPrice' | translate }}
                      </label>
                      <input
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value="{{ product.unitPrice }}"
                      />
                    </div>
                  </div>
                  <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                      <label
                        class="block text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                      {{ 'grossWeight' | translate }}
                      </label>
                      <input
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value="{{ product.grossWeight }}"
                      />
                    </div>
                  </div>
                  <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                      <label
                        class="block text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                      {{ 'netWeight' | translate }}
                      </label>
                      <input
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value="{{ product.netWeight }}"
                      />
                    </div>
                  </div>
                  <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                      <label
                        class="block text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                      {{ 'weightUnit' | translate }}
                      </label>
                      <input
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value="{{ product.weightUnit }}"
                      />
                    </div>
                  </div>
                  <!---------------------------------------   Description Of Goods ---------------------------------------------------------->

                  <div class="w-full lg:w-12/12 px-4">
                    <div class="relative w-full mb-3">
                      <label
                        class="block text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Description Of Goods*
                      </label>
                      <textarea
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        rows="4"
                        value="{{ product.descriptionOfGoods }}"
                      >
                      </textarea>
                    </div>
                  </div>
                </div>
              </div>
               <button class="luxury-btn secondary" (click)="previousStep()">Back</button>
               <button class="luxury-btn" (click)="nextStep()">Next</button>
             </ng-container>
             
                     
                    
                          <!-------------------------------------------------------------------------------------
               --------------------------------------------------------------------------------------
               --------------------------------------------------------------------------------------
               ------------------------------------------------------------------------------------->

                     
                          <ng-container *ngIf="currentStep === 3">
                          <h6
                            class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase titles"
                          >
                            <i class="fas fa-dolly-flatbed"></i> {{ 'general_segment' | translate }}
                          </h6>
                          <div class="flex flex-wrap">
                            <!---------------------------------------   purchaseNumber ---------------------------------------------------------->

                            <!---------------------------------------      invoiceSubtotal* ---------------------------------------------------------->

                            <div class="w-full lg:w-4/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'invoice_subtotal' | translate }}*
                                </label>
                                <input
                                  type="number"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  formControlName="invoiceSubtotal"
                                  placeholder="Invoice Subtotal"
                                  (change)="onChange()"
                                />
                              </div>
                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    acidForm.controls['invoiceSubtotal']
                                      .invalid &&
                                    (acidForm.controls['invoiceSubtotal']
                                      .dirty ||
                                      acidForm.controls['invoiceSubtotal']
                                        .touched)
                                  "
                                >
                                  <div
                                    *ngIf="
                                      acidForm.controls['invoiceSubtotal']
                                        .errors.required
                                    "
                                  >
                                  {{ 'required' | translate: { field: ('invoice_subtotal' | translate) } }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!---------------------------------------      freightCost* ---------------------------------------------------------->

                            <div class="w-full lg:w-4/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'freightCost' | translate }}*
                                </label>
                                <input
                                  type="number"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  formControlName="freightCost"
                                  placeholder="Freight Cost"
                                  (change)="onChange()"
                                />
                              </div>
                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    acidForm.controls['freightCost'].invalid &&
                                    (acidForm.controls['freightCost'].dirty ||
                                      acidForm.controls['freightCost'].touched)
                                  "
                                >
                                  <div
                                    *ngIf="
                                      acidForm.controls['freightCost'].errors
                                        .required
                                    "
                                  >
                                  {{ 'freightCostRequired' | translate }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!---------------------------------------      insuranceCost* ---------------------------------------------------------->

                            <div class="w-full lg:w-4/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'insuranceCost' | translate }}*
                                </label>
                                <input
                                  type="number"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  formControlName="insuranceCost"
                                  placeholder=" Insurance Cost"
                                  (change)="onChange()"
                                />
                              </div>
                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    acidForm.controls['insuranceCost']
                                      .invalid &&
                                    (acidForm.controls['insuranceCost'].dirty ||
                                      acidForm.controls['insuranceCost']
                                        .touched)
                                  "
                                >
                                  <div
                                    *ngIf="
                                      acidForm.controls['insuranceCost'].errors
                                        .required
                                    "
                                  >
                                  {{ 'insuranceCostRequired' | translate }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!---------------------------------------      otherCosts* ---------------------------------------------------------->

                            <div class="w-full lg:w-6/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'otherCosts' | translate }}*
                                </label>
                                <input
                                  type="number"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  formControlName="otherCosts"
                                  placeholder="Other Costs "
                                  (change)="onChange()"
                                />
                              </div>
                              <div class="text-xs" style="color: red">
                                <div
                                  *ngIf="
                                    acidForm.controls['otherCosts'].invalid &&
                                    (acidForm.controls['otherCosts'].dirty ||
                                      acidForm.controls['otherCosts'].touched)
                                  "
                                >
                                  <div
                                    *ngIf="
                                      acidForm.controls['otherCosts'].errors
                                        .required
                                    "
                                  >
                                  {{ 'otherCostsRequired' | translate }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!---------------------------------------      Total* ---------------------------------------------------------->

                            <div class="w-full lg:w-6/12 px-4">
                              <div class="relative w-full mb-3">
                                <label
                                  class="block text-blueGray-600 text-xs font-bold mb-2"
                                  htmlFor="grid-password"
                                >
                                {{ 'total' | translate }}*
                                </label>
                                <input
                                  type="number"
                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  formControlName="total"
                                  placeholder="Total"
                                />
                              </div>
                            </div>

                            <!------------------------------------------------------------------------------------------------------------------------------------------>

                            <!------------------------------------------------------------------------------------------------->
                          </div>
                          <br />

                          <div
                            style="display: flex; justify-content: center"
                            *ngIf="(isAdmin || isMinistry) && !isReplied"
                          >
                            <button
                              class="bg-emerald-500 text-white active:bg-emerald-600 font-bold text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                              type="button"
                              (click)="accept()"
                            >
                            {{ 'accept' | translate }}
                            </button>

                            <button
                              class="bg-red-500 text-white active:bg-red-600 font-bold text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                              type="button"
                              (click)="showdiv('reject')"
                            >
                              <!----- [disabled]="acidForm.pristine || acidForm.invalid  " -->
                              {{ 'reject' | translate }}
                            </button>
                          </div>
                          <br />
                          <div
                            style="display: flex; justify-content: center"
                            *ngIf="(isAdmin || isMinistry) && !isReplied"
                          >
                            <button
                              class="bg-teal-500 text-white active:bg-teal-600 font-bold text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                              type="button"
                              (click)="showdiv('addInfo')"
                            >
                              <!----- [disabled]="acidForm.pristine || acidForm.invalid  " -->
                              {{ 'additionalInfo' | translate }}
                            </button>

                            <button
                              class="bg-blueGray-600 text-white active:bg-blueGray-400 font-bold text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                              type="button"
                              (click)="waitingExporterValidation()"
                            >
                              <!----- [disabled]="acidForm.pristine || acidForm.invalid  " -->
                              {{ 'waitingForValidation' | translate }}
                            </button>
                          </div>
                          <div *ngIf="isAdmin || isMinistry">
                            <div *ngIf="isRejected">
                              <div class="w-full lg:w-12/12 px-4">
                                <div class="relative w-full mb-3">
                                  <label
                                    class="block text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                  >
                                    {{ reasonComment }}*
                                  </label>
                                  <textarea
                                    type="text"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    rows="4"
                                    placeholder="{{ reasonComment }}"
                                    id="comment"
                                    formControlName="comment"
                                  >
                                  </textarea>
                                </div>
                                <div class="text-xs" style="color: red">
                                  <div
                                    *ngIf="
                                      acidForm.controls['comment'].invalid &&
                                      (acidForm.controls['comment'].dirty ||
                                        acidForm.controls['comment'].touched)
                                    "
                                    class="text-danger-filed fs-13 mt-1"
                                  >
                                    <div
                                      *ngIf="
                                        acidForm.controls['comment'].errors
                                          .required
                                      "
                                    >
                                      {{ reasonComment }} is required.
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                style="display: flex; justify-content: center"
                              >
                                <button
                                  class="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                  type="button"
                                  [ngStyle]="{
                                    'background-color': '#0d6efd',
                                    'border-color': '#0d6efd',
                                    padding: '12px'
                                  }"
                                  (click)="reject()"
                                >
                                  <!----- [disabled]="acidForm.pristine || acidForm.invalid  " -->
                                  {{ 'save' | translate }}
                                </button>
                              </div>
                            </div>

                            <div *ngIf="isAddInfo">
                              <div class="w-full lg:w-12/12 px-4">
                                <div class="relative w-full mb-3">
                                  <label
                                    class="block text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                  >
                                    {{ reasonComment }}*
                                  </label>
                                  <textarea
                                    type="text"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    rows="4"
                                    placeholder="{{ reasonComment }}"
                                    id="comment"
                                    formControlName="comment"
                                  >
                                  </textarea>
                                </div>
                                <div class="text-xs" style="color: red">
                                  <div
                                    *ngIf="
                                      acidForm.controls['comment'].invalid &&
                                      (acidForm.controls['comment'].dirty ||
                                        acidForm.controls['comment'].touched)
                                    "
                                    class="text-danger-filed fs-13 mt-1"
                                  >
                                    <div
                                      *ngIf="
                                        acidForm.controls['comment'].errors
                                          .required
                                      "
                                    >
                                      {{ reasonComment }} is required.
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                style="display: flex; justify-content: center"
                              >
                                <button
                                  class="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                  type="button"
                                  [ngStyle]="{
                                    'background-color': '#0d6efd',
                                    'border-color': '#0d6efd',
                                    padding: '12px'
                                  }"
                                  (click)="addtionalInformation()"
                                >
                                  <!----- [disabled]="acidForm.pristine || acidForm.invalid  " -->
                                  {{ 'save' | translate }}
                                </button>
                              </div>
                            </div>
                          </div>

                          <div
                            style="display: flex; justify-content: center"
                            *ngIf="willBeConsulted == false"
                          >
                            <button
                              class="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                              type="button"
                              style="
                          background-color:rgb(177 192 213)  ;
                           border-color:rgb(177 192 213)';
                         "
                              (click)="previous()"
                            >
                            {{ 'previous' | translate }}
                            </button>

                            <button
                              class="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                              type="button"
                              [ngStyle]="{
                                'background-color': '#0d6efd',
                                'border-color': '#0d6efd',
                                padding: '12px'
                              }"
                              (click)="onSubmit()"
                            >
                              <!----- [disabled]="acidForm.pristine || acidForm.invalid  " -->
                              {{ 'save' | translate }}
                            </button>
                          </div>
                          <button class="luxury-btn secondary" (click)="previousStep()">Back</button>
                          <button class="luxury-btn success" (click)="resetStep()">Finish</button>
                        </ng-container>
                          <br />
                        </form>
                      </div>

                      <!-- end of form -->

                      <!------------------------------------------------------->
                    
                    </div> </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
<app-footer></app-footer>
