import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Company } from 'src/app/models/Company';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-display-members',
  templateUrl: './display-members.component.html',
  styleUrls: ['./display-members.component.css']
})
export class DisplayMembersComponent implements OnInit {

  

  members:User[] = [];
  currentMember: User = {};
  currentIndex = -1;
  title = '';
  usernameManger?:string;
  
  page = 1;
  count = 0;
  pageSize = 5;
  pageSizes = [5, 10, 50];
  myid!:any;
  userId:any;
  searchText='';

  @Input()
  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  constructor(private route: ActivatedRoute,public userService: UserService,public authService:AuthService,public notificationService:NotificationService) {
    
    this.myid=this.authService.currentUserValue.user_id
    
   }

  ngOnInit(): void {
    this.retrieveMembers();
    this.route.paramMap.subscribe(params => {
      const companyIdParam = params.get('userId');
      this.userId = companyIdParam ? +companyIdParam : null;
      
      if(this.userId != null){
        this.onDelete(this.userId,this.myid)
      }
      
    });
    
  
  }

  getRequestParams(mangerid: number, page: number, pageSize: number): any {
    let params: any = {};

    
      params[`mangerid`] = mangerid;
    

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  retrieveMembers(): void {
    const params = this.getRequestParams(this.myid, this.page, this.pageSize);

    this.userService.getAllMembersByManagerPagination(params)
    .subscribe(
      response => {
        const { members, totalItems } = response;
        this.members = members;
        this.count = totalItems;
        
        
      },
      error => {
        console.log(error);
      });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveMembers();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveMembers();
  }

  refreshList(): void {
    this.retrieveMembers();
    this.currentMember = {};
    this.currentIndex = -1;
  }

  setActiveCompany(tutorial: User, index: number): void {
    this.currentMember = tutorial;
    this.currentIndex = index;
  }



  

  onDelete(user_id:any,manager_id:any){

    this.userService.deleteUser(user_id,manager_id).subscribe({
      next: (data) => { 
        
        this.notificationService.showSuccess("","Member Deleted ! ")
        window.location.href ='members'
      },
      error: (err) => {
        console.log(err)
        this.notificationService.showError("", "Member not found ! ");
      },
    });
    

  }

}
