<app-auth-navbar></app-auth-navbar>
<div  *ngIf="isSignedIn == false">
  <app-navbarg></app-navbarg>
<div class="Hero">
  <span>Liste des organisations</span>
</div>

<div class="barre-recherche">
  <div class="search">
    <input type="text"  [(ngModel)]="searchText" class="search-input" placeholder="Recherchez...">
    <button class="search-btn"><i class="fas fa-search"></i></button>
  </div>
</div>

<div class="organisation">
  <div class="liste-card">
    <div class="card" *ngFor="let company of companies | filter:searchText">
      <div class="image">
        <ng-container [ngSwitch]="company.activityType">
          <img *ngSwitchCase="'Office account'" src="assets/images/office2.png" class="card-img-top" alt="Office account">
          <img *ngSwitchCase="'Public Broker'" src="assets/images/broker.png" class="card-img-top" alt="Public Broker">
          <img *ngSwitchCase="'Company account'" src="assets/images/societe.png" class="card-img-top" alt="Company account">
          <img *ngSwitchCase="'Container Terminal'" style="width: 200px;" src="assets/images/cargo.png" class="card-img-top" alt="Container Terminal">
          <img *ngSwitchCase="'Shipping line / Agent Maritime'"  src="assets/images/lois-maritimes.png" class="card-img-top" alt="Shipping line / Agent Maritime">
          <img *ngSwitchCase="'Airline'" src="assets/images/icons/airline.png" class="card-img-top" alt="Airline">
          <img *ngSwitchCase="'Freight Forwarder'" src="assets/images/icons/international-shipping.png" class="card-img-top" alt="Freight Forwarder">
          <img *ngSwitchCase="'Qualitative testing laboratories'" src="assets/images/chimie.png" class="card-img-top" alt="Qualitative testing laboratories">
          <img *ngSwitchCase="'Airline Warehouse'" src="assets/images/icons/global-distribution.png" class="card-img-top" alt="Airline Warehouse">
          <img *ngSwitchDefault src="assets/images/icons/default.png" class="card-img-top" alt="Default">
        </ng-container>
      </div>
      <h5>{{ company.raisonSocial }}</h5>
      <div class="desc">
        <span>Type:</span>
        <p>{{ company.activityType }}</p>
      </div>
      <div class="desc">
        <span>Tax Identification Number:</span>
        <p>{{ company.matriculeFiscal }}</p>
      </div>
      <div class="desc">
        <span>RNE:</span>
        <p>{{ company.rne }}</p>
      </div>
      <div class="desc">
        <span>Registration date:</span>
        <p> {{ company.dateInscription | date: 'short' }}</p>
      </div>
      <div class="footer-card">
        <button (click)="showCompanyDetails(company)">
          <i class="fas fa-info-circle icon"></i>&ensp;Détaille
        </button>
        <button class="pdf" (click)="navigateToPDF(company.company_id)">
          <i class="fas fa-file-pdf icon"></i>&ensp;PDF
        </button>
      </div>
    </div>
  </div>
  <nav>
    <ul class="pagination justify-content-center">
      <li class="page-item" [class.disabled]="currentPage === 0">
        <button class="page-link" (click)="goToPage(currentPage - 1)">Précédent</button>
      </li>
      <li *ngFor="let page of [].constructor(totalPages); let i = index" 
          class="page-item" [class.active]="i === currentPage">
        <button class="page-link" (click)="goToPage(i)">{{ i + 1 }}</button>
      </li>
      <li class="page-item" [class.disabled]="currentPage === totalPages - 1">
        <button class="page-link" (click)="goToPage(currentPage + 1)">Suivant</button>
      </li>
    </ul>
  </nav>
</div>


<footer>
    <div class="content">
      <div class="top">
        <div class="logo-details">
            <img src="../../assets/images/auth/logo-dark2.png" alt="Logo" />
            <h4>ACID PORTAL</h4>
        </div>
        <div class="media-icons">
          <a href="#"><i class="fab fa-facebook-f"></i></a>
          <a href="#"><i class="fab fa-twitter"></i></a>
          <a href="#"><i class="fab fa-instagram"></i></a>
          <a href="#"><i class="fab fa-linkedin-in"></i></a>
        </div>
      </div>
      <div class="link-boxes">
        <ul class="box_desc">
          <li class="link_name">DESCRIPTION</li>
          <p>We enable our stakeholders to have a trouble free experience by submitting all the documentations needed 
            for import, export & transit of goods in a single point of entry.</p>
        </ul>
            <ul class="box">
            <li class="link_name">Liens Utiles</li>
            <li><a href="#"><i class="fas fa-forward" style="color: #7166F5;"></i>&ensp;Companies</a></li>
            <li><a href="#"><i class="fas fa-forward" style="color: #7166F5;"></i>&ensp;join us</a></li>
            <li><a href="#"><i class="fas fa-forward" style="color: #7166F5;"></i>&ensp;Login</a></li>

            </ul>
        <ul class="box input-box">
          <li class="link_name">S'abonner</li>
          <li><input type="text" placeholder="Enter your email"></li>
          <li><input type="button" value="S'abonner"></li>
        </ul>
      </div>
    </div>
    <div class="bottom-details">
      <div class="bottom_text">
        <span class="copyright_text">Copyright © 2024 <a href="#" class="nom_societe">MSS Consulting.</a>All rights reserved</span>
        <span class="policy_terms">
          <a href="#">Privacy policy</a>
          <a href="#">Terms & condition</a>
        </span>
      </div>
    </div>
  </footer>