<app-auth-navbar></app-auth-navbar>

<div class="header">
  <p>Éléments : {{ users.length }}</p>
  <input type="text" [(ngModel)]="searchText" placeholder="Recherchez un utilisateur..." />
</div>

<div class="user-list">
  <div class="card-container">
    <div class="card" *ngFor="let user of users | filter:searchText">
      <div class="card-header">
        <h3>{{ user.firstName }} {{ user.lastName }}</h3>
        <p class="username">{{ user.username }}</p>
      </div>
      <div class="card-body">
        <p><strong>Email:</strong> {{ user.email }}</p>
        <p><strong>Date d'inscription:</strong> {{ user.inscriptionDate | date: 'dd/MM/yyyy' }}</p>
        <p><strong>Double Auth:</strong> {{ user.twoFactorAuthEnabled ? 'Oui' : 'Non' }}</p>
        <p><strong>Rôles:</strong></p>
        <ul>
          <li *ngFor="let role of user.roles">{{ role.name }}</li>
        </ul>
      </div>
      <div class="card-footer">
        <button (click)="updateTwoFactorAuth(user.user_id)">Activer/Désactiver Double Auth</button>
      </div>
    </div>
  </div>

  <div class="pagination">
    <button [disabled]="currentPage === 0" (click)="changePage(currentPage - 1)">Précédent</button>
    <span>Page {{ currentPage + 1 }} sur {{ totalPages }}</span>
    <button [disabled]="currentPage === totalPages - 1" (click)="changePage(currentPage + 1)">Suivant</button>
  </div>
</div>
