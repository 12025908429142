import { Component, OnInit, HostListener } from '@angular/core';import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CompanyService } from 'src/app/services/company.service';
import { interval } from 'rxjs';
import Swal from 'sweetalert2';
declare var AOS: any;

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.css']
})
export class CompanyListComponent implements OnInit {
  companies: any[] = [];
  currentPage: number = 0;
  totalPages: number = 0;
  totalItems: number = 0;
  pageSize: number = 7;
  searchText: string = '';
  currentDateTime: Date = new Date();
  isSignedIn = false;
  formattedDate: string = '';
  navbarOpen = false;
  lang: string = 'en';
  isMenuOpen: boolean = false;
  isScrolled = false;


  constructor(private translateService:TranslateService, private companyService: CompanyService, private router: Router) {}

  ngOnInit(): void {
    this.loadCompanies();
    AOS.init();  // Initialisation de AOS
    const storedLang = localStorage.getItem('lang');
    if (storedLang) {
      this.lang = storedLang;
      this.translateService.use(this.lang);
    }
    interval(1000).subscribe(() => {
      this.currentDateTime = new Date();
      this.formatDateInArabic();
    });
  }

  navigateToPDF(companyId: number): void {
   

    this.router.navigate([`/pdf-companys/${companyId}`]);
  }

  loadCompanies(): void {
    const params = {
      page: this.currentPage,
      size: this.pageSize,
      sort: 'dateInscription,desc',
    };

    this.companyService.getAllCompanies(params).subscribe(
      (data) => {
        this.companies = data.companies;
        this.currentPage = data.currentPage;
        this.totalPages = data.totalPages;
        this.totalItems = data.totalItems;
      },
      (error) => {
        console.error('Error fetching companies:', error);
      }
    );
  }
// Fonction pour formater la date en arabe
formatDateInArabic() {
  this.formattedDate = new Intl.DateTimeFormat('ar-EG', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  }).format(this.currentDateTime);
}
@HostListener('window:scroll', [])
onWindowScroll() {
  this.isScrolled = window.scrollY > 50;
}
toggleMenu() {
  this.isMenuOpen = !this.isMenuOpen;
}


  goToPage(page: number): void {
    this.currentPage = page;
    this.loadCompanies();
  }

  showCompanyDetails(company: any): void {
    Swal.fire({
      title: `Détails de ${company.raisonSocial}`,
      html: `
<p><strong>Company Name:</strong> ${company.raisonSocial}</p>
<p><strong>RNE:</strong> ${company.rne}</p>
<p><strong>Tax Identification Number:</strong> ${company.matriculeFiscal}</p>

<p><strong>Food Importer:</strong> ${company.foodImporter ? 'Yes' : 'No'}</p>

<p><strong>Company Phone:</strong> ${company.companyPhone}</p>
<p><strong>Company Email:</strong> ${company.companyEmail}</p>
<p><strong>Company Address:</strong> ${company.companyAdress}</p>
<p><strong>Company Country:</strong> ${company.companyCountry}</p>

<p><strong>Website:</strong> ${company.webSite || 'Not available'}</p>
<p><strong>Fax:</strong> ${company.fax || 'Not available'}</p>
<p><strong>City Code:</strong> ${company.cityCode || 'Not available'}</p>

<p><strong>Company Type:</strong> ${company.companyType}</p>
<p><strong>Registration Date:</strong> ${new Date(company.dateInscription).toLocaleDateString()}</p>

      `,
      icon: 'info',
      confirmButtonText: 'Fermer'
    });
  }

   
  ChangeLang(lang: any) {
    const selectedLanguage = lang.target.value;
    localStorage.setItem('lang', selectedLanguage);
    this.translateService.use(selectedLanguage);
    this.lang = selectedLanguage; 
  }

}
