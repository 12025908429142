import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Company } from '../models/Company';
import { Observable } from 'rxjs';

import { User } from '../models/User';
import { Ministry } from '../models/Ministry';

const api = environment.baseURL+"ministry/";



@Injectable({
  providedIn: 'root'
})
export class MinistryService {

  constructor(private http: HttpClient) {}

  getAll(params: any) {
    return this.http.get<any>(api+'getAll', { params });
  }

  getAllWP() {
    return this.http.get<any>(api+'getAllWP');
  }

  add(
   
    item:Ministry
  ): Observable<any> {
    
    return this.http.post(
      api + 'add' ,item
     
    );
  }

  update(
   
    item:Ministry
  ): Observable<any> {
    
    return this.http.put(
      api + 'update' ,item
     
    );
  }

  delete(
    id:any
  ): Observable<any> {
    
    return this.http.delete(
      api + 'delete/' +id  
    );
  }
  
}
