import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SpecificList } from 'src/app/models/SpecificList';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth.service';
import { MinistryService } from 'src/app/services/ministry.service';
import { NotificationService } from 'src/app/services/notification.service';
import { RoleService } from 'src/app/services/role.service';
import { SpecificListService } from 'src/app/services/specificList.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-list',
  templateUrl: './add-list.component.html',
  styleUrls: ['./add-list.component.css']
})
export class AddListComponent implements OnInit {
  @Input()
  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [5, 10, 50];

  currentItem:any
  listDetails: FormGroup | any;
  list_step=true
  currentIndex = -1;
  willbeUpdated = false


  items:any[]=[];
  codes:any[]=[];
types:any[]=[]

  
  userId: any;
  
  users:any[]=[];
  mySet: string[] = [];
  constructor(private formBuilder: FormBuilder ,public authService: AuthService,
   public notificationService:NotificationService,private cdr: ChangeDetectorRef,
    public specificListService:SpecificListService,public ministryService:MinistryService) { 
      

    this.listDetails = this.formBuilder.group({
      ministry: ['', Validators.required],
      prouctCodes: ['', Validators.required],
      productDescription: ['', Validators.required],
      itemNo: ['',Validators.required],
    });

 
  }
  get list() { return this.listDetails.controls; }

  getRequestParams( page: number, pageSize: number): any {
    let params: any = {};

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  ngOnInit(): void {
   
    this.retrieveLists()
    this.getMinistries()
  }
  retrieveLists(){

    const params = this.getRequestParams( this.page, this.pageSize);

    this.specificListService.getAll(params)
    .subscribe(
      response => {
        const { items, totalItems } = response;
        this.items = items;
        this.count = totalItems;
      
       
        
      },
      error => {
        console.log(error);
      });

  }
  getMinistries(){
    this.ministryService.getAllWP()
    .subscribe(
      response => {
        this.types=response
       

      },
      error => {
        console.log(error);
      });

  }
  

  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveLists();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveLists();
  }

  refreshList(): void {
    this.retrieveLists();
    this.currentItem = {};
    this.currentIndex = -1;
  }

  setActiveCompany(tutorial: SpecificList, index: number): void {
    this.currentItem = tutorial;
    this.currentIndex = index;
    this.setDetails(this.currentItem)
    this.willbeUpdated=true
    this.codes=[]
    if(tutorial.prouctCodes!=null){
      this.codes=tutorial.prouctCodes
    }
    

  }

  deleteSelectedItem(id:any){
    this.specificListService.delete(id)
    .subscribe(
      response => {
       
        console.log(this.users)
        this.cdr.detectChanges();
        this.retrieveLists()
        
      },
      error => {
        console.log(error);
      });

  }
  setDetails(data:SpecificList){
    this.listDetails.controls['ministry'].setValue(data.ministry?.sector);
    this.listDetails.controls['prouctCodes'].setValue(data?.prouctCodes?.[0]);
    this.listDetails.controls['productDescription'].setValue(data.productDescription); 
    this.listDetails.controls['itemNo'].setValue(data.itemNo); 
   
  }
  add(){
    let item:SpecificList =new SpecificList;
   
    item.ministry=this.listDetails.get(["ministry"]).value;
    console.log(this.listDetails.get(["ministry"]).value)

    item.prouctCodes=[]
    item.prouctCodes=this.codes
    item.prouctCodes.push(this.listDetails.get(["prouctCodes"]).value)
    item.productDescription=this.listDetails.get(["productDescription"]).value;
    item.itemNo=this.listDetails.get(["itemNo"]).value;
   console.log(item)

   this.specificListService.add(item).subscribe({
      next: (data) => {
        console.log(data)
        this.retrieveLists()
        
       
      },
      error: (err) => {
        console.log(err)
        this.notificationService.showError(err.error.message,"");
        
      },})


  }

update(){

  let item:SpecificList =this.currentItem;
  console.log(this.codes)
  item.prouctCodes=this.codes
  item.prouctCodes.push(this.listDetails.get(["prouctCodes"]).value)
  item.ministry=this.listDetails.get(["ministry"]).value
  item.productDescription=this.listDetails.get(["productDescription"]).value;
  item.itemNo=this.listDetails.get(["itemNo"]).value;
  console.log(item)

 this.specificListService.update(item).subscribe({
    next: (data) => {
      console.log(data)
     this.retrieveLists()
    },
    error: (err) => {
      console.log(err)
      this.notificationService.showError(err.error.message,"");
      
    },})
}

addCode(){
  console.log(this.listDetails.get(["prouctCodes"]).value)
  console.log(this.listDetails.get(["itemNo"]).value)
  let value=this.listDetails.get(["prouctCodes"]).value
  this.codes.push(value)
  this.listDetails.controls['prouctCodes'].setValue("");
  
}


}
