import { Company } from "./Company";
import { GeneralSegment } from "./GeneralSegment";
import { Invoice } from "./Invoice";
import { User } from "./User";

export class AcidRequest {

    id?:number
    generalSegment?: GeneralSegment;
    state:string;
    user:User;
    userId:number
    rejectionReason:string;
    acidNumber:string;
    comment:string
    folderDate:Date
    folderStatus:string

    
 }


