import { User } from "./User";

export class Company {
    company_id?: number;
    activityType?: string;
    dateInscription?: Date;
    reference?: string;
    etat?: string;
    rne?: string;
    matriculeFiscal?: string;
    raisonSocial?: string;
    documentSubmissionCentre?: string;
    foodImporter?: boolean;
    applicantStatus?: string;
    companyPhone?: string;
    companyEmail?: string;
    logoCompany?: string;
    haveCustomerNumber?: boolean; 
    companyAdress?: string;
    companyCountry?: string;
    manager?: User;
    webSite?: string;
    fax?: string;
    cityCode?: string;
    verificationCode?: string;
    companyType?: string;
    comment?: string;
    verificationLinkOfCommercialReg?: string;

  }