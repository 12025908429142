import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Company } from 'src/app/models/Company';
import { CompanyService } from 'src/app/services/company.service';
import {  countries } from 'countries-list';
import { NotificationService } from 'src/app/services/notification.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-admin-companies',
  templateUrl: './admin-companies.component.html',
  styleUrls: ['./admin-companies.component.css']
})
export class AdminCompaniesComponent implements OnInit {

  @ViewChild('dialogTemplate') dialogTemplate!: TemplateRef <any>;
  companies: Company[] = [];
  companiesAll: Company[] = [];
  currentCompany: Company = {};
  currentIndex = -1;
  title = '';
  searchForm: FormGroup | any;
  dailogForm: FormGroup | any;
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 30, 50];
  myid!:any;
  companyId:any;
  countryList: any[] = [];
  activityTypeList:String[]=[
    'Company account',
    'Office account',
    'Public Broker',
    'Container Terminal',
    'Shipping line / Agent Maritime',
    'Airline',
    'Freight Forwarder',
    'Qualitative testing laboratories',
    'Airline Warehouse'

    ]
    isRejected=false
    isAddInfo=true
    reasonComment:any;
  @Input()
  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  constructor(public companyService: CompanyService,private fb: FormBuilder,public notificationService:NotificationService,
    private dialog: MatDialog) {
    Object.values(countries).forEach((country: any) => {
      this.countryList.push( country.name);
      this.countryList=this.countryList.sort()
    });

    this.searchForm = this.fb.group({
      
      rne:['', [Validators.required ]],
      companyName:['', [Validators.required ]],
      originCountry:['', [Validators.required ]],
      companyType:['', [Validators.required ]],
      

    });

    this.dailogForm = this.fb.group({
      comment:['', [Validators.required ]],
    });
   }

  ngOnInit(): void {
    this.retrieveCompaniesAdmin();
  }

  getRequestParamsAdmin( page: number, pageSize: number): any {
    let params: any = {};
    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  retrieveCompaniesAdmin(): void {
    const params = this.getRequestParamsAdmin( this.page, this.pageSize);

    this.companyService.getAllImporters(params)
    .subscribe(
      response => {
        const { companies, totalItems } = response;
        this.companies = companies;
        this.companiesAll = companies;
        this.count = totalItems;
        
       
      },
      error => {
        console.log(error);
      });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveCompaniesAdmin();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveCompaniesAdmin();
  }

  refreshList(): void {
    this.retrieveCompaniesAdmin();
    this.currentCompany = {};
    this.currentIndex = -1;
  }

  setActiveCompany(tutorial: Company, index: number): void {
    this.currentCompany = tutorial;
    this.currentIndex = index;
  }
  cancel(){
    this.companies=this.companiesAll
    window.location.href='admin/importers'
  }


  search(){
    this.companies=this.companiesAll
    let rne=this.searchForm.controls['rne'].value
    let companyName=this.searchForm.controls['companyName'].value
    let activityType=this.searchForm.controls['companyType'].value
    let country=this.searchForm.controls['originCountry'].value
    
  
  

    if(!(rne == "")){
      this.companies=this.companies.filter(request => request?.rne === rne || request.rne === rne)

    }
    if(!(companyName == "")){
      this.companies=this.companies.filter(request => request?.raisonSocial == companyName || request?.raisonSocial == companyName)

    }
 
    if(!(activityType == "")){
      this.companies=this.companies.filter(request => request.activityType == activityType )

    }
    if(!(country == "")){
      this.companies=this.companies.filter(request => request?.companyCountry === country)

  }

    }

    onchange(){
      this.companies=this.companiesAll
      let country=this.searchForm.controls['originCountry'].value
      this.companies=this.companies.filter(request => request.companyCountry === country)
    }
onchangeCompanyType(){
  this.companies=this.companiesAll
  let companyType=this.searchForm.controls['companyType'].value
  this.companies=this.companies.filter(request => request.activityType === companyType)

}
accept(company:any){
 
  this.companyService.accept( company).subscribe({
    next: (data) => {
     
      this.notificationService.showSuccess("", "Company accepted!");
      window.location.href='admin/importers'
      
    },
    error: (err) => {
      console.log(err)
      this.notificationService.showError("", err.error.message);
    },
  });
}
reject(company:any){
  company.comment=this.dailogForm.controls['comment'].value
  this.companyService.reject( company).subscribe({
    next: (data) => {
      this.notificationService.showSuccess("", "Company rejected!");
      window.location.href='admin/importers'  
    },
    error: (err) => {
      console.log(err)
      this.notificationService.showError("", err.error.message);
    },
  });
}
addInfo(company:Company){
 
  company.comment=this.dailogForm.controls['comment'].value
  this.companyService.addtionalInformation( company).subscribe({
    next: (data) => {
      this.notificationService.showSuccess("", "Company Replied By 'AddtionalInformation!' ");
      window.location.href='admin/importers'  
    },
    error: (err) => {
      console.log(err)
      this.notificationService.showError("", err.error.message);
    },
  });
}

/************************************* */


openDialog(dialogTemplate: TemplateRef<any>,response:any): void {
  const dialogRef = this.dialog.open(dialogTemplate);
  if(response == "reject"){
    this.isAddInfo=false
    this.isRejected=true
    this.reasonComment="Reject reason"
  }else if(response == "addInfo"){
    this.isRejected=false
    this.isAddInfo=true
    this.reasonComment="Comment"
  }


  dialogRef.afterClosed().subscribe(result => {
    console.log('Dialog closed with result:', result);
  });
}

}
