import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';

export interface Verify2FACodeRequest {
  username: any;
  password: any;
  verificationCode: any;
}


@Component({
  selector: 'app-verifytofa',
  templateUrl: './verifytofa.component.html',
  styleUrls: ['./verifytofa.component.css']
})


export class VerifytofaComponent implements OnInit {
  
  username: string = '';
  password: string = '';
  verificationCode: string = '';
  message: string = '';

  constructor(private authService: AuthService,public notification: NotificationService) {}

  ngOnInit(): void {
  }
  
  onVerify2FACode(): void {
    const verifyRequest: Verify2FACodeRequest = {
      username: this.username,
      password: this.password,
      verificationCode: this.verificationCode
    };

    this.authService.verify2FACode(verifyRequest).subscribe(
      response => {
        this.message = 'Authentification réussie!';
        window.location.href = 'home'; 
        this.notification.showInfo('Authentification réussie!', '');
      },
      error => {
        this.message = 'Erreur de vérification du code 2FA.';
        console.error(error);
      }
    );
  }
}
