<div   *ngIf="!acidRequest;">
  <div class="navi">
    <ul [ngClass]="{'ul1': lang === 'ab', 'ul': lang !== 'ab'}">
      
      <li class="date-heure">{{ currentDateTime | date:'EEEE d MMMM y, HH:mm:ss' }}</li>
      <li>
        <a href="">{{ 'Site-Map' | translate }}</a>
      </li>
      <li>
          <a href="">{{ 'Qui sommes-nous' | translate }}</a>
      </li>
      <li>
        <a href="">{{ 'Contactez-nous' | translate }}</a>
      </li>
    </ul>
    <ul class="groupe-item">
      <li class="nav-item2">
        <a   href="#"><i class="fab fa-facebook"></i></a>
      </li>
      <li class="nav-item2">
        <a  href="#">
          <i class="fab fa-linkedin"></i></a>
      </li>
      <li class="nav-item2">
        <a   href="#">
          <i class="fab fa-twitter"></i></a>
      </li>
      <li class="nav-item2">
        <a   href="#">
           </a>
      </li>
    </ul>
  </div>
  
  <header class="navbar">
    
    <div class="logo">
      <img src="../../../assets/images/auth/logo-nav3.png" alt="">
      <span> <span class="logo-text">ACID</span> PORTAL</span>
    </div>
  
    <div class="hamburger-icon" (click)="toggleMenu()">
        <span> <span class="logo-text">ACID</span> PORTAL&ensp;<i class="fas fa-bars"></i> </span>
  
    </div>
  
      <ul class="nav-items" [ngClass]="{'active': isMenuOpen}">
        <li class="flex items-center" ><a [routerLink]="['/']">Home</a></li>
        <li class="flex items-center">
          <a [routerLink]="['/companys']">
             {{ 'companys' | translate }}
          </a>
        </li>
        <li class="">
          <a  href="/verify-acid">
              {{ 'validator.verif_acid' | translate }}
          </a>
        </li>
        <li class="flex items-center">
          <a [routerLink]="['/']">
              {{ 'Contact' | translate }}
          </a>
        </li>
      </ul>
  
    <div class="navbar-links" [ngClass]="{'active': isMenuOpen}">
      <select [ngClass]="{'select1': lang === 'ab', 'select': lang !== 'ab'}" id="language" (change)="ChangeLang($event)" [value]="lang">
        <option value="en" [selected]="lang === 'en'">🇬🇧&ensp;English</option>
        <option value="fr" [selected]="lang === 'fr'">🇫🇷&ensp;French</option>
        <option value="ab" [selected]="lang === 'ab'">🇸🇦&ensp;عربي</option>
      </select>
      
      <button [ngClass]= "{'btn1ar': lang === 'ab', 'btn2': lang !== 'ab'}"
      [routerLink]="['/login']"><i class="fas fa-user-circle" style="font-size: 22px;"></i>&ensp;{{ 'login' | translate }}</button>
      <button  [ngClass]="{'btn1ar': lang === 'ab', 'btn3': lang !== 'ab'}"
      [routerLink]="['/signup']"><i class="fas fa-user-edit"></i>&ensp;{{ 'join_us' | translate }}</button>
  
    </div>
  </header>
    <div class="hero">
      <div class="text-hero">
        <span>(ACI) Request validator</span>
        <p>Check the ACID number</p>
      </div>
    </div>
    <div class="validator">


        <div class="partie1">
            <div class="titre-block">
                <span class="sous-titre">(ACI) Request validation</span>
                <span class="titre-form">Please read the instructions carefully.</span>
            </div>
            <form  #form="ngForm">
                <div class="input-group">
                  <label for="acid">ACID *</label>
                  <input type="text"  id="acid"  class="form-control" [(ngModel)]="acidNumber" name="acidNumber" placeholder="Type the Acid number" required>
                
                </div>
              
                <div class="input-group">
                  <label for="matriculeFiscal">Importer Matricule Fiscal *</label>
                  <input type="text" id="matriculeFiscal" class="form-control"  placeholder="Importer Matricule Fiscal" [(ngModel)]="matriculeFiscal" name="matriculeFiscal" required>
                
                </div>
              
                <div class="input-group">
                  <label for="rne">Exporter RNE *</label>
                  <input type="text" id="rne" class="form-control" [(ngModel)]="rne" name="rne" placeholder="Exporter RNE *" required>
                </div>
              
                <div class="input-group">
                  <label for="companyCountry">Exporter Pays *</label>
                  <input type="text" id="companyCountry" class="form-control" [(ngModel)]="companyCountry" name="companyCountry" required>
                </div>
                
                <!-- reCAPTCHA -->
                <div class="input-group">
                  <re-captcha
                    formControlName="recaptcha"
                    siteKey="6LcAjZImAAAAAHZuw6J69eaJO5VDydZDihnd1-0i"
                    (resolved)="onCaptchaResolved($event)"
                    required
                  ></re-captcha>
                  <div *ngIf="form.controls['recaptcha']?.invalid && (form.controls['recaptcha']?.touched)" class="invalid-feedback">
                    Please complete the CAPTCHA.
                  </div>
                </div>
                <div class="footer-valid">
                <button type="submit">Verify&ensp;<i class="fas fa-check-circle"></i></button>
                </div>
              </form>
              
        </div>





        <div class="partie2">
            <div class="titre-block">
                <span class="titre">What is Verifying the Validity of ACID No. service?</span>
                <span class="sous-titre">Inquiry and check the validity of the ACI Shipment No. ACI Shipment ID </span>
                <p>An e-service provided by MSS Consulting, which enables Trade & Business community to enquiry about their 
                issued ACIDs and verify its validity electronically without the need to visit the logistic services center in order 
                to save time and effort. </p>
                <div class="alert">
                    <p>An electronic system for advance information on goods before they are shipped from the port of export to the 
                    Tunisian ports, for achieving an early warning system for the departments concerned with risks and targeting, including 
                    inspections before shipping goods from the country of export </p>
                </div>
              
            </div>
            <br>
            <hr>
            <div class="image-block">
              <img src="../../assets/images/valid.png" alt="">
            </div>
        </div>
    </div>
</div>