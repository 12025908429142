<app-auth-navbar></app-auth-navbar>
<main class="profile-page">
  <section class="relative block h-500-px">
    <div
      class="absolute top-0 w-full h-full bg-center bg-cover"
      style="background-image: url('assets/images/slider/comp.jpg')"
    >
      <span
        id="blackOverlay"
        class="w-full h-full absolute opacity-50 bg-black"
      ></span>
    </div>
  </section>
  <section class="relative py-16 bg-blueGray-800">
    <div class="container mx-auto px-4">
      <div
        class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64"
        style="opacity: 0.9"
      >
        <div
          class="w-full lg:w-6/12 px-6 py-6"
          style="height: 100%; width: 100%"
        >
          <!--------------------------------------------------------------------->

          <div class="px-6">
            <div class="text-center">
              <div class="flex flex-wrap justify-center">
                <div
                  class="w-full lg:w-9/12 px-4"
                  style="width: 100%; height: 100%"
                >
                  <div class="flex flex-wrap mt-6">
                    <div class="w-full mb-24 px-4">
                      <div class="rounded-t mb-0 px-4 border-0">
                        <div class="py-6 px-3 mt-32 sm:mt-0">
                          <form
                            action="#"
                            method="post"
                            [formGroup]="searchForm"
                            novalidate
                          >
                            <div class="flex flex-wrap">
                              <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                  <label
                                    class="block text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                  >
                                  {{ 'status' | translate }}
                                  </label>
                                  <select
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    id="state"
                                    formControlName="state"
                                    (change)="onchangeStatus()"
                                  >
                                    <option selected value="" disabled>
                                      {{ 'status' | translate }}
                                    </option>
                                    <option
                                      *ngFor="let state of statusList"
                                      value="{{ state }}"
                                    >
                                      {{ state }}
                                    </option>
                                  </select>
                                </div>
                              </div>

                              <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                  <label
                                    class="block text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                  >
                                  {{ 'shipmentOriginCountry' | translate }}
                                  </label>
                                  <select
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    id="originCountry"
                                    formControlName="originCountry"
                                    (change)="onchange()"
                                  >
                                    <option selected value="" disabled>
                                      {{ 'originCountry' | translate }}
                                    </option>
                                    <option
                                      *ngFor="let country of countryList"
                                      value="{{ country }}"
                                    >
                                      {{ country }}
                                    </option>
                                  </select>
                                </div>
                              </div>

                              <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                  <label
                                    class="block text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                  >
                                  {{ 'rne' | translate }}
                                  </label>
                                  <input
                                    type="text"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder="RNE"
                                    id="rne"
                                    formControlName="rne"
                                  />
                                </div>
                              </div>

                              <div class="w-full lg:w-3/12 px-4">
                                <div class="relative w-full mb-3">
                                  <label
                                    class="block text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                  >
                                  {{ 'companyName' | translate }}
                                  </label>
                                  <input
                                    type="text"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder="Company Name"
                                    id="companyName"
                                    formControlName="companyName"
                                  />
                                </div>
                              </div>
                            </div>
                            <input
                              class="bg-blueGray-600 text-white active:bg-blueGray-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                              type="reset"
                              value="Cancel"
                              (click)="cancel()"
                            />
                            <button
                              class="bg-black active:bg-blueGray-200 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                              type="button"
                              (click)="search()"
                            >
                              <i class="fas fa-search"></i>
                              {{ 'search' | translate }}
                            </button>
                          </form>

                          <div class="rounded-t mb-0 px-4 py-3 border-0">
                            <div class="flex flex-wrap items-center">
                              <div
                                class="relative w-full px-4 max-w-full flex-grow flex-1"
                              >
                                <h3
                                  class="px-6 align-middle border border-solid py-3 text-lg uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center titles"
                                  [ngClass]="
                                    color === 'light'
                                      ? 'text-blueGray-700'
                                      : 'text-white'
                                  "
                                >
                                {{ 'acidRequests' | translate }}
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="flex flex-wrap">
                        <!----------------------------------------------------->
                        <!----------------------------------------------------->

                        <div class="block w-full overflow-x-auto">
                          <!-- Projects table -->
                          <table
                            class="items-center w-full bg-transparent border-collapse"
                          >
                            <thead>
                              <tr>
                                <th
                                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                  [ngClass]="
                                    color === 'light'
                                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                      : 'bg-red-800 text-red-300 border-red-700'
                                  "
                                >
                                {{ 'shipmentOriginCountry' | translate }}
                                </th>

                                <th
                                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                  [ngClass]="
                                    color === 'light'
                                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                      : 'bg-red-800 text-red-300 border-red-700'
                                  "
                                >
                                {{ 'importerCompany' | translate }}
                                </th>

                                <th
                                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                  [ngClass]="
                                    color === 'light'
                                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                      : 'bg-red-800 text-red-300 border-red-700'
                                  "
                                >
                                {{ 'importerRne' | translate }}
                                </th>

                                <th
                                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                  [ngClass]="
                                    color === 'light'
                                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                      : 'bg-red-800 text-red-300 border-red-700'
                                  "
                                >
                                {{ 'exporterCompany' | translate }}
                                </th>

                                <th
                                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                  [ngClass]="
                                    color === 'light'
                                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                      : 'bg-red-800 text-red-300 border-red-700'
                                  "
                                >
                                {{ 'exporterRne' | translate }}
                                </th>

                                <th
                                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                  [ngClass]="
                                    color === 'light'
                                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                      : 'bg-red-800 text-red-300 border-red-700'
                                  "
                                >
                                {{ 'exporterStatus' | translate }}
                                </th>
                                <th
                                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                  [ngClass]="
                                    color === 'light'
                                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                      : 'bg-red-800 text-red-300 border-red-700'
                                  "
                                >
                                {{ 'status' | translate }}
                                </th>
                                <th
                                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                  [ngClass]="
                                    color === 'light'
                                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                      : 'bg-red-800 text-red-300 border-red-700'
                                  "
                                >
                                {{ 'commentRejectionReason' | translate }}
                                </th>
                                <th
                                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                  [ngClass]="
                                    color === 'light'
                                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                      : 'bg-red-800 text-red-300 border-red-700'
                                  "
                                >
                                {{ 'invoiceDate' | translate }}
                                </th>

                                <th
                                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                  [ngClass]="
                                    color === 'light'
                                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                      : 'bg-red-800 text-red-300 border-red-700'
                                  "
                                >
                                {{ 'acidNumber' | translate }}
                                </th>

                                <th
                                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                  [ngClass]="
                                    color === 'light'
                                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                      : 'bg-red-800 text-red-300 border-red-700'
                                  "
                                >
                                {{ 'acidCreationDate' | translate }}
                                </th>

                                <th
                                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                  [ngClass]="
                                    color === 'light'
                                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                      : 'bg-red-800 text-red-300 border-red-700'
                                  "
                                >
                                {{ 'options' | translate }}
                                </th>

                                <th
                                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                  [ngClass]="
                                    color === 'light'
                                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                      : 'bg-red-800 text-red-300 border-red-700'
                                  "
                                ></th>
                              </tr>
                            </thead>
                            <tbody
                              *ngFor="
                                let acidRequest of acidRequests
                                  | paginate
                                    : {
                                        itemsPerPage: pageSize,
                                        currentPage: page,
                                        totalItems: count
                                      };
                                let i = index
                              "
                              [class.active]="i == currentIndex"
                              (click)="setActiveCompany(acidRequest, i)"
                            >
                              <tr>
                                <td
                                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                >
                                  {{
                                    acidRequest.generalSegment?.invoice
                                      ?.originCountry
                                  }}
                                </td>
                                <td
                                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                >
                                  {{
                                    acidRequest.generalSegment?.invoice
                                      ?.importer?.raisonSocial
                                  }}
                                </td>
                                <td
                                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                >
                                  {{
                                    acidRequest.generalSegment?.invoice
                                      ?.importer?.rne
                                  }}
                                </td>

                                <td
                                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                >
                                  {{
                                    acidRequest.generalSegment?.invoice
                                      ?.exporter?.raisonSocial
                                  }}
                                </td>
                                <td
                                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                >
                                  {{
                                    acidRequest.generalSegment?.invoice
                                      ?.exporter?.rne
                                  }}
                                </td>

                                <td
                                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                >
                                  {{
                                    acidRequest.generalSegment?.invoice
                                      ?.exporter?.etat
                                  }}
                                </td>

                                <td
                                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                >
                                  <a
                                    *ngIf="acidRequest?.state == 'WAITING'"
                                    style="color: orange"
                                    >{{ acidRequest.state }}</a
                                  >
                                  <a
                                    *ngIf="acidRequest?.state == 'ACCEPTED'"
                                    style="color: green"
                                    >{{ acidRequest.state }}</a
                                  >
                                  <a
                                    *ngIf="
                                      acidRequest?.state ==
                                      'ADDITIONAL_INFORMATION'
                                    "
                                    style="color: blue"
                                    >{{ acidRequest.state }}</a
                                  >
                                  <a
                                    *ngIf="
                                      acidRequest?.state ==
                                      'WAITING_EXPORTER_ACCEPT'
                                    "
                                    style="color: rgb(92, 11, 95)"
                                    >{{ acidRequest.state }}</a
                                  >
                                  <a
                                    *ngIf="acidRequest?.state == 'REJECTED'"
                                    style="color: red"
                                    >{{ acidRequest.state }}</a
                                  >
                                </td>
                                <td
                                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                >
                                  <a
                                    *ngIf="
                                      acidRequest.state ==
                                      'ADDITIONAL_INFORMATION'
                                    "
                                    >{{ acidRequest.comment }}</a
                                  >
                                  <a *ngIf="acidRequest.state == 'REJECTED'">{{
                                    acidRequest.rejectionReason
                                  }}</a>
                                </td>

                                <td
                                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                >
                                  {{
                                    acidRequest.generalSegment?.invoice
                                      ?.invoiceDate
                                  }}
                                </td>

                                <td
                                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                >
                                  <a
                                    href="details/{{ acidRequest.acidNumber }}"
                                    style="color: blue"
                                    *ngIf="
                                      AcidConsultAuthority ||
                                      isMinistry == false
                                    "
                                    >{{ acidRequest.acidNumber }}</a
                                  >
                                  <a *ngIf="AcidConsultAuthority == false">{{
                                    acidRequest.acidNumber
                                  }}</a>
                                </td>

                                <td
                                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                >
                                  {{ acidRequest.folderDate }}
                                </td>

                                <td
                                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"
                                >
                                  <!--a routerLink="/delete-company/"><img   src="assets/images/icons/rejected.png" alt="delete" style="width: 30%; height:  30%;float: right;"> </a-->
                                  <a
                                    routerLink="/request/{{ acidRequest.id }}"
                                    *ngIf="
                                      acidRequest?.state ==
                                        'ADDITIONAL_INFORMATION' ||
                                      acidRequest?.state ==
                                        'WAITING_EXPORTER_ACCEPT' ||
                                      acidRequest?.state == 'WAITING'
                                    "
                                    ><img
                                      src="assets/images/icons/rply.png"
                                      alt="reply"
                                      style="
                                        width: 30%;
                                        height: 30%;
                                        float: right;
                                      "
                                    />
                                  </a>
                                  <a
                                    routerLink="/request/{{ acidRequest.id }}"
                                    *ngIf="
                                      acidRequest?.state == 'ACCEPTED' ||
                                      acidRequest?.state == 'REJECTED'
                                    "
                                    ><img
                                      src="assets/images/icons/view.png"
                                      alt="display"
                                      style="
                                        width: 30%;
                                        height: 30%;
                                        float: right;
                                      "
                                    />
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <!---------------------------------------->
                        </div>

                        <!------------------------------------------------------->
                      </div>
                      <br />
                      <div
                        class="list row"
                        style="display: flex; justify-content: center"
                      >
                        <div class="col-md-12">
                          <pagination-controls
                            previousLabel="Prev"
                            nextLabel="Next"
                            [responsive]="true"
                            (pageChange)="handlePageChange($event)"
                          ></pagination-controls>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!---------------------------------------------------------------------->
        </div>
      </div>
    </div>
  </section>
</main>
<app-footer></app-footer>
