<h2 mat-dialog-title>Product Details</h2>
<mat-dialog-content>
    <form  action="#" method="post" [formGroup]="productForm" novalidate mat-form>

        <div class="flex flex-wrap text-left">
          <!---------------------------------------      Item Number* ---------------------------------------------------------->

          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block text-white  text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              Item Number*
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                formControlName="itemNo" placeholder="itemNo "
                
              />
            </div>
            <div class="text-xs" style="color: rgb(235, 82, 82);">
              <div *ngIf="productForm.controls['itemNo'].invalid && (productForm.controls['itemNo'].dirty || productForm.controls['itemNo'].touched)">            
                <div *ngIf="productForm.controls['itemNo'].errors.required">
                    Item Number is required.
                  </div>
            </div>
            </div>
          </div>
          <!---------------------------------------      Product Code* ---------------------------------------------------------->

          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block text-white  text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              Product Code*
              </label>
              <input
                type="number"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                formControlName="productCode" placeholder="Product Code "
              />
            </div>
            <div class="text-xs" style="color: rgb(235, 82, 82);">
              <div *ngIf="productForm.controls['productCode'].invalid && (productForm.controls['productCode'].dirty || productForm.controls['productCode'].touched)">            
                <div *ngIf="productForm.controls['productCode'].errors.required">
                    Product Code is required.
                  </div>
            </div>
            </div>
          </div>
          <!---------------------------------------      commodityCode* ---------------------------------------------------------->

         
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block text-white  text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              Commodity Code*
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                formControlName="commodityCode" placeholder="Commodity Code "
              />
            </div>
            <div class="text-xs" style="color: rgb(235, 82, 82);">
              <div *ngIf="productForm.controls['commodityCode'].invalid && (productForm.controls['commodityCode'].dirty || productForm.controls['commodityCode'].touched)">            
                <div *ngIf="productForm.controls['commodityCode'].errors.required">
                    Commodity Code is required.
                  </div>
            </div>
            </div>
          </div>
          <!---------------------------------------      tradeMarkOwner* ---------------------------------------------------------->

         
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block text-white  text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              Trade Mark Owner*
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                formControlName="tradeMarkOwner" placeholder="Trade Mark Owner "
              />
            </div>
            <div class="text-xs" style="color: rgb(235, 82, 82);">
              <div *ngIf="productForm.controls['tradeMarkOwner'].invalid && (productForm.controls['tradeMarkOwner'].dirty || productForm.controls['tradeMarkOwner'].touched)">            
                <div *ngIf="productForm.controls['tradeMarkOwner'].errors.required">
                    Trade Mark Owner is required.
                  </div>
            </div>
            </div>
          </div>
          <!---------------------------------------      brandName* ---------------------------------------------------------->

         
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block text-white  text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              Brand Name*
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                formControlName="brandName" placeholder="Brand Name"
              />
            </div>
            <div class="text-xs" style="color: rgb(235, 82, 82);">
              <div *ngIf="productForm.controls['brandName'].invalid && (productForm.controls['brandName'].dirty || productForm.controls['brandName'].touched)">            
                <div *ngIf="productForm.controls['brandName'].errors.required">
                    Brand Name is required.
                  </div>
            </div>
            </div>
          </div>
           <!---------------------------------------      Model* ---------------------------------------------------------->

         
           <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block text-white  text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              Model*
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                formControlName="model" placeholder="Model"
              />
            </div>
            <div class="text-xs" style="color: rgb(235, 82, 82);">
              <div *ngIf="productForm.controls['model'].invalid && (productForm.controls['model'].dirty || productForm.controls['model'].touched)">            
                <div *ngIf="productForm.controls['model'].errors.required">
                  Model is required.
                  </div>
            </div>
            </div>
          </div>
          <!---------------------------------------      quantity* ---------------------------------------------------------->

         
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block text-white  text-xs font-bold mb-2"
                htmlFor="grid-password"
                
              >
              Quantity*
              </label>
              <input
                type="number"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                formControlName="quantity" placeholder="Quantity "
                (change)="onChange()"
              />
            </div>
            <div class="text-xs" style="color: rgb(235, 82, 82);">
              <div *ngIf="productForm.controls['quantity'].invalid && (productForm.controls['quantity'].dirty || productForm.controls['quantity'].touched)">            
                <div *ngIf="productForm.controls['quantity'].errors.required">
                    Quantity is required.
                  </div>
            </div>
            </div>
          </div>
          <!---------------------------------------      quantityUnit* ---------------------------------------------------------->

         
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block text-white  text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              Unit quantity *
              </label>
              

              <select 
                              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              id="quantityUnit" formControlName="quantityUnit"
                              >
                                
                              
                              <option *ngFor="let unity of unities" value={{unity}}>{{unity}}</option>
                              </select>
            </div>
            <div class="text-xs" style="color: rgb(235, 82, 82);">
              <div *ngIf="productForm.controls['quantityUnit'].invalid && (productForm.controls['quantityUnit'].dirty || productForm.controls['quantityUnit'].touched)">            
                <div *ngIf="productForm.controls['quantityUnit'].errors.required">
                    Unit quantity is required.
                  </div>
            </div>
            </div>
          </div>

           <!---------------------------------------      unitPrice* ---------------------------------------------------------->

         
           <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block text-white  text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              Unit Price *
              </label>
              <input
                type="number"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                formControlName="unitPrice" placeholder=" Unit Price "
                (change)="onChange()"
              />
            </div>
            <div class="text-xs" style="color: rgb(235, 82, 82);">
              <div *ngIf="productForm.controls['unitPrice'].invalid && (productForm.controls['unitPrice'].dirty || productForm.controls['unitPrice'].touched)">            
                <div *ngIf="productForm.controls['unitPrice'].errors.required">
                    Unit Price is required.
                  </div>
            </div>
            </div>
          </div>
 <!---------------------------------------      weightUnit* ---------------------------------------------------------->

         
 <div class="w-full lg:w-4/12 px-4">
  <div class="relative w-full mb-3">
    <label
      class="block text-white  text-xs font-bold mb-2"
      htmlFor="grid-password"
    >
    Weight Unit*
    </label>
    <input
      type="number"
      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
      formControlName="weightUnit" placeholder="Weight Unit"
      (change)="onChange()"
    />
  </div>
  <div class="text-xs" style="color: rgb(235, 82, 82);">
    <div *ngIf="productForm.controls['weightUnit'].invalid && (productForm.controls['weightUnit'].dirty || productForm.controls['weightUnit'].touched)">            
      <div *ngIf="productForm.controls['weightUnit'].errors.required">
          Weight Unit is required.
        </div>
  </div>
  </div>
</div>

           <!---------------------------------------      grossWeight* ---------------------------------------------------------->

         
           <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block text-white  text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              Gross Weight *
              </label>
              <input
                type="number"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                formControlName="grossWeight" placeholder="Gross Weight"
              />
            </div>
            <div class="text-xs" style="color: rgb(235, 82, 82);">
              <div *ngIf="productForm.controls['grossWeight'].invalid && (productForm.controls['grossWeight'].dirty || productForm.controls['grossWeight'].touched)">            
                <div *ngIf="productForm.controls['grossWeight'].errors.required">
                    Gross Weight is required.
                  </div>
            </div>
            </div>
          </div>


        
           <!---------------------------------------      netWeight* ---------------------------------------------------------->

         
           <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block text-white  text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              Net Weight*
              </label>
              <input
                type="number"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                formControlName="netWeight" placeholder=" Net Weight"
              />
            </div>
            <div class="text-xs" style="color: rgb(235, 82, 82);">
              <div *ngIf="productForm.controls['netWeight'].invalid && (productForm.controls['netWeight'].dirty || productForm.controls['netWeight'].touched)">            
                <div *ngIf="productForm.controls['netWeight'].errors.required">
                    Net Weight is required.
                  </div>
            </div>
            </div>
          </div>

          

          <!---------------------------------------   Description Of Goods ---------------------------------------------------------->

          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block  text-white text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
               Description Of Goods*
              </label>
              <textarea
            type="text"
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            rows="4"
            placeholder="Description Of Goods" id="descriptionOfGoods" formControlName="descriptionOfGoods"

          >
               
            </textarea>
            </div>
            
            <div class="text-xs" style="color: red;">
              <div *ngIf="productForm.controls['descriptionOfGoods'].invalid && (productForm.controls['descriptionOfGoods'].dirty || productForm.controls['descriptionOfGoods'].touched)" class="text-danger-filed fs-13 mt-1">
               
                <div *ngIf="productForm.controls['descriptionOfGoods'].errors.required">
                  Description Of Goods is required.
                </div>
              </div>
                  
            </div>
          </div>
            <!---------------------------------------      Total Price* ---------------------------------------------------------->

         
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block text-white  text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                Total Price*
                </label>
                <input
                  type="number"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  formControlName="totalPrice" placeholder="Total Price"
                />
              </div>
              <div class="text-xs" style="color: rgb(235, 82, 82);">
                <div *ngIf="productForm.controls['totalPrice'].invalid && (productForm.controls['totalPrice'].dirty || productForm.controls['totalPrice'].touched)">            
                  <div *ngIf="productForm.controls['totalPrice'].errors.required">
                    Total Price is required.
                    </div>
              </div>
              </div>
            </div>
        
        </div>

      </form>
</mat-dialog-content>
<mat-dialog-actions  >

    
      
          <div style="display: flex;justify-content: center;">
            <button 
    mat-button mat-dialog-close
    class="bg-blueGray-400 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
    style="background-color: rgb(136, 138, 141);"       
    >Cancel
</button>
  
  
  <button 
  mat-button 
  class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
  (click)="save()"
  
  >Save</button>
        </div>

        
  
    



</mat-dialog-actions>