<app-auth-navbar></app-auth-navbar>
<main class="profile-page">
    <section class="relative block h-500-px">
      <div
        class="absolute top-0 w-full h-full bg-center bg-cover"
        style="
          background-image: url('assets/images/slider/comp.jpg');
        "
      >
        <span
          id="blackOverlay"
          class="w-full h-full absolute opacity-50 bg-black"
        ></span>
      </div>
    
    </section>
    <section class="relative py-16 bg-blueGray-800">
        <div class="container mx-auto px-4">
          <div class="relative flex flex-col min-w-0 break-words  w-full mb-6 shadow-xl rounded-lg -mt-64" style="opacity: 0.9; background-color: rgb(241, 242, 248);">
            <div class="w-full lg:w-6/12 px-6 py-6" style="height: 100%;width: 100%;">
  
                <!--------------------------------------------------------------------->
    
                        <div class="px-6">
                          
                       
                          <div class="  text-center" >
                            <div class="flex flex-wrap justify-center" >
                              <div class="w-full lg:w-9/12 px-4" style="width: 100%; height: 100%;">
                                <div class="flex flex-wrap mt-6" >
                                  <div class="w-full mb-24 px-4" >



                                    <!------------------------------------------------------>


                                    <div class="flex-auto px-4 lg:px-10 py-10 pt-0 text-left">
                                        <form  action="#" method="post" [formGroup]="listDetails" novalidate>
                                        
                    
                                          <div class="flex flex-wrap">
                                            <!---------------------------------------     rne ---------------------------------------------------------->
                    
                                            <div class="w-full lg:w-6/12 px-4">
                                              <div class="relative w-full mb-3">
                                                <label
                                                  class="block  text-blueGray-600 text-xs font-bold mb-2"
                                                  htmlFor="grid-password"
                                                >
                                                {{ 'registerCode' | translate }}
                                                </label>
                                                <input
                                                  type="text"
                                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                  formControlName="rne" placeholder="RNE "
                                                />
                                               
                                             
                                              </div>
                                              <div class="text-xs" style="color: red;">
                                                <div *ngIf="list_step && listDetails.controls.rne.errors && listDetails.controls.rne.dirty || listDetails.controls.rne.touched">
                                                  <div *ngIf="list.rne.errors?.required">{{ 'rneRequired' | translate }}</div>
                                              </div>
                                              </div>
                                            </div>
                                            <!--------------------------------------------------------------------->
                                            
                                            <!---------------------------------------     country ---------------------------------------------------------->
                    
                                            <div class="w-full lg:w-6/12 px-4">
                                                <div class="relative w-full mb-3">
                                                  <label
                                                    class="block  text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                  >
                                                  {{ 'COUNTRY' | translate }}
                                                  </label>
                                                  <select 
                                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                  id="country" formControlName="country"
                                                  >
                                                    
                                                  <option selected value="" disabled>  {{ 'COUNTRY' | translate }}</option>
                                                  <option *ngFor="let country of countryList" value={{country}}>{{country}}</option>
                                                  </select>
                                                </div>
                                                <div class="text-xs" style="color: red;">
                                                  <div *ngIf="list_step && listDetails.controls.country.errors && listDetails.controls.country.dirty || listDetails.controls.country.touched">
                                                    <div *ngIf="list.country.errors?.required"> Country is required</div>
                                                </div>
                                                </div>
                                              </div>
                                              <!--------------------------------------------------------------------->
                                            <!---------------------------------------     startDate ---------------------------------------------------------->
                    
                                            <div class="w-full lg:w-4/12 px-4">
                                              <div class="relative w-full mb-3">
                                                <label
                                                  class="block  text-blueGray-600 text-xs font-bold mb-2"
                                                  htmlFor="grid-password"
                                                >
                                                {{ 'START_DATE' | translate }}
                                                </label>
                                                <input
                                                  type="text"
                                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                  onfocus="(this.type='date')" formControlName="startDate" placeholder="Start Date"
                                                />
                                              </div>
                                              

                                              
                                            </div>
                                            
                                            <!---------------------------------------     endDate ---------------------------------------------------------->
                    
                                            <div class="w-full lg:w-4/12 px-4">
                                              <div class="relative w-full mb-3">
                                                <label
                                                  class="block  text-blueGray-600 text-xs font-bold mb-2"
                                                  htmlFor="grid-password"
                                                >
                                                {{ 'END_DATE' | translate }}
                                                </label>
                                                <input
                                                  type="text"
                                                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                  onfocus="(this.type='date')" formControlName="endDate" placeholder="End Date "
                                                />
                                              </div>
                                             
                                            </div>
                                            <!--------------------------------------------------------------------->

                                         




                                            <!--------------------------------------------------------------------->
                                         
                                            <div class="w-full lg:w-4/12 px-4">
                                                <div class="relative w-full mb-3">
                                                  <label
                                                    class="block  text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                  >
                                                  {{ 'company' | translate }}
                                                  </label>
                                                  <input
                                                    type="text"
                                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    formControlName="company" name="company" placeholder="Company"
                                                  />
                                                </div>
                                                <div class="text-xs" style="color: red;">
                                                  <div *ngIf="list_step && listDetails.controls.company.errors && listDetails.controls.company.dirty || listDetails.controls.company.touched">
                                                    <div *ngIf="list.company.errors?.required"> Company is required</div>
                                                </div>
                                                </div>
                                              </div>
                                </div>
                    
                                          
                    
                                  
                                  <!--------------------------------------------------------------->
                    
                                  

                                 

                                  <div  style="display: flex; justify-content: center;" >

                                    <button
                                  class="bg-red-600 text-white active:bg-red-700 font-bold  text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                  type="button"
                                  [ngStyle]="{
                                    'background-color':
                                    listDetails.pristine || listDetails.invalid ? 'gray' :'#0d6efd'  , 'border-color': listDetails.pristine || listDetails.invalid ? 'gray' :'#0d6efd', 'padding': '12px'
                                  }"
                                  (click)="add()"
                                  [disabled]="listDetails.pristine || listDetails.invalid "
                                >
                               {{ 'add' | translate }}
                                </button>
                                <button
                                  class="bg-red-600 text-white active:bg-red-700 font-bold  text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                  type="button"
                                  *ngIf="willbeUpdated"
                                  [ngStyle]="{
                                    'background-color':
                                    listDetails.pristine || listDetails.invalid ? 'gray' :'#14B8A6'  , 'border-color': listDetails.pristine || listDetails.invalid ? 'gray' :'#14B8A6', 'padding': '12px'
                                  }"
                                  (click)="update()"
                                  [disabled]="listDetails.pristine || listDetails.invalid "
                                >
                                update
                                </button>
                                  </div>




                                        </form>
                                      </div>

                                     <!---------------------------------------------------------------------->

                <div class="block w-full overflow-x-auto" >
                    <!-- Projects table -->
                    <table class="items-center w-full bg-transparent border-collapse" >
                      <thead>
                        <tr>
                         
                
                          
                
                          <th
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            [ngClass]="
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-red-800 text-red-300 border-red-700'
                            "
                          >
                            RNE 
                          </th>
                          <th
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            [ngClass]="
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-red-800 text-red-300 border-red-700'
                            "
                          >
                          
                          Start Date
                          </th>
                          <th
                          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          [ngClass]="
                            color === 'light'
                              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                              : 'bg-red-800 text-red-300 border-red-700'
                          "
                        >
                        End Date
                
                       
                      </th>
                
                          
                          <th
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            [ngClass]="
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-red-800 text-red-300 border-red-700'
                            "
                          >
                           Country
                          </th>
                          <th
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            [ngClass]="
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-red-800 text-red-300 border-red-700'
                            "
                          >
                           Company
                          </th>
                        <th
                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                        [ngClass]="
                          color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-red-800 text-red-300 border-red-700'
                        "
                      >
                      :
                      </th>
                
                
                
                
                          <th
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            [ngClass]="
                              color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-red-800 text-red-300 border-red-700'
                            "
                          ></th>
                        </tr>
                      </thead>
                      <tbody *ngFor="
                      let item of items | paginate : {
                              itemsPerPage: pageSize,
                              currentPage: page,
                              totalItems: count
                            };
                      let i = index
                    "
                    [class.active]="i == currentIndex"
                        (click)="setActiveCompany(item, i)"
                    
                    
                    >
                        
                        <tr>
                         
                
                         
                          
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                          {{item.rne}}
                          </td>
                
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                            
                          >
                          
                            {{item.startDate}}

                          
                          
                          </td>
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                          {{item.endDate}}
                          </td>
                          
                          
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                          {{item.country}}
                          </td>
                         
                
                          
                          <td
                          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                        >
                        {{item.company?.raisonSocial}}
                        </td>
                          
                
                
                
                          <td
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"
                            
                          >
                          
                          
                          
                          
                         
                          <a class="cursor-pointer"  (click)="deleteSelectedItem(item.id)"><img   src="assets/images/icons/delete.png" alt="delete" style="width: 1cm; height:  1cm;float: left;"> </a>
                          
                
                
                
                          </td>
                        </tr>
                       
                         
                             
                        
                      </tbody>
                
                      
                    </table>
                  </div>

                
           
                  <div class="list row">


                    <div class="col-md-12">
                      <pagination-controls
                        previousLabel="Prev"
                        nextLabel="Next"
                        [responsive]="true"
                        (pageChange)="handlePageChange($event)"
                      ></pagination-controls>
                    </div>
                  </div>
    
    
    
                <!---------------------------------------------------------------------->
                
                 

                  
                                <!------------------------------------------------------->
                              
                            </div>
                          </div>
                          
                        </div>

                        
                      </div>
                    </div>
                </div>


               
                
            </div>
          </div>
        </div>
      </section>




    </main>
    <app-footer></app-footer>
 
