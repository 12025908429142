<app-navbarg></app-navbarg>
<section class="login p-fixed d-flex text-center bg-primary common-img-bg responsive" *ngIf="step1">
  <!-- starts -->

  <div class="auth-fluid">
    <div class="row">
      <div class="col-sm-12">
        <!-- Authentication card start -->
        <div class="login-card card-body auth-body" >     
     
          <main  [ngClass]="{'hero1': lang === 'ab', 'hero': lang !== 'ab'}">
        
            <div class="text-center" style="display: flex; justify-content: center; margin-top: 20px;">
            
              <!-- <img src="assets/images/auth/logo-dark.png" alt="TSN" style="height: 7%;width: 7%;">-->
            </div><br><br><br>
            <div class="flex flex-wrap" style="display: flex; justify-content: center;">
              <div class="w-full lg:w-4/12 px-4" >
                
                
                <div
                [ngClass]="{'globale relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg  border-0  ': lang === 'ab', 'globale3 relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg  border-0  ': lang !== 'ab'}"

                style="opacity: 0.95;background-color: rgb(241, 242, 248);"
                
              >
                <div class="rounded-t  mb-0 px-6 py-6 " >
                  <div class="text-center flex justify-between">
                    <h5 [ngClass]="{'text-arabic-login': lang === 'ab', 'text-blueGray-700 text-xl font-bold titles': lang !== 'ab'}" > 
                      {{ 'account_data2' | translate }}</h5>
                    
                  </div>
                  <div [ngClass]="{'text-left': lang === 'ab', 'text-right': lang !== 'ab'}"  >
                    <a [routerLink]="['/signup']" class="text-blueGray-400 text-sm mt-3 mb-6 font-bold " style="text-decoration: underline;">  Create new account</a>
                  </div>
                </div>
                <div class="flex-auto px-4 lg:px-10 py-10 pt-0 text-left">
                  <form  action="#" method="post" [formGroup]="angForm" novalidate>
                  

              
                    <h5 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase Subtitles"
                    [ngClass]="{'text-arabic-login text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase Subtitles': lang === 'ab', 'text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase Subtitles': lang !== 'ab'}">
                      <i class="fas fa-key"></i> {{ 'account_data' | translate }}
                    </h5>
                    <div class="flex flex-wrap">
                      <!---------------------------------------     Email ---------------------------------------------------------->

                      <div class="w-full lg:w-12/12 px-4">
                        <div class="relative w-full mb-3">
                          <div [ngClass]="{'title-input': lang === 'ab', 'title-input2': lang !== 'ab'}">
                            <label
                              class=""
                              [ngClass]="{'text-arabic-login': lang === 'ab', 'block  text-blueGray-600 text-xs font-bold mb-2': lang !== 'ab'}"
                              htmlFor="grid-password"
                            >
                            {{ 'username' | translate }}
                            </label>
                          </div>

                          <input
                            type="text"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="{{ 'username' | translate }}" id="email" formControlName="email"
                          />
                        </div>
                        
                        <div class="text-xs" style="color: red;">
                          <div *ngIf="angForm.controls['email'].invalid && (angForm.controls['email'].dirty || angForm.controls['email'].touched)" class="text-danger-filed fs-13 mt-1">
                            <div *ngIf="angForm.controls['email'].errors.required" [ngClass]="{'text-arabic-login': lang === 'ab', '': lang !== 'ab'}">
                              {{ 'required-acid' | translate }}
                            </div>
                           
                          </div>
                              
                        </div>
                      </div>


                    
                      <!-----------------------------------   Password -------------------------------------------------------------->

                      <div class="w-full lg:w-12/12 px-4">
                        <div class="relative w-full mb-3">
                          <div [ngClass]="{'title-input': lang === 'ab', 'title-input2': lang !== 'ab'}">
                          <label
                          [ngClass]="{'text-arabic-login': lang === 'ab', 'block  text-blueGray-600 text-xs font-bold mb-2': lang !== 'ab'}"
                          htmlFor="grid-password"
                          [ngClass]="{'text-arabic-login': lang === 'ab', '': lang !== 'ab'}">
                          {{ 'password' | translate }}
                          </label></div>
                          <input type="password"   #inputPassword
                          class="input-group border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="{{ 'password' | translate }}" formControlName="password"  id="password" required="true"
                          >
                          <!--span class="input-group-text">
                            <i class="fa" style="color: black;" [ngClass]="{'fa-eye-slash': toggleEye, 'fa-eye': !toggleEye}" (click)="toggleEyeIcon(inputPassword)"></i>
                          </span-->
                          
                        </div>

                        <div class="text-xs" style="color: red;">
                          <div *ngIf="angForm.controls['password'].invalid && (angForm.controls['password'].dirty || angForm.controls['password'].touched)" class="text-danger-filed fs-13 mt-1">
                        
                            <div *ngIf="angForm.controls['password'].errors.required" [ngClass]="{'text-arabic-login': lang === 'ab', '': lang !== 'ab'}">
                              {{ 'required-acid' | translate }}
                            </div>
                            </div>	
                              
                        </div>

                      </div>



                      
                    </div>

                    <!------------------------------------------RECAPTCHA----------------------->
                    <hr class="mt-6 border-b-1 border-blueGray-300" />    
                    <br> 
            
                    <div class="w-full lg:w-12/12 px-4">
                      <div class="relative w-full mb-3">
                <re-captcha formControlName="recaptcha" siteKey="6LeJQtYqAAAAALZpP8KadG2yw5AN3Fmc2SUroi06" class="w-full lg:w-4/6 px-4"></re-captcha>
                <div *ngIf="angForm.controls['recaptcha'].invalid && (angForm.controls['recaptcha'].dirty || angForm.controls['recaptcha'].touched)" class="invalid-feedback">
                  <div *ngIf="angForm.controls['recaptcha'].errors?.['required']">{{ 'recaptcha_required' | translate }}</div>
                </div>
              </div>
            </div>

            <!--------------------------------------------------------------->

            <div class="row m-t-25 text-left">
              <div class="col-md-12">
                <!--div class="checkbox-fade fade-in-primary" >
                  <label>
                    <input type="checkbox" value="">
                    <span class="cr"><i class="cr-icon icofont icofont-ui-check txt-primary"></i></span>
                    <span class="text-inverse">I read and accept <a href="javascript:;">Terms &amp; Conditions.</a></span>
                  </label>
                </div-->
                

              </div>

              
              
            </div>
            <!--------------------------------------------------------------->

                    <div  style="display: flex; justify-content: center;">

                      <button
                    class="bg-red-600 text-white active:bg-red-700 font-bold  text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    
                    type="button"
                    [ngClass]="{'btn_login': button_load === false, 'btn_login1': button_load === true }"
                    [ngStyle]="{
                      'background-color':
                      angForm.pristine || angForm.invalid ? 'gray' :'#7166F5'  , 'border-color': angForm.pristine || angForm.invalid ? 'gray' :'#0d6efd', 'padding': '12px'
                    }"
                    (click)="onSubmit()"
                    [disabled]="angForm.pristine || angForm.invalid " 
                  >
                  <span [ngClass]="{'text-arabic-login': lang === 'ab', 'spi': lang !== 'ab'}">
                  {{ text_load }}
                  </span>
                  </button>
                    </div>
                    <br>
<!--
                    <div class="row">
                      <div class="col-md-10">
                        <p class="text-inverse text-left m-b-0 text-sm" style="color: rgb(46, 44, 44);">{{ 'thank_you' | translate }}</p>
                        <p class="text-inverse text-left text-sm" style="color: rgb(46, 44, 44);"><b>{{ 'mss_consulting' | translate }}</b></p>
                      </div>
                      
                    </div>-->

                    
                  </form>
                </div>
              </div>
              

                
              </div>
            </div>
      
          </main>
    
       
        </div>
<!--
        <footer class="footer-section">
          <p>Connect with us:</p>
          <a href="#">Facebook</a> | <a href="#">Twitter</a> | <a href="#">LinkedIn</a>
        </footer>-->

            

     
      </div>

    </div>
  
  </div>
</section>


<section class="otp" *ngIf="step2">
  <div class="globale-otp">
    <div class="image">
      <img src="../../assets/images/verif2.png" alt="">
    </div>
    <span>Enter your OTP code (Check your email)</span>
    <p><a href="">Resend the code</a></p>
    <form (ngSubmit)="onVerify2FACode()">
      <input 
        type="text" 
        placeholder="Enter your code"
        [(ngModel)]="otpCode"  
        name="otpCode"          
        required
      >
      <div class="btn-footer">
        <button type="submit" class="submit-btn">
          Verify &ensp;<i class="fas fa-paper-plane"></i>
        </button>
      </div>
    </form>
  </div>
</section>





