<a
  class="lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
  href="#pablo"
  (click)="toggleDropdown($event)"
  #btnDropdownRef
  style="
    padding: 3px;"
>
<i

class="lg:text-blueGray-200 text-blueGray-400 fas fa-user-cog text-lg leading-lg"

></i>
<span class="lg:hidden inline-block ml-2">Settings</span>
</a>





<div
  class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
  [ngClass]="dropdownPopoverShow ? 'block' : 'hidden'"
  #popoverDropdownRef
>
  
  <a
  [routerLink]="['/profile']"
    class="text-sm pt-2 pb-0 px-4 uppercase block w-full whitespace-nowrap bg-transparent text-blueGray-400"
    *ngIf="isImporter || isMember"
  >
    Profile
  </a>

  <a
  [routerLink]="['/password']"
    class="text-sm pt-2 pb-0 px-4 uppercase block w-full whitespace-nowrap bg-transparent text-blueGray-400 cursor-pointer"
  >
    Change password
  </a>
  
  <a
  (click)="onSubmit()"
    class="text-sm pt-2 pb-0 px-4 uppercase block w-full whitespace-nowrap bg-transparent text-blueGray-400 cursor-pointer"
  >
    Logout
  </a>
</div>









