import { Company } from "./Company";
import { Ministry } from "./Ministry";
import { Role } from "./Role";

export class User {
   
    user_id?: number;
    firstName?: string;
    lastName?: string;
    dateOfBirth?: Date;
    gender?: string;
    address?: string;
    country?: string;
    phoneNumber?: string;
    username?: string;
    email?: string;
    password?: string;
    jwt?: string;
    activated?:boolean;
    team_name?:string;
    typeofIdentification?:string;
    expiryDate?:Date;
    nationalID?:string;
    manager?:User;
    companyId?:Company;
    roles?:Role[];
    role?:String[];
    ministry?:Ministry
    
  }