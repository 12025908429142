import { Company } from "./Company";


export class BlackList {

    id?:number
    rne:string;
    startDate:Date;
    endDate:Date
    country:string;
    company: Company;

    
 }


